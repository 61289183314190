import PropTypes from 'prop-types'
import isEmpty from 'lodash.isempty'

/* helpers */
import updateObject from 'state-manager/reducers/helpers/update-object'

/* constants */
import { CHAT_ACTIONS } from 'state-manager/constants'

const initialState = {
  chatInfo: {},
  messages: [],
  messageQueue: [],
  isStopUpdateMessages: false,
  isChatEnabled: false,
}

export const types = {
  chatInfo: PropTypes.object,
  messages: PropTypes.array,
  messageQueue: PropTypes.array,
  isStopUpdateMessages: PropTypes.bool,
  isChatEnabled: PropTypes.bool,
}

const maxMessagesCount = 29

export default (state = initialState, action) => {
  switch (action.type) {

    case CHAT_ACTIONS.GET_INFO.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CHAT_ACTIONS.GET_INFO.SUCCESS:
      return updateObject(state, {
        chatInfo: action.payload.chatInfo,

        responseWaiting: false,
      })

    case CHAT_ACTIONS.GET_INFO.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CHAT_ACTIONS.GET_MESSAGE_WS.SUCCESS:
      const updateMessages = [...state.messages]
      const updateMessageQueue = [...state.messageQueue]

      if (state.isStopUpdateMessages) {
        if (updateMessageQueue.length > maxMessagesCount) {
          updateMessageQueue.shift()
        }
        updateMessageQueue.push(action.payload.message)

        return updateObject(state, {
          messageQueue: updateMessageQueue,
        })
      }

      if (updateMessages.length > maxMessagesCount) {
        updateMessages.shift()
      }
      updateMessages.push(action.payload.message)

      return updateObject(state, {
        messages: updateMessages,
      })

    case CHAT_ACTIONS.PUSH_QUEUE.SYSTEM:
      const queue = [...state.messageQueue]
      const updateMessagesFromQueue = [...state.messages]

      if (queue.length > 0) {
        updateMessagesFromQueue.push(...queue)
        if (updateMessagesFromQueue.length + queue.length > maxMessagesCount) {
          for (let i = 2; i < (updateMessagesFromQueue.length + queue.length) - maxMessagesCount; i++) {
            updateMessagesFromQueue.shift()
          }
        }
      }

      return updateObject(state, {
        messages: updateMessagesFromQueue,
        messageQueue: [],
      })

    case CHAT_ACTIONS.STOP_UPDATE.SYSTEM:
      return updateObject(state, {
        isStopUpdateMessages: true,
      })

    case CHAT_ACTIONS.START_UPDATE.SYSTEM:
      return updateObject(state, {
        isStopUpdateMessages: false,
      })

    case CHAT_ACTIONS.CHAT_CLEAR.SYSTEM:
      return updateObject(state, {
        messages: [],
        messageQueue: [],
      })

    case CHAT_ACTIONS.CHAT_ENABLE.SYSTEM:
      return updateObject(state, {
        isChatEnabled: true,
      })

    case CHAT_ACTIONS.MESSAGE_DELETED_CHANGE.SYSTEM:
      const updateIfExistDeletedMessage = ({ stateItem, messageItem }) => {
        if (!isEmpty(stateItem)) {
          const currentStateItem = [...stateItem]
          const deletedIndex = currentStateItem.findIndex((item) => item.message.id === messageItem.id)

          if (deletedIndex >= 0) {
            currentStateItem[deletedIndex].message.body = 'message deleted by a moderator'

            return currentStateItem
          }
        }

        return null
      }

      const updatedMessage = updateIfExistDeletedMessage({
        stateItem: state.messages,
        messageItem: action.payload.message,
      })

      const updateDeletedInMessageQueue = updateIfExistDeletedMessage({
        stateItem: state.messageQueue,
        messageItem: action.payload.message,
      })

      if (updatedMessage || updateDeletedInMessageQueue) {
        return updateObject(state, {
          messages: updatedMessage || state.messages,
          messageQueue: updateDeletedInMessageQueue || state.messageQueue,
        })
      }

      return state

    default:
      return state
  }
}
