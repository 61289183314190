import React from 'react'
import { history } from 'state-manager/store'
import routes from 'routes'
import { useLocation } from 'react-router-dom'
import SpriteIcon from '../icons/SpriteIcon'

const MainSideBar = () => {
  const location = useLocation()

  return (
    <aside className="homepage__sidebar">
      <div
        role="button"
        onClick={() => history.push(routes.homepage)}
        className={`item ${location.pathname === routes.homepage && 'active'}`}>
        <SpriteIcon
          name="for-you"
          size="md"
        />
        <span className="item__label">Home</span>
      </div>
      <div
        role="button"
        onClick={() => history.push(routes.uxResearch)}
        className={`item ${location.pathname === routes.uxResearch && 'active'}`}>
        <SpriteIcon
          name="for-you"
          size="md"
        />
        <span className="item__label">UX Research</span>
      </div>
    </aside>
  )
}

export default MainSideBar
