import React from 'react'
import * as PropTypes from 'prop-types'
import Slider from 'react-slick'

/* styles */
import './category.scss'

/* helpers */
import { getSlidesCount } from 'helpers/get-slides-count'

/* assets */
import researchResources from 'assets/images/research_resources.png'

/* components */
import clsx from 'clsx'
import CustomArrow from 'components/ui/CustomArrow'
import CategoryItem from './CategoryItem'
import CategoryBanner from './CategoryBanner'
import ContentCardItem from '../ContentCard/ContentCardItem'

const CategoryItems = ({
  items,
  skipPortraitFilter,
  setModalVisibility,
  getCurrentStory,
  isPortrait,
  disabled,
  isCategoryWithBanner,
  isSlider,
  categoryId,
}) => {
  const renderCategoryItems = () => (
    items.filter((item, key) => {
      if (isCategoryWithBanner && !skipPortraitFilter && item.orientation === 0 && key === 2) {
        return null
      }
      return item
    }).map((item, key) => (
      <CategoryItem
        key={key}
        id={item.id}
        slug={item.slug}
        video={item.preview_link}
        img={item.feature_image}
        photo={item.creator ? item.creator.photo : null}
        creatorId={item.creator ? item.creator.id : null}
        title={item.title}
        viewsCount={item.views_count}
        shortDescription={item.short_description ?? ''}
        speakersInfo={item.speakers_info}
        duration={item.duration}
        currentDuration={item?.current_duration || 0}
        tags={item.tags}
        isPortrait={item.orientation === 0}
        disabled={disabled}
        setModalVisibility={setModalVisibility}
        getCurrentStory={getCurrentStory}
      />
    ))
  )

  return (
    <div className={clsx('wrapper-category', isSlider && 'slider-category', isPortrait && 'thoughts-category')}>
      {isPortrait && isCategoryWithBanner && (
        <CategoryBanner
          url="https://www.thoughts.joinlearners.com"
          img="https://urx-videos.s3.amazonaws.com/51883497/Thought_CTA_Artwork.png"
        />
      )}

      {isSlider ? (
        <Slider
          variableWidth
          dots={false}
          infinite={false}
          speed={500}
          slidesToShow={getSlidesCount(isPortrait ? 5 : 4)}
          slidesToScroll={1}
          arrows
          nextArrow={<CustomArrow />}
          prevArrow={<CustomArrow isPrevArrow />}
          swipe
          responsive={[
            {
              breakpoint: 1399,
              settings: {
                slidesToShow: getSlidesCount(isPortrait ? 4 : 3, false, items.length),
              },
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: getSlidesCount(isPortrait ? 4 : 2, true, items.length),
              },
            },
            {
              breakpoint: 992,
              settings: {
                arrows: false,
                slidesToShow: getSlidesCount(2, true, items.length),
              },
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
                arrows: false,
              },
            },
          ]}>
          {categoryId === 27 && (
            <ContentCardItem
              image={researchResources}
              description="View Our Research Resources, Crowdsourced From The Learners Community"
              link="https://www.researchresources.joinlearners.com/"
              isImageWithMediaPrefix={false}
            />
          )}
          {renderCategoryItems()}
        </Slider>
      ) : (
        renderCategoryItems()
      )}
    </div>
  )
}

CategoryItems.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array,
  setModalVisibility: PropTypes.func.isRequired,
  getCurrentStory: PropTypes.func.isRequired,
  skipPortraitFilter: PropTypes.bool,
  isPortrait: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  isCategoryWithBanner: PropTypes.bool,
  isSlider: PropTypes.bool,
  categoryId: PropTypes.number,
}

CategoryItems.defaultProps = {
  items: null,
  skipPortraitFilter: false,
  isPortrait: false,
  isCategoryWithBanner: false,
  isSlider: false,
  categoryId: 0,
}

export default CategoryItems
