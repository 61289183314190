import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { formatRoute } from 'react-router-named-routes'
import { useParams } from 'react-router-dom'

/* components */
import MainSideBar from 'components/MainSideBar'
import Categories from 'components/ui/Categories'
import Share from 'components/ui/Share'
import SpriteIcon from 'components/icons/SpriteIcon'

/* state-manager */
import { history } from 'state-manager/store'
import { getSingleQuestion } from 'state-manager/actions/content'

/* utils */
import customEvent from 'utils/custom-event'

/* helpers */
import checkIsMobileDevice from 'helpers/check-is-mobile-device'
import getUrlByMediaPrefix from 'helpers/get-url-by-media-prefix'

/* hooks */
import _useDidMount from 'hooks/lifecycle/use-did-mount'

/* constants */
import { TOGGLE__VISIBILITY_DOWNLOAD_APP_MODAL } from 'constants/custom-events'

/* routes */
import routes from 'routes'

/* assets */
import askQuestion from 'assets/images/ask-question.png'
import avatar from 'assets/images/default_avatar.png'

import classes from './QuestionPage.module.scss'

const QuestionPage = ({ currentQuestionData, getSingleQuestion }) => {
  const params = useParams()

  _useDidMount(() => {
    getSingleQuestion(params.slug)
  })

  if (!currentQuestionData) {
    return null
  }

  const handleAuthorClick = () => {
    const detailsPagePath = formatRoute(routes.creator, { slug: currentQuestionData.user.slug })
    history.push(detailsPagePath)
  }

  return (
    <div className="homepage--light creator-page-wrapper">
      <div className="homepage">
        <div className="homepage__wrapper">
          <div className="container">
            <div className="homepage__inner">
              <MainSideBar />
              <div className="creator-page-content">
                <div className={classes.questionPageHeader}>
                  <h3 className={classes.questionTitle}>{currentQuestionData.question}</h3>
                  {checkIsMobileDevice() && (
                    currentQuestionData.user ? (
                      <div className={classes.questionAuthor} onClick={handleAuthorClick} role="button">
                        <img src={currentQuestionData?.user.photo ? getUrlByMediaPrefix.photo(currentQuestionData?.user.photo, currentQuestionData?.user.id) : avatar} alt="avatar" className={classes.authorAvatar} />
                        <div className={classes.authorInfo}>
                          {`${currentQuestionData.user.first_name} ${currentQuestionData.user.last_name}`}
                          <div>
                            {currentQuestionData.user.work_role} at
                          </div>
                          <div className={classes.authorInfo__workCompany}>
                            {currentQuestionData.user && currentQuestionData.user.company}
                          </div>
                        </div>
                      </div>
                    ) :
                      (
                        <div className={classes.questionAuthor}>
                          <img src={askQuestion} alt="avatar" className={classes.authorAvatar} />
                          <div className={classes.authorInfo}>
                            Question submitted anonymously
                          </div>
                        </div>
                      )

                  )}
                  <div
                    className={classes.questionBtnWrapper}>
                    <div
                      className={classes.questionViews}
                      onClick={() => customEvent.trigger(TOGGLE__VISIBILITY_DOWNLOAD_APP_MODAL)}
                      role="button">
                      <SpriteIcon name="triangle-top" />
                      {currentQuestionData.question_vote_count}
                    </div>
                    <div
                      className={classes.questionRespondBtn}
                      onClick={() => customEvent.trigger(TOGGLE__VISIBILITY_DOWNLOAD_APP_MODAL)}
                      role="button">
                      <img src={askQuestion} alt="askQuestion" className={classes.askQuestion} />
                      Respond
                    </div>
                    <div className={clsx(classes.questionRespondBtn, classes.questionShareBtn)}>
                      <Share
                        slug={currentQuestionData.slug}
                        title={currentQuestionData ? currentQuestionData.question : ''}
                        isOnlyIcon
                        isQuestion
                        placement="bottom"
                        queryId={currentQuestionData.id}
                      />
                    </div>
                  </div>
                </div>
                {!checkIsMobileDevice() && (
                  currentQuestionData.user ? (
                    <div className={classes.questionAuthor} onClick={handleAuthorClick} role="button">
                      <img src={currentQuestionData?.user.photo ? getUrlByMediaPrefix.photo(currentQuestionData?.user.photo, currentQuestionData?.user.id) : avatar} alt="avatar" className={classes.authorAvatar} />
                      <div className={classes.authorInfo}>
                        {`${currentQuestionData.user.first_name} ${currentQuestionData.user.last_name}`}
                        <div>
                          {`${currentQuestionData.user.work_role} at `}
                          <span className={classes.authorInfo__workCompany}>
                            {currentQuestionData.user.company}
                          </span>
                        </div>

                      </div>
                    </div>
                  ) :
                    (
                      <div className={classes.questionAuthor}>
                        <img src={askQuestion} alt="avatar" className={classes.authorAvatar} />
                        <div className={classes.authorInfo}>
                          Question submitted anonymously
                        </div>
                      </div>
                    )

                )}
                {checkIsMobileDevice() ? (
                  <div className={classes.tabMenu}>
                    <SpriteIcon
                      name="tab-videos"
                      size="md"
                    />
                  </div>
                ) : (
                  <div className={classes.responsesCount}>
                    <img src={askQuestion} alt="askQuestion" className={classes.askQuestion} />
                    {currentQuestionData.answers_count}
                    {currentQuestionData.answers_count === 1 ? ' Response' : ' Responses'}
                  </div>
                )}
                {currentQuestionData.podcasts.length && (
                  <div className={classes.userVideos}>
                    <Categories
                      disabled={false}
                      categories={[{
                        podcasts: currentQuestionData.podcasts,
                      }]}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>

  )
}

QuestionPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentQuestionData: PropTypes.object,
  getSingleQuestion: PropTypes.func.isRequired,
}

QuestionPage.defaultProps = {
  currentQuestionData: {},
}

const mapStateToProps = (state) => ({
  currentQuestionData: state.content.singleQuestion,
})

const actionsToProps = {
  getSingleQuestion,
}
export default connect(mapStateToProps, actionsToProps)(QuestionPage)
