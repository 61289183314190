import { toast } from 'react-toastify'

/* env */
import { ENV } from 'constants/main'

/* utils */
import reqH from 'utils/request-handler'

/* constants */
import { USER_ACTIONS } from 'state-manager/constants'

export const getUserData = (additionalUserData = {}) => (dispatch) => {
  dispatch({ type: USER_ACTIONS.DATA_GET.ATTEMPT })

  return reqH({
    method: 'GET',
    url: 'user/me',
    urlPrefix: 'api',
    skipUnauthorizedNotification: true,
  })
    .then((res) => {
      const userData = {
        ...additionalUserData,
        ...res.data.data,
      }

      /* Send analytics */
      if (ENV === 'production') {
        window.dataLayer.push({
          event: 'user',
          logged_in: true,
          email: userData.email,
          userId: userData.id,
          name: `${userData.first_name} ${userData.last_name}`,
        })
      }

      dispatch({
        type: USER_ACTIONS.DATA_GET.SUCCESS,
        payload: userData,
      })

      return userData
    })
    .catch(() => {
      dispatch({ type: USER_ACTIONS.DATA_GET.ERROR })
    })
}

export const updateUserData = (data) => (dispatch) => {
  const updateUserProfileData = {
    handle: data.handle,
    first_name: data.firstName,
    last_name: data.lastName,
    discipline_id: data.jobTitle.value,
    company: data.company,
    country: data.country,
    city: data.city,
  }

  reqH({
    method: 'POST',
    url: 'user/profile/update',
    urlPrefix: 'api',
    sendMethod: 'form-data',
    shouldUpdateUserData: true,
    data: data.photo ? { ...updateUserProfileData, photo: data.photo } : updateUserProfileData,
  })
    .then(() => {
      dispatch({ type: USER_ACTIONS.DATA_UPDATE.SYSTEM })
      toast.success('Profile has been successfully updated!')
    })
}

export const sendEmailGetLink = (data) => {
  reqH({
    method: 'POST',
    url: 'send-get-app-email',
    urlPrefix: 'api',
    sendMethod: 'form-data',
    data,
  })
    .then(() => {
      toast.success('Link has been sent to your email!')
    })
    .catch((error) => {
      if (error.response.status === 429) {
        toast.warn('Too many requests!')
      }
    })
}

export const sendEmailToEventList = (data) => {
  reqH({
    method: 'POST',
    url: 'research/add-user-to-list',
    urlPrefix: 'api',
    sendMethod: 'form-data',
    data,
  })
    .then(() => {
      toast.success('You’ve been successfully added to the mailing list.')
    })
    .catch((error) => {
      if (error.response.status === 429) {
        toast.warn('Too many requests!')
      }
    })
}

export const updateSignUpTimer = () => (dispatch) => {
  dispatch({ type: USER_ACTIONS.TIMER_UPDATE.SYSTEM })
}

export const clearSignUpTimer = () => (dispatch) => {
  dispatch({ type: USER_ACTIONS.TIMER_CLEAR.SYSTEM })
}

export const agreeWithCodeOfConduct = () => (dispatch) => {
  dispatch({ type: USER_ACTIONS.AGREE_WITH_CHAT_RULES.SYSTEM })
}

export const setMobileBannerLastClosed = (date) => (dispatch) => {
  dispatch({
    type: USER_ACTIONS.LAST_CLOSED_BANNER_SET.SUCCESS,
    payload: { date },
  })
}
