import * as Yup from 'yup'
import { useFormik } from 'formik'

import { formFields } from './constants'

export default ({
  onSubmit,
  email,
}) => useFormik({
  initialValues: {
    [formFields.email]: email,
    [formFields.password]: '',
  },

  validationSchema: Yup.object({
    [formFields.email]: Yup.string()
      .label('Email address')
      .email()
      .required(),

    [formFields.password]: Yup.string()
      .label('Password')
      .required(),
  }),

  onSubmit(values) {
    onSubmit(values)
  },
})
