export default {
  // unauthorized
  // signIn: ['/', '/login', '/signin', '/sign-in'],
  // signUp: ['/register', '/sign-up', '/signup', '/register-with-email/:email'],
  // forgotPassword: '/forgot-password',
  // resetPassword: '/reset-password/:token',

  // authorized
  homepage: '/',
  oldHomepage: '/old-homepage',
  resources: '/recordings', // old homepage
  uxResearch: '/ux-research',
  talkDetails: '/talk/:slug',
  thoughtDetails: '/thought/:slug',
  singleCategory: '/category/:slug',
  series: '/series/:slug',
  bookmarks: '/bookmarks',
  stream: '/live/:slug',
  creator: '/creator/:slug',
  questionPage: '/question/:slug',

  selectSubscription: '/subscriptions',
  billing: '/billing',
  billingSummary: '/billing-summary',

  manageProfileDetails: '/manage-account/profile-details',
  manageBillingAndSubscription: '/manage-account/billing-and-subscriptions',
  manageSettings: '/manage-account/settings',

  // shared
  termsOfService: '/terms-of-service',
  privacyPolicy: '/privacy-policy',

  // error page
  errorPage: '/404',
}
