import { REACT_APP_DOMAIN } from 'constants/main'
import { MEDIA_PREFIX } from 'constants/media-prefix'

const protocol = `${window.location.protocol}//`

const getUrlByMediaPrefix = {
  image: {
    original: (mediaUrl, id) => `${protocol}${MEDIA_PREFIX.IMAGES}.${REACT_APP_DOMAIN}/podcasts/images/${id}/${mediaUrl}`,
    small: (mediaUrl, id) => `${protocol}${MEDIA_PREFIX.IMAGES}.${REACT_APP_DOMAIN}/podcasts/images/${id}/small_${mediaUrl}`,
    middle: (mediaUrl, id) => `${protocol}${MEDIA_PREFIX.IMAGES}.${REACT_APP_DOMAIN}/podcasts/images/${id}/middle_${mediaUrl}`,
    large: (mediaUrl, id) => `${protocol}${MEDIA_PREFIX.IMAGES}.${REACT_APP_DOMAIN}/podcasts/images/${id}/large_${mediaUrl}`,
  },
  video: (mediaUrl) => `${protocol}${MEDIA_PREFIX.VIDEO}.${REACT_APP_DOMAIN}/${mediaUrl}`,
  advisorPhoto: (mediaUrl, id) => `${protocol}${MEDIA_PREFIX.IMAGES}.${REACT_APP_DOMAIN}/advisors/images/${id}/${mediaUrl}`,
  photo: (mediaUrl, id) => `${protocol}${MEDIA_PREFIX.IMAGES}.${REACT_APP_DOMAIN}/users/images/${id}/${mediaUrl}`,
  companyPhoto: (mediaUrl, id) => `${protocol}${MEDIA_PREFIX.IMAGES}.${REACT_APP_DOMAIN}/partners/images/${id}/${mediaUrl}`,
  eventPhoto: (mediaUrl) => `${protocol}${MEDIA_PREFIX.IMAGES}.${REACT_APP_DOMAIN}/event_card/images/${mediaUrl}`,
  jobPhoto: (mediaUrl) => `${protocol}${MEDIA_PREFIX.IMAGES}.${REACT_APP_DOMAIN}/jobs/images/${mediaUrl}`,
  thoughts: (mediaUrl) => `${protocol}${MEDIA_PREFIX.VIDEO}.${REACT_APP_DOMAIN}/${mediaUrl}`,
  subtitles: (mediaUrl) => `${protocol}${MEDIA_PREFIX.SUBTITLES}.${REACT_APP_DOMAIN}/srt/${mediaUrl}`,
  downloadableResource: (mediaUrl) => `${protocol}${MEDIA_PREFIX.RESOURCE}.${REACT_APP_DOMAIN}/uploaded_resources/${mediaUrl}`,
  heroImage: (mediaUrl, id) => `${protocol}${MEDIA_PREFIX.IMAGES}.${REACT_APP_DOMAIN}/events/images/${id}/${mediaUrl}`,
  ads: (mediaUrl) => `${protocol}${MEDIA_PREFIX.IMAGES}.${REACT_APP_DOMAIN}/ads/images/${mediaUrl}`,
  streamPreview: (mediaUrl, id) => `${protocol}${MEDIA_PREFIX.IMAGES}.${REACT_APP_DOMAIN}/streams/images/${id}/large_${mediaUrl}`,
  staticImage: (mediaUrl) => `${protocol}${MEDIA_PREFIX.IMAGES}.${REACT_APP_DOMAIN}/images/${mediaUrl}`,
  contentCard: (mediaUrl, id) => `${protocol}${MEDIA_PREFIX.IMAGES}.${REACT_APP_DOMAIN}/content_cards/images/${id}/${mediaUrl}`,
}

export default getUrlByMediaPrefix
