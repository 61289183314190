export const TOGGLE__VISIBILITY__SPINNER = 'TOGGLE/VISIBILITY/SPINNER'

export const SAVE__BILLING_INFO_FORM = 'SAVE__BILLING_INFO_FORM'

export const NEXT_STEP__FORGOT_PASSWORD = 'NEXT_STEP/FORGOT-PASSWORD'

export const TOGGLE__VISIBILITY_SIGNUP_MODAL = 'TOGGLE/VISIBILITY/SIGNUP_MODAL'

export const SHOW_SIGN_IN_MODAL = 'SHOW/SIGN_IN_MODAL'

export const HIDE_SIGN_IN_MODAL = 'HIDE/SIGN_IN_MODAL'

export const SHOW_FORGOT_PASSWORD_MODAL = 'SHOW_FORGOT_PASSWORD_MODAL'

export const HIDE_FORGOT_PASSWORD_MODAL = 'HIDE_FORGOT_PASSWORD_MODAL'

export const SHOW_CALENDAR_MODAL = 'SHOW_CALENDAR_MODAL'

export const HIDE_CALENDAR_MODAL = 'HIDE_CALENDAR_MODAL'

export const SHOW_NOTIFY_ME_MODAL = 'SHOW_NOTIFY_ME_MODAL'

export const HIDE_NOTIFY_ME_MODAL = 'HIDE_NOTIFY_ME_MODAL'

export const PAUSE_PLAYER = 'PAUSE_PLAYER'

export const TOGGLE__VISIBILITY_DOWNLOAD_APP_MODAL = 'TOGGLE/VISIBILITY/DOWNLOAD_APP_MODAL'

export const SET__DARK_COLOR_THEME = 'SET/DARK/COLOR_THEME'

export const TOGGLE__VISIBILITY_MAIL_LIST_MODAL = 'TOGGLE/VISIBILITY/MAIL_LIST_MODAL'
