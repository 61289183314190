import React from 'react'
import './styles.scss'
import { history } from 'state-manager/store'

import customEvent from 'utils/custom-event'
import { TOGGLE__VISIBILITY_DOWNLOAD_APP_MODAL } from 'constants/custom-events'

import Button from 'components/ui/Button/base'
import SpriteIcon from '../../components/icons/SpriteIcon'

import isMobile from '../../helpers/check-is-mobile-device'
import routes from '../../routes'
import purpleBackground from '../../assets/images/purple_homepage_background.png'

const PurpleHomepage = () => (
  <main className="homepage--purple">
    <div className={`purple-background-wrapper ${isMobile() ? 'mobile' : ''}`}>
      <img
        src={purpleBackground}
        className="purple-background"
        alt="PurpleBackground"
      />
    </div>
    <div className="homepage--purple__wrapper">
      <div className="container">
        <h1 className="purple-main-title">
          Something <span className="blue-highlight">exciting </span>is coming soon
          <SpriteIcon
            name="purple-homepage-smile"
            size="sliderMd"
            colorIcon="#FFFFFF"
            className="purple-title-icon"
          />
        </h1>
        <div className="purple-buttons-block">
          <p className="purple-buttons-block-text">In the meantime...</p>
          <div className="purple-buttons-wrapper">
            <Button
              Btn="redesign-button"
              className="purple-btn with-arrow-button"
              variant="primary"
              text="Check out our event recordings here"
              type="button"
              size="large"
              nameIcon="icon_recordings"
              sizeIcon="sliderMd"
              isBold
              withArrow
              onClick={() => history.push(routes.resources)}
            />
            <Button
              Btn="redesign-button"
              className="purple-btn"
              variant="primary"
              text="Download our mobile app here"
              type="button"
              size="large"
              nameIcon="icon_phone"
              sizeIcon="sliderMd"
              isBold
              withArrow
              onClick={() => customEvent.trigger(TOGGLE__VISIBILITY_DOWNLOAD_APP_MODAL)}
            />
          </div>
        </div>
      </div>
    </div>
  </main>
)
export default PurpleHomepage
