import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import clsx from 'clsx'
import Picker from 'emoji-picker-react'

/* components */
import SpriteIcon from 'components/icons/SpriteIcon'

/* styles */
import classes from './ChatInput.module.scss'

const ChatInput = ({
  name,
  type,
  value,
  placeholder,
  className,
  error,
  errorInput,
  disabled,
  spacing,
  onBlur,
  onChange,
  onClickButton,
  onEmojiClick,
}) => {
  const [isShownEmojiPicker, setIsShownEmojiPicker] = useState(false)

  const handleEmojiClick = (event, emojiObject) => {
    onEmojiClick(event, emojiObject)
  }

  return (
    <div className={clsx(
      classes.inputWrapper,
      spacing,
      className,
    )}>
      {error && (
        <span className={classes.error}>
          {error}
        </span>
      )}

      <div className={classes.emoji}>
        <div className={classes.emojiInner}>
          <div
            className={classes.emojiButton}
            role="button"
            onClick={() => setIsShownEmojiPicker(!isShownEmojiPicker)}>
            <SpriteIcon
              name="smile-regular"
              size="fullWidth"
              withState
            />
          </div>

          <div
            onMouseLeave={() => setIsShownEmojiPicker(false)}
            className={clsx(classes.picker, isShownEmojiPicker && classes.active)}>
            <Picker onEmojiClick={handleEmojiClick} />
          </div>
        </div>
      </div>

      <button
        type="submit"
        disabled={disabled}
        className={classes.button}
        onClick={onClickButton}>
        <SpriteIcon
          name="share-arrow"
          size="fullWidth"
          disabled={disabled}
          withState
        />
      </button>

      <input
        type={type}
        className={clsx(classes.input, classes[errorInput])}
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        autoComplete="off"
        onBlur={onBlur}
        onChange={onChange}
      />
    </div>
  )
}

ChatInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  spacing: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'email', 'tel', 'number']),
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  errorInput: PropTypes.string,
  disabled: PropTypes.bool,
  onClickButton: PropTypes.func,
  onEmojiClick: PropTypes.func.isRequired,
}

ChatInput.defaultProps = {
  placeholder: '',
  value: '',
  className: '',
  type: 'text',
  spacing: '',
  onChange: null,
  onBlur: null,
  error: '',
  errorInput: '',
  disabled: false,
  onClickButton: null,
}

export default ChatInput
