import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isEmpty from 'lodash.isempty'
import { useParams } from 'react-router-dom'

/* components */
import SpriteIcon from 'components/icons/SpriteIcon'
import Categories from 'components/ui/Categories'
import TabNavItem from 'components/TabsMenu/TabNavItem'
import TabContent from 'components/TabsMenu/TabContent'
import Share from 'components/ui/Share'
import MainSideBar from 'components/MainSideBar'

/* actions */
import { getSingleCreator } from 'state-manager/actions/content'

/* hooks */
import _useDidMount from 'hooks/lifecycle/use-did-mount'

/* utils */
import customEvent from 'utils/custom-event'

/* helpers */
import getUrlByMediaPrefix from 'helpers/get-url-by-media-prefix'
import isMobile from 'helpers/check-is-mobile-device'

import { TOGGLE__VISIBILITY_DOWNLOAD_APP_MODAL } from 'constants/custom-events'

/* types */
import { types as contentDataTypes } from 'state-manager/reducers/content'

/* assets */
import avatar from 'assets/images/default_avatar.png'
import askQuestion from 'assets/images/ask-question.png'

/* styles */
import './styles.scss'

const CreatorPage = ({
  creators,
  currentCreatorData,
  getSingleCreator,
}) => {
  const params = useParams()
  const [activeTab, setActiveTab] = useState('tab1')

  _useDidMount(() => {
    if (isEmpty(creators) && isEmpty(currentCreatorData)) {
      getSingleCreator(params.slug)
    }
  })

  if (isEmpty(currentCreatorData)) {
    return null
  }

  const getViewsSum = (podcasts) => podcasts.reduce((acc, obj) => acc + parseInt(obj.views_count), 0)

  const tabNavItems = [
    {
      id: 1,
      icon: 'tab-videos',
    },
  ]

  return (
    <div className="homepage--light creator-page-wrapper">
      <div className="homepage">
        <div className="homepage__wrapper">
          <div className="container">
            <div className="homepage__inner">
              <MainSideBar />
              <div className="creator-page-content">
                <section className="creator-page__header">
                  <div className="creator-page__header__inner">
                    <div className="creator-page__header-image-wrapper">
                      <img src={currentCreatorData.photo ? getUrlByMediaPrefix.photo(currentCreatorData.photo, currentCreatorData.id) : avatar} alt="" className="avatar" />

                      {isMobile() && (
                        <div className="buttons-wrapper">
                          <div
                            className="ask-question-wrapper"
                            onClick={() => customEvent.trigger(TOGGLE__VISIBILITY_DOWNLOAD_APP_MODAL)}
                            role="button">
                            <img src={askQuestion} alt="askQuestion" className="ask-question" />
                          </div>

                          <div className="share-wrapper">
                            <Share
                              slug={currentCreatorData.slug}
                              title={currentCreatorData.first_name}
                              creatorID={currentCreatorData.id}
                              description="send-profile"
                              icon="send-profile"
                              isOnlyIcon
                              placement="bottom"
                              isCreator
                              queryId={currentCreatorData.id}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="creator-page__header-info">
                      <div className="creator-page__header-info__wrapper">
                        <div className="handle">
                          @{currentCreatorData.handle}
                        </div>

                        <div className="name">
                          {`${currentCreatorData.first_name} ${currentCreatorData.last_name}`}
                        </div>

                        <div className="work-role">
                          {currentCreatorData.work_role}
                        </div>
                        <div className="work-company">
                          {currentCreatorData.company}
                        </div>
                      </div>
                      {!isMobile() && (
                        <>
                          <div
                            className="ask-question-wrapper"
                            onClick={() => customEvent.trigger(TOGGLE__VISIBILITY_DOWNLOAD_APP_MODAL)}
                            role="button">
                            <img src={askQuestion} alt="askQuestion" className="ask-question" />
                            Q&A
                          </div>

                          <div className="share-wrapper">
                            <Share
                              slug={`${currentCreatorData.slug}`}
                              title={currentCreatorData.first_name}
                              creatorID={currentCreatorData.id}
                              description="send-profile"
                              icon="send-profile"
                              isOnlyIcon
                              placement="bottom"
                              isCreator
                              queryId={currentCreatorData.id}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                </section>

                <main className="creator-page__main">
                  <div className="creator-page__content">
                    <div className="count-views">
                      <div className="videos">
                        <span className="title">{currentCreatorData.podcasts?.length || '0'}</span>
                        Videos
                      </div>
                      <div className="views">
                        <span className="title">{getViewsSum(currentCreatorData.podcasts) || '0'}</span>
                        Views
                      </div>
                    </div>
                    {currentCreatorData.bio && (
                      <div className="bio">
                        {currentCreatorData.bio}
                      </div>
                    )}
                    {currentCreatorData.link && (
                      <a href={currentCreatorData.link} className="link">
                        {currentCreatorData.link}
                      </a>
                    )}
                    <div className="tabs-menu-wrapper">
                      {tabNavItems.map((item) => (
                        <TabNavItem id={`tab${item.id}`} activeTab={activeTab} setActiveTab={setActiveTab} key={item.id}>
                          <SpriteIcon
                            name={item.icon}
                            size="md"
                          />
                        </TabNavItem>
                      ))}
                    </div>

                    <TabContent id="tab1" activeTab={activeTab}>
                      {!isEmpty(currentCreatorData.podcasts) ? (
                        <>
                          <Categories
                            disabled={false}
                            categories={[{
                              podcasts: currentCreatorData.podcasts.filter((podcast) => podcast.orientation === 0),
                            }]}
                            isFullCategories={false}
                          />
                        </>
                      ) :
                        (
                          <div className="empty-podcasts">
                            <h3 className="title">Ask {currentCreatorData.first_name} a Question 🙂</h3>
                            <span>All questions are sent to creators anonymously.</span>
                            <div
                              className="ask-question-wrapper"
                              onClick={() => customEvent.trigger(TOGGLE__VISIBILITY_DOWNLOAD_APP_MODAL)}
                              role="button">
                              <img src={askQuestion} alt="askQuestion" className="ask-question" />
                              Q&A
                            </div>
                          </div>
                        )}
                    </TabContent>
                  </div>

                </main>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}

CreatorPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentCreatorData: PropTypes.object,
  creators: contentDataTypes.creators,
  getSingleCreator: PropTypes.func.isRequired,
}

CreatorPage.defaultProps = {
  currentCreatorData: {},
  creators: [],
}

const mapStateToProps = (state, ownProps) => ({
  currentCreatorData: state.content.creators.find((creator) => creator.slug === ownProps.match.params.slug),
})

const actionsToProps = {
  getSingleCreator,
}

export default connect(mapStateToProps, actionsToProps)(CreatorPage)
