const FREE = 'free_user'
const PAID = 'premium_user'
const TEAM = 'team_user'
const ADMIN = 'admin'
const CREATOR = 'creator'
const UNAUTHORIZED = 'unauthorized'

export const ROLES = {
  FREE,
  PAID,
  TEAM,
  ADMIN,
  CREATOR,
  UNAUTHORIZED,
}
