import React from 'react'
import * as PropTypes from 'prop-types'

const TabContent = ({ id, activeTab, children }) => (
  activeTab === id ? (
    <div className="tab-content">
      {children}
    </div>
  )
    : null
)
TabContent.propTypes = {
  id: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
}

export default TabContent
