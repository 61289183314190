export default ({ errors, touched, values, submitCount, field }) => {
  if (errors[field]) {
    if (touched[field]) {
      if (values[field]) {
        return true
      }
      if (submitCount > 0) {
        return true
      }

    }
  }
}
