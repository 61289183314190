import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import LazyLoad from 'react-lazyload'
import isEmpty from 'lodash.isempty'
import { formatRoute } from 'react-router-named-routes'

/* helpers */
import getUrlByMediaPrefix from 'helpers/get-url-by-media-prefix'
import isIOS from 'helpers/is-ios-device'

/* state-manager */
import { history } from 'state-manager/store'
import routes from 'routes'

/* components */
import SpriteIcon from 'components/icons/SpriteIcon'
import Loader from 'components/ui/Loader'

/* hooks */
import _useDidUpdate from 'hooks/lifecycle/use-did-update'

/* assets */
import avatar from 'assets/images/default_avatar.png'
import verifyMark from 'assets/images/verifyMark.svg'

const VideoSlide = ({
  video,
}) => {
  const [inHover, setHover] = useState(false)
  const [isLoadedVideo, setIsLoadedVideo] = useState(false)
  const [isLoadedImg, setIsLoadedImg] = useState(false)
  const [isMuted, setIsMuted] = useState(true)

  const videoRef = useRef()

  _useDidUpdate(() => {
    if (isIOS() && inHover) {
      setIsMuted(true)
      videoRef?.current?.muted
      videoRef?.current?.play()
    }
  }, [inHover])

  const handleOnMouseEnter = () => {
    setHover(true)
  }

  const handleOnMouseLeave = () => {
    setHover(false)
  }

  const handleLoaded = () => {
    setIsLoadedVideo(true)
  }
  if (isLoadedVideo && inHover && !isIOS()) {
    videoRef?.current?.play()
  }
  const handleLoaderClick = () => {}

  const handleMute = () => {
    // if (isMutedVideos && !isIOS()) {
    //   setUnmute()
    // }
    setIsMuted(!isMuted)
  }
  const handleContextMenu = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }
  const handleVideoClick = () => {
    const detailsPagePath = formatRoute(routes.thoughtDetails, { slug: video.slug })
    history.push(detailsPagePath)
  }

  const getCreatorPhoto = () => {
    if (video.creator.id && video.creator.photo) {
      return getUrlByMediaPrefix.photo(video.creator.photo, video.creator.id)
    }
    return avatar
  }

  const getWorkRole = (creator) => {
    if (creator.work_role) {
      return creator.company ? `${creator.work_role} at ${creator.company}` : creator.work_role
    }
    return creator.company ? `${creator.discipline} at ${creator.company}` : creator.discipline
  }

  const handleAuthorClick = () => {
    const detailsPagePath = formatRoute(routes.creator, { slug: video.creator.slug })
    history.push(detailsPagePath)
  }

  const renderBusiness = (creator) => (
    <div
      role="button"
      className="thoughts__business business-slider"
      style={{ backgroundColor: creator.business_team.color }}
      onClick={() => window.open(creator.business_team.link)}>
      <div className="thoughts__business--icon">
        <img
          src={creator.business_team.icon}
          alt="Logo"
          className="thoughts__business--logo"
        />
      </div>
      <div className="thoughts__business--name">
        {creator.business_team.name}
      </div>
    </div>
  )

  return (
    <LazyLoad height={512} unmountIfInvisible>
      <div className="thoughts__wrapper--pure">
        <div
          className="thoughts__video-item video-small"
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}>
          <div className="thoughts__image-title--wrapper">
            <div className="thoughts__image-title text-multiline-ellipsis">
              {video.title}
            </div>
            <div className="thoughts__views-count">
              <SpriteIcon
                name="views"
                size="sm"
              />
              <span className="count">{video.views_count}</span>
            </div>
          </div>
          <div className="thoughts__image-preview">
            <img
              alt={video.title}
              className={clsx('thoughts__image',
                inHover && isLoadedVideo && 'hide',
                isLoadedImg && 'lazyload')}
              src={getUrlByMediaPrefix.image.middle(video.media.preview_image, video.id)}
              onLoad={() => setIsLoadedImg(true)}
              onContextMenu={handleContextMenu}
            />

            {!isLoadedImg && (
              <Loader action={handleLoaderClick} />
            )}

            {inHover && !isLoadedVideo && (
              <Loader action={handleLoaderClick} />
            )}
          </div>

          <div className="thoughts__video-preview">
            {inHover && (
              <>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                  ref={videoRef}
                  className="thoughts__video video-middle"
                  src={getUrlByMediaPrefix.video(video.preview_link)}
                  loop
                  muted={isMuted}
                  playsInline
                  onLoadedData={handleLoaded}
                  onClick={handleVideoClick}
                />
                <div
                  role="button"
                  className="thoughts__controls"
                  aria-label={isMuted ? 'Unmute' : 'Mute'}
                  onClick={handleMute}>
                  <SpriteIcon
                    name={isMuted ? 'volume-mute' : 'volume-up'}
                    size="md"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {video.creator && (
        <>
          <div className="thoughts__creator video-slider" onClick={handleAuthorClick} role="button">
            <div className="wrapper">
              <div className="thoughts__creator-avatar">
                <img
                  className="photo"
                  src={getCreatorPhoto()}
                  alt="Avatar"
                />
                {video.creator.is_verified && <img src={verifyMark} alt="verifyMark" className="creator-verify" />}
              </div>
            </div>
            <div className="thoughts__creator-profile-info">
              <div className="thoughts__creator--name">
                {`${video.creator.first_name} ${video.creator.last_name}`}
              </div>

              <div className="thoughts__creator--position-and-company text-multiline-ellipsis">
                {getWorkRole(video.creator)}
              </div>
            </div>
          </div>

          {!isEmpty(video.creator.business_team) && renderBusiness(video.creator)}
        </>
      )}

    </LazyLoad>
  )
}

VideoSlide.propTypes = {
  video: PropTypes.shape({
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    videoUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    speakersInfo: PropTypes.string.isRequired,
    preview_link: PropTypes.string.isRequired,
    views_count: PropTypes.number.isRequired,
    media: PropTypes.shape({
      preview_image: PropTypes.string.isRequired,
    }),
    creator: PropTypes.shape({
      company: PropTypes.string,
      discipline: PropTypes.string,
      first_name: PropTypes.string,
      id: PropTypes.number,
      last_name: PropTypes.string,
      photo: PropTypes.string,
      slug: PropTypes.string,
      handle: PropTypes.string,
      is_verified: PropTypes.bool,
      work_role: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      business_team: PropTypes.any, // TODO object or array
    }).isRequired,
    statistics: PropTypes.shape({
      reviews_count: PropTypes.number,
      views: PropTypes.number,
      shares: PropTypes.number,
      comments_count: PropTypes.number,
    }).isRequired,
  }),
}

VideoSlide.defaultProps = {
  video: {},
}

export default VideoSlide
