import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

/* helpers */
import isMobile from 'helpers/check-is-mobile-device'

/* components */
import Slider from 'rc-slider'
import SpriteIcon from 'components/icons/SpriteIcon'
import PlayerButton from '../PlayerButton'

/* styles */
import classes from '../VideoPlayer.module.scss'
import 'rc-slider/assets/index.css'

const PlayerControls = (
  {
    playing,
    handlePlayPause,
    muted,
    handleMuted,
    handleRewind,
    handleForward,
    volume,
    handleVolumeChange,
    fullscreen,
    toggleFullScreen,
    isFullScreenEnabled,
    played,
    handlePlayedChange,
    duration,
    current,
    controlsVisible,
    playBackRate,
    playBackRateValues,
    handlePlayBackRate,
    qualities,
    quality,
    handleQuality,
    pip,
    handlePip,
    subtitles,
    selectedSubtitles,
    handleChangeSubtitles,
    isStream,
    streamStatus,
  },
) => {
  const renderStreamStatus = () => {
    if (streamStatus !== null) {
      if (streamStatus) {
        return (
          <span className={classes.live}>Live</span>
        )
      }
      return (
        <span className={classes.down}>Pause</span>
      )
    }
  }

  return (
    <div className={clsx(classes.wrapperControls, controlsVisible ? null : classes.hidden)}>
      <div
        aria-label="Play/pause"
        role="button"
        className={classes.playPauseLayer}
        onClick={handlePlayPause}>

        <div className={clsx(classes.bigPlay)}>
          <div className={playing ? classes.play : classes.pause}>
            <SpriteIcon
              name={playing ? 'play' : 'pause-circle'}
              size="playerLg"
            />
          </div>
        </div>
      </div>

      <div className={clsx(classes.controls, fullscreen ? classes.fullscreen : null)}>
        {!isStream && (
          <div className={classes.timeSlider}>
            <Slider
              min={0}
              max={100}
              value={played}
              onChange={handlePlayedChange}
              ariaLabelForHandle="Current time slider"
            />
          </div>
        )}

        <div className={classes.wrapperButtons}>
          <div className={classes.leftButtons}>
            {playing ? (
              <PlayerButton
                label="Pause"
                wrapperClass="icon"
                handleClick={handlePlayPause}>

                <SpriteIcon
                  name="pause"
                  size="playerMd"
                />
              </PlayerButton>
            ) : (
              <PlayerButton
                label="Play"
                wrapperClass="icon"
                handleClick={handlePlayPause}>

                <SpriteIcon
                  name="play-solid"
                  size="playerMd"
                />
              </PlayerButton>
            )}

            {muted ? (
              <PlayerButton
                label="Unmute"
                wrapperClass="icon"
                handleClick={handleMuted}>

                <SpriteIcon
                  name="volume-mute"
                  size="playerMd"
                />
              </PlayerButton>
            ) : (
              <PlayerButton
                label="mute"
                wrapperClass="icon"
                handleClick={handleMuted}>

                <SpriteIcon
                  name="volume-up"
                  size="playerMd"
                />
              </PlayerButton>
            )}

            <div
              title="Volume"
              className={classes.volumeSlider}>

              <Slider
                min={0}
                max={100}
                value={volume * 100}
                defaultValue={volume * 100}
                handleStyle={{
                  width: '10px',
                  height: '10px',
                  marginTop: '-3px',
                }}
                onChange={handleVolumeChange}
                ariaLabelForHandle="Volume slider"
              />
            </div>

            <div className={classes.wrapperDuration}>
              {isStream ? (
                renderStreamStatus()
              ) : (
                <span>{`${current} - ${duration}`}</span>
              )}
            </div>
          </div>

          <div className={classes.rightButtons}>
            {!isStream && (
              <PlayerButton
                wrapperClass="icon"
                menuItems={qualities}
                menuSelectedItem={quality}
                handleMenuOption={handleQuality}>

                <span>{quality}</span>
              </PlayerButton>
            )}

            {!isStream && (
              <>
                <PlayerButton
                  label="Skip back 30 sec."
                  wrapperClass="icon"
                  handleClick={handleRewind}>

                  <SpriteIcon
                    name="backward"
                    size="playerMd"
                  />
                </PlayerButton>

                <PlayerButton
                  label="Jump forward 30 sec."
                  wrapperClass="icon"
                  handleClick={handleForward}>

                  <SpriteIcon
                    name="forward"
                    size="playerMd"
                  />
                </PlayerButton>

                <PlayerButton
                  label="Playback rate"
                  wrapperClass="icon"
                  menuItems={playBackRateValues}
                  menuSelectedItem={playBackRate}
                  handleMenuOption={handlePlayBackRate}>

                  <span>{playBackRate}x</span>
                </PlayerButton>
              </>
            )}

            {!isMobile() && subtitles && !isStream && (
              <PlayerButton
                label="Subtitles"
                wrapperClass="icon"
                menuItems={subtitles}
                menuSelectedItem={selectedSubtitles}
                handleMenuOption={handleChangeSubtitles}>

                <SpriteIcon
                  name="subtitles"
                  size="playerMd"
                />
              </PlayerButton>
            )}

            {pip ? (
              <PlayerButton
                label="Disable pip"
                wrapperClass="icon"
                handleClick={handlePip}>

                <SpriteIcon
                  name="pip-on"
                  size="playerMd"
                />
              </PlayerButton>
            ) : pip !== null && (
              <PlayerButton
                label="Enable pip"
                wrapperClass="icon"
                handleClick={handlePip}>

                <SpriteIcon
                  name="pip-off"
                  size="playerMd"
                />
              </PlayerButton>
            )}

            {/* eslint-disable-next-line no-nested-ternary */}
            {isFullScreenEnabled ? fullscreen ? (
              <PlayerButton
                label="Close fullscreen mode"
                wrapperClass="icon"
                handleClick={toggleFullScreen}>

                <SpriteIcon
                  name="compress"
                  size="playerMd"
                />
              </PlayerButton>
            ) : (
              <PlayerButton
                label="Fullscreen"
                wrapperClass="icon"
                handleClick={toggleFullScreen}>

                <SpriteIcon
                  name="full-screen"
                  size="playerMd"
                />
              </PlayerButton>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

PlayerControls.propTypes = {
  playing: PropTypes.bool.isRequired,
  handlePlayPause: PropTypes.func.isRequired,
  muted: PropTypes.bool.isRequired,
  handleMuted: PropTypes.func.isRequired,
  handleRewind: PropTypes.func.isRequired,
  handleForward: PropTypes.func.isRequired,
  volume: PropTypes.number.isRequired,
  handleVolumeChange: PropTypes.func.isRequired,
  fullscreen: PropTypes.bool.isRequired,
  isFullScreenEnabled: PropTypes.bool.isRequired,
  toggleFullScreen: PropTypes.func.isRequired,
  played: PropTypes.number.isRequired,
  handlePlayedChange: PropTypes.func.isRequired,
  duration: PropTypes.string.isRequired,
  current: PropTypes.string.isRequired,
  controlsVisible: PropTypes.bool.isRequired,
  playBackRate: PropTypes.number.isRequired,
  playBackRateValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  handlePlayBackRate: PropTypes.func.isRequired,
  qualities: PropTypes.arrayOf(PropTypes.string).isRequired,
  quality: PropTypes.string.isRequired,
  handleQuality: PropTypes.func.isRequired,
  handlePip: PropTypes.func.isRequired,
  pip: PropTypes.bool,
  subtitles: PropTypes.arrayOf(PropTypes.string),
  selectedSubtitles: PropTypes.string,
  handleChangeSubtitles: PropTypes.func,
  isStream: PropTypes.bool.isRequired,
  streamStatus: PropTypes.bool,
}

PlayerControls.defaultProps = {
  pip: null,
  subtitles: null,
  streamStatus: null,
  selectedSubtitles: null,
  handleChangeSubtitles: null,
}

export default PlayerControls
