import React from 'react'
import * as PropTypes from 'prop-types'
import isEmpty from 'lodash.isempty'
import Slider from 'react-slick'

/* helpers */
import { getSlidesCount } from 'helpers/get-slides-count'

/* styles */
import classes from './VideoCarousel.module.scss'

/* components */
import VideoSlide from './VideoSlide'
import CustomArrow from '../CustomArrow'
import CtaCard from '../CTACard'

const VideoCarousel = ({
  videos,
}) => {
  if (isEmpty(videos)) return null
  const renderSlides = (videos) => (
    videos.map((video) => (
      <VideoSlide
        key={video.id}
        video={video}
      />
    ))
  )

  return (
    <div className={classes.videoCarousel}>
      <div className={classes.slider}>
        <Slider
          variableWidth
          dots={false}
          infinite={false}
          speed={500}
          slidesToShow={4}
          slidesToScroll={1}
          arrows
          nextArrow={<CustomArrow isBold />}
          prevArrow={<CustomArrow isPrevArrow isBold />}
          swipe
          responsive={[
            {
              breakpoint: 1399,
              settings: {
                slidesToShow: getSlidesCount(3, false, videos.length),
              },
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: getSlidesCount(2, false, videos.length),
              },
            },
            {
              breakpoint: 992,
              settings: {
                arrows: false,
                slidesToShow: getSlidesCount(2, true, videos.length),
              },
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
                arrows: false,
              },
            },
          ]}>
          {renderSlides(videos)}
          <CtaCard forVideo />
        </Slider>
      </div>
    </div>
  )
}

VideoCarousel.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.object),
}

VideoCarousel.defaultProps = {
  videos: [],
}

export default VideoCarousel
