import React from 'react'

/* layout */
import GlobalLayout from 'pages/shared/layouts/global-layout'

/* components */
import BaseLink from 'components/ui/Link/Base'

/* styles */
import 'pages/shared/legals/legals.scss'

const PrivacyPolicyPage = () => (
  <GlobalLayout useSimpleVariant>
    <div className="container">
      <section className="terms-page">
        <div className="terms-page__wrapper">
          <div className="terms-page__inner">

            <h1 className="mb-3">
              Privacy Policy
            </h1>
            <div className="footer__first-block__text">
              Effective Date: Wednesday September 30, 2020
              <br />
              <br />
              <b>Our Commitment:</b> Thank you for accessing any of the following of UX Research Collective Inc. (herein
              references as “Company”, “we”, “us”, “our”), our website, at www.uxrcollective.com (the “Site”), our
              online subscription-based professional development content library and related functions (“UXR Collective
              Hub”), and/or any services available via the Site and UXR Collective Hub (collectively the foregoing being
              the “Services”). We respect your privacy and want to protect your personal information. To learn more,
              please read this policy (the “Privacy Policy”).
              <br />
              <br />
              This Privacy Policy explains how personal information is collected, used and disclosed by the Company. We
              are committed to collecting, using and disclosing personal information responsibly and in accordance with
              this Privacy Policy, the Personal Information Protection and Electronic Documents Act (“PIPEDA”) and the
              Data Protection Act.
              <br />
              <br />
              If you do not consent to the collection, use and disclosure of your personal information in accordance
              with this Privacy Policy, do not provide us with any personal information and if you are an active user
              you can close your account, unless the account cannot be closed in accordance with other agreements
              between you and the Company. It is important to note that some of our products and services can only be
              provided if we receive appropriate and required personal information. Consequently, should you choose not
              to provide us with the required personal information, we may be unable to offer these products and
              services to you.
              <br />
              <br />
              <b>Personal Information:</b> For the purposes of this Privacy Policy, the term “personal information”
              means any information that is defined to be personal information under applicable privacy laws. Usually,
              this refers to any information about an identifiable individual or any information that can be used to
              identify an individual.
              <br />
              <br />
              Personal information may include, for example, your name and home address, your date of birth and gender,
              health information, your occupation, medical conditions or other health information, your personal
              financial records or credit information, or any personal identification numbers.
              <br />
              <br />
              In most circumstances, personal information does not include the business contact information of a
              representative of an organization, such as the representative’s name, position or title, business address,
              business telephone number or business fax number. Publicly available information, such as information in a
              public directory, is not personal information under this Privacy Policy.
              <br />
              <br />
              <b>Purposes:</b> We may collect, use or disclose personal information for the purposes identified at the
              time of collection, as otherwise permitted or required by applicable law, or for any of the following
              purposes:

              <ul className="pl-4 disc">
                <li className="pl-4">To allow you to participate in a contest;</li>
                <li className="pl-4">To receive and process your comments about the business, and for other
                  business activities;
                </li>
                <li className="pl-4">To provide products and/or Services to users and/or access to the Site;
                </li>
                <li className="pl-4">To comply with our legal obligations and regulatory requirements;</li>
                <li className="pl-4">To provide information to anyone working with or for the Company as
                  needed for the provision of our products and services;
                </li>
                <li className="pl-4">To develop and manage our business and operations, including but not
                  limited to for billing, accounting and debt collection services relating to our products and Services;
                </li>
                <li className="pl-4">To establish, maintain and manage our relationships with users of the
                  Services;
                </li>
                <li className="pl-4">To respond to questions and concerns of users;</li>
                <li className="pl-4">To detect and protect the Company and third parties from error,
                  negligence, breach of contract, fraud, theft or other illegal activity, and which may include sharing
                  personal information with our insurers, and to otherwise communicate with our insurers;
                </li>
                <li className="pl-4">To review potential fraud and as required in the course of activities to
                  achieve credit risk reduction;
                </li>
                <li className="pl-4">To generate, maintain, and utilize (as required to provide the Services)
                  a user’s account for any Services;
                </li>
                <li className="pl-4">To generate analytics and statistical data; and</li>
                <li className="pl-4">For any other purpose for which the Company has your consent.</li>
              </ul>
              <br />
              <br />
              <b>Consent:</b> Privacy legislation requires the Company to obtain the consent of an individual to the
              collection, use or disclosure of personal information in many circumstances.
              <br />
              <br />
              By providing your personal information to the Company, you agree that we may collect, use and disclose
              your personal information in accordance with this Privacy Policy and as otherwise permitted or required by
              law, and that we may access, process and store your personal information in Canada or another
              jurisdiction.
              <br />
              <br />
              When we receive personal information from you for the purposes of providing you with our products or
              Services, or for other uses identified herein, you are providing us with consent as follows:
              <ol>
                <li className="pl-4">
                  to allow us to deal with that personal information in a reasonable manner;
                </li>

                <li className="pl-4">
                  to allow us to provide your personal information to third parties we engage to provide or support the
                  Services;
                </li>

                <li className="pl-4">
                  o allow us to use and store your personal information for the purpose of providing you with the
                  products and Services you purchase from us and to utilize the Services; and
                </li>

                <li className="pl-4">
                  to allow us to transfer your personal information outside of Canada (whereby your personal information
                  will be treated in accordance with applicable foreign laws) for the purpose of storage and use of your
                  personal information by the Company.
                </li>
              </ol>
              <br />
              <br />
              If you need to provide us with personal information about other individuals, you represent and warrant to
              us that, where required by law and prior to your disclosure to us, you will obtain the consent of each
              individual to the collection, use and disclosure by us for the specific purpose(s) that the disclosure is
              made by you.
              <br />
              <br />
              Providing us with your personal information is always your choice. When you request products and/or
              Services from us, or utilize our Services, we ask that you provide information that enables us to respond
              to your request and/or provide you with our Services. In doing so, you consent to our collection, use and
              disclosure to appropriate third parties of such personal information for these purposes.
              <br />
              <br />
              If we sell our organization or merge with another party, your consent will be implied to transfer your
              information to our successor for the same purposes as set out in this Privacy Policy.
              <br />
              <br />
              <b>Consent Exceptions:</b> There are legal exceptions where we will not need to obtain consent or explain
              the purposes for the collection, use or disclosure of personal information. Some examples of situations
              where consent is not required include an emergency that threatens the life, health or security of an
              individual, or if we must comply with a court order or governmental order.
              <br />
              <br />
              We may use personal information without your knowledge or consent in limited circumstances whereby we are
              required to provide personal information to third parties for legal or regulatory purposes. For example,
              under certain exceptional circumstance we may have a legal duty or right to disclose personal information
              without your knowledge or consent. Various government agencies such as the Canada Revenue Agency, Human
              Rights Commission, Canadian Radio-television and Telecommunications Commission, and law enforcement, may
              have the authority to review our files and interview our staff when deemed necessary. These agencies have
              their own strict privacy obligations.
              <br />
              <br />
              <b>Limited Collection:</b> We will only collect such information that is deemed necessary to meet the
              purpose for which such personal information is collected.
              <br />
              <br />
              <b>Limited Use, Disclosure and Retention:</b> We will only use your personal information in accordance
              with this Privacy Policy. If in the course of managing its business operations, the Company shares your
              personal information with professional advisors, franchisees, suppliers, any such entities shall be
              required to comply with privacy guidelines that impose obligations at least commensurate with this Privacy
              Policy. The Company does not sell or disclose personal information to third parties, other than in
              compliance with this Privacy Policy and as required to satisfy any law, regulation, legal request, to
              protect its business interests, and to co-operate with any law enforcement investigation or on matters
              concerning public policy and safety.
              <br />
              <br />
              <b>Privacy and Our Websites:</b> Each time the Services are utilized by a user, our web server or other
              tools may collect and log certain information in access logs. Such access logs may be kept for a
              reasonable period of time. Such access logs may include, but not be limited to, information such as the
              following: your machine’s TCP/IP address, files accessed, the date and time of such access, and other
              information pertaining to your activities on the Services and/or webpages linked thereto. These logs are
              used solely for performance, site administration and security reviews. They are not sold or shared in any
              way to third party organizations. We may use this non-personal information to optimize the Services, and
              our products.

              <br />
              <br />
              We may collect anonymous information as you navigate and/or interact with the Services. We may use
              anonymous information to operate and improve the Services. We may also use anonymous information to track
              what visitors to the Services are looking at most frequently so we can recognize and deliver more of the
              desired features, products, and Services.

              <br />
              <br />
              We may collect information pertaining to you from third party sources, including but not limited to, for
              the purpose of generating your account for our Services. It is your responsibility to review such
              information in your account to ensure its accuracy.

              <br />
              <br />
              By accessing and browsing the Site, or our products and/or Service(s), you agree that the Company, or any
              third party it engages or that assists it, may collect, use and disclose any information collected about
              you through the Site, or our products and/or Services or that you provide to us through the Site, or our
              products and/or Services as described in this Privacy Policy.
              <br />
              <br />
              We may use web beacons alone or in conjunction with cookies to compile information about you and your
              usage of the Services, as well as your interaction with emails from us. Web beacons are clear electronic
              images that can recognize certain types of information on your computer, such as cookies, when you viewed
              a particular website tied to the web beacon, and a description of a website tied to the web beacon. For
              example, we may use web beacons to operate and improve the Services and email communications. We may use
              information from web beacons in combination with personal information about you to provide you with
              information about us and our products and Services
              <br />
              <br />
              The Site, and our products and/or Service(s) may contain links to other sites and may provide forms on
              behalf of other organizations, including but not limited to third party payment facilities. This Privacy
              Policy only applies to personal information that we collect, use and disclose. We are not responsible or
              liable for the privacy practices of third parties, including any organizations on the behalf of which we
              provide forms, links or information, and we strongly recommend that you review a third party’s privacy
              policy before disclosing any personal information to the third party. You should review the terms of use
              and privacy policies relating to all third party websites, services and products accessible via the
              Services prior to engaging with such third parties, and you will bear all liabilities and obligations for
              all engagements and activities you undertake with such third parties and their websites, products and
              services.

              <br />
              <br />
              <b>Cookie Policy</b>

              <br />
              <br />
              We may, from time to time, use “cookies” solely for security and authentication purposes.
              <br />
              <br />
              Most websites use cookies in order to make them work, or to work more efficiently, as well as to provide
              information to the owners of the website. They help us to understand how users access our website so we
              can develop and improve the design, layout, content and function of the Site and Services.

              <br />
              <br />
              Cookies are small text files that a website saves on your computer or mobile device when you visit the
              site. They save and retrieve pieces of information about your visit to the website - for example, how you
              entered the site, how you navigated through the site and what information and documentation was of
              interest to you. This means that when you go back to a website, it can often give you tailored options
              based on the information it has stored about you on your last visit. It enables the website to remember
              your actions and preferences (such as login, language, font size and other display preferences) over a
              period of time, so you don&apos;t have to keep re-entering them whenever you come back to the site or browse
              from one page to another.
              <br />
              <br />
              On our website, cookies can be set by us (first party cookies) or by our partners (third party cookies).
              <br />
              <br />
              We set cookies for the purposes set out below:
              <ul className="pl-4 disc">
                <li className="pl-4">analytics;
                </li>
                <li className="pl-4">collecting page views;</li>
                <li className="pl-4">viewing history; and</li>
                <li className="pl-4">session cookies – for keeping users logged in while browsing (during of
                  up to one hour)
                </li>
              </ul>

              <br />
              <br />
              The following major third-party analytics services (among others) may set cookies and have access to your
              IP address based upon your use of our website:
              <ul className="pl-4 disc">
                <li className="pl-4">Google Analytics</li>
                <li className="pl-4">Hotjar</li>
                <li className="pl-4">Facebook</li>
                <li className="pl-4">LinkedIn</li>
                <li className="pl-4">Intercom</li>
              </ul>
              <br />
              <br />
              We cannot provide the names and purposes of the third party cookies as we do not have access to that
              information, however they are necessary for us to provide our Services. These third parties face serious
              consequences if they are in breach of applicable privacy laws.

              <br />
              <br />
              If you are uncomfortable with the use of cookies, you can disable cookies on your computer by changing the
              settings in the preferences or options menu in your browser. You can set your browser to reject or block
              cookies or to tell you when a website tries to put a cookie on your computer. You can also delete any
              cookies that are already stored on your computer&apos;s hard drive. However, please be aware that if you do
              delete and block all cookies parts of our Services will not then work. This is because some of the cookies
              we use are essential for parts of our Services to operate. Likewise, you may not be able to use some
              products and services on other websites without cookies.

              <br />
              <br />
              To find out more about cookies, including to see what cookies have been set and how to manage and delete
              them, visit
              <BaseLink path="www.allaboutcookies.org" title="www.allaboutcookies.org" spacing="ml-1" />

              <br />
              <br />
              If you do not wish to accept cookies from our Site, please leave the Site immediately and then delete and
              block all cookies from the Site.
              <br />
              <br />
              <b>Accuracy: </b> While the Company collects the personal information that you supply, it does so with the
              understanding that any such information is as accurate. It is important to us that your personal
              information held by us is accurate and complete. Having accurate information about you enables us to
              provide you with better products and services. You can help us keep personal information up-to-date by
              keeping us informed of any changes, such as a change of address, telephone number or any other
              circumstances. We will not be able to provide you with any notices if we do not have accurate contact
              information for you and we will bear no responsibility for any action or omission as a result of our lack
              of accurate contact information for you.
              <br />
              <br />
              <b>Retention and Destruction of Personal Information:</b> The amount of time we will retain personal
              information varies, depending on the products and Services we have provided and the nature of the personal
              information that we hold. We retain personal information for such period of time as is necessary for us to
              maintain sufficient information so we may respond to any issues that arise at a later date or as is
              required by law.

              <br />
              <br />
              When personal information is no longer required by us or by law, we will either convert it into an
              aggregated non-identifying form or we will appropriately destroy or erase the personal information in a
              manner that is in accordance with our current policies and procedures.

              <br />
              <br />
              <b>Safeguarding Personal Information:</b> We employ reasonable physical, electronic and procedural
              safeguards that are designed to protect our systems and personal information under our control against
              unauthorized access and use. In accordance with appropriate industry standards in Canada, all safety and
              security measures are appropriate to the sensitivity level of the information collected. At Company’s sole
              discretion, our safeguards may be subjected to penetration-testing at scale, and our system may be
              monitored and validated by third parties. Unfortunately, no measures can be guaranteed to provide 100%
              security. Accordingly, we cannot guarantee the security of your information.

              <br />
              <br />
              We may engage third party external servers as well as other external services, including cloud services
              and cloud compatible servers, and we do not have any control over such external servers or services.

              <br />
              <br />
              We have implemented measures to prevent theft and loss, as well as unauthorized access, disclosure, use,
              modification, transfer, or modification of personal information. However, we cannot guarantee that such
              measures will achieve absolute security for your personal information.

              <br />
              <br />
              You will bear full responsibility for the storage, maintenance, control and protection for all data that
              is processed or otherwise utilized by UXR Collective Hub and/or other Services relating to our products
              that require data input. We bear no responsibility for such data whatsoever.

              <br />
              <br />
              <b>Transfers of Personal Information:</b> The Company is responsible for your personal information, which
              it processes and stores in Canada, in accordance with the terms of this Privacy Policy. If you are not
              resident in Canada and/or are located of Canada, you bear all responsibility for ensuring that your use of
              our Services is compliant with all applicable laws.

              <br />
              <br />
              <b>Access and Correction:</b> The Company recognizes that you may have the right to access your personal
              information. Any access request must be made in writing to: hello@joinlearners.com

              <br />
              <br />
              We may charge a reasonable fee for providing information in response to an access request, and, upon
              request, we will provide an estimate of any such fee upon receiving a request to access information. We
              may require a deposit for all or part of the fee.

              <br />
              <br />
              Where applicable or permitted, we will make the information available within 30 days or provide written
              notice when we require additional time to respond to a request for access to information.
              <br />
              <br />
              In some situations, we may not be able to provide access to certain personal information as the right to
              access personal information is not absolute. If we do not provide you with the requested information, we
              will notify you in writing and explain our reason(s) for not fulfilling your request.
              <br />
              <br />
              <b>Withdrawal of Consent:</b> You may, at any time, withdraw your consent to the Company’s collection, use
              and disclosure of your personal information, subject to reasonable notice and any legal and/or contractual
              restrictions.
              <br />
              <br />
              Should you choose to withdraw your consent, the Company may be unable to provide, or continue to provide,
              the Services or some of Company’s products that can only be provided if the Company receives appropriate
              and required personal information.

              <br />
              <br />
              <b>Sensitive Data:</b> We request that you do not send us any sensitive data, including but not limited to
              social security or national identification numbers, information related to racial or ethnic origin,
              political opinions, religious beliefs, health data, biometrics or genetic, criminal background or trade
              union membership information, unless we explicitly request such information. In accordance with applicable
              privacy laws, the sensitivity of data may be determined based upon context, or by other measures. If you
              do send us sensitive data then you are consenting to its processing in accordance with this Policy.

              <br />
              <br />
              <b> Our policy on children’s information:</b> We do not knowingly collect the personal information of
              children or anyone below the age majority in their applicable jurisdiction. If we believe any person under
              the age of majority in his or her jurisdiction of residence is using the Services we will not process any
              personal information of that person.

              <br />
              <br />
              <b>Sharing for Marketing Purposes:</b> We do not share your personal information with unaffiliated third
              parties for their own independent marketing purposes without your consent. If you are a resident in a
              jurisdiction wherein you are permitted by law to do so, you may request the categories of personal
              information we share with third parties for the third parties direct marketing purposes during the
              previous calendar year, if any. To make your request, send an email to: hello@joinlearners.com. We will
              respond to these requests within 30 days. Requests that come to us by other means may result in a delayed
              response.

              <br />
              <br />
              <b>Privacy Concerns:</b> The Company will respond to questions and concerns relating to the Company’s
              personal information handling practices and this Privacy Policy.

              <br />
              <br />
              <b>Contact Information:</b> Please contact the Company to obtain further information about the Company’s
              policies and procedures, to seek access to personal information the Company may have about you, or if you
              have any unresolved inquiries or concerns. The Company will respond to you promptly and do the its utmost
              to resolve your concerns. The Company may need to ask you for personal information to verify your
              identity. The Company can be contacted at:
              <BaseLink path="mailto:hello@joinlearners.com" title="hello@joinlearners.com" spacing="ml-1" />
              <br />
              <br />
              <b>Changes to this Privacy Policy:</b> This Privacy Policy is effective as of the date indicated herein.
              The Company reserves the right to change this Privacy Policy, and any of the Company’s policies and
              procedures concerning the Company’s practices for handling personal information, at any time and without
              any prior notice. Any changes that are made to this Privacy Policy will apply to both any personal
              information that the Company holds prior to the effective date of the amended Privacy Policy and any
              personal information that the Company collects on or after such effective date.

              <br />
              <br />
              You should review this Privacy Policy and all policies regularly to make sure that you are aware of any
              changes. Your access to use of the Services on and after the date on which we post the revised terms
              constitutes your acceptance of the Privacy Policy as revised as well as of any revised policies.

              <br />
              <br />
              <b>Successors and Assigns:</b> The Company’s successors and assigns may collect, use and disclose your
              personal information for substantially similar purposes as described in this Privacy Policy.

              <br />
              <br />

              <br />
              <br />

              <br />
              <br />
              © UX Research Collective Inc., 2021.
            </div>

          </div>
        </div>
      </section>
    </div>
  </GlobalLayout>
)

export default PrivacyPolicyPage
