import React, { useState } from 'react'
import Modal from 'react-modal'
import { useFormik } from 'formik'
import * as Yup from 'yup'

/* constants */
import { TOGGLE__VISIBILITY_MAIL_LIST_MODAL } from 'constants/custom-events'

/* actions */
import { sendEmailToEventList } from 'state-manager/actions/user'

/* hooks */
import _useCustomEventListener from 'hooks/use-custom-event-listener'
import _useDidMount from 'hooks/lifecycle/use-did-mount'

/* components */
import { CloseModalButton } from 'components/ui/CloseModalButton'
import Input from 'components/ui/Inputs/Base'
import Button from 'components/ui/Button/base'

import { formFields } from '../ForgotPassword/constants'
/* styles */
import classes from './MailList.module.scss'
import { RECAPTCHA_SITE_KEY } from '../../../constants/main'

const MailList = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [eventId, setEventId] = useState(null)

  _useDidMount(() => {
    // Add reCaptcha
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  })
  const emailFormik = useFormik({
    initialValues: {
      [formFields.email]: '',
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .email('Enter a valid email')
        .required('Email is required'),
    }),

    async onSubmit(values) {
      const recaptcha = window.grecaptcha
      const recaptchaToken = await recaptcha.execute(
        RECAPTCHA_SITE_KEY,
        { action: 'add_user_to_list' },
      )
      if (recaptchaToken) {
        await sendEmailToEventList({ email: values.email, event_id: eventId, reCaptchaToken: recaptchaToken })
      }
      emailFormik.resetForm()
      setIsOpen(false)
    },
  })

  const handleClose = () => setIsOpen(false)

  _useCustomEventListener(TOGGLE__VISIBILITY_MAIL_LIST_MODAL, (event) => {
    setEventId(event.detail.eventId)
    setIsOpen(!isOpen)
  })

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className={classes.MailListModal}
      ariaHideApp={false}
      overlayClassName={classes.MailListOverlay}>
      <div className={classes.MailList}>
        <div className={classes.MailListHeader}>
          <span className={classes.MailListHeaderTitle}>Get the mobile app</span>
          <CloseModalButton right={25} top={25} onClick={handleClose} color="grey" />
        </div>
        <div className={classes.MailListContent}>
          <div className={classes.description}>
            Enter your email address to get updates about this event.
          </div>
          <form onSubmit={emailFormik.handleSubmit} className={classes.MailListForm}>
            <Input
              id="email"
              name="email"
              variant="secondary"
              size="small"
              type="text"
              value={emailFormik.values.email}
              onChange={emailFormik.handleChange}
              onBlur={emailFormik.handleBlur}
            />
            <Button
              Btn="redesign-button"
              className="get-app"
              variant="primary"
              text="Submit"
              type="submit"
              size="medium"
              disabled={(!emailFormik.isValid || Object.keys(emailFormik.touched).length === 0)}
            />
          </form>
        </div>
      </div>

    </Modal>
  )
}

export default MailList
