import React, { useState } from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

/* assets */
import signUpStep1Cover from 'assets/images/sign_up_step1_cover.png'

/* components */
import Button from 'components/ui/Button/base'
import { CloseModalButton } from 'components/ui/CloseModalButton'

/* custom events */
import {
  SHOW_SIGN_IN_MODAL,
  HIDE_SIGN_IN_MODAL, TOGGLE__VISIBILITY_SIGNUP_MODAL, SHOW_FORGOT_PASSWORD_MODAL,
} from 'constants/custom-events'

/* hooks */
import _useCustomEventListener from 'hooks/use-custom-event-listener'

/* helpers */
import renderInputs from 'helpers/render-inputs'

/* actions */
import { signIn } from 'state-manager/actions/auth'
import customEvent from '../../../utils/custom-event'

/* constants */
import { formFields } from '../SignUp/components/step1/constants'

/* formik */
import signInFormik from './formik'

/* styles */
import './styles.scss'

const inputsConfig = [
  {
    type: 'email',
    name: formFields.email,
    label: 'Email address',
    placeholder: 'email.address@example.com',
    variant: 'secondary',
    spacing: 'mb-1',
  },
  {
    type: 'password',
    name: formFields.password,
    label: 'Password',
    variant: 'secondary',
    spacing: 'mb-1',
  },
]

const SignIn = ({
  signIn,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleClose = () => setIsOpen(false)

  _useCustomEventListener(SHOW_SIGN_IN_MODAL, () => setIsOpen(true))
  _useCustomEventListener(HIDE_SIGN_IN_MODAL, () => setIsOpen(false))

  const handleClickRegister = () => {
    customEvent.trigger(TOGGLE__VISIBILITY_SIGNUP_MODAL)
    customEvent.trigger(HIDE_SIGN_IN_MODAL)
  }

  const signInForm = signInFormik({
    onSubmit: signIn,
    email: '',
  })
  const handleClickForgotPassword = () => {
    customEvent.trigger(HIDE_SIGN_IN_MODAL)
    customEvent.trigger(SHOW_FORGOT_PASSWORD_MODAL)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="SignIn-modal"
      ariaHideApp={false}
      overlayClassName="SignUp-overlay">
      <CloseModalButton
        className="SignUp-close-button"
        color="grey"
        right={10}
        top={10}
        onClick={handleClose}
      />

      <div className="SignUp SignIn">
        <div className="SignUp-content">
          <div className="SignUp-content-wrapper">
            <div>
              <h2 className="title">Log In</h2>
              <div className="subtitle">
                Don’t have a Learners account?
                <span
                  role="button"
                  className="link-to-login"
                  onClick={handleClickRegister}>
                  Join
                </span>
              </div>

              <form onSubmit={signInForm.handleSubmit}>
                {renderInputs({
                  inputsConfig,
                  values: signInForm.values,
                  touched: signInForm.touched,
                  errors: signInForm.errors,
                  handleChange: signInForm.handleChange,
                  handleBlur: signInForm.handleBlur,
                  submitCount: signInForm.submitCount,
                })}

                <div className="Step1-bottom-content">
                  <Button
                    text="Login"
                    type="submit"
                    variant="primary"
                    size="large"
                  />
                </div>
              </form>

              <div className="SignIn-password-reset-wrapper">
                Forgot your password? <span role="button" className="password-reset" onClick={handleClickForgotPassword}>Reset</span>
              </div>
            </div>
          </div>
        </div>

        <div className="SignUp-image">
          <img src={signUpStep1Cover} alt="Sign up step 1 cover" />
        </div>
      </div>
    </Modal>
  )
}

SignIn.propTypes = {
  signIn: PropTypes.func.isRequired,
}

const actionsToProps = {
  signIn,
}

export default connect(null, actionsToProps)(SignIn)
