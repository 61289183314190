import routes from 'routes'

export const navConfig = [
  {
    title: 'Home',
    route: routes.homepage,
  },
  {
    title: 'UX Research',
    route: routes.uxResearch,
  },
]
