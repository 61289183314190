import React from 'react'
import Slider from 'react-slick'
import * as PropTypes from 'prop-types'
import isEmpty from 'lodash.isempty'

/* components */
import CustomArrow from '../CustomArrow'
import QuestionCard from './QuestionSlide'

/* styles */
import classes from './QuestionCarousel.module.scss'
import { getSlidesCount } from '../../../helpers/get-slides-count'
import CtaCard from '../CTACard'

const QuestionCarousel = ({ questions }) => {
  if (isEmpty(questions)) return null

  const renderSlides = (questions) => (
    questions.map((question) => (
      <QuestionCard
        key={question.id}
        question={question}
      />
    ))
  )

  return (
    <div className={classes.questionCarousel}>
      <div className={classes.slider}>
        <Slider
          variableWidth
          dots={false}
          infinite={false}
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          arrows
          nextArrow={<CustomArrow isBold />}
          prevArrow={<CustomArrow isPrevArrow isBold />}
          swipe
          responsive={[
            {
              breakpoint: 1399,
              settings: {
                slidesToShow: getSlidesCount(2, false, questions.length),
              },
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: getSlidesCount(1, false, questions.length),
              },
            },
            {
              breakpoint: 992,
              settings: {
                arrows: false,
                slidesToShow: getSlidesCount(1, true, questions.length),
              },
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
                arrows: false,
              },
            },
          ]}>
          {renderSlides(questions)}
          <CtaCard />
        </Slider>
      </div>
    </div>
  )
}

QuestionCarousel.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object),
}

QuestionCarousel.defaultProps = {
  questions: [],
}
export default QuestionCarousel
