import PropTypes from 'prop-types'

/* helpers */
import updateObject from 'state-manager/reducers/helpers/update-object'

/* constants */
import { APP_ACTIONS } from 'state-manager/constants'

const initialState = {
  checksum: {},
  categories: [],
  levels: [],
  disciplines: [],
  yearsOfExperience: [],
  isMutedVideos: true,
  thoughtsState: [],
}

export const types = {
  checksum: PropTypes.arrayOf(PropTypes.string),
  categories: PropTypes.array,
  levels: PropTypes.array,
  disciplines: PropTypes.array,
  yearsOfExperience: PropTypes.array,
  isMutedVideos: PropTypes.bool,
  thoughtsState: PropTypes.array,
}

export default (state = initialState, action) => {
  switch (action.type) {

    case APP_ACTIONS.APP_GET_CHECKSUM.SUCCESS:
      return updateObject(state, {
        checksum: action.payload.checksum,
      })

    case APP_ACTIONS.APP_GET_CATEGORIES.SUCCESS:
      return updateObject(state, {
        categories: action.payload.categories,
      })

    case APP_ACTIONS.APP_GET_LEVELS.SUCCESS:
      return updateObject(state, {
        levels: action.payload.levels,
      })

    case APP_ACTIONS.APP_GET_DISCIPLINES.SUCCESS:
      return updateObject(state, {
        disciplines: action.payload.disciplines,
      })

    case APP_ACTIONS.APP_GET_EXPERIENCE.SUCCESS:
      return updateObject(state, {
        yearsOfExperience: action.payload.yearsOfExperience,
      })

    case APP_ACTIONS.APP_SET_MUTE.SUCCESS:
      return updateObject(state, {
        isMutedVideos: true,
      })

    case APP_ACTIONS.APP_SET_UNMUTE.SUCCESS:
      return updateObject(state, {
        isMutedVideos: false,
      })

    case APP_ACTIONS.APP_SET_THOUGHTS_STATE.SUCCESS:
      return updateObject(state, {
        thoughtsState: action.payload,
      })

    case APP_ACTIONS.APP_SET_THOUGHTS_PLAYING.SUCCESS:
      const updateThoughtsState = [...state.thoughtsState]
      const index = updateThoughtsState.findIndex((thoughts) => thoughts.id === action.payload.id)
      updateThoughtsState.map((item, itemIndex) => {
        item.isPlaying = itemIndex === index
        return item
      })

      return updateObject(state, {
        thoughtsState: updateThoughtsState,
      })

    default:
      return state
  }
}
