import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

/* routes */
import routes from 'routes'

/* styles */
import 'pages/shared/layouts/styles.scss'

/* hooks */
import _useDidMount from 'hooks/lifecycle/use-did-mount'
import _useDidUpdate from 'hooks/lifecycle/use-did-update'
import _useCustomEventListener from 'hooks/use-custom-event-listener'

/* utils */
import changeTheme from 'utils/toggle-themes'

/* helpers */
import { getPageName } from 'helpers/get-page-name'

/* constants */
import { SET__DARK_COLOR_THEME } from 'constants/custom-events'

/* components */
import Header from 'pages/shared/layouts/global-layout/header'
import Footer from './footer'
import HeaderLight from './header-light'

const GlobalLayout = ({ children }) => {
  const [headerComponent, setHeaderComponent] = useState(null)
  const [footerComponent, setFooterComponent] = useState(null)

  _useCustomEventListener(SET__DARK_COLOR_THEME, () => {
    changeTheme('dark')
    setHeaderComponent(<Header />)
    setFooterComponent(<Footer />)
  })

  const location = useLocation()
  const lightPages = [
    routes.oldHomepage,
    routes.resources,
    routes.uxResearch,
    getPageName(routes.talkDetails),
    getPageName(routes.thoughtDetails),
    getPageName(routes.singleCategory),
    getPageName(routes.creator),
    getPageName(routes.questionPage),
  ]
  const isLightPage = (url) => {
    const modifiedUrl = url.replace(/\/$/, '')
    return lightPages.includes(modifiedUrl) || lightPages.includes(getPageName(location.pathname))
  }

  const purplePage = [
    routes.homepage,
  ]
  const secondWrapperPages = [
    routes.uxResearch,
  ]
  const changeThemeHandler = () => {
    if (location.pathname === routes.homepage) {
      changeTheme('light')
      setHeaderComponent(<HeaderLight withoutGetAppBtn />)
      setFooterComponent(null)
      return null
    }
    if (isLightPage(location.pathname)) {
      changeTheme('light')
      setHeaderComponent(<HeaderLight />)
      setFooterComponent(null)
      return null
    }
    if (purplePage.includes(location.pathname) || purplePage.includes(getPageName(location.pathname))) {
      changeTheme('light')
      setHeaderComponent(<HeaderLight className="header--purple" />)
      setFooterComponent(null)
      return null
    }
    changeTheme('dark')
    setHeaderComponent(<Header />)
    setFooterComponent(<Footer />)
  }

  const getWrapper = () => (secondWrapperPages.includes(location.pathname) ? 'secondary-wrapper' : 'main-wrapper')

  _useDidMount(() => {
    changeThemeHandler()
  })

  _useDidUpdate(() => {
    changeThemeHandler()
  }, [location.pathname])

  return (
    <>
      {headerComponent}

      <div className={getWrapper()}>
        {children}
      </div>

      {footerComponent}
    </>
  )
}

GlobalLayout.propTypes = {
  children: PropTypes.element.isRequired,
}

export default GlobalLayout
