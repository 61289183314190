import React, { useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import isEmpty from 'lodash.isempty'
import clsx from 'clsx'

/* styles */
import './styles.scss'

/* components */
import Button from 'components/ui/Button/base'
import MessageItem from 'components/ui/Stream/ChatBox/MessageItem'
import ChatForm from 'components/ui/Stream/ChatBox/ChatForm'
import SpriteIcon from 'components/icons/SpriteIcon'

/* utils */
import _useConnectToEchoServer from 'utils/echo/useConnectToEchoServer'
import customEvent from 'utils/custom-event'
import { MESSAGE_DELETED, MESSAGE_SENT, USER_MUTE, USER_UNMUTE } from 'utils/echo/events'
import channelsByName from 'utils/echo/channels'

/* types */
import { types as userDataTypes } from 'state-manager/reducers/user-data'
import { types as chatDataTypes } from 'state-manager/reducers/chat-data'

/* constants */
import { ROLES } from 'constants/roles'

/* hooks */
import _useDidMount from 'hooks/lifecycle/use-did-mount'
import _useDidUpdate from 'hooks/lifecycle/use-did-update'

/* actions */
import {
  clearChatMessages,
  getChatInfo,
  getMessage,
  muteUser,
  unmuteUser,
  pushQueueToMessages,
  sendMessage,
  startUpdateMessages,
  stopUpdateMessages,
  deleteMessage,
  changeDeletedMessage,
} from 'state-manager/actions/chat'

/* constants */
import {
  SHOW_SIGN_IN_MODAL,
  TOGGLE__VISIBILITY_SIGNUP_MODAL,
} from 'constants/custom-events'
import { agreeWithCodeOfConduct } from '../../../../state-manager/actions/user'

const ChatBox = ({
  chatId,
  udIsAuthorized,
  udIsAgreeWithCodeOfConduct,
  udId,
  udRole,
  isChatEnabled,
  getChatInfo,
  chatInfo,
  messages,
  getMessage,
  sendMessage,
  stopUpdateMessages,
  startUpdateMessages,
  isStopUpdateMessages,
  pushQueueToMessages,
  queueLength,
  clearChatMessages,
  muteUser,
  unmuteUser,
  deleteMessage,
  changeDeletedMessage,
  agreeWithCodeOfConduct,
}) => {
  const messagesEndRef = useRef(null)
  const chatRef = useRef(null)
  const [isDisabled, setIsDisabled] = useState(!isChatEnabled)
  const [toggleQueueCounter, setToggleQueueCounter] = useState(false)
  const [currentPosition, setCurrentPosition] = useState(0)
  const [isMutedChat, setIsMutedChat] = useState(false)

  _useDidMount(() => {
    if (udIsAuthorized) {
      getChatInfo({ id: chatId })
    }

    return () => clearChatMessages()
  })

  if (isDisabled) {
    if (isChatEnabled) {
      setIsDisabled(false)
    }
  }

  const handleReceivedMessage = (data) => {
    getMessage(data)
  }
  const handleMuteUser = (data) => {
    if (data.id === udId) {
      setIsMutedChat(true)
    }
  }

  const handleUnMuteUser = (data) => {
    if (data.id === udId) {
      setIsMutedChat(false)
    }
  }

  const handleDeletedMessage = (data) => {
    changeDeletedMessage(data)
  }

  const scrollToBottom = () => {
    if (chatRef && chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight
    }
  }

  const handleScroll = () => {
    if (chatRef.current.scrollTop < currentPosition) {
      if (!isStopUpdateMessages) {
        stopUpdateMessages()
      }
    }
    /* if scrolled to end */
    if (chatRef.current.scrollHeight - chatRef.current.scrollTop === chatRef.current.clientHeight) {
      pushQueueToMessages()
      startUpdateMessages()
    }

    setCurrentPosition(chatRef.current.scrollTop)
  }

  _useDidUpdate(() => {
    if (!isStopUpdateMessages && !isDisabled) {
      scrollToBottom()
    }
  }, [messages])

  _useDidUpdate(() => {
    if (chatInfo.isMuted) {
      setIsMutedChat(true)
    }
  }, [chatInfo])

  _useConnectToEchoServer({
    channelName: channelsByName.conversationById(chatId),
    isAuthorized: udIsAuthorized,
    events: {
      [MESSAGE_SENT]: handleReceivedMessage,
      [USER_MUTE]: handleMuteUser,
      [USER_UNMUTE]: handleUnMuteUser,
      [MESSAGE_DELETED]: handleDeletedMessage,
    },
  })

  const handleRegisterClick = () => {
    customEvent.trigger(TOGGLE__VISIBILITY_SIGNUP_MODAL)
  }

  const handleLoginClick = () => {
    customEvent.trigger(SHOW_SIGN_IN_MODAL)
  }

  const handleHover = () => {
    setToggleQueueCounter(true)
  }
  const handleLeave = () => {
    setToggleQueueCounter(false)
  }

  const handleCodeOfConductClick = () => {
    agreeWithCodeOfConduct()
  }

  const renderMessages = useMemo(
    () => {
      const toggleMute = ({ isMuted, conversationId, userId }) => {
        if (isMuted) {
          muteUser({
            conversationId,
            userId,
          })
        } else {
          unmuteUser({
            conversationId,
            userId,
          })
        }
      }

      const handleDeleteMessage = ({ conversationId, messageId }) => {
        deleteMessage({
          conversationId,
          messageId,
        })
      }

      return (
        messages.map((item) => (
          <MessageItem
            key={item.message.id}
            time={item.message.sentAt}
            photo={item.message.userAvatar}
            handle={item.message.userHandle}
            messageBody={item.message.body}
            messageId={item.message.id}
            conversationId={item.message.conversationId}
            userId={item.message.senderId}
            onMuteClick={toggleMute}
            onDeleteClick={handleDeleteMessage}
            isModerator={udRole === ROLES.ADMIN}
          />
        ))

      )
    }, [deleteMessage, messages, muteUser, udRole, unmuteUser],
  )

  const renderDisabled = () => (
    <div className="disabled">
      <div className="icon">
        <SpriteIcon
          name="quotation-mark"
          size="fullWidth"
        />
      </div>
      <div className="description">
        The chat will open 20 mins before the start of the event.
      </div>
    </div>
  )

  const renderCodeOfConduct = () => (
    <div className="code-of-conduct">
      <div className="icon">
        <SpriteIcon
          name="quotation-mark"
          size="fullWidth"
        />
      </div>
      <div className="title">
        Before you get going, we want to let you know about the Learners Code of Conduct.
      </div>
      <div className="description">
        Learners will not tolerate harassment, hatred or bigotry in our spaces, whether in-person or through any
        of our channels.
      </div>

      <div className="read">
        Read our Code of Conduct
        <a
          className="ml-1"
          rel="noreferrer noopener"
          href="https://codeofconduct.joinlearners.com/"
          target="_blank">
          here
        </a>.
      </div>

      <div className="wrapper-button">
        <Button
          variant="secondary"
          text="Join the Chat"
          size="large"
          type="button"
          spacing="mr-1"
          onClick={handleCodeOfConductClick}
        />
      </div>
    </div>
  )

  const renderChat = () => (
    isDisabled ? (
      renderDisabled()
    ) : (
      <div className="chat">
        <div ref={chatRef} className="wrapper-messages" onScroll={handleScroll}>
          {!isEmpty(messages) && renderMessages}
          <div ref={messagesEndRef} className="messages-end" />
        </div>

        <div
          className={clsx('chat-status', isStopUpdateMessages && 'show')}
          onMouseEnter={handleHover}
          onMouseLeave={handleLeave}>
          {toggleQueueCounter ? (
            <div
              role="button"
              className="status"
              onClick={() => scrollToBottom()}>
              <div className="counter">
                {`${queueLength > 20 ? '20+' : queueLength} new messages`}
              </div>
              <div className="arrow">
                <div className="chevron" />
                <div className="chevron" />
                <div className="chevron" />
              </div>
            </div>
          ) : (
            <div className="status paused">
              Chat paused due to scroll
            </div>
          )}
        </div>

        <div className="wrapper-message-input">
          <ChatForm
            chatId={chatId}
            sendMessage={sendMessage}
            isMuted={isMutedChat}
          />
        </div>

        {!udIsAgreeWithCodeOfConduct && renderCodeOfConduct()}
      </div>
    )
  )

  const renderCta = () => (
    <div className="cta-join">
      Log in or register to join the conversation!
      <span className="free">It’s free!</span>

      <div className="wrapper-button">
        <Button
          variant="secondary"
          text="LOG IN"
          size="extraSmall"
          type="button"
          spacing="mr-1"
          onClick={handleLoginClick}
        />

        <Button
          variant="tertiary"
          text="Join"
          size="small"
          type="button"
          spacing="ml-1"
          onClick={handleRegisterClick}
        />
      </div>
    </div>
  )

  return (
    <div className="chatbox">
      {udIsAuthorized ? renderChat() : renderCta()}
    </div>
  )
}

ChatBox.propTypes = {
  chatId: PropTypes.number.isRequired,
  udId: PropTypes.number,
  messages: chatDataTypes.messages.isRequired,
  udIsAuthorized: userDataTypes.isAuthorized.isRequired,
  udIsAgreeWithCodeOfConduct: userDataTypes.isAgreeWithCodeOfConduct.isRequired,
  udRole: userDataTypes.role,
  isChatEnabled: chatDataTypes.isChatEnabled.isRequired,
  isStopUpdateMessages: chatDataTypes.isStopUpdateMessages.isRequired,
  chatInfo: chatDataTypes.chatInfo.isRequired,
  getChatInfo: PropTypes.func.isRequired,
  getMessage: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  stopUpdateMessages: PropTypes.func.isRequired,
  startUpdateMessages: PropTypes.func.isRequired,
  pushQueueToMessages: PropTypes.func.isRequired,
  queueLength: PropTypes.number.isRequired,
  clearChatMessages: PropTypes.func.isRequired,
  muteUser: PropTypes.func.isRequired,
  unmuteUser: PropTypes.func.isRequired,
  deleteMessage: PropTypes.func.isRequired,
  changeDeletedMessage: PropTypes.func.isRequired,
  agreeWithCodeOfConduct: PropTypes.func.isRequired,
}

ChatBox.defaultProps = {
  udRole: null,
  udId: null,
}

const mapStateToProps = (state) => ({
  udIsAuthorized: state.userData.isAuthorized,
  udIsAgreeWithCodeOfConduct: state.userData.isAgreeWithCodeOfConduct,
  udId: state.userData.id,
  udRole: state.userData.role,
  isChatEnabled: state.chat.isChatEnabled,
  chatInfo: state.chat.chatInfo,
  messages: state.chat.messages,
  isStopUpdateMessages: state.chat.isStopUpdateMessages,
  queueLength: state.chat.messageQueue.length,
})

const actionsToProps = {
  getChatInfo,
  getMessage,
  sendMessage,
  muteUser,
  unmuteUser,
  deleteMessage,
  clearChatMessages,
  stopUpdateMessages,
  startUpdateMessages,
  pushQueueToMessages,
  changeDeletedMessage,
  agreeWithCodeOfConduct,
}

export default connect(mapStateToProps, actionsToProps)(ChatBox)
