import React, { useState } from 'react'
import { formatRoute } from 'react-router-named-routes'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import LazyLoad from 'react-lazyload'

/* styles */
import './styles.scss'

/* components */
import StoriesComponent from 'react-insta-stories'

/* assets */
import avatar from 'assets/images/default_avatar.png'

/* actions */
import { sendEvent } from 'state-manager/actions/content'

/* constants */
import { EVENTS } from 'constants/events'

/* routes */
import routes from 'routes'

/* helpers */
import isMobile from 'helpers/check-is-mobile-device'

/* hooks */
import _useDidMount from 'hooks/lifecycle/use-did-mount'

/* state-manager */
import { history } from 'state-manager/store'

/* helpers */
import getUrlByMediaPrefix from 'helpers/get-url-by-media-prefix'

const Stories = ({
  items,
  currentStory,
  sendEvent,
  isStoryWithBanner,
  isLazyLoad,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const customCollapsedComponent = ({
    id,
    slug,
  }) => {
    _useDidMount(() => {
      const timeout = setTimeout(() => sendEvent({
        typeId: EVENTS.MEDIA_CLICK,
        mediaId: id,
      }), 5000)
      return () => {
        clearTimeout(timeout)
      }
    })

    const handleClick = () => {
      sendEvent({
        typeId: EVENTS.MEDIA_CLICK,
        mediaId: id,
      })
      const thoughtDetailsPagePath = formatRoute(routes.thoughtDetails, { slug })
      history.push(thoughtDetailsPagePath)
    }
    return (
      <div
        role="button"
        className="see-more-button"
        onClick={() => handleClick()}>
        <span>See more</span>
      </div>
    )
  }

  const bannerSeeMore = (url) => {
    window.open(url, '_blank')

    return null
  }

  const renderPhoto = (creator) => (creator.photo ? getUrlByMediaPrefix.photo(creator.photo, creator.id) : avatar)

  const newStories = items.map((item, index) => {
    if (currentStory === item.id) {
      if (index !== currentIndex) {
        setCurrentIndex(index)
      }
    }
    return ({
      url: item.media.urls?.p480 ? getUrlByMediaPrefix.thoughts(item.media.urls.p480) : getUrlByMediaPrefix.thoughts(item.media.urls?.p360),
      type: 'video',
      header: {
        subheading: item.speakers_info,
        profileImage: item.creator ? renderPhoto(item.creator) : avatar,
      },
      seeMoreCollapsed: () => customCollapsedComponent({
        id: item.id,
        slug: item.slug,
      }),
      seeMore: () => null,
    })
  })

  if (isStoryWithBanner && isMobile()) {
    const banner = {
      url: 'https://urx-videos.s3.amazonaws.com/51883497/Thought_CTA_Artwork.png',
      seeMoreCollapsed: customCollapsedComponent,
      seeMore: () => bannerSeeMore('https://www.thoughts.joinlearners.com'),
    }

    newStories.splice(2, 0, banner)
  }

  const renderCustomHeader = (config) => (
    <div className="stories__header">
      <img
        alt="Avatar"
        src={config.profileImage}
        className="stories__header-image"
      />
      <div className="stories__header-title">
        {config.subheading}
      </div>
    </div>
  )

  const renderStoriesComponent = (isMobile) => (
    <StoriesComponent
      stories={newStories}
      width="100%"
      height={isMobile ? '640px' : '100%'}
      currentIndex={currentIndex}
      storyStyles={{
        width: '487px',
      }}
      header={(config) => renderCustomHeader(config)}
      loop
    />
  )

  return (
    <>
      {isLazyLoad ? (
        <LazyLoad height={640}>
          {renderStoriesComponent(true)}
        </LazyLoad>
      ) : (
        renderStoriesComponent(false)
      )}
    </>
  )
}

Stories.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
  currentStory: PropTypes.number,
  sendEvent: PropTypes.func.isRequired,
  isStoryWithBanner: PropTypes.bool,
  isLazyLoad: PropTypes.bool,
}

Stories.defaultProps = {
  currentStory: 0,
  isStoryWithBanner: false,
  isLazyLoad: false,
}

const actionsToProps = {
  sendEvent,
}

export default connect(null, actionsToProps)(Stories)
