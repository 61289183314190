import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'

/* helpers */
import getUrlByMediaPrefix from 'helpers/get-url-by-media-prefix'

/* styles */
import clsx from 'clsx'
import '../../Category/category.scss'

const ContentCardItem = ({
  id,
  description,
  link,
  image,
  video,
  isImageWithMediaPrefix,
}) => {
  const [inHover, setHover] = useState(false)
  const [isLoadedVideo, setIsLoadedVideo] = useState(false)
  const [isLoadedImg, setIsLoadedImg] = useState(false)
  const videoRef = useRef()

  const handleOnMouseEnter = () => {
    if (video) {
      setHover(true)
    }
  }

  const handleOnMouseLeave = () => {
    if (video) {
      setHover(false)
    }
  }

  const handleClick = () => {
    window.open(link, '_blank')
  }

  const handleLoaded = () => {
    setIsLoadedVideo(true)
  }

  if (isLoadedVideo && inHover) {
    videoRef?.current?.play()
  }

  const handleContextMenu = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const renderSpinner = () => (
    <div
      role="button"
      className="preview-spinner"
      onClick={handleClick}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  )

  return (
    <div
      role="button"
      className="item">
      <LazyLoad height={160}>
        <div className="video-preview">
          {inHover && (
            <video
              ref={videoRef}
              className="video"
              src={getUrlByMediaPrefix.video(video)}
              muted
              loop
              onLoadedData={handleLoaded}
              onClick={handleClick}
            />
          )}

          <div
            className="image-preview-wrapper"
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}>
            <img
              role="button"
              aria-label="Open content details"
              className={clsx('image-preview',
                inHover && isLoadedVideo && 'hide',
                isLoadedImg && 'lazyload')}
              alt=""
              src={isImageWithMediaPrefix ? getUrlByMediaPrefix.contentCard(image, id) : image}
              onLoad={() => setIsLoadedImg(true)}
              onClick={handleClick}
              onContextMenu={handleContextMenu}
            />

            {!isLoadedImg && renderSpinner()}

            {inHover && !isLoadedVideo && renderSpinner()}
          </div>
        </div>
      </LazyLoad>

      <a
        href={link}
        rel="noreferrer noopener"
        target="_blank"
        className={clsx('wrapper-info', 'description', 'content-card-description')}>
        {description}
      </a>
    </div>
  )
}

ContentCardItem.propTypes = {
  id: PropTypes.number,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  video: PropTypes.string,
  isImageWithMediaPrefix: PropTypes.bool,
}

ContentCardItem.defaultProps = {
  id: 0,
  video: null,
  isImageWithMediaPrefix: true,
}

export default ContentCardItem
