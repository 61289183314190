import React from 'react'
import * as PropTypes from 'prop-types'
import Slider from 'react-slick'
import clsx from 'clsx'

/* styles */
import '../Category/category.scss'

/* helpers */
import isMobile from 'helpers/check-is-mobile-device'

/* components */
import CustomArrow from 'components/ui/CustomArrow'
import ContentCardItem from './ContentCardItem'

const ContentCards = ({
  items,
}) => {
  const renderContentCards = () => (
    items.map((item, key) => (
      <ContentCardItem
        key={key}
        id={item.id}
        description={item.description}
        link={item.link}
        image={item.image}
        video={item.video}
      />
    ))
  )

  const getSlidesCount = (defaultValue, skipMobile) => {
    if (isMobile() && !skipMobile) {
      return 1.5
    }

    if (items.length < defaultValue) {
      return items.length
    }

    return defaultValue
  }

  return (
    <div className={clsx('wrapper-category', 'slider-category')}>
      <Slider
        variableWidth
        dots={false}
        infinite={false}
        speed={500}
        slidesToShow={getSlidesCount(4)}
        slidesToScroll={1}
        arrows
        nextArrow={<CustomArrow />}
        prevArrow={<CustomArrow isPrevArrow />}
        swipe
        responsive={[
          {
            breakpoint: 1399,
            settings: {
              slidesToShow: getSlidesCount(3),
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: getSlidesCount(2, true),
            },
          },
          {
            breakpoint: 992,
            settings: {
              arrows: false,
              slidesToShow: getSlidesCount(2, true),
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              arrows: false,
            },
          },
        ]}>
        {renderContentCards()}
      </Slider>
    </div>
  )
}

ContentCards.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array,
}

ContentCards.defaultProps = {
  items: [],
}

export default ContentCards
