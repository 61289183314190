import React from 'react'
import * as PropTypes from 'prop-types'
import clsx from 'clsx'

/* helpers */
import getUrlByMediaPrefix from 'helpers/get-url-by-media-prefix'

/* assets */
import avatar from 'assets/images/default_avatar.png'

/* styles */
import classes from './EventCard.module.scss'

const EventCard = ({ eventInfo }) => {
  const handleOpenEvent = () => {
    window.open('https://uxrconf23.joinlearners.com/', '_blank')
  }
  const getCreatorPhoto = () => {
    if (eventInfo.id && eventInfo.banner) {
      return getUrlByMediaPrefix.eventPhoto(eventInfo.banner)
    }
    return avatar
  }
  return (
    <div className={classes.eventCard} onClick={handleOpenEvent} role="button">
      <div className={classes.logoContent}>
        <img className={classes.eventBanner} src={getCreatorPhoto()} alt="event-logo" />
      </div>
      <div className={classes.infoContent}>
        <h5 className={clsx(classes.eventTitle, 'text-multiline-ellipsis')}>{eventInfo.title}</h5>
        <p className={clsx(classes.eventDescription, 'text-multiline-ellipsis')}>{eventInfo.description} </p>
        <p className={classes.eventDateTime}>{eventInfo.date_time} </p>
      </div>
    </div>
  )
}

EventCard.propTypes = {
  eventInfo: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    date_time: PropTypes.string.isRequired,
    banner: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
}

EventCard.defaultProps = {
  eventInfo: {},
}
export default EventCard
