import React from 'react'
import PropTypes from 'prop-types'
import { formatRoute } from 'react-router-named-routes'

/* components */
import BaseLink from 'components/ui/Link/Base'

/* routes */
import routes from 'routes'

/* state-manager */
import { history } from 'state-manager/store'

/* helpers */
import getUrlByMediaPrefix from 'helpers/get-url-by-media-prefix'

const CategoryCreator = ({
  miniSeriesSlug,
  title,
  creator,
}) => {
  const seriesPagePath = formatRoute(routes.series, { slug: miniSeriesSlug })
  const creatorPagePath = formatRoute(routes.creator, { slug: creator.slug })

  return (
    <div className="creator__wrapper">
      <div
        role="button"
        className="avatar"
        onClick={() => history.push(creatorPagePath)}>
        <img src={getUrlByMediaPrefix.photo(creator.photo, creator.id)} alt="" className="img" />
      </div>
      <div
        role="button"
        onClick={() => history.push(seriesPagePath)}
        className="info__wrapper">
        <div className="profile">
          <div className="title">{title}</div>
          <div className="description">
            <div className="with">
              {'with '}
              <span className="font-bold">{` ${creator.first_name} ${creator.last_name}`}</span>
            </div>

            <BaseLink
              path={seriesPagePath}
              variant="primary"
              title="View all"
            />
          </div>
        </div>
      </div>

    </div>
  )
}

CategoryCreator.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  creator: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  miniSeriesSlug: PropTypes.string.isRequired,
}

export default CategoryCreator
