import React from 'react'
import * as PropTypes from 'prop-types'
import clsx from 'clsx'
import { formatRoute } from 'react-router-named-routes'

/* components */
import SpriteIcon from 'components/icons/SpriteIcon'

/* state-manager */
import { history } from 'state-manager/store'

/* utils */
import customEvent from 'utils/custom-event'

/* helpers */
import getUrlByMediaPrefix from 'helpers/get-url-by-media-prefix'

/* constants */
import { TOGGLE__VISIBILITY_DOWNLOAD_APP_MODAL } from 'constants/custom-events'

/* routes */
import routes from 'routes'

/* assets */
import avatar from 'assets/images/default_avatar.png'
import askQuestion from 'assets/images/ask-question.png'
import verifyMark from 'assets/images/verifyMark.svg'

/* styles */
import classes from '../QuestionCarousel.module.scss'

const QuestionSlide = ({ question }) => {
  const handleAuthorClick = () => {
    const detailsPagePath = formatRoute(routes.creator, { slug: question.user.slug })
    history.push(detailsPagePath)
  }

  const handleQuestionClick = () => {
    const detailsPagePath = formatRoute(routes.questionPage, { slug: question.slug })
    history.push(detailsPagePath)
  }

  const getCreatorPhoto = (user) => {
    if (user.id && user.photo) {
      return getUrlByMediaPrefix.photo(user.photo, user.id)
    }
    return avatar
  }

  const getWorkRole = (creator) => {
    if (creator.work_role) {
      return creator.company ? `${creator.work_role} at ${creator.company}` : creator.work_role
    }
    return creator.company ? `${creator.discipline} at ${creator.company}` : creator.discipline
  }
  return (
    <div className={classes.questionWrapper}>
      <div className={classes.questionContent}>
        <div className={classes.questionUser}>
          <p className={clsx(classes.question, 'text-multiline-ellipsis')} onClick={handleQuestionClick} role="button">
            {question.question}
          </p>

          <div className={classes.questionAuthor} onClick={question.user && handleAuthorClick} role="button">
            <div className={classes.avatarWrapper}>
              <img
                className={classes.avatar}
                src={question.is_anonymously ? askQuestion : getCreatorPhoto(question.user)}
                alt="Avatar"
              />
              {question.user?.is_verified && <img src={verifyMark} className={classes.verifyMark} alt="verifyMark" />}
            </div>

            <div className={classes.profileInfo}>
              {question.is_anonymously ? (
                <div>
                  Question submitted anonymously
                </div>
              ) :
                (
                  <div className={classes.handle}>
                    {`@${question.user.handle}`}
                  </div>
                )}

              <div className={classes.workPosition}>
                {question.user && getWorkRole(question.user)}
              </div>
            </div>
          </div>

        </div>
        <div
          className={classes.questionViews}
          onClick={() => customEvent.trigger(TOGGLE__VISIBILITY_DOWNLOAD_APP_MODAL)}
          role="button">
          <SpriteIcon
            name="triangle-top"
            size="sm"
          />
          <p>{question.question_vote_count}</p>
        </div>
      </div>
      <div
        className={classes.questionResponses}
        onClick={handleQuestionClick}
        role="button">
        <img src={askQuestion} alt="askQuestion" className={classes.askQuestion} />
        {question.answers_count ?? 0} Responses
        <SpriteIcon
          name="slider-next"
          size="sm"
        />
      </div>
    </div>

  )
}
QuestionSlide.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.number.isRequired,
    question: PropTypes.string.isRequired,
    is_anonymously: PropTypes.bool.isRequired,
    answers_count: PropTypes.number.isRequired,
    question_vote_count: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    user: PropTypes.shape({
      company: PropTypes.string,
      discipline: PropTypes.string,
      id: PropTypes.number,
      photo: PropTypes.string,
      slug: PropTypes.string,
      handle: PropTypes.string,
      work_role: PropTypes.string,
      is_verified: PropTypes.bool,
    }).isRequired,
  }),
}
QuestionSlide.defaultProps = {
  question: {},
}

export default QuestionSlide
