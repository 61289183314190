import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import classes from './Button.module.scss'
import SpriteIcon from '../../../icons/SpriteIcon'

const Button = (
  {
    Btn,
    color,
    variant,
    text,
    size,
    spacing,
    onClick,
    disabled,
    type,
    nameIcon,
    sizeIcon,
    colorIcon,
    isBold,
    style,
    className,
    withArrow,
  },
) => (
  <button
    /* eslint-disable-next-line react/button-has-type */
    type={type}
    style={style}
    className={clsx(classes[color], spacing, classes[size], classes[Btn], classes[variant], 'd-flex align-items-center', className)}
    onClick={onClick}
    disabled={disabled}>
    {nameIcon ? <SpriteIcon name={nameIcon} className={classes.icon} size={sizeIcon} colorIcon={colorIcon} /> : null}
    <span className={clsx('button-text', isBold ? classes.isBold : null)}>
      {text}
    </span>
    {withArrow ? <SpriteIcon name="button_arrow" size="md" className="btn-arrow-icon" /> : null}
  </button>
)

Button.propTypes = {
  color: PropTypes.string,
  Btn: PropTypes.oneOf(['basic-button', 'redesign-button']),
  text: PropTypes.string,
  spacing: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary', 'streamBtn', 'transparent']),
  size: PropTypes.oneOf(['extraSmall', 'small', 'medium', 'large', 'max-content']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  nameIcon: PropTypes.string,
  isBold: PropTypes.bool,
  sizeIcon: PropTypes.string,
  colorIcon: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  className: PropTypes.string,
  withArrow: PropTypes.bool,
}

Button.defaultProps = {
  type: 'submit',
  Btn: 'basic-button',
  variant: 'primary',
  text: '',
  color: '',
  size: 'large',
  spacing: '',
  sizeIcon: '',
  nameIcon: '',
  colorIcon: '',
  disabled: false,
  isBold: true,
  onClick: null,
  style: {},
  className: '',
  withArrow: false,
}

export default Button
