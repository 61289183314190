import React from 'react'
import * as PropTypes from 'prop-types'
import clsx from 'clsx'

import getUrlByMediaPrefix from 'helpers/get-url-by-media-prefix'
import avatar from 'assets/images/default_avatar.png'
import verifyMark from 'assets/images/verifyMark.svg'

import { formatRoute } from 'react-router-named-routes'
import classes from '../CreatorsCarousel.module.scss'
import routes from '../../../../routes'
import { history } from '../../../../state-manager/store'

const CreatorCard = ({ creator, isAdvisors, isCompany }) => {
  const getCreatorPhoto = (advisor, isAdvisor, isCompanies) => {
    if (advisor.id && (advisor.photo || advisor.company_logo || advisor.avatar)) {
      if (isCompanies) return getUrlByMediaPrefix.companyPhoto(advisor.company_logo, advisor.id)
      if (isAdvisor) return getUrlByMediaPrefix.advisorPhoto(advisor.avatar, advisor.id)
      return getUrlByMediaPrefix.photo(advisor.photo, advisor.id)
    }
    return avatar
  }

  const getWorkRole = (creator) => {
    if (creator.work_role) {
      return creator.company ? `${creator.work_role} at ${creator.company}` : creator.work_role
    }
    return creator.company ? `${creator.discipline} at ${creator.company}` : creator.discipline
  }

  const getName = (user) => {
    if (isAdvisors) {
      return isCompany ? user.company_name : user.full_name
    }
    return `${user.first_name} ${user.last_name}`
  }

  const getDescription = (user) => {
    if (isAdvisors) {
      return isCompany ? user.company_description : user.position
    }
    return getWorkRole(user)
  }

  const handleOpenLink = (url) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = `http://${url}`
    }
    window.open(url, '_blank')
  }

  const handleAuthorClick = () => {
    if (!isAdvisors) {
      const detailsPagePath = formatRoute(routes.creator, { slug: creator.slug })
      history.push(detailsPagePath)
    } else if (creator.link) {
      handleOpenLink(creator.link)
    }
  }

  return (
    <div
      className={clsx(classes.creatorWrapper, isAdvisors && classes.advisorModify)}
      onClick={handleAuthorClick}
      role="button">
      <div className={classes.avatar}>
        <img
          className={clsx(classes.photo, isAdvisors && classes.advisorLogo, isCompany && classes.companyLogo)}
          src={getCreatorPhoto(creator, isAdvisors, isCompany)}
          alt="Avatar"
        />
        {creator.is_verified && <img src={verifyMark} alt="verifyMark" className={classes.verify} />}
      </div>
      <div className={classes.creatorInfo}>
        <h5 className={clsx(classes.creatorName, 'text-multiline-ellipsis')}>{getName(creator)}</h5>
        <p className={clsx(classes.creatorJob, 'text-multiline-ellipsis')}>{getDescription(creator)}</p>
      </div>
    </div>
  )
}

CreatorCard.propTypes = {
  creator: PropTypes.shape({
    id: PropTypes.number.isRequired,
    slug: PropTypes.oneOfType([PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired]),
    discipline: PropTypes.oneOfType([PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired]),
    work_role: PropTypes.oneOfType([PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired]),
    company: PropTypes.oneOfType([PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired]),
    is_verified: PropTypes.oneOfType([PropTypes.bool.isRequired,
      PropTypes.oneOf([null]).isRequired]),
    link: PropTypes.oneOfType([PropTypes.string.isRequired,
      PropTypes.oneOf([null]).isRequired]),
  }),
  isAdvisors: PropTypes.bool,
  isCompany: PropTypes.bool,
}

CreatorCard.defaultProps = {
  creator: {},
  isAdvisors: false,
  isCompany: false,
}
export default CreatorCard
