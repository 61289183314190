import { persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createBlacklistFilter } from 'redux-persist-transform-filter'
import { connectRouter } from 'connected-react-router'

/* reducers */
import appDataReducer from 'state-manager/reducers/app-data'
import userDataReducer from 'state-manager/reducers/user-data'
import subscriptionInfoReducer from 'state-manager/reducers/subscription-info'
import contentReducer from 'state-manager/reducers/content'
import chatReducer from 'state-manager/reducers/chat-data'

const reducers = {
  appData: appDataReducer,
  userData: userDataReducer,

  subscriptionInfo: subscriptionInfoReducer,
  content: contentReducer,
  chat: chatReducer,
}

const filterTransforms = []
for (const reducer in reducers) {
  filterTransforms.push(
    createBlacklistFilter(reducer, ['responseWaiting']),
  )
}

const transforms = [...filterTransforms]
const version = 'v-3.0'
const persistConfig = {
  key: `root-${version}`,
  storage,
  whitelist: ['userData'],
  transforms,
}

export default (history) => persistCombineReducers(
  persistConfig,
  {
    router: connectRouter(history),
    ...reducers,
  },
)
