import React, { useState } from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

/* constants */
import { TOGGLE__VISIBILITY_SIGNUP_MODAL } from 'constants/custom-events'

/* hooks */
import _useCustomEventListener from 'hooks/use-custom-event-listener'
import _useDidUpdate from 'hooks/lifecycle/use-did-update'

/* assets */
import signUpStep1Cover from 'assets/images/sign_up_step1_cover.png'

/* styles */
import clsx from 'clsx'
import './styles.scss'

/* components */
import { CloseModalButton } from 'components/ui/CloseModalButton'
import Step1 from './components/step1'
import Step2 from './components/step2'

const SignUp = ({
  currentStep,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleClose = () => setIsOpen(false)

  _useCustomEventListener(TOGGLE__VISIBILITY_SIGNUP_MODAL, () => setIsOpen(!isOpen))

  _useDidUpdate(() => {
    if (currentStep === 3) {
      handleClose()
    }
  })

  const renderCurrentStepContent = () => {
    switch (currentStep) {
      case 1:
        return <Step1 />
      case 2:
        return <Step2 />
      default:
        return null
    }
  }

  const renderCurrentStepImage = () => {
    switch (currentStep) {
      case 1:
        return <img src={signUpStep1Cover} alt="Sign up step 1 cover" />
      case 2:
        return <img src={signUpStep1Cover} alt="Sign up step 1 cover" />
      default:
        return null
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="SignUp-modal"
      ariaHideApp={false}
      overlayClassName="SignUp-overlay">
      <CloseModalButton right={10} top={10} onClick={handleClose} color="grey" className="SignUp-close-button" />

      <div className="SignUp">
        <div className={clsx('SignUp-content', currentStep === 3 && 'SignUp-content-wide')}>
          <div className={clsx('SignUp-content-wrapper', currentStep === 3 && 'step3-wrapper')}>
            {renderCurrentStepContent()}
          </div>
        </div>

        {currentStep < 3 && (
          <div className="SignUp-image">
            {renderCurrentStepImage()}
          </div>
        )}
      </div>
    </Modal>
  )
}

SignUp.propTypes = {
  currentStep: PropTypes.number.isRequired,
}

const mapStateToProps = (state) => ({
  currentStep: state.userData.signUpStep,
})

export default connect(mapStateToProps)(SignUp)
