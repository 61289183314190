export default {
  'product-component-1695953397471': `(function () {
      var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
      if (window.ShopifyBuy) {
        if (window.ShopifyBuy.UI) {
          ShopifyBuyInit();
        } else {
          loadScript();
        }
      } else {
        loadScript();
      }
      function loadScript() {
        var script = document.createElement('script');
        script.async = true;
        script.src = scriptURL;
        (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
        script.onload = ShopifyBuyInit;
      }
      function ShopifyBuyInit() {
        var client = ShopifyBuy.buildClient({
          domain: '87ea82.myshopify.com',
          storefrontAccessToken: 'dad8dd7a9c329184721806d7bd79c130',
        });
        ShopifyBuy.UI.onReady(client).then(function (ui) {
          ui.createComponent('product', {
            id: '8650815013147',
            node: document.getElementById('product-component-1695953397471'),
            moneyFormat: '%24%7B%7Bamount%7D%7D',
            options: {
      "product": {
        "iframe": false,
        "contents": {
          "img": false,
          "title": false,
          "price": false
        },
        "text": {
          "button": "Buy in-person ticket $499"
        },
        "googleFonts": [
          "Montserrat"
        ]
      },
      "productSet": {
        "styles": {
          "products": {
            "@media (min-width: 601px)": {
              "margin-left": "-20px"
            }
          }
        }
      },
      "modalProduct": {
        "contents": {
          "img": false,
          "imgWithCarousel": true,
          "button": false,
          "buttonWithQuantity": true
        },
        "styles": {
          "product": {
            "@media (min-width: 601px)": {
              "max-width": "100%",
              "margin-left": "0px",
              "margin-bottom": "0px"
            }
          },
          "button": {
            "width": "100%",
            "font-family": "Montserrat, sans-serif",
            "font-weight": "bold",
            "font-size": "13px",
            "padding-top": "14.5px",
            "padding-bottom": "14.5px",
            ":hover": {
              "background-color": "#473abd"
            },
            "background-color": "#4f40d2",
            ":focus": {
              "background-color": "#473abd"
            },
            "border-radius": "5px",
            "padding-left": "50px",
            "padding-right": "50px"
          },
          "quantityInput": {
            "font-size": "13px",
            "padding-top": "14.5px",
            "padding-bottom": "14.5px"
          },
          "title": {
            "font-family": "Montserrat, sans-serif",
            "font-weight": "bold",
            "font-size": "26px",
            "color": "#4c4c4c"
          }
        },
        "googleFonts": [
          "Montserrat"
        ],
        "text": {
          "button": "Add to cart"
        }
      },
      "option": {},
      "cart": {
        "styles": {
          "button": {
            "width": "100%",
            "font-family": "Montserrat, sans-serif",
            "font-weight": "bold",
            "font-size": "13px",
            "padding-top": "14.5px",
            "padding-bottom": "14.5px",
            ":hover": {
              "background-color": "#473abd"
            },
            "background-color": "#4f40d2",
            ":focus": {
              "background-color": "#473abd"
            },
            "border-radius": "5px"
          },
          "title": {
            "color": "#4f40d2"
          },
          "header": {
            "color": "#4f40d2"
          },
          "lineItems": {
            "color": "#4f40d2"
          },
          "subtotalText": {
            "color": "#4f40d2"
          },
          "subtotal": {
            "color": "#4f40d2"
          },
          "notice": {
            "color": "#4f40d2"
          },
          "currency": {
            "color": "#4f40d2"
          },
          "close": {
            "color": "#4f40d2",
            ":hover": {
              "color": "#4f40d2"
            }
          },
          "empty": {
            "color": "#4f40d2"
          },
          "noteDescription": {
            "color": "#4f40d2"
          },
          "discountText": {
            "color": "#4f40d2"
          },
          "discountIcon": {
            "fill": "#4f40d2"
          },
          "discountAmount": {
            "color": "#4f40d2"
          },
          "cart": {
            "background-color": "#f6f5ff"
          },
          "footer": {
            "background-color": "#f6f5ff"
          }
        },
        "text": {
          "total": "Subtotal",
          "button": "Checkout"
        },
        "googleFonts": [
          "Montserrat"
        ]
      },
      "toggle": {
        "styles": {
          "toggle": {
            "font-family": "Montserrat, sans-serif",
            "font-weight": "bold",
            "background-color": "#4f40d2",
            ":hover": {
              "background-color": "#473abd"
            },
            ":focus": {
              "background-color": "#473abd"
            }
          },
          "count": {
            "font-size": "13px"
          }
        },
        "googleFonts": [
          "Montserrat"
        ]
      },
      "lineItem": {
        "styles": {
          "variantTitle": {
            "color": "#4f40d2"
          },
          "title": {
            "color": "#4f40d2"
          },
          "price": {
            "color": "#4f40d2"
          },
          "fullPrice": {
            "color": "#4f40d2"
          },
          "discount": {
            "color": "#4f40d2"
          },
          "discountIcon": {
            "fill": "#4f40d2"
          },
          "quantity": {
            "color": "#4f40d2"
          },
          "quantityIncrement": {
            "color": "#4f40d2",
            "border-color": "#4f40d2"
          },
          "quantityDecrement": {
            "color": "#4f40d2",
            "border-color": "#4f40d2"
          },
          "quantityInput": {
            "color": "#4f40d2",
            "border-color": "#4f40d2"
          }
        }
      }
    },
          });
        });
      }
    })();`,
  'product-component-1695953227420': `(function () {
      var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
      if (window.ShopifyBuy) {
        if (window.ShopifyBuy.UI) {
          ShopifyBuyInit();
        } else {
          loadScript();
        }
      } else {
        loadScript();
      }
      function loadScript() {
        var script = document.createElement('script');
        script.async = true;
        script.src = scriptURL;
        (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
        script.onload = ShopifyBuyInit;
      }
      function ShopifyBuyInit() {
        var client = ShopifyBuy.buildClient({
          domain: '87ea82.myshopify.com',
          storefrontAccessToken: 'dad8dd7a9c329184721806d7bd79c130',
        });
        ShopifyBuy.UI.onReady(client).then(function (ui) {
          ui.createComponent('product', {
            id: '8650782933275',
            node: document.getElementById('product-component-1695953227420'),
            moneyFormat: '%24%7B%7Bamount%7D%7D',
            options: {
      "product": {
        "iframe": false,
        "contents": {
          "img": false,
          "title": false,
          "price": false
        },
        "text": {
          "button": "In-person ticket $49"
        },
        "googleFonts": [
          "Montserrat"
        ]
      },
      "productSet": {
        "styles": {
          "products": {
            "@media (min-width: 601px)": {
              "margin-left": "-20px"
            }
          }
        }
      },
      "modalProduct": {
        "contents": {
          "img": false,
          "imgWithCarousel": true,
          "button": false,
          "buttonWithQuantity": true
        },
        "styles": {
          "product": {
            "@media (min-width: 601px)": {
              "max-width": "100%",
              "margin-left": "0px",
              "margin-bottom": "0px"
            }
          },
          "button": {
            "width": "100%",
            "font-family": "Montserrat, sans-serif",
            "font-weight": "bold",
            "font-size": "13px",
            "padding-top": "14.5px",
            "padding-bottom": "14.5px",
            "color": "#4f40d2",
            ":hover": {
              "color": "#4f40d2",
              "background-color": "#cccae6"
            },
            "background-color": "#e3e0ff",
            ":focus": {
              "background-color": "#cccae6"
            },
            "border-radius": "5px",
            "padding-left": "50px",
            "padding-right": "50px"
          },
          "quantityInput": {
            "font-size": "13px",
            "padding-top": "14.5px",
            "padding-bottom": "14.5px"
          },
          "title": {
            "font-family": "Montserrat, sans-serif",
            "font-weight": "bold",
            "font-size": "26px",
            "color": "#4c4c4c"
          }
        },
        "googleFonts": [
          "Montserrat"
        ],
        "text": {
          "button": "Add to cart"
        }
      },
      "option": {},
      "cart": {
        "styles": {
          "button": {
            "width": "100%",
            "font-family": "Montserrat, sans-serif",
            "font-weight": "bold",
            "font-size": "13px",
            "padding-top": "14.5px",
            "padding-bottom": "14.5px",
            "color": "#4f40d2",
            ":hover": {
              "color": "#4f40d2",
              "background-color": "#cccae6"
            },
            "background-color": "#e3e0ff",
            ":focus": {
              "background-color": "#cccae6"
            },
            "border-radius": "5px"
          },
          "title": {
            "color": "#4f40d2"
          },
          "header": {
            "color": "#4f40d2"
          },
          "lineItems": {
            "color": "#4f40d2"
          },
          "subtotalText": {
            "color": "#4f40d2"
          },
          "subtotal": {
            "color": "#4f40d2"
          },
          "notice": {
            "color": "#4f40d2"
          },
          "currency": {
            "color": "#4f40d2"
          },
          "close": {
            "color": "#4f40d2",
            ":hover": {
              "color": "#4f40d2"
            }
          },
          "empty": {
            "color": "#4f40d2"
          },
          "noteDescription": {
            "color": "#4f40d2"
          },
          "discountText": {
            "color": "#4f40d2"
          },
          "discountIcon": {
            "fill": "#4f40d2"
          },
          "discountAmount": {
            "color": "#4f40d2"
          },
          "cart": {
            "background-color": "#f6f5ff"
          },
          "footer": {
            "background-color": "#f6f5ff"
          }
        },
        "text": {
          "total": "Subtotal",
          "button": "Checkout"
        },
        "googleFonts": [
          "Montserrat"
        ]
      },
      "toggle": {
        "styles": {
          "toggle": {
            "font-family": "Montserrat, sans-serif",
            "font-weight": "bold",
            "background-color": "#e3e0ff",
            ":hover": {
              "background-color": "#cccae6"
            },
            ":focus": {
              "background-color": "#cccae6"
            }
          },
          "count": {
            "font-size": "13px",
            "color": "#4f40d2",
            ":hover": {
              "color": "#4f40d2"
            }
          },
          "iconPath": {
            "fill": "#4f40d2"
          }
        },
        "googleFonts": [
          "Montserrat"
        ]
      },
      "lineItem": {
        "styles": {
          "variantTitle": {
            "color": "#4f40d2"
          },
          "title": {
            "color": "#4f40d2"
          },
          "price": {
            "color": "#4f40d2"
          },
          "fullPrice": {
            "color": "#4f40d2"
          },
          "discount": {
            "color": "#4f40d2"
          },
          "discountIcon": {
            "fill": "#4f40d2"
          },
          "quantity": {
            "color": "#4f40d2"
          },
          "quantityIncrement": {
            "color": "#4f40d2",
            "border-color": "#4f40d2"
          },
          "quantityDecrement": {
            "color": "#4f40d2",
            "border-color": "#4f40d2"
          },
          "quantityInput": {
            "color": "#4f40d2",
            "border-color": "#4f40d2"
          }
        }
      }
    },
          });
        });
      }
  })();`,
  'product-component-1695953527959': `(function () {
    var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }
    function loadScript() {
      var script = document.createElement('script');
      script.async = true;
      script.src = scriptURL;
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
      script.onload = ShopifyBuyInit;
    }
    function ShopifyBuyInit() {
      var client = ShopifyBuy.buildClient({
        domain: '87ea82.myshopify.com',
        storefrontAccessToken: 'dad8dd7a9c329184721806d7bd79c130',
      });
      ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent('product', {
          id: '8650785358107',
          node: document.getElementById('product-component-1695953527959'),
          moneyFormat: '%24%7B%7Bamount%7D%7D',
          options: {
    "product": {
      "iframe": false,
      "styles": {
        "product": {
          "@media (min-width: 601px)": {
            "max-width": "calc(25% - 20px)",
            "margin-left": "20px",
            "margin-bottom": "50px"
          }
        },
        "title": {
          "font-family": "Montserrat, sans-serif",
          "color": "#8a36f5"
        },
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          ":hover": {
            "background-color": "#473abd"
          },
          "background-color": "#4f40d2",
          ":focus": {
            "background-color": "#473abd"
          },
          "border-radius": "5px",
          "padding-left": "50px",
          "padding-right": "50px"
        },
        "quantityInput": {
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px"
        }
      },
      "contents": {
        "img": false,
        "title": false,
        "price": false
      },
      "text": {
        "button": "Grab free remote ticket"
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "productSet": {
      "styles": {
        "products": {
          "@media (min-width: 601px)": {
            "margin-left": "-20px"
          }
        }
      }
    },
    "modalProduct": {
      "contents": {
        "img": false,
        "imgWithCarousel": true,
        "button": false,
        "buttonWithQuantity": true
      },
      "styles": {
        "product": {
          "@media (min-width: 601px)": {
            "max-width": "100%",
            "margin-left": "0px",
            "margin-bottom": "0px"
          }
        },
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          ":hover": {
            "background-color": "#473abd"
          },
          "background-color": "#4f40d2",
          ":focus": {
            "background-color": "#473abd"
          },
          "border-radius": "5px",
          "padding-left": "50px",
          "padding-right": "50px"
        },
        "quantityInput": {
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px"
        },
        "title": {
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "26px",
          "color": "#4c4c4c"
        }
      },
      "googleFonts": [
        "Montserrat"
      ],
      "text": {
        "button": "Add to cart"
      }
    },
    "option": {},
    "cart": {
      "styles": {
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          ":hover": {
            "background-color": "#473abd"
          },
          "background-color": "#4f40d2",
          ":focus": {
            "background-color": "#473abd"
          },
          "border-radius": "5px"
        },
        "title": {
          "color": "#4f40d2"
        },
        "header": {
          "color": "#4f40d2"
        },
        "lineItems": {
          "color": "#4f40d2"
        },
        "subtotalText": {
          "color": "#4f40d2"
        },
        "subtotal": {
          "color": "#4f40d2"
        },
        "notice": {
          "color": "#4f40d2"
        },
        "currency": {
          "color": "#4f40d2"
        },
        "close": {
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2"
          }
        },
        "empty": {
          "color": "#4f40d2"
        },
        "noteDescription": {
          "color": "#4f40d2"
        },
        "discountText": {
          "color": "#4f40d2"
        },
        "discountIcon": {
          "fill": "#4f40d2"
        },
        "discountAmount": {
          "color": "#4f40d2"
        },
        "cart": {
          "background-color": "#f6f5ff"
        },
        "footer": {
          "background-color": "#f6f5ff"
        }
      },
      "text": {
        "total": "Subtotal",
        "button": "Checkout"
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "toggle": {
      "styles": {
        "toggle": {
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "background-color": "#4f40d2",
          ":hover": {
            "background-color": "#473abd"
          },
          ":focus": {
            "background-color": "#473abd"
          }
        },
        "count": {
          "font-size": "13px"
        }
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "lineItem": {
      "styles": {
        "variantTitle": {
          "color": "#4f40d2"
        },
        "title": {
          "color": "#4f40d2"
        },
        "price": {
          "color": "#4f40d2"
        },
        "fullPrice": {
          "color": "#4f40d2"
        },
        "discount": {
          "color": "#4f40d2"
        },
        "discountIcon": {
          "fill": "#4f40d2"
        },
        "quantity": {
          "color": "#4f40d2"
        },
        "quantityIncrement": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        },
        "quantityDecrement": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        },
        "quantityInput": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        }
      }
    }
  },
        });
      });
    }
  })();`,
  'product-component-1695953493242': `(function () {
    var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }
    function loadScript() {
      var script = document.createElement('script');
      script.async = true;
      script.src = scriptURL;
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
      script.onload = ShopifyBuyInit;
    }
    function ShopifyBuyInit() {
      var client = ShopifyBuy.buildClient({
        domain: '87ea82.myshopify.com',
        storefrontAccessToken: 'dad8dd7a9c329184721806d7bd79c130',
      });
      ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent('product', {
          id: '8650798760219',
          node: document.getElementById('product-component-1695953493242'),
          moneyFormat: '%24%7B%7Bamount%7D%7D',
          options: {
    "product": {
      "iframe": false,
      "styles": {
        "product": {
          "@media (min-width: 601px)": {
            "max-width": "calc(25% - 20px)",
            "margin-left": "20px",
            "margin-bottom": "50px"
          }
        },
        "title": {
          "font-family": "Montserrat, sans-serif",
          "color": "#8a36f5"
        },
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          ":hover": {
            "background-color": "#473abd"
          },
          "background-color": "#4f40d2",
          ":focus": {
            "background-color": "#473abd"
          },
          "border-radius": "5px",
          "padding-left": "50px",
          "padding-right": "50px"
        },
        "quantityInput": {
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px"
        }
      },
      "contents": {
        "img": false,
        "title": false,
        "price": false
      },
      "text": {
        "button": "Grab free remote ticket"
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "productSet": {
      "styles": {
        "products": {
          "@media (min-width: 601px)": {
            "margin-left": "-20px"
          }
        }
      }
    },
    "modalProduct": {
      "contents": {
        "img": false,
        "imgWithCarousel": true,
        "button": false,
        "buttonWithQuantity": true
      },
      "styles": {
        "product": {
          "@media (min-width: 601px)": {
            "max-width": "100%",
            "margin-left": "0px",
            "margin-bottom": "0px"
          }
        },
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          ":hover": {
            "background-color": "#473abd"
          },
          "background-color": "#4f40d2",
          ":focus": {
            "background-color": "#473abd"
          },
          "border-radius": "5px",
          "padding-left": "50px",
          "padding-right": "50px"
        },
        "quantityInput": {
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px"
        },
        "title": {
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "26px",
          "color": "#4c4c4c"
        }
      },
      "googleFonts": [
        "Montserrat"
      ],
      "text": {
        "button": "Add to cart"
      }
    },
    "option": {},
    "cart": {
      "styles": {
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          ":hover": {
            "background-color": "#473abd"
          },
          "background-color": "#4f40d2",
          ":focus": {
            "background-color": "#473abd"
          },
          "border-radius": "5px"
        },
        "title": {
          "color": "#4f40d2"
        },
        "header": {
          "color": "#4f40d2"
        },
        "lineItems": {
          "color": "#4f40d2"
        },
        "subtotalText": {
          "color": "#4f40d2"
        },
        "subtotal": {
          "color": "#4f40d2"
        },
        "notice": {
          "color": "#4f40d2"
        },
        "currency": {
          "color": "#4f40d2"
        },
        "close": {
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2"
          }
        },
        "empty": {
          "color": "#4f40d2"
        },
        "noteDescription": {
          "color": "#4f40d2"
        },
        "discountText": {
          "color": "#4f40d2"
        },
        "discountIcon": {
          "fill": "#4f40d2"
        },
        "discountAmount": {
          "color": "#4f40d2"
        },
        "cart": {
          "background-color": "#f6f5ff"
        },
        "footer": {
          "background-color": "#f6f5ff"
        }
      },
      "text": {
        "total": "Subtotal",
        "button": "Checkout"
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "toggle": {
      "styles": {
        "toggle": {
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "background-color": "#4f40d2",
          ":hover": {
            "background-color": "#473abd"
          },
          ":focus": {
            "background-color": "#473abd"
          }
        },
        "count": {
          "font-size": "13px"
        }
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "lineItem": {
      "styles": {
        "variantTitle": {
          "color": "#4f40d2"
        },
        "title": {
          "color": "#4f40d2"
        },
        "price": {
          "color": "#4f40d2"
        },
        "fullPrice": {
          "color": "#4f40d2"
        },
        "discount": {
          "color": "#4f40d2"
        },
        "discountIcon": {
          "fill": "#4f40d2"
        },
        "quantity": {
          "color": "#4f40d2"
        },
        "quantityIncrement": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        },
        "quantityDecrement": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        },
        "quantityInput": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        }
      }
    }
  },
        });
      });
    }
  })();`,
  'product-component-1695953433592': `(function () {
    var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }
    function loadScript() {
      var script = document.createElement('script');
      script.async = true;
      script.src = scriptURL;
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
      script.onload = ShopifyBuyInit;
    }
    function ShopifyBuyInit() {
      var client = ShopifyBuy.buildClient({
        domain: '87ea82.myshopify.com',
        storefrontAccessToken: 'dad8dd7a9c329184721806d7bd79c130',
      });
      ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent('product', {
          id: '8650854629659',
          node: document.getElementById('product-component-1695953433592'),
          moneyFormat: '%24%7B%7Bamount%7D%7D',
          options: {
    "product": {
      "iframe": false,
      "styles": {
        "product": {
          "@media (min-width: 601px)": {
            "max-width": "calc(25% - 20px)",
            "margin-left": "20px",
            "margin-bottom": "50px"
          }
        },
        "title": {
          "font-family": "Montserrat, sans-serif",
          "color": "#8a36f5"
        },
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          ":hover": {
            "background-color": "#473abd"
          },
          "background-color": "#4f40d2",
          ":focus": {
            "background-color": "#473abd"
          },
          "border-radius": "5px",
          "padding-left": "50px",
          "padding-right": "50px"
        },
        "quantityInput": {
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px"
        }
      },
      "contents": {
        "img": false,
        "title": false,
        "price": false
      },
      "text": {
        "button": "Grab free remote ticket"
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "productSet": {
      "styles": {
        "products": {
          "@media (min-width: 601px)": {
            "margin-left": "-20px"
          }
        }
      }
    },
    "modalProduct": {
      "contents": {
        "img": false,
        "imgWithCarousel": true,
        "button": false,
        "buttonWithQuantity": true
      },
      "styles": {
        "product": {
          "@media (min-width: 601px)": {
            "max-width": "100%",
            "margin-left": "0px",
            "margin-bottom": "0px"
          }
        },
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          ":hover": {
            "background-color": "#473abd"
          },
          "background-color": "#4f40d2",
          ":focus": {
            "background-color": "#473abd"
          },
          "border-radius": "5px",
          "padding-left": "50px",
          "padding-right": "50px"
        },
        "quantityInput": {
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px"
        },
        "title": {
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "26px",
          "color": "#4c4c4c"
        }
      },
      "googleFonts": [
        "Montserrat"
      ],
      "text": {
        "button": "Add to cart"
      }
    },
    "option": {},
    "cart": {
      "styles": {
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          ":hover": {
            "background-color": "#473abd"
          },
          "background-color": "#4f40d2",
          ":focus": {
            "background-color": "#473abd"
          },
          "border-radius": "5px"
        },
        "title": {
          "color": "#4f40d2"
        },
        "header": {
          "color": "#4f40d2"
        },
        "lineItems": {
          "color": "#4f40d2"
        },
        "subtotalText": {
          "color": "#4f40d2"
        },
        "subtotal": {
          "color": "#4f40d2"
        },
        "notice": {
          "color": "#4f40d2"
        },
        "currency": {
          "color": "#4f40d2"
        },
        "close": {
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2"
          }
        },
        "empty": {
          "color": "#4f40d2"
        },
        "noteDescription": {
          "color": "#4f40d2"
        },
        "discountText": {
          "color": "#4f40d2"
        },
        "discountIcon": {
          "fill": "#4f40d2"
        },
        "discountAmount": {
          "color": "#4f40d2"
        },
        "cart": {
          "background-color": "#f6f5ff"
        },
        "footer": {
          "background-color": "#f6f5ff"
        }
      },
      "text": {
        "total": "Subtotal",
        "button": "Checkout"
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "toggle": {
      "styles": {
        "toggle": {
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "background-color": "#4f40d2",
          ":hover": {
            "background-color": "#473abd"
          },
          ":focus": {
            "background-color": "#473abd"
          }
        },
        "count": {
          "font-size": "13px"
        }
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "lineItem": {
      "styles": {
        "variantTitle": {
          "color": "#4f40d2"
        },
        "title": {
          "color": "#4f40d2"
        },
        "price": {
          "color": "#4f40d2"
        },
        "fullPrice": {
          "color": "#4f40d2"
        },
        "discount": {
          "color": "#4f40d2"
        },
        "discountIcon": {
          "fill": "#4f40d2"
        },
        "quantity": {
          "color": "#4f40d2"
        },
        "quantityIncrement": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        },
        "quantityDecrement": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        },
        "quantityInput": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        }
      }
    }
  },
        });
      });
    }
  })();`,
  'product-component-1695953571085': `(function () {
    var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }
    function loadScript() {
      var script = document.createElement('script');
      script.async = true;
      script.src = scriptURL;
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
      script.onload = ShopifyBuyInit;
    }
    function ShopifyBuyInit() {
      var client = ShopifyBuy.buildClient({
        domain: '87ea82.myshopify.com',
        storefrontAccessToken: 'dad8dd7a9c329184721806d7bd79c130',
      });
      ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent('product', {
          id: '8650743873819',
          node: document.getElementById('product-component-1695953571085'),
          moneyFormat: '%24%7B%7Bamount%7D%7D',
          options: {
    "product": {
      "iframe": false,
      "styles": {
        "product": {
          "@media (min-width: 601px)": {
            "max-width": "calc(25% - 20px)",
            "margin-left": "20px",
            "margin-bottom": "50px"
          }
        },
        "title": {
          "font-family": "Montserrat, sans-serif",
          "color": "#8a36f5"
        },
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          ":hover": {
            "background-color": "#473abd"
          },
          "background-color": "#4f40d2",
          ":focus": {
            "background-color": "#473abd"
          },
          "border-radius": "5px",
          "padding-left": "50px",
          "padding-right": "50px"
        },
        "quantityInput": {
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px"
        }
      },
      "contents": {
        "img": false,
        "title": false,
        "price": false
      },
      "text": {
        "button": "Grab free remote ticket"
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "productSet": {
      "styles": {
        "products": {
          "@media (min-width: 601px)": {
            "margin-left": "-20px"
          }
        }
      }
    },
    "modalProduct": {
      "contents": {
        "img": false,
        "imgWithCarousel": true,
        "button": false,
        "buttonWithQuantity": true
      },
      "styles": {
        "product": {
          "@media (min-width: 601px)": {
            "max-width": "100%",
            "margin-left": "0px",
            "margin-bottom": "0px"
          }
        },
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          ":hover": {
            "background-color": "#473abd"
          },
          "background-color": "#4f40d2",
          ":focus": {
            "background-color": "#473abd"
          },
          "border-radius": "5px",
          "padding-left": "50px",
          "padding-right": "50px"
        },
        "quantityInput": {
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px"
        },
        "title": {
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "26px",
          "color": "#4c4c4c"
        }
      },
      "googleFonts": [
        "Montserrat"
      ],
      "text": {
        "button": "Add to cart"
      }
    },
    "option": {},
    "cart": {
      "styles": {
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          ":hover": {
            "background-color": "#473abd"
          },
          "background-color": "#4f40d2",
          ":focus": {
            "background-color": "#473abd"
          },
          "border-radius": "5px"
        },
        "title": {
          "color": "#4f40d2"
        },
        "header": {
          "color": "#4f40d2"
        },
        "lineItems": {
          "color": "#4f40d2"
        },
        "subtotalText": {
          "color": "#4f40d2"
        },
        "subtotal": {
          "color": "#4f40d2"
        },
        "notice": {
          "color": "#4f40d2"
        },
        "currency": {
          "color": "#4f40d2"
        },
        "close": {
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2"
          }
        },
        "empty": {
          "color": "#4f40d2"
        },
        "noteDescription": {
          "color": "#4f40d2"
        },
        "discountText": {
          "color": "#4f40d2"
        },
        "discountIcon": {
          "fill": "#4f40d2"
        },
        "discountAmount": {
          "color": "#4f40d2"
        },
        "cart": {
          "background-color": "#f6f5ff"
        },
        "footer": {
          "background-color": "#f6f5ff"
        }
      },
      "text": {
        "total": "Subtotal",
        "button": "Checkout"
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "toggle": {
      "styles": {
        "toggle": {
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "background-color": "#4f40d2",
          ":hover": {
            "background-color": "#473abd"
          },
          ":focus": {
            "background-color": "#473abd"
          }
        },
        "count": {
          "font-size": "13px"
        }
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "lineItem": {
      "styles": {
        "variantTitle": {
          "color": "#4f40d2"
        },
        "title": {
          "color": "#4f40d2"
        },
        "price": {
          "color": "#4f40d2"
        },
        "fullPrice": {
          "color": "#4f40d2"
        },
        "discount": {
          "color": "#4f40d2"
        },
        "discountIcon": {
          "fill": "#4f40d2"
        },
        "quantity": {
          "color": "#4f40d2"
        },
        "quantityIncrement": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        },
        "quantityDecrement": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        },
        "quantityInput": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        }
      }
    }
  },
        });
      });
    }
  })();`,
  'product-component-1695953464361': `(function () {
    var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }
    function loadScript() {
      var script = document.createElement('script');
      script.async = true;
      script.src = scriptURL;
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
      script.onload = ShopifyBuyInit;
    }
    function ShopifyBuyInit() {
      var client = ShopifyBuy.buildClient({
        domain: '87ea82.myshopify.com',
        storefrontAccessToken: 'dad8dd7a9c329184721806d7bd79c130',
      });
      ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent('product', {
          id: '8650807509275',
          node: document.getElementById('product-component-1695953464361'),
          moneyFormat: '%24%7B%7Bamount%7D%7D',
          options: {
    "product": {
      "iframe": false,
      "styles": {
        "product": {
          "@media (min-width: 601px)": {
            "max-width": "calc(25% - 20px)",
            "margin-left": "20px",
            "margin-bottom": "50px"
          }
        },
        "title": {
          "font-family": "Montserrat, sans-serif",
          "color": "#8a36f5"
        },
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          ":hover": {
            "background-color": "#473abd"
          },
          "background-color": "#4f40d2",
          ":focus": {
            "background-color": "#473abd"
          },
          "border-radius": "5px",
          "padding-left": "50px",
          "padding-right": "50px"
        },
        "quantityInput": {
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px"
        }
      },
      "contents": {
        "img": false,
        "title": false,
        "price": false
      },
      "text": {
        "button": "Grab free remote ticket"
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "productSet": {
      "styles": {
        "products": {
          "@media (min-width: 601px)": {
            "margin-left": "-20px"
          }
        }
      }
    },
    "modalProduct": {
      "contents": {
        "img": false,
        "imgWithCarousel": true,
        "button": false,
        "buttonWithQuantity": true
      },
      "styles": {
        "product": {
          "@media (min-width: 601px)": {
            "max-width": "100%",
            "margin-left": "0px",
            "margin-bottom": "0px"
          }
        },
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          ":hover": {
            "background-color": "#473abd"
          },
          "background-color": "#4f40d2",
          ":focus": {
            "background-color": "#473abd"
          },
          "border-radius": "5px",
          "padding-left": "50px",
          "padding-right": "50px"
        },
        "quantityInput": {
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px"
        },
        "title": {
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "26px",
          "color": "#4c4c4c"
        }
      },
      "googleFonts": [
        "Montserrat"
      ],
      "text": {
        "button": "Add to cart"
      }
    },
    "option": {},
    "cart": {
      "styles": {
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          ":hover": {
            "background-color": "#473abd"
          },
          "background-color": "#4f40d2",
          ":focus": {
            "background-color": "#473abd"
          },
          "border-radius": "5px"
        },
        "title": {
          "color": "#4f40d2"
        },
        "header": {
          "color": "#4f40d2"
        },
        "lineItems": {
          "color": "#4f40d2"
        },
        "subtotalText": {
          "color": "#4f40d2"
        },
        "subtotal": {
          "color": "#4f40d2"
        },
        "notice": {
          "color": "#4f40d2"
        },
        "currency": {
          "color": "#4f40d2"
        },
        "close": {
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2"
          }
        },
        "empty": {
          "color": "#4f40d2"
        },
        "noteDescription": {
          "color": "#4f40d2"
        },
        "discountText": {
          "color": "#4f40d2"
        },
        "discountIcon": {
          "fill": "#4f40d2"
        },
        "discountAmount": {
          "color": "#4f40d2"
        },
        "cart": {
          "background-color": "#f6f5ff"
        },
        "footer": {
          "background-color": "#f6f5ff"
        }
      },
      "text": {
        "total": "Subtotal",
        "button": "Checkout"
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "toggle": {
      "styles": {
        "toggle": {
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "background-color": "#4f40d2",
          ":hover": {
            "background-color": "#473abd"
          },
          ":focus": {
            "background-color": "#473abd"
          }
        },
        "count": {
          "font-size": "13px"
        }
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "lineItem": {
      "styles": {
        "variantTitle": {
          "color": "#4f40d2"
        },
        "title": {
          "color": "#4f40d2"
        },
        "price": {
          "color": "#4f40d2"
        },
        "fullPrice": {
          "color": "#4f40d2"
        },
        "discount": {
          "color": "#4f40d2"
        },
        "discountIcon": {
          "fill": "#4f40d2"
        },
        "quantity": {
          "color": "#4f40d2"
        },
        "quantityIncrement": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        },
        "quantityDecrement": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        },
        "quantityInput": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        }
      }
    }
  },
        });
      });
    }
  })();`,
  'product-component-1695952855079': `(function () {
    var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }
    function loadScript() {
      var script = document.createElement('script');
      script.async = true;
      script.src = scriptURL;
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
      script.onload = ShopifyBuyInit;
    }
    function ShopifyBuyInit() {
      var client = ShopifyBuy.buildClient({
        domain: '87ea82.myshopify.com',
        storefrontAccessToken: 'dad8dd7a9c329184721806d7bd79c130',
      });
      ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent('product', {
          id: '8587095998747',
          node: document.getElementById('product-component-1695952855079'),
          moneyFormat: '%24%7B%7Bamount%7D%7D',
          options: {
            "product": {
              "iframe": false,
              "styles": {
                "product": {
                  "@media (min-width: 601px)": {
                    "max-width": "calc(25% - 20px)",
                    "margin-left": "20px",
                    "margin-bottom": "50px"
                  }
                },
                "title": {
                  "font-family": "Montserrat, sans-serif",
                  "color": "#8a36f5"
                },
                "button": {
                  "width": "100%",
                  "font-family": "Montserrat, sans-serif",
                  "font-weight": "bold",
                  "font-size": "13px",
                  "padding-top": "14.5px",
                  "padding-bottom": "14.5px",
                  ":hover": {
                    "background-color": "#473abd"
                  },
                  "background-color": "#4f40d2",
                  ":focus": {
                    "background-color": "#473abd"
                  },
                  "border-radius": "5px",
                  "padding-left": "50px",
                  "padding-right": "50px"
                },
                "quantityInput": {
                  "font-size": "13px",
                  "padding-top": "14.5px",
                  "padding-bottom": "14.5px"
                }
              },
              "contents": {
                "img": false,
                "title": false,
                "price": false
              },
              "text": {
                "button": "Grab free remote ticket"
              },
              "googleFonts": [
                "Montserrat"
              ]
            },
            "productSet": {
              "styles": {
                "products": {
                  "@media (min-width: 601px)": {
                    "margin-left": "-20px"
                  }
                }
              }
            },
            "modalProduct": {
              "contents": {
                "img": false,
                "imgWithCarousel": true,
                "button": false,
                "buttonWithQuantity": true
              },
              "styles": {
                "product": {
                  "@media (min-width: 601px)": {
                    "max-width": "100%",
                    "margin-left": "0px",
                    "margin-bottom": "0px"
                  }
                },
                "button": {
                  "width": "100%",
                  "font-family": "Montserrat, sans-serif",
                  "font-weight": "bold",
                  "font-size": "13px",
                  "padding-top": "14.5px",
                  "padding-bottom": "14.5px",
                  ":hover": {
                    "background-color": "#473abd"
                  },
                  "background-color": "#4f40d2",
                  ":focus": {
                    "background-color": "#473abd"
                  },
                  "border-radius": "5px",
                  "padding-left": "50px",
                  "padding-right": "50px"
                },
                "quantityInput": {
                  "font-size": "13px",
                  "padding-top": "14.5px",
                  "padding-bottom": "14.5px"
                },
                "title": {
                  "font-family": "Montserrat, sans-serif",
                  "font-weight": "bold",
                  "font-size": "26px",
                  "color": "#4c4c4c"
                }
              },
              "googleFonts": [
                "Montserrat"
              ],
              "text": {
                "button": "Add to cart"
              }
            },
            "option": {},
            "cart": {
              "styles": {
                "button": {
                  "width": "100%",
                  "font-family": "Montserrat, sans-serif",
                  "font-weight": "bold",
                  "font-size": "13px",
                  "padding-top": "14.5px",
                  "padding-bottom": "14.5px",
                  ":hover": {
                    "background-color": "#473abd"
                  },
                  "background-color": "#4f40d2",
                  ":focus": {
                    "background-color": "#473abd"
                  },
                  "border-radius": "5px"
                },
                "title": {
                  "color": "#4f40d2"
                },
                "header": {
                  "color": "#4f40d2"
                },
                "lineItems": {
                  "color": "#4f40d2"
                },
                "subtotalText": {
                  "color": "#4f40d2"
                },
                "subtotal": {
                  "color": "#4f40d2"
                },
                "notice": {
                  "color": "#4f40d2"
                },
                "currency": {
                  "color": "#4f40d2"
                },
                "close": {
                  "color": "#4f40d2",
                  ":hover": {
                    "color": "#4f40d2"
                  }
                },
                "empty": {
                  "color": "#4f40d2"
                },
                "noteDescription": {
                  "color": "#4f40d2"
                },
                "discountText": {
                  "color": "#4f40d2"
                },
                "discountIcon": {
                  "fill": "#4f40d2"
                },
                "discountAmount": {
                  "color": "#4f40d2"
                },
                "cart": {
                  "background-color": "#f6f5ff"
                },
                "footer": {
                  "background-color": "#f6f5ff"
                }
              },
              "text": {
                "total": "Subtotal",
                "button": "Checkout"
              },
              "googleFonts": [
                "Montserrat"
              ]
            },
            "toggle": {
              "styles": {
                "toggle": {
                  "font-family": "Montserrat, sans-serif",
                  "font-weight": "bold",
                  "background-color": "#4f40d2",
                  ":hover": {
                    "background-color": "#473abd"
                  },
                  ":focus": {
                    "background-color": "#473abd"
                  }
                },
                "count": {
                  "font-size": "13px"
                }
              },
              "googleFonts": [
                "Montserrat"
              ]
            },
            "lineItem": {
              "styles": {
                "variantTitle": {
                  "color": "#4f40d2"
                },
                "title": {
                  "color": "#4f40d2"
                },
                "price": {
                  "color": "#4f40d2"
                },
                "fullPrice": {
                  "color": "#4f40d2"
                },
                "discount": {
                  "color": "#4f40d2"
                },
                "discountIcon": {
                  "fill": "#4f40d2"
                },
                "quantity": {
                  "color": "#4f40d2"
                },
                "quantityIncrement": {
                  "color": "#4f40d2",
                  "border-color": "#4f40d2"
                },
                "quantityDecrement": {
                  "color": "#4f40d2",
                  "border-color": "#4f40d2"
                },
                "quantityInput": {
                  "color": "#4f40d2",
                  "border-color": "#4f40d2"
                }
              }
            }
          },
        });
      });
    }
  })();`,
  'product-component-1695952998246': `(function () {
    var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }
    function loadScript() {
      var script = document.createElement('script');
      script.async = true;
      script.src = scriptURL;
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
      script.onload = ShopifyBuyInit;
    }
    function ShopifyBuyInit() {
      var client = ShopifyBuy.buildClient({
        domain: '87ea82.myshopify.com',
        storefrontAccessToken: 'dad8dd7a9c329184721806d7bd79c130',
      });
      ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent('product', {
          id: '8587598070043',
          node: document.getElementById('product-component-1695952998246'),
          moneyFormat: '%24%7B%7Bamount%7D%7D',
          options: {
    "product": {
      "iframe": false,
      "styles": {
        "product": {
          "@media (min-width: 601px)": {
            "max-width": "calc(25% - 20px)",
            "margin-left": "20px",
            "margin-bottom": "50px"
          }
        },
        "title": {
          "font-family": "Montserrat, sans-serif",
          "color": "#8a36f5"
        },
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2",
            "background-color": "#cccae6"
          },
          "background-color": "#e3e0ff",
          ":focus": {
            "background-color": "#cccae6"
          },
          "border-radius": "5px",
          "padding-left": "50px",
          "padding-right": "50px"
        },
        "quantityInput": {
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px"
        }
      },
      "contents": {
        "img": false,
        "title": false,
        "price": false
      },
      "text": {
        "button": "In-person ticket $20"
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "productSet": {
      "styles": {
        "products": {
          "@media (min-width: 601px)": {
            "margin-left": "-20px"
          }
        }
      }
    },
    "modalProduct": {
      "contents": {
        "img": false,
        "imgWithCarousel": true,
        "button": false,
        "buttonWithQuantity": true
      },
      "styles": {
        "product": {
          "@media (min-width: 601px)": {
            "max-width": "100%",
            "margin-left": "0px",
            "margin-bottom": "0px"
          }
        },
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2",
            "background-color": "#cccae6"
          },
          "background-color": "#e3e0ff",
          ":focus": {
            "background-color": "#cccae6"
          },
          "border-radius": "5px",
          "padding-left": "50px",
          "padding-right": "50px"
        },
        "quantityInput": {
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px"
        },
        "title": {
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "26px",
          "color": "#4c4c4c"
        }
      },
      "googleFonts": [
        "Montserrat"
      ],
      "text": {
        "button": "Add to cart"
      }
    },
    "option": {},
    "cart": {
      "styles": {
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2",
            "background-color": "#cccae6"
          },
          "background-color": "#e3e0ff",
          ":focus": {
            "background-color": "#cccae6"
          },
          "border-radius": "5px"
        },
        "title": {
          "color": "#4f40d2"
        },
        "header": {
          "color": "#4f40d2"
        },
        "lineItems": {
          "color": "#4f40d2"
        },
        "subtotalText": {
          "color": "#4f40d2"
        },
        "subtotal": {
          "color": "#4f40d2"
        },
        "notice": {
          "color": "#4f40d2"
        },
        "currency": {
          "color": "#4f40d2"
        },
        "close": {
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2"
          }
        },
        "empty": {
          "color": "#4f40d2"
        },
        "noteDescription": {
          "color": "#4f40d2"
        },
        "discountText": {
          "color": "#4f40d2"
        },
        "discountIcon": {
          "fill": "#4f40d2"
        },
        "discountAmount": {
          "color": "#4f40d2"
        },
        "cart": {
          "background-color": "#f6f5ff"
        },
        "footer": {
          "background-color": "#f6f5ff"
        }
      },
      "text": {
        "total": "Subtotal",
        "button": "Checkout"
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "toggle": {
      "styles": {
        "toggle": {
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "background-color": "#e3e0ff",
          ":hover": {
            "background-color": "#cccae6"
          },
          ":focus": {
            "background-color": "#cccae6"
          }
        },
        "count": {
          "font-size": "13px",
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2"
          }
        },
        "iconPath": {
          "fill": "#4f40d2"
        }
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "lineItem": {
      "styles": {
        "variantTitle": {
          "color": "#4f40d2"
        },
        "title": {
          "color": "#4f40d2"
        },
        "price": {
          "color": "#4f40d2"
        },
        "fullPrice": {
          "color": "#4f40d2"
        },
        "discount": {
          "color": "#4f40d2"
        },
        "discountIcon": {
          "fill": "#4f40d2"
        },
        "quantity": {
          "color": "#4f40d2"
        },
        "quantityIncrement": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        },
        "quantityDecrement": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        },
        "quantityInput": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        }
      }
    }
  },
        });
      });
    }
  })();`,
  'product-component-1695953182323': `(function () {
    var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }
    function loadScript() {
      var script = document.createElement('script');
      script.async = true;
      script.src = scriptURL;
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
      script.onload = ShopifyBuyInit;
    }
    function ShopifyBuyInit() {
      var client = ShopifyBuy.buildClient({
        domain: '87ea82.myshopify.com',
        storefrontAccessToken: 'dad8dd7a9c329184721806d7bd79c130',
      });
      ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent('product', {
          id: '8650734600475',
          node: document.getElementById('product-component-1695953182323'),
          moneyFormat: '%24%7B%7Bamount%7D%7D',
          options: {
    "product": {
      "iframe": false,
      "contents": {
        "img": false,
        "title": false,
        "price": false
      },
      "text": {
        "button": "In-person ticket $49"
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "productSet": {
      "styles": {
        "products": {
          "@media (min-width: 601px)": {
            "margin-left": "-20px"
          }
        }
      }
    },
    "modalProduct": {
      "contents": {
        "img": false,
        "imgWithCarousel": true,
        "button": false,
        "buttonWithQuantity": true
      },
      "styles": {
        "product": {
          "@media (min-width: 601px)": {
            "max-width": "100%",
            "margin-left": "0px",
            "margin-bottom": "0px"
          }
        },
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2",
            "background-color": "#cccae6"
          },
          "background-color": "#e3e0ff",
          ":focus": {
            "background-color": "#cccae6"
          },
          "border-radius": "5px",
          "padding-left": "50px",
          "padding-right": "50px"
        },
        "quantityInput": {
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px"
        },
        "title": {
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "26px",
          "color": "#4c4c4c"
        }
      },
      "googleFonts": [
        "Montserrat"
      ],
      "text": {
        "button": "Add to cart"
      }
    },
    "option": {},
    "cart": {
      "styles": {
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2",
            "background-color": "#cccae6"
          },
          "background-color": "#e3e0ff",
          ":focus": {
            "background-color": "#cccae6"
          },
          "border-radius": "5px"
        },
        "title": {
          "color": "#4f40d2"
        },
        "header": {
          "color": "#4f40d2"
        },
        "lineItems": {
          "color": "#4f40d2"
        },
        "subtotalText": {
          "color": "#4f40d2"
        },
        "subtotal": {
          "color": "#4f40d2"
        },
        "notice": {
          "color": "#4f40d2"
        },
        "currency": {
          "color": "#4f40d2"
        },
        "close": {
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2"
          }
        },
        "empty": {
          "color": "#4f40d2"
        },
        "noteDescription": {
          "color": "#4f40d2"
        },
        "discountText": {
          "color": "#4f40d2"
        },
        "discountIcon": {
          "fill": "#4f40d2"
        },
        "discountAmount": {
          "color": "#4f40d2"
        },
        "cart": {
          "background-color": "#f6f5ff"
        },
        "footer": {
          "background-color": "#f6f5ff"
        }
      },
      "text": {
        "total": "Subtotal",
        "button": "Checkout"
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "toggle": {
      "styles": {
        "toggle": {
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "background-color": "#e3e0ff",
          ":hover": {
            "background-color": "#cccae6"
          },
          ":focus": {
            "background-color": "#cccae6"
          }
        },
        "count": {
          "font-size": "13px",
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2"
          }
        },
        "iconPath": {
          "fill": "#4f40d2"
        }
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "lineItem": {
      "styles": {
        "variantTitle": {
          "color": "#4f40d2"
        },
        "title": {
          "color": "#4f40d2"
        },
        "price": {
          "color": "#4f40d2"
        },
        "fullPrice": {
          "color": "#4f40d2"
        },
        "discount": {
          "color": "#4f40d2"
        },
        "discountIcon": {
          "fill": "#4f40d2"
        },
        "quantity": {
          "color": "#4f40d2"
        },
        "quantityIncrement": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        },
        "quantityDecrement": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        },
        "quantityInput": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        }
      }
    }
  },
        });
      });
    }
  })();`,
  'product-component-1695953263930': `(function () {
    var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }
    function loadScript() {
      var script = document.createElement('script');
      script.async = true;
      script.src = scriptURL;
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
      script.onload = ShopifyBuyInit;
    }
    function ShopifyBuyInit() {
      var client = ShopifyBuy.buildClient({
        domain: '87ea82.myshopify.com',
        storefrontAccessToken: 'dad8dd7a9c329184721806d7bd79c130',
      });
      ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent('product', {
          id: '8650791911707',
          node: document.getElementById('product-component-1695953263930'),
          moneyFormat: '%24%7B%7Bamount%7D%7D',
          options: {
    "product": {
      "iframe": false,
      "contents": {
        "img": false,
        "title": false,
        "price": false
      },
      "text": {
        "button": "In-person ticket $149"
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "productSet": {
      "styles": {
        "products": {
          "@media (min-width: 601px)": {
            "margin-left": "-20px"
          }
        }
      }
    },
    "modalProduct": {
      "contents": {
        "img": false,
        "imgWithCarousel": true,
        "button": false,
        "buttonWithQuantity": true
      },
      "styles": {
        "product": {
          "@media (min-width: 601px)": {
            "max-width": "100%",
            "margin-left": "0px",
            "margin-bottom": "0px"
          }
        },
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2",
            "background-color": "#cccae6"
          },
          "background-color": "#e3e0ff",
          ":focus": {
            "background-color": "#cccae6"
          },
          "border-radius": "5px",
          "padding-left": "50px",
          "padding-right": "50px"
        },
        "quantityInput": {
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px"
        },
        "title": {
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "26px",
          "color": "#4c4c4c"
        }
      },
      "googleFonts": [
        "Montserrat"
      ],
      "text": {
        "button": "Add to cart"
      }
    },
    "option": {},
    "cart": {
      "styles": {
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2",
            "background-color": "#cccae6"
          },
          "background-color": "#e3e0ff",
          ":focus": {
            "background-color": "#cccae6"
          },
          "border-radius": "5px"
        },
        "title": {
          "color": "#4f40d2"
        },
        "header": {
          "color": "#4f40d2"
        },
        "lineItems": {
          "color": "#4f40d2"
        },
        "subtotalText": {
          "color": "#4f40d2"
        },
        "subtotal": {
          "color": "#4f40d2"
        },
        "notice": {
          "color": "#4f40d2"
        },
        "currency": {
          "color": "#4f40d2"
        },
        "close": {
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2"
          }
        },
        "empty": {
          "color": "#4f40d2"
        },
        "noteDescription": {
          "color": "#4f40d2"
        },
        "discountText": {
          "color": "#4f40d2"
        },
        "discountIcon": {
          "fill": "#4f40d2"
        },
        "discountAmount": {
          "color": "#4f40d2"
        },
        "cart": {
          "background-color": "#f6f5ff"
        },
        "footer": {
          "background-color": "#f6f5ff"
        }
      },
      "text": {
        "total": "Subtotal",
        "button": "Checkout"
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "toggle": {
      "styles": {
        "toggle": {
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "background-color": "#e3e0ff",
          ":hover": {
            "background-color": "#cccae6"
          },
          ":focus": {
            "background-color": "#cccae6"
          }
        },
        "count": {
          "font-size": "13px",
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2"
          }
        },
        "iconPath": {
          "fill": "#4f40d2"
        }
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "lineItem": {
      "styles": {
        "variantTitle": {
          "color": "#4f40d2"
        },
        "title": {
          "color": "#4f40d2"
        },
        "price": {
          "color": "#4f40d2"
        },
        "fullPrice": {
          "color": "#4f40d2"
        },
        "discount": {
          "color": "#4f40d2"
        },
        "discountIcon": {
          "fill": "#4f40d2"
        },
        "quantity": {
          "color": "#4f40d2"
        },
        "quantityIncrement": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        },
        "quantityDecrement": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        },
        "quantityInput": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        }
      }
    }
  },
        });
      });
    }
  })();`,
  'product-component-1695953292964': `(function () {
    var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }
    function loadScript() {
      var script = document.createElement('script');
      script.async = true;
      script.src = scriptURL;
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
      script.onload = ShopifyBuyInit;
    }
    function ShopifyBuyInit() {
      var client = ShopifyBuy.buildClient({
        domain: '87ea82.myshopify.com',
        storefrontAccessToken: 'dad8dd7a9c329184721806d7bd79c130',
      });
      ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent('product', {
          id: '8650803642651',
          node: document.getElementById('product-component-1695953292964'),
          moneyFormat: '%24%7B%7Bamount%7D%7D',
          options: {
    "product": {
      "iframe": false,
      "styles": {
        "product": {
          "@media (min-width: 601px)": {
            "max-width": "calc(25% - 20px)",
            "margin-left": "20px",
            "margin-bottom": "50px"
          }
        },
        "title": {
          "font-family": "Montserrat, sans-serif",
          "color": "#8a36f5"
        },
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2",
            "background-color": "#cccae6"
          },
          "background-color": "#e3e0ff",
          ":focus": {
            "background-color": "#cccae6"
          },
          "border-radius": "5px",
          "padding-left": "50px",
          "padding-right": "50px"
        },
        "quantityInput": {
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px"
        }
      },
      "contents": {
        "img": false,
        "title": false,
        "price": false
      },
      "text": {
        "button": "Grab free in-person ticket"
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "productSet": {
      "styles": {
        "products": {
          "@media (min-width: 601px)": {
            "margin-left": "-20px"
          }
        }
      }
    },
    "modalProduct": {
      "contents": {
        "img": false,
        "imgWithCarousel": true,
        "button": false,
        "buttonWithQuantity": true
      },
      "styles": {
        "product": {
          "@media (min-width: 601px)": {
            "max-width": "100%",
            "margin-left": "0px",
            "margin-bottom": "0px"
          }
        },
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2",
            "background-color": "#cccae6"
          },
          "background-color": "#e3e0ff",
          ":focus": {
            "background-color": "#cccae6"
          },
          "border-radius": "5px",
          "padding-left": "50px",
          "padding-right": "50px"
        },
        "quantityInput": {
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px"
        },
        "title": {
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "26px",
          "color": "#4c4c4c"
        }
      },
      "googleFonts": [
        "Montserrat"
      ],
      "text": {
        "button": "Add to cart"
      }
    },
    "option": {},
    "cart": {
      "styles": {
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2",
            "background-color": "#cccae6"
          },
          "background-color": "#e3e0ff",
          ":focus": {
            "background-color": "#cccae6"
          },
          "border-radius": "5px"
        },
        "title": {
          "color": "#4f40d2"
        },
        "header": {
          "color": "#4f40d2"
        },
        "lineItems": {
          "color": "#4f40d2"
        },
        "subtotalText": {
          "color": "#4f40d2"
        },
        "subtotal": {
          "color": "#4f40d2"
        },
        "notice": {
          "color": "#4f40d2"
        },
        "currency": {
          "color": "#4f40d2"
        },
        "close": {
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2"
          }
        },
        "empty": {
          "color": "#4f40d2"
        },
        "noteDescription": {
          "color": "#4f40d2"
        },
        "discountText": {
          "color": "#4f40d2"
        },
        "discountIcon": {
          "fill": "#4f40d2"
        },
        "discountAmount": {
          "color": "#4f40d2"
        },
        "cart": {
          "background-color": "#f6f5ff"
        },
        "footer": {
          "background-color": "#f6f5ff"
        }
      },
      "text": {
        "total": "Subtotal",
        "button": "Checkout"
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "toggle": {
      "styles": {
        "toggle": {
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "background-color": "#e3e0ff",
          ":hover": {
            "background-color": "#cccae6"
          },
          ":focus": {
            "background-color": "#cccae6"
          }
        },
        "count": {
          "font-size": "13px",
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2"
          }
        },
        "iconPath": {
          "fill": "#4f40d2"
        }
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "lineItem": {
      "styles": {
        "variantTitle": {
          "color": "#4f40d2"
        },
        "title": {
          "color": "#4f40d2"
        },
        "price": {
          "color": "#4f40d2"
        },
        "fullPrice": {
          "color": "#4f40d2"
        },
        "discount": {
          "color": "#4f40d2"
        },
        "discountIcon": {
          "fill": "#4f40d2"
        },
        "quantity": {
          "color": "#4f40d2"
        },
        "quantityIncrement": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        },
        "quantityDecrement": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        },
        "quantityInput": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        }
      }
    }
  },
        });
      });
    }
  })();`,
  'product-component-1695953320762': `(function () {
    var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }
    function loadScript() {
      var script = document.createElement('script');
      script.async = true;
      script.src = scriptURL;
      (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
      script.onload = ShopifyBuyInit;
    }
    function ShopifyBuyInit() {
      var client = ShopifyBuy.buildClient({
        domain: '87ea82.myshopify.com',
        storefrontAccessToken: 'dad8dd7a9c329184721806d7bd79c130',
      });
      ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent('product', {
          id: '8650810851611',
          node: document.getElementById('product-component-1695953320762'),
          moneyFormat: '%24%7B%7Bamount%7D%7D',
          options: {
    "product": {
      "iframe": false,
      "contents": {
        "img": false,
        "title": false,
        "price": false
      },
      "text": {
        "button": "In-person ticket $199"
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "productSet": {
      "styles": {
        "products": {
          "@media (min-width: 601px)": {
            "margin-left": "-20px"
          }
        }
      }
    },
    "modalProduct": {
      "contents": {
        "img": false,
        "imgWithCarousel": true,
        "button": false,
        "buttonWithQuantity": true
      },
      "styles": {
        "product": {
          "@media (min-width: 601px)": {
            "max-width": "100%",
            "margin-left": "0px",
            "margin-bottom": "0px"
          }
        },
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2",
            "background-color": "#cccae6"
          },
          "background-color": "#e3e0ff",
          ":focus": {
            "background-color": "#cccae6"
          },
          "border-radius": "5px",
          "padding-left": "50px",
          "padding-right": "50px"
        },
        "quantityInput": {
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px"
        },
        "title": {
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "26px",
          "color": "#4c4c4c"
        }
      },
      "googleFonts": [
        "Montserrat"
      ],
      "text": {
        "button": "Add to cart"
      }
    },
    "option": {},
    "cart": {
      "styles": {
        "button": {
          "width": "100%",
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "font-size": "13px",
          "padding-top": "14.5px",
          "padding-bottom": "14.5px",
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2",
            "background-color": "#cccae6"
          },
          "background-color": "#e3e0ff",
          ":focus": {
            "background-color": "#cccae6"
          },
          "border-radius": "5px"
        },
        "title": {
          "color": "#4f40d2"
        },
        "header": {
          "color": "#4f40d2"
        },
        "lineItems": {
          "color": "#4f40d2"
        },
        "subtotalText": {
          "color": "#4f40d2"
        },
        "subtotal": {
          "color": "#4f40d2"
        },
        "notice": {
          "color": "#4f40d2"
        },
        "currency": {
          "color": "#4f40d2"
        },
        "close": {
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2"
          }
        },
        "empty": {
          "color": "#4f40d2"
        },
        "noteDescription": {
          "color": "#4f40d2"
        },
        "discountText": {
          "color": "#4f40d2"
        },
        "discountIcon": {
          "fill": "#4f40d2"
        },
        "discountAmount": {
          "color": "#4f40d2"
        },
        "cart": {
          "background-color": "#f6f5ff"
        },
        "footer": {
          "background-color": "#f6f5ff"
        }
      },
      "text": {
        "total": "Subtotal",
        "button": "Checkout"
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "toggle": {
      "styles": {
        "toggle": {
          "font-family": "Montserrat, sans-serif",
          "font-weight": "bold",
          "background-color": "#e3e0ff",
          ":hover": {
            "background-color": "#cccae6"
          },
          ":focus": {
            "background-color": "#cccae6"
          }
        },
        "count": {
          "font-size": "13px",
          "color": "#4f40d2",
          ":hover": {
            "color": "#4f40d2"
          }
        },
        "iconPath": {
          "fill": "#4f40d2"
        }
      },
      "googleFonts": [
        "Montserrat"
      ]
    },
    "lineItem": {
      "styles": {
        "variantTitle": {
          "color": "#4f40d2"
        },
        "title": {
          "color": "#4f40d2"
        },
        "price": {
          "color": "#4f40d2"
        },
        "fullPrice": {
          "color": "#4f40d2"
        },
        "discount": {
          "color": "#4f40d2"
        },
        "discountIcon": {
          "fill": "#4f40d2"
        },
        "quantity": {
          "color": "#4f40d2"
        },
        "quantityIncrement": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        },
        "quantityDecrement": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        },
        "quantityInput": {
          "color": "#4f40d2",
          "border-color": "#4f40d2"
        }
      }
    }
  },
        });
      });
    }
  })();`,
}
