import { toast } from 'react-toastify'

/* utils */
import reqH from 'utils/request-handler'
import customEvent from 'utils/custom-event'

/* state-manager */
import { history } from 'state-manager/store'

/* constants */
import { AUTH_ACTIONS } from 'state-manager/constants'
import {
  NEXT_STEP__FORGOT_PASSWORD,
  HIDE_SIGN_IN_MODAL, HIDE_FORGOT_PASSWORD_MODAL,
} from 'constants/custom-events'

/* routes */
import routes from 'routes'

/* actions */
import { getUserData } from 'state-manager/actions/user'
import { clearStore } from 'state-manager/actions/clear-store'

export const signIn = (data, { loginUnverifiedUser = false } = {}) => (dispatch) => {
  dispatch({ type: AUTH_ACTIONS.SIGN_IN.ATTEMPT })

  const signInData = {
    email: data.email,
    password: data.password,
  }

  if (loginUnverifiedUser) {
    signInData.loginUnverifiedUser = loginUnverifiedUser
  }

  reqH({
    method: 'POST',
    url: 'login',
    urlPrefix: 'api',
    data: signInData,
  })
    .then((res) => {
      const userData = {}
      userData.isAuthorized = true
      userData.authorizationData = {
        token_type: res.data.data.token_type,
        access_token: res.data.data.access_token,
      }

      customEvent.trigger(HIDE_SIGN_IN_MODAL)

      dispatch({
        type: AUTH_ACTIONS.SIGN_IN.SUCCESS,
        payload: userData,
      })

      dispatch(getUserData(userData))
    })
    .catch(() => {
      dispatch({ type: AUTH_ACTIONS.SIGN_IN.ERROR })
    })
}

export const signOut = () => (dispatch) => {
  reqH({
    method: 'POST',
    url: 'user/logout',
    urlPrefix: 'api',
  })
    .finally(() => {
      dispatch(clearStore())
      history.push(routes.homepage)
    })
}

export const signUp = (data) => (dispatch) => {
  reqH({
    method: 'POST',
    url: 'register',
    urlPrefix: 'api',
    data,
  })
    .then(() => {
      const signInData = {
        email: data.email,
        password: data.password,
      }
      dispatch(signIn(signInData, { loginUnverifiedUser: true }))
    })
    .finally(() => {
      dispatch({ type: AUTH_ACTIONS.SIGN_UP.SYSTEM })
    })
}

export const forgotPasswordRequestCode = (data) => (dispatch) => {
  reqH({
    method: 'POST',
    url: 'forgot-password/send-code',
    urlPrefix: 'api',
    data: {
      email: data.email,
    },
  })
    .then(() => {
      toast.success('Email sent! Please check your email.')

      customEvent.trigger(NEXT_STEP__FORGOT_PASSWORD)
    })
    .finally(() => {
      dispatch({ type: AUTH_ACTIONS.FORGOT_PASSWORD__REQUEST_CODE.SYSTEM })
    })
}

export const forgotPasswordCheckCode = (data) => (dispatch) => {
  reqH({
    method: 'POST',
    url: 'forgot-password/check-code',
    urlPrefix: 'api',
    data,
  })
    .then(() => {
      customEvent.trigger(NEXT_STEP__FORGOT_PASSWORD)
    })
    .finally(() => {
      dispatch({ type: AUTH_ACTIONS.FORGOT_PASSWORD__CHECK_CODE.SYSTEM })
    })
}

export const resetPassword = (data) => (dispatch) => {
  reqH({
    method: 'POST',
    url: 'forgot-password/submit-code-password',
    urlPrefix: 'api',
    data,
  })
    .then(() => {
      toast.success('Your new password has been successfully reset and updated!')
      customEvent.trigger(NEXT_STEP__FORGOT_PASSWORD)
      customEvent.trigger(HIDE_FORGOT_PASSWORD_MODAL)
    })
    .finally(() => {
      dispatch({ type: AUTH_ACTIONS.RESET_PASSWORD.SYSTEM })
    })
}

export const changePassword = (data) => (dispatch) => {
  reqH({
    method: 'POST',
    url: 'user/change-password',
    urlPrefix: 'api',
    data,
  })
    .then(() => {
      dispatch({ type: AUTH_ACTIONS.CHANGE_PASSWORD.SUCCESS })
      toast.success('Your password has been changed successfully')
    })
    .catch(() => {
      dispatch({ type: AUTH_ACTIONS.CHANGE_PASSWORD.ERROR })
    })
}
