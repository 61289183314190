import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { formatRoute } from 'react-router-named-routes'
import { format, intervalToDuration, set } from 'date-fns'
import isEmpty from 'lodash.isempty'

/* routes */
import routes from 'routes'

/* actions */
import { sendEvent } from 'state-manager/actions/content'

/* state-manager */
import { history } from 'state-manager/store'

/* utils */
import customEvent from 'utils/custom-event'

/* helpers */
import getUrlByMediaPrefix from 'helpers/get-url-by-media-prefix'

/* constants */
import { TOGGLE__VISIBILITY_SIGNUP_MODAL } from 'constants/custom-events'
import { EVENTS } from 'constants/events'

/* styles */
import clsx from 'clsx'

/* components */
import SpriteIcon from 'components/icons/SpriteIcon'
import ProgressBar from '@ramonak/react-progress-bar'

const EpisodeItem = ({
  index,
  img,
  video,
  title,
  views,
  likes,
  shortDescription,
  id,
  slug,
  duration,
  currentDuration,
  sendEvent,
  disabled,
  currentStats,
}) => {
  const [inHover, setHover] = useState(false)
  const [isLoadedVideo, setIsLoadedVideo] = useState(false)
  const [isLoadedImg, setIsLoadedImg] = useState(false)
  const videoRef = useRef()

  const handleOnMouseEnter = () => {
    setHover(true)
  }

  const handleOnMouseLeave = () => {
    setHover(false)
    videoRef?.current?.pause()
  }

  const handleClick = (id) => {
    sendEvent({
      typeId: EVENTS.MEDIA_CLICK,
      mediaId: id,
    })

    if (!disabled) {
      const talkDetailsPagePath = formatRoute(routes.talkDetails, { slug })
      history.push(talkDetailsPagePath)
    } else {
      customEvent.trigger(TOGGLE__VISIBILITY_SIGNUP_MODAL)
    }
  }

  const handleLoaded = () => {
    setIsLoadedVideo(true)
  }

  if (isLoadedVideo && inHover) {
    videoRef?.current?.play()
  }

  const handleContextMenu = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const formatTime = (sec) => {
    const duration = intervalToDuration({ start: 0, end: sec * 1000 })
    const setDateByDuration = set(new Date(0), duration)
    return format(setDateByDuration, duration.hours > 0 ? 'HH:mm:ss' : 'mm:ss')
  }

  const renderSpinner = () => (
    <div
      role="button"
      className="preview-spinner"
      onClick={() => handleClick(id)}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  )

  return (
    <div
      role="button"
      className="item"
      onClick={() => handleClick(id)}>

      <div className="video-preview">
        {inHover && (
          <video
            ref={videoRef}
            className="video"
            src={getUrlByMediaPrefix.video(video)}
            muted
            loop
            onLoadedData={handleLoaded}
          />
        )}

        <div
          className="image-preview-wrapper"
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}>
          <img
            role="button"
            aria-label="Open content details"
            className={clsx('image-preview',
              inHover && isLoadedVideo && 'hide',
              isLoadedImg && 'lazyload')}
            alt=""
            src={getUrlByMediaPrefix.image.middle(img, id)}
            onLoad={() => setIsLoadedImg(true)}
            onContextMenu={handleContextMenu}
          />

          {!isLoadedImg && renderSpinner()}

          {inHover && !isLoadedVideo && renderSpinner()}
        </div>

        {!inHover && (
          <>
            <div className="video-duration">{formatTime(duration)}</div>
            <div className="wrapper-progressbar">
              <ProgressBar
                className="time-mark__progressbar"
                completed={(currentDuration / duration) * 100}
                borderRadius="0"
                isLabelVisible={false}
                bgColor="#1e6a89"
                baseBgColor="#30323C"
                height="4px"
              />
            </div>
          </>
        )}
      </div>

      <div className="wrapper-info">
        <div className="info">
          <div className="episode-index">
            EPISODE {index}
          </div>
          <div className="title">
            {title}
          </div>
          <div className="description">
            {shortDescription}
          </div>
        </div>

        <div className="wrapper-statistic">
          <div className="likes">
            <SpriteIcon
              name="snap"
              size="md"
              className="mr-2"
            />
            {`${!isEmpty(currentStats) ? currentStats.review_count : likes} snaps`}
          </div>
          <div className="views">
            <SpriteIcon
              name="youtube"
              size="md"
              className="mr-2"
            />
            {`${!isEmpty(currentStats) ? currentStats.clicks : views} views`}
          </div>
        </div>
      </div>
    </div>
  )
}

EpisodeItem.propTypes = {
  id: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  img: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  currentDuration: PropTypes.number.isRequired,
  shortDescription: PropTypes.string.isRequired,
  views: PropTypes.number.isRequired,
  likes: PropTypes.number.isRequired,
  sendEvent: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  currentStats: PropTypes.shape({
    id: PropTypes.number,
    clicks: PropTypes.number,
    shares: PropTypes.number,
    positive_review_percent: PropTypes.number,
    review_count: PropTypes.number,
  }),
}

EpisodeItem.defaultProps = {
  currentStats: {},
}

const mapStateToProps = (state, ownProps) => ({
  currentStats: state.content.statistics.find((item) => item.id === ownProps.id),
})

const actionsToProps = {
  sendEvent,
}

export default connect(mapStateToProps, actionsToProps)(EpisodeItem)
