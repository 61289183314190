import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import isEmpty from 'lodash.isempty'
import { connect } from 'react-redux'

/* assets */
import backgroundLogo from 'assets/images/background-logo.svg'

/* actions */
import {
  getCategories,
  // getMiniSeries,
  getUpcomingEvents,
} from 'state-manager/actions/content'

/* types */
import { types as contentDataTypes } from 'state-manager/reducers/content'

/* hooks */
import _useDidMount from 'hooks/lifecycle/use-did-mount'

/* styles */
import './styles.scss'

/* components */
// import Search from 'components/ui/Search'
import Categories from 'components/ui/Categories'

const ResourcesPage = ({
  categories,
  // miniSeries,
  getCategories,
  // getMiniSeries,
  upcomingEvents,
  getUpcomingEvents,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [isSearchRequest, setIsSearchRequest] = useState(false)

  _useDidMount(() => {
    getCategories()
    getUpcomingEvents()
    // getMiniSeries()
  })

  const renderCategories = () => {
    if (categories.length <= 1 && !isSearchRequest) {
      return null
    }
    if (!isEmpty(categories)) {
      const categoriesWithoutThoughts = categories.filter((category) => category.podcasts[0]?.orientation === 1)
      return (
        <Categories
          disabled={false}
          categories={categoriesWithoutThoughts}
          upcomingEvents={upcomingEvents}
          isFullCategories={false}
          isSliderCategory
          isShowMore
        />
      )
    }
    return null
  }

  // const handleSearch = () => {
  //   setIsSearchRequest(true)
  // }

  return (
    <>
      <main className="homepage--light">
        <div className="parallax-bg resources">
          <img src={backgroundLogo} alt="Background watermark" className="logo" />
        </div>
        <div className="homepage">
          <div className="homepage__wrapper">
            <div className="homepage__inner">

              {/* <section className="homepage__search my-5"> */}
              {/*  <div className="container"> */}
              {/*    <Search */}
              {/*      limit={8} */}
              {/*      handleSearch={handleSearch} */}
              {/*    /> */}
              {/*  </div> */}
              {/* </section> */}

              {renderCategories()}

              {/* {!isEmpty(categories) && ( */}
              {/*  <div className="homepage__creators"> */}
              {/*    <Categories */}
              {/*      isCreatorCategories */}
              {/*      disabled={false} */}
              {/*      isFullCategories={false} */}
              {/*      categories={miniSeries} */}
              {/*      isSliderCategory */}
              {/*    /> */}
              {/*  </div> */}
              {/* )} */}

            </div>
          </div>
        </div>
      </main>
    </>
  )
}

ResourcesPage.propTypes = {
  categories: contentDataTypes.categories.isRequired,
  // miniSeries: contentDataTypes.miniSeries.isRequired,
  getCategories: PropTypes.func.isRequired,
  // getMiniSeries: PropTypes.func.isRequired,
  upcomingEvents: contentDataTypes.upcomingEvents.isRequired,
  getUpcomingEvents: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  categories: state.content.categories,
  // miniSeries: state.content.miniSeries,
  udPermissions: state.userData.permissions,
  upcomingEvents: state.content.upcomingEvents,
})

const actionsToProps = {
  getCategories,
  // getMiniSeries,
  getUpcomingEvents,
}
export default connect(mapStateToProps, actionsToProps)(ResourcesPage)
