import React, { useState } from 'react'
import PropTypes, { oneOfType } from 'prop-types'
import clsx from 'clsx'
import classes from '../VideoPlayer.module.scss'

const PlayerButton = (
  {
    wrapperClass,
    size,
    children,
    handleClick,
    label,
    menuItems,
    menuSelectedItem,
    handleMenuOption,
  },
) => {
  const [menuVisible, setMenuVisible] = useState(false)

  const onClick = () => {
    if (handleClick) {
      handleClick()
    } else {
      setMenuVisible(!menuVisible)
    }
  }

  return (
    <div
      role="button"
      title={label}
      className={clsx(classes[wrapperClass], classes[size])}
      onClick={onClick}>

      {children}

      {menuItems && (
        <div className={clsx(classes.wrapperMenu, menuVisible && classes.visible)}>
          {menuItems.map((item, key) => (
            <span
              role="button"
              key={key}
              className={menuSelectedItem === item ? clsx(classes.menuItem, classes.selectedItem) : classes.menuItem}
              onClick={() => handleMenuOption(key)}>
              {item}
            </span>
          ))}
        </div>
      )}
    </div>
  )
}

PlayerButton.propTypes = {
  label: PropTypes.string,
  wrapperClass: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  menuItems: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  menuSelectedItem: oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  size: PropTypes.oneOf(['sm', 'md']),
  handleClick: PropTypes.func,
  handleMenuOption: PropTypes.func,
}

PlayerButton.defaultProps = {
  label: '',
  menuItems: null,
  size: null,
  handleMenuOption: null,
  handleClick: null,
  menuSelectedItem: null,
}

export default PlayerButton
