import { useLocation } from 'react-router-dom'
import { useLayoutEffect } from 'react'

export default () => {
  const location = useLocation()

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return null
}
