import React, {} from 'react'
import * as PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'
import classes from './BaseLink.module.scss'

const BaseLink = (
  {
    path,
    title,
    spacing,
    variant,
    children,
  },
) => (
  <NavLink to={path} className={clsx(classes.link, spacing, classes[variant])}>
    {title || children}
  </NavLink>
)

BaseLink.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
  spacing: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'header']),
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
}

BaseLink.defaultProps = {
  variant: 'primary',
  spacing: '',
  title: '',
  children: '',
}

export default BaseLink
