import React, { useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import screenfull from 'screenfull'
import { intervalToDuration, format, set } from 'date-fns'

/* components */
import SpriteIcon from 'components/icons/SpriteIcon'

/* state-manager */
import { history } from 'state-manager/store'

/* actions */
import { sendEvent, sendTimeMark } from 'state-manager/actions/content'

/* constants */
import { EVENTS } from 'constants/events'
import { PAUSE_PLAYER } from 'constants/custom-events'

/* hooks */
import _useDidMount from 'hooks/lifecycle/use-did-mount'
import _useDebounce from 'hooks/lifecycle/use-debounce'
import _useCustomEventListener from 'hooks/use-custom-event-listener'

/* helpers */
import isMobile from 'helpers/check-is-mobile-device'

/* styles */
import clsx from 'clsx'
import classes from './VideoPlayer.module.scss'

/* components */
import PlayerControls from './Controls'

const VideoPlayer = ({
  mediaId,
  videos,
  isPortrait,
  subtitles,
  sendEvent,
  sendTimeMark,
  disabled,
  videoDuration,
  currentDuration,
  isStream,
  previewImg,
  isMuted,
}) => {
  const qualities = []
  if (!isStream) {
    videos.map((video) => {
      qualities.push(video.quality)
    })
  }

  const playBackRateValues = [0.5, 1, 1.25, 1.5, 2]
  const [isContinueWatch, setIsContinueWatch] = useState(false)

  const playerRef = useRef()
  const playerContainerRef = useRef()
  const saveCurrentTime = useRef(0)

  const [playing, setPlaying] = useState(true)
  const [muted, setMuted] = useState(isMuted)
  const [volume, setVolume] = useState(0.5)
  const [fullscreen, setFullscreen] = useState(false)
  const [isFullScreenEnabled, setIsFullScreenEnabled] = useState(false)
  const [played, setPlayed] = useState(0)
  const [playBackRate, setPlayBackRate] = useState(1)
  const [duration, setDuration] = useState('00:00')
  const [current, setCurrent] = useState('00:00')
  const [count, setCount] = useState(0)
  const [statsCount, setStatsCount] = useState(0)
  const [controlsVisible, setControlsVisible] = useState(true)
  const [url, setUrl] = useState(isStream ? `${videos[0].src}?${Date.now()}` : videos[0].src)
  const [type, setType] = useState(videos[0].type)
  const [quality, setQuality] = useState(isStream ? 'Live' : qualities[0])
  const [pip, setPip] = useState(ReactPlayer.canEnablePIP(url) ? false : null)
  const [selectedSubtitles, setSelectedSubtitles] = useState('None')
  const [video, setVideo] = useState(null)
  const [height, setHeight] = useState('100%')
  const [isShowPreview, setIsShowPreview] = useState(!!previewImg)

  /* true = Live, false = pause/down */
  const [streamStatus, setStreamStatus] = useState(null)

  const debounceSendPlayPauseEvent = _useDebounce(() => sendEvent({
    typeId: playing ? EVENTS.MEDIA_STOP : EVENTS.MEDIA_START,
    mediaId,
  }), 500)

  _useDidMount(() => {
    if (isPortrait) {
      setHeight(isMobile() ? 'calc(100vh - 79px)' : 'calc(100vh - 91px)')
      // setHeight(isMobile() ? '100vh' : '640px')
    }
  })

  const subtitleLabels = subtitles && subtitles.map((subtitle) => (subtitle.label))

  const reloadUrl = () => {
    setUrl(`${videos[0].src}?${Date.now()}`)
  }

  const handlePlayedChange = (value) => {
    setPlayed(value)
    playerRef.current.seekTo(value / 100)
  }

  const handleReady = () => {
    if (saveCurrentTime.current > 0) {
      playerRef.current.seekTo(saveCurrentTime.current)
      saveCurrentTime.current = 0
    }
    if (screenfull.isEnabled) {
      setIsFullScreenEnabled(true)
      screenfull.on('change', () => {
        screenfull.isFullscreen ? setFullscreen(true) : setFullscreen(false)
      })
    } else {
      setIsFullScreenEnabled(false)
    }

    if (subtitleLabels) {
      /* Subtitles init */
      setVideo(playerRef.current.player.player.player)

      const tracks = playerRef.current.player.player.player.textTracks
      const subtitleIndex = subtitleLabels.indexOf(selectedSubtitles)

      for (let i = 0; i < tracks.length; i++) {
        tracks[i].mode = subtitleIndex === i ? 'showing' : 'hidden'
      }
    }

    if (currentDuration > 0 && !isContinueWatch) {
      setIsContinueWatch(true)
      handlePlayedChange(Math.floor((currentDuration / videoDuration) * 100))
    }

    if (isStream) {
      setStreamStatus(true)
      if (isShowPreview) {
        setIsShowPreview(false)
      }
    }
  }

  const formatTime = (sec) => {
    const duration = intervalToDuration({ start: 0, end: sec * 1000 })
    const setDateByDuration = set(new Date(0), duration)
    return format(setDateByDuration, duration.hours > 0 ? 'HH:mm:ss' : 'mm:ss')
  }

  const handlePlayPause = () => {
    if (isStream) {
      if (!playing) {
        handlePlayedChange(100)
      }
      setPlaying(!playing)

    } else {
      setPlaying(!playing)
      sendEvent({
        typeId: playing ? EVENTS.MEDIA_STOP : EVENTS.MEDIA_START,
        mediaId,
      })
    }
  }

  const handlePause = () => {
    if (playing) {
      setPlaying(false)
      if (!isStream) {
        sendEvent({
          typeId: EVENTS.MEDIA_STOP,
          mediaId,
        })
      }
    }
  }

  _useCustomEventListener(PAUSE_PLAYER, () => handlePause())

  const handleProgress = (value) => {
    setPlayed(value.played * 100)
    setCurrent(formatTime(value.playedSeconds))

    if (statsCount < 5) {
      setStatsCount(statsCount + 1)
    }

    if (statsCount === 5) {
      setStatsCount(0)
      if (!isStream) {
        sendEvent({
          typeId: EVENTS.MEDIA_WATCH_MORE_FIVE_SECONDS,
          mediaId,
        })
      }

      if (!disabled && !isStream) {
        sendTimeMark({
          podcastId: mediaId,
          timeMark: Math.floor(value.playedSeconds),
        })
      }
    }

    if (count > 2) {
      setControlsVisible(false)
      setCount(0)
    }

    if (controlsVisible) {
      setCount(count + 1)
    }

    if (isStream && !streamStatus) {
      setStreamStatus(true)
      if (isShowPreview) {
        setIsShowPreview(false)
      }
    }
  }

  const handleMouseMove = () => {
    setControlsVisible(true)
    setCount(0)
  }

  const handleDuration = (value) => {
    setDuration(formatTime(value))
  }

  const handleMuted = () => {
    setMuted(!muted)
  }

  const handleRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 30)
  }

  const handleForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 30)
  }

  const handleVolumeChange = (value) => {
    value === 0 ? setMuted(true) : setMuted(false)
    setVolume(value / 100)
  }

  const toggleFullScreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle(playerContainerRef.current)
    }
  }

  const handlePlayBackRate = (rate) => {
    setPlayBackRate(playBackRateValues[rate])

  }

  const handleQuality = (quality) => {
    saveCurrentTime.current = played / 100
    setQuality(qualities[quality])

    setType(videos[quality].type)
    setUrl(videos[quality].src)
  }

  const handlePip = () => {
    setPip(!pip)
  }

  const handleDisablePIP = () => {
    setPlaying(false)
  }

  const handleChangeSubtitles = (subtitles) => {
    setSelectedSubtitles(subtitleLabels[subtitles])
    const tracks = video.textTracks
    const track = tracks[subtitles]

    /* Set cue position */
    for (let i = 0; i < track.cues.length; i++) {
      track.cues[i].line = 12
    }

    /* Show current subtitles */
    for (let i = 0; i < tracks.length; i++) {
      tracks[i].mode = subtitles === i ? 'showing' : 'hidden'
    }
  }

  const handleError = (error, errorObject) => {
    if (isStream && error === 'hlsError') {
      if (errorObject.details === 'manifestLoadError') {
        setTimeout(() => {
          reloadUrl()
        }, 5 * 1000)
      }
      setStreamStatus(false)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === ' ') {
      event.preventDefault()
      event.stopPropagation()

      if (!controlsVisible) {
        handleMouseMove()
      }

      if (isStream) {
        if (!playing) {
          handlePlayedChange(100)
        }
      }
      setPlaying(!playing)

      if (!isStream) {
        debounceSendPlayPauseEvent()
      }
    }
  }

  const handleClose = () => {
    history.goBack()
  }

  return (
    <div
      tabIndex="0"
      aria-label="Video Player"
      role="button"
      ref={playerContainerRef}
      className={clsx(classes.wrapperPlayer, isStream && classes.wrapperStream)}
      onMouseMove={handleMouseMove}
      onKeyDown={handleKeyDown}>
      {isShowPreview && (
        <div className={classes.previewWrapper}>
          <div
            style={{
              backgroundImage: `url(${previewImg})`,
            }}
            className={classes.preview}
          />
        </div>
      )}

      {isPortrait && (
        <div className={classes.wrapperClose} onClick={handleClose} role="button">
          <SpriteIcon name="cross-close" size="md-big" />
        </div>
      )}

      <ReactPlayer
        ref={playerRef}
        url={isStream ? url : [
          {
            src: url,
            type,
            quality,
          },
        ]}
        width="100%"
        height={height}
        style={{
          backgroundColor: '#000',
        }}
        playing={playing}
        playbackRate={playBackRate}
        muted={muted}
        volume={volume}
        onReady={handleReady}
        onProgress={handleProgress}
        onDuration={handleDuration}
        onError={handleError}
        pip={pip}
        onDisablePIP={handleDisablePIP}
        playsinline={isMobile()}
        config={subtitles ? {
          file: {
            attributes: {
              // crossOrigin: 'true',
            },
            tracks: subtitles,
          },
        } : {
          file: {
            attributes: {
              // crossOrigin: 'true',
            },
            forceHLS: isStream,
          },
        }}
      />

      <PlayerControls
        playing={playing}
        handlePlayPause={handlePlayPause}
        muted={muted}
        handleMuted={handleMuted}
        handleRewind={handleRewind}
        handleForward={handleForward}
        volume={volume}
        handleVolumeChange={handleVolumeChange}
        fullscreen={fullscreen}
        toggleFullScreen={toggleFullScreen}
        isFullScreenEnabled={isFullScreenEnabled}
        played={played}
        handlePlayedChange={handlePlayedChange}
        duration={duration}
        current={current}
        controlsVisible={controlsVisible}
        playBackRate={playBackRate}
        playBackRateValues={playBackRateValues}
        handlePlayBackRate={handlePlayBackRate}
        qualities={qualities}
        quality={quality}
        handleQuality={handleQuality}
        pip={pip}
        handlePip={handlePip}
        subtitles={subtitleLabels}
        selectedSubtitles={selectedSubtitles}
        handleChangeSubtitles={handleChangeSubtitles}
        isStream={isStream}
        streamStatus={streamStatus}
      />
    </div>
  )
}

VideoPlayer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  videos: PropTypes.array.isRequired,
  subtitles: PropTypes.arrayOf(PropTypes.object),
  mediaId: PropTypes.number,
  sendEvent: PropTypes.func.isRequired,
  isPortrait: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  videoDuration: PropTypes.number,
  currentDuration: PropTypes.number,
  sendTimeMark: PropTypes.func.isRequired,
  isStream: PropTypes.bool,
  previewImg: PropTypes.string,
  isMuted: PropTypes.bool,
}

VideoPlayer.defaultProps = {
  subtitles: [],
  isPortrait: false,
  videoDuration: 0,
  currentDuration: 0,
  isStream: false,
  mediaId: null,
  previewImg: null,
  isMuted: false,
}

const actionsToProps = {
  sendEvent,
  sendTimeMark,
}

export default connect(null, actionsToProps)(VideoPlayer)
