import React from 'react'
import Slider from 'react-slick'
import * as PropTypes from 'prop-types'
import isEmpty from 'lodash.isempty'
import { getSlidesCount } from 'helpers/get-slides-count'
import CustomArrow from '../CustomArrow'

import classes from './CreatorsCarousel.module.scss'
import CreatorCard from './CreatorCard'

const CreatorsCarousel = ({ creators, isAdvisors, isCompany }) => {
  if (isEmpty(creators)) return null
  const renderSlides = (creators) => {
    const result = []
    if (!isAdvisors) {
      creators.forEach((value, index, array) => {
        if (index % 2 === 0 && array[index + 1] !== undefined) {
          result.push([value, array[index + 1]])
        } else if (index % 2 === 0) {
          result.push([value])
        }
      })
      return result.map((item, index) => (
        <div key={index.toString() + new Date().getTime()}>
          {item.map((user) => (<CreatorCard creator={user} key={user.id.toString() + new Date().getTime()} />))}
        </div>
      ))
    }
    return creators.map((creator) => (
      <CreatorCard creator={creator} key={creator.id.toString() + new Date().getTime()} isAdvisors={isAdvisors} isCompany={isCompany} />
    ))
  }

  return (
    <div className={classes.creatorsCarousel}>
      <div className={classes.slider}>
        <Slider
          variableWidth
          dots={false}
          infinite={false}
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          arrows
          nextArrow={<CustomArrow isBold />}
          prevArrow={<CustomArrow isPrevArrow isBold />}
          swipe
          responsive={[
            {
              breakpoint: 1399,
              settings: {
                slidesToShow: getSlidesCount(3, false, creators.length),
              },
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: getSlidesCount(3, false, creators.length),
              },
            },
            {
              breakpoint: 992,
              settings: {
                arrows: false,
                slidesToShow: getSlidesCount(2, true, creators.length),
              },
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
                arrows: false,
              },
            },
          ]}>
          {renderSlides([...creators])}
        </Slider>
      </div>
    </div>
  )
}

CreatorsCarousel.propTypes = {
  creators: PropTypes.arrayOf(PropTypes.object),
  isAdvisors: PropTypes.bool,
  isCompany: PropTypes.bool,
}

CreatorsCarousel.defaultProps = {
  creators: [],
  isAdvisors: false,
  isCompany: false,
}
export default CreatorsCarousel
