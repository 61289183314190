import { useCallback, useContext, useRef } from 'react'
import { EchoContext } from './Provider'
import _useDidMount from '../../hooks/lifecycle/use-did-mount'

const _useConnectToEchoServer = ({ channelName, isAuthorized, events = {} }) => {
  const channel = useRef(null)
  const Echo = useContext(EchoContext)
  const instance = useRef(null)

  const connectToEcho = useCallback(async () => {
    instance.current = await Echo.connect()
    channel.current = await instance.current.channel(channelName)
    Object.entries(events).forEach(([name, callback]) => channel.current.listen(name, callback))
  }, [Echo, channelName, events])

  _useDidMount(() => {
    if (isAuthorized) {
      connectToEcho()

      return () => {
        setTimeout(() => {
          instance.current.leave(channelName)
        })
      }
    }
  })
}

export default _useConnectToEchoServer
