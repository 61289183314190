import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

/* styles */
import './styles.scss'

/* components */
import VideoPlayer from 'components/ui/VideoPlayer'
import ChatBox from 'components/ui/Stream/ChatBox'

/* hooks */
import _useDidMount from 'hooks/lifecycle/use-did-mount'
import _useDidUpdate from 'hooks/lifecycle/use-did-update'

/* actions */
import { getStreamInfo } from 'state-manager/actions/content'

/* state-manager */
import { history } from 'state-manager/store'

/* routes */
import routes from 'routes'

/* helpers */
import renderHtml from 'helpers/render-html'
import getUrlByMediaPrefix from 'helpers/get-url-by-media-prefix'

const StreamPage = ({
  streamInfo,
  getStreamInfo,
}) => {
  const params = useParams()

  _useDidMount(() => {
    if (!streamInfo) {
      getStreamInfo()
    }
  })

  _useDidUpdate(() => {
    if (!streamInfo) {
      history.push(routes.homepage)
    }
  }, [streamInfo])

  if (streamInfo) {
    if (streamInfo.slug !== params.slug) {
      history.push(routes.errorPage)
    }
  } else {
    return null
  }

  return (
    <main>
      <section className="stream-page">
        <div className="stream-page__wrapper">
          <div className="stream-page__inner">
            <div className="stream-page__main">
              <div className="stream-page__player">
                <VideoPlayer
                  disabled={false}
                  videos={[{
                    src: streamInfo.playback_url,
                  }]}
                  isPortrait={false}
                  previewImg={streamInfo.image ?
                    getUrlByMediaPrefix.streamPreview(streamInfo.image, streamInfo.id) :
                    getUrlByMediaPrefix.staticImage('stream_default.png')}
                  isStream
                />
              </div>
            </div>
            <aside className="stream-page__sidebar">
              <ChatBox chatId={streamInfo.conversation_id} />
            </aside>
          </div>

          {streamInfo.description && (
            <div className="container">
              <div className="stream-page__description">
                {renderHtml(streamInfo.description)}
              </div>
            </div>
          )}
        </div>
      </section>
    </main>
  )
}

StreamPage.propTypes = {
  streamInfo: PropTypes.shape({
    conversation_id: PropTypes.number,
    playback_url: PropTypes.string,
    slug: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
    image: PropTypes.string,
  }),
  getStreamInfo: PropTypes.func.isRequired,
}

StreamPage.defaultProps = {
  streamInfo: null,
}

const actionsToProps = {
  getStreamInfo,
}

const mapStateToProps = (state) => ({
  streamInfo: state.content.streamInfo,
})

export default connect(mapStateToProps, actionsToProps)(StreamPage)
