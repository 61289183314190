import React, {} from 'react'
import './styles.scss'
import * as PropTypes from 'prop-types'

/* helpers */
import renderInputs from 'helpers/render-inputs'

/* components */
import Button from 'components/ui/Button/base'

/* constants */
import { formFields } from './constants'

const CreatePasswordTemplate = ({ handleSubmit, handleChange, values, errors, touched, handleBlur, submitCount }) => {
  const inputsConfig = [
    {
      label: 'Create password',
      variant: 'secondary',
      id: formFields.password,
      type: 'password',
      name: formFields.password,
      spacing: 'mb-1',
    },
    {
      label: 'Confirm password',
      variant: 'secondary',
      id: formFields.password_confirmation,
      type: 'password',
      name: formFields.password_confirmation,
      spacing: 'mb-1',
    },
  ]

  return (
    <section className="create-password-page">
      <div className="create-password-page__wrapper">
        <div className="max-width-400">
          <div className="create-password-page__inner">
            <h2 className="create-password-page__description">
              Create a Secure Password
            </h2>

            <form onSubmit={handleSubmit}>
              {renderInputs({
                inputsConfig,
                values,
                submitCount,
                touched,
                errors,
                handleBlur,
                handleChange,
              })}

              <div className="create-password-page__bottom-text">
                Use at least one lowercase letter, one uppercase letter, one number and one special character (e.g.
                !, @,
                #). 8 character minimum.
              </div>

              <Button
                text="Next"
                type="submit"
                variant="primary"
                size="medium"
              />
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

CreatePasswordTemplate.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.string),
  errors: PropTypes.objectOf(PropTypes.string),
  touched: PropTypes.objectOf(PropTypes.bool),
  submitCount: PropTypes.number.isRequired,
}

CreatePasswordTemplate.defaultProps = {
  errors: {},
  values: {},
  touched: {},
}

export default CreatePasswordTemplate
