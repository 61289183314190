import axios from 'axios'
import FormData from 'form-data'
import isPlainObject from 'lodash.isplainobject'
import { toast } from 'react-toastify'

/* routes */
import routes from 'routes'

/* state-manager */
import { store, history } from 'state-manager/store'

/* actions */
import { getUserData } from 'state-manager/actions/user'

/* utils */
import globalSpinner from 'utils/global-spinner'

/* constants */
import { API_URL } from 'constants/main'

const reqH = (options = {}) => {
  const requestOptions = {}

  if (typeof options.method === 'undefined') {
    options.method = 'GET'
  }
  requestOptions.method = options.method

  if (typeof options.params === 'undefined') {
    options.params = {}
  }
  requestOptions.params = options.params

  if (options.method !== 'GET' && options.data) {
    if (typeof options.sendMethod === 'undefined') {
      options.sendMethod = 'json'
    }

    if (options.sendMethod === 'form-data') {
      const formData = new FormData()
      Object.keys(options.data).forEach((key) => {
        const value = options.data[key]
        if (Array.isArray(value)) { // if value is array - add each key
          Object.keys(value).forEach((innerKey) => {
            formData.append(`${key}[]`, value[innerKey] || '')
          })
        } else if (isPlainObject(value) && !(value instanceof File)) { // if value is object - add each key to the overall key
          for (const innerKey in value) {
            formData.append(`${key}[${innerKey}]`, value[innerKey] || '')
          }
        } else {
          formData.append(key, value || '')
        }
      })
      requestOptions.data = formData
    } else {
      requestOptions.data = options.data
    }
  }

  if (options.urlPrefix) {
    options.url = `${options.urlPrefix}/${options.url}`
  }
  requestOptions.url = options.url

  // requestOptions.headers = new Headers() // eslint-disable-line compat/compat
  requestOptions.mode = 'cors'
  requestOptions.withCredentials = false

  if (!options.skipGlobalSpinner) {
    globalSpinner.show()
  }

  const axiosInstance = axios.create({
    baseURL: options.otherDomain || API_URL,
    timeout: 1000 * 60 * 1.5,
  })

  const requestInterceptor = (config) => {
    if (!window.navigator.onLine) {
      toast.error('No internet connection')
    }

    const state = store.getState()

    let token = null
    if (state && state.userData && state.userData.authorizationData) {
      token = `${state.userData.authorizationData.token_type} ${state.userData.authorizationData.access_token}`
    }
    config.headers.Authorization = token

    return config
  }

  const responseInterceptorSuccess = (res) => {
    if (options.shouldUpdateUserData) {
      store.dispatch(getUserData())
    }

    if (!options.keepGlobalSpinnerAfterResponse) {
      globalSpinner.hide()
    }

    return res
  }

  const responseInterceptorError = (error) => {
    globalSpinner.hide()
    // eslint-disable-next-line no-console
    console.error('error.response Interceptor', error.response)

    const validation = error.response?.data.errors
    if (!options.skipUnauthorizedNotification && validation) {
      Object.values(validation).forEach((fieldErrors) => {
        if (Array.isArray(fieldErrors)) {
          fieldErrors.forEach((error) => {
            toast.error(error)
          })
        } else {
          toast.error(fieldErrors)
        }
      })

      throw error
    }

    const errorStatuses = [
      404,
      500,
    ]

    if (errorStatuses.includes(error.response.status)) {
      history.push(routes.errorPage)
    }

    // if (isUnauthorizedError) {
    // store.dispatch(signOutSimple())
    // }

    throw error
  }

  axiosInstance.interceptors.request.use(requestInterceptor)
  axiosInstance.interceptors.response.use(responseInterceptorSuccess, responseInterceptorError)

  return axiosInstance(requestOptions)
}

export default reqH
