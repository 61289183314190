import { useRef, useEffect } from 'react'
import throttle from 'lodash.throttle'

const _useEventListener = (element, eventName, handler, { useThrottle = false, delay = 100 } = {}) => {
  const savedHandler = useRef()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(
    () => {
      const isSupported = element && element.addEventListener
      if (!isSupported) {
        return
      }

      const eventListener = (event) => savedHandler.current(event)

      const updatedEventListener =
        useThrottle ?
          throttle(() => { eventListener() }, delay)
          :
          eventListener

      element.addEventListener(eventName, updatedEventListener)
      return () => {
        element.removeEventListener(eventName, updatedEventListener)
      }
    },
    [eventName, element],
  )
}

export default _useEventListener
