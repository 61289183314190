import PropTypes from 'prop-types'

/* helpers */
import updateObject from 'state-manager/reducers/helpers/update-object'

/* constants */
import { CONTENT_ACTIONS } from 'state-manager/constants'

const initialState = {
  categories: [],
  videos: [],
  miniSeries: [],
  upcomingEvents: [],
  singleMiniSeries: [],
  creators: [],
  header: [],
  filters: {},
  ads: [],
  statistics: [],
  fellowship: [],
  streamInfo: null,
  responseWaiting: false,
  topQuestions: [],
  topCreators: [],
  advisors: [],
  partners: [],
  topVideos: [],
  latestVideos: [],
  jobs: [],
  singleQuestion: null,
  pastEvents: [],
}

export const types = {
  categories: PropTypes.array,
  videos: PropTypes.array,
  miniSeries: PropTypes.array,
  upcomingEvents: PropTypes.array,
  singleMiniSeries: PropTypes.array,
  creators: PropTypes.array,
  header: PropTypes.array,
  filters: PropTypes.object,
  ads: PropTypes.array,
  statistics: PropTypes.array,
  fellowship: PropTypes.array,
  streamInfo: PropTypes.object,
  responseWaiting: PropTypes.bool,
  topQuestions: PropTypes.array,
  topCreators: PropTypes.array,
  advisors: PropTypes.array,
  partners: PropTypes.array,
  jobs: PropTypes.array,
  pastEvents: PropTypes.array,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CONTENT_ACTIONS.DELETE_FROM_BOOKMARKS.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CONTENT_ACTIONS.DELETE_FROM_BOOKMARKS.SUCCESS:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.DELETE_FROM_BOOKMARKS.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.ADD_TO_BOOKMARKS.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CONTENT_ACTIONS.ADD_TO_BOOKMARKS.SUCCESS:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.ADD_TO_BOOKMARKS.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_CATEGORIES.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CONTENT_ACTIONS.GET_CATEGORIES.SUCCESS:
      // const updateVideos = []
      // action.payload.categories.map((category) => {
      //   updateVideos.push(...category.podcasts)
      // })

      return updateObject(state, {
        categories: action.payload.categories,
        // videos: updateVideos,
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_TOP_VIDEOS.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CONTENT_ACTIONS.GET_TOP_VIDEOS.SUCCESS:
      return updateObject(state, {
        topVideos: action.payload.topVideos,
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_TOP_VIDEOS.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_LATEST_VIDEOS.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CONTENT_ACTIONS.GET_LATEST_VIDEOS.SUCCESS:
      return updateObject(state, {
        latestVideos: action.payload.latestVideos,
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_LATEST_VIDEOS.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_CATEGORIES.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_HEADER.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CONTENT_ACTIONS.GET_HEADER.SUCCESS:
      return updateObject(state, {
        header: action.payload.header,
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_HEADER.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_SINGLE.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CONTENT_ACTIONS.GET_SINGLE.SUCCESS:
      const updatedVideos = [...state.videos]
      const currentVideoIndex = state.videos.findIndex((video) => video.id === action.payload.video.id)

      if (currentVideoIndex < 0) {
        updatedVideos.push(action.payload.video)

        return updateObject(state, {
          videos: updatedVideos,
          responseWaiting: false,
        })
      }

      updatedVideos.splice(currentVideoIndex, 1, action.payload)
      return updateObject(state, {
        videos: updatedVideos,
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_SINGLE.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_SINGLE_STATS.SUCCESS:
      return updateObject(state, {
        statistics: [action.payload.statistics],
      })

    case CONTENT_ACTIONS.GET_EPISODES_STATS.SUCCESS:
      return updateObject(state, {
        statistics: action.payload.statistics,
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_FILTERS.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CONTENT_ACTIONS.GET_FILTERS.SUCCESS:
      return updateObject(state, {
        filters: action.payload.filters,
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_FILTERS.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_ADS.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CONTENT_ACTIONS.GET_ADS.SUCCESS:
      return updateObject(state, {
        ads: action.payload.ads,
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_ADS.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_PROFILE.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CONTENT_ACTIONS.GET_PROFILE.SUCCESS:
      const updateProfiles = [...state.creators]
      const currentProfileIndex = state.creators.findIndex((creator) => creator.id === action.payload.creator.id)
      const podcasts = action.payload.creator.mini_series[0].podcasts
      const updateVideoFromCreators = [...state.videos]

      podcasts.map((podcast) => {
        if (updateVideoFromCreators.findIndex((video) => video.id === podcast.id) < 0) {
          updateVideoFromCreators.push(podcast)
        }
      })

      if (currentProfileIndex < 0) {
        updateProfiles.push(action.payload.creator)

        return updateObject(state, {
          creators: updateProfiles,
          videos: updateVideoFromCreators,
          responseWaiting: false,
        })
      }

      updateProfiles.splice(currentProfileIndex, 1, action.payload)
      return updateObject(state, {
        creators: updateProfiles,
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_PROFILE.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_SINGLE_MINISERIES.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CONTENT_ACTIONS.GET_SINGLE_MINISERIES.SUCCESS:
      const updateSeries = [...state.singleMiniSeries]
      const currentSeriesIndex = state.singleMiniSeries.findIndex((series) => series.id === action.payload.singleMiniSeries.id)

      if (currentSeriesIndex < 0) {
        updateSeries.push(action.payload.singleMiniSeries)

        return updateObject(state, {
          singleMiniSeries: updateSeries,
          responseWaiting: false,
        })
      }

      updateSeries.splice(currentSeriesIndex, 1, action.payload.singleMiniSeries)
      return updateObject(state, {
        singleMiniSeries: updateSeries,
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_SINGLE_MINISERIES.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_MINISERIES.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CONTENT_ACTIONS.GET_MINISERIES.SUCCESS:
      return updateObject(state, {
        miniSeries: action.payload.miniSeries,
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_MINISERIES.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_CREATOR.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CONTENT_ACTIONS.GET_CREATOR.SUCCESS:
      const updateCreators = [...state.creators]

      const currentCreatorIndex = state.creators.findIndex((creator) => creator.slug === action.payload.creator.slug)

      if (currentCreatorIndex < 0) {
        updateCreators.push(action.payload.creator)

        return updateObject(state, {
          creators: updateCreators,
          responseWaiting: false,
        })
      }

      return updateObject(state, {
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_CREATOR.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_QUESTION.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CONTENT_ACTIONS.GET_QUESTION.SUCCESS:
      return updateObject(state, {
        singleQuestion: action.payload.singleQuestion,
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_QUESTION.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.UPDATE_TIME_MARK.SYSTEM:
      const updateVideosTimeMark = [...state.videos]
      const index = updateVideosTimeMark.findIndex((video) => video.id === action.payload.videoId)
      updateVideosTimeMark[index].current_duration = action.payload.timeMark

      return updateObject(state, {
        videos: updateVideosTimeMark,
      })

    case CONTENT_ACTIONS.GET_FELLOWSHIP_CATEGORY.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CONTENT_ACTIONS.GET_FELLOWSHIP_CATEGORY.SUCCESS:
      return updateObject(state, {
        fellowship: action.payload.fellowship,
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_FELLOWSHIP_CATEGORY.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_UPCOMING_EVENTS.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CONTENT_ACTIONS.GET_UPCOMING_EVENTS.SUCCESS:
      return updateObject(state, {
        upcomingEvents: action.payload.upcomingEvents,
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_UPCOMING_EVENTS.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_STREAM_INFO.SUCCESS:
      return updateObject(state, {
        streamInfo: action.payload.streamInfo,
      })

    case CONTENT_ACTIONS.GET_TOP_QUESTIONS.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CONTENT_ACTIONS.GET_TOP_QUESTIONS.SUCCESS:
      return updateObject(state, {
        topQuestions: action.payload.topQuestions,
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_TOP_QUESTIONS.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_TOP_CREATORS.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CONTENT_ACTIONS.GET_TOP_CREATORS.SUCCESS:
      return updateObject(state, {
        topCreators: action.payload.creators,
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_TOP_CREATORS.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_ADVISORS.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CONTENT_ACTIONS.GET_ADVISORS.SUCCESS:
      return updateObject(state, {
        advisors: action.payload.advisors,
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_ADVISORS.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_PARTNERS.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CONTENT_ACTIONS.GET_PARTNERS.SUCCESS:
      return updateObject(state, {
        partners: action.payload.partners,
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_PARTNERS.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_JOBS.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CONTENT_ACTIONS.GET_JOBS.SUCCESS:
      return updateObject(state, {
        jobs: action.payload.jobs,
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_JOBS.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_PAST_EVENTS.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case CONTENT_ACTIONS.GET_PAST_EVENTS.SUCCESS:
      return updateObject(state, {
        pastEvents: action.payload.pastEvents,
        responseWaiting: false,
      })

    case CONTENT_ACTIONS.GET_PAST_EVENTS.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    default:
      return state
  }
}
