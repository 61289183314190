import React from 'react'
import PropTypes from 'prop-types'
import declineWord from 'decline-word'

/* styles */
import './styles.scss'

/* constants */
// import { ROLES } from 'constants/roles'

/* types */
// import { types as userDataTypes } from 'state-manager/reducers/user-data'

/* component */
import VideoPlayer from 'components/ui/VideoPlayer'
// import NotifyMeButton from 'components/ui/NotifyMeButton'
import Share from 'components/ui/Share'
import CalendarButton from 'components/ui/CalendarButton'
// import Button from 'components/ui/Button/base'
import Episodes from '../episodes'

const Content = ({
  miniSeries,
  disabled,
  // udRole,
  disciplineName,
}) => {
  const fullVideos = []
  // const link = 'https://docs.google.com/forms/d/e/1FAIpQLSfAERp-L00Q9fxZZmOiLGTV2QX9utlXGmr3fIMET_aZpMTKww/viewform?usp=sf_link'

  if (miniSeries) {
    for (const key in miniSeries?.trailer.urls) {
      if (miniSeries.trailer.urls.hasOwnProperty(key)) {
        fullVideos.push(
          {
            src: miniSeries.trailer.urls[key],
            type: 'video/mp4',
            quality: `${key.substr(1, key.length)}p`,
          },
        )
      }
    }
  }

  const subtitles = miniSeries?.trailer.subtitles.length ?
    miniSeries?.trailer.subtitles.map((sub) => ({
      src: sub.url,
      label: sub.lang,
      default: false,
    })) : null

  return (
    <main className="Creators_content">
      <section className="Creators_content_info">
        <div className="Creators_content-learners-series">
          <div>
            LEARNERS ORIGINAL SERIES
          </div>
          <div className="Creators_content-buttons">
            <Share
              slug={miniSeries.slug}
              title={miniSeries ? miniSeries.title : ''}
              description={miniSeries ? miniSeries.description : ''}
              isOnlyIcon
              iconsWithState
              placement="bottom"
              isCreator
              creatorID={miniSeries.id}
            />

            {miniSeries.google_calendar_link && (
              <CalendarButton className="Creators_content-calendar" />
            )}

            {/* <NotifyMeButton className="Creators_content-notify-me" /> */}
          </div>
        </div>
        {miniSeries && (
          <div className="title">
            {miniSeries.title}
          </div>
        )}
        <div className="with-creator max-width-660">{'with '}
          <span className="font-bold">{`${miniSeries.creators[0].first_name} ${miniSeries.creators[0].last_name}`}</span>
          {', '}
          <span className="font-bold">{disciplineName}</span>
          {' at '}
          <span className="font-bold">{miniSeries.creators[0].company}</span>
        </div>
        {miniSeries && (
          <div className="description max-width-660">
            {miniSeries.description}
          </div>
        )}
        {miniSeries && (
          <div className="count-of-episodes">
            {`${miniSeries.podcasts.length} ${declineWord(miniSeries.podcasts.length, 'episode')}`}
          </div>
        )}
        <div className="Creators_content-trailer-wrapper">
          {fullVideos.length > 0 && (
            <VideoPlayer
              disabled={disabled}
              mediaId={miniSeries.trailer.id}
              subtitles={subtitles ? [{ label: 'None' }, ...subtitles] : null}
              videos={fullVideos}
            />
          )}
        </div>
      </section>

      {/* {udRole !== ROLES.UNAUTHORIZED && ( */}
      {/*  <section className="join-group"> */}
      {/*    <div className="join-group__wrapper"> */}
      {/*      <div className="join-group__title"> */}
      {/*        Join a learning group for this series. */}
      {/*      </div> */}
      {/*      <div className="join-group__description"> */}
      {/*        Why learn alone when you can learn with some new friends? Join a small group of peers to share your */}
      {/*        insights and grow together. */}
      {/*      </div> */}
      {/*    </div> */}

      {/*    <div className="wrapper-button"> */}
      {/*      <Button */}
      {/*        variant="secondary" */}
      {/*        text="Join" */}
      {/*        type="button" */}
      {/*        size="small" */}
      {/*        onClick={() => window.open(link, '_blank')} */}
      {/*      /> */}
      {/*    </div> */}
      {/*  </section> */}
      {/* )} */}

      <Episodes
        episodes={miniSeries.podcasts}
        disabled={false}
      />
    </main>
  )
}

Content.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  miniSeries: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  disciplineName: PropTypes.string.isRequired,
  // udRole: userDataTypes.role.isRequired,
}

export default Content
