import { connect } from 'react-redux'

/* hooks */
import _useDidMount from 'hooks/lifecycle/use-did-mount'

/* types */
import { types as userDataTypes } from 'state-manager/reducers/user-data'

const Profilwell3dParty = ({
  udEmail,
}) => {

  _useDidMount(() => {
    if (window.profitwell && udEmail) {
      window.profitwell('start', {
        user_email: udEmail,
      })
    }
  })

  return null
}

Profilwell3dParty.propTypes = {
  udEmail: userDataTypes.email,
}

const mapStateToProps = (state) => ({
  udEmail: state.userData.email,
})

export default connect(mapStateToProps)(Profilwell3dParty)
