import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import Avatar from 'react-avatar-edit'

/* styles */
import './styles.scss'

/* components */
import SpriteIcon from 'components/icons/SpriteIcon'
import { CloseModalButton } from 'components/ui/CloseModalButton'
import Button from 'components/ui/Button/base'

/* helpers */
import isMobile from 'helpers/check-is-mobile-device'

const CropImage = ({
  setPreview,
  isOpen,
  handleModalVisibility,
}) => {
  const [isShow, setIsShow] = useState(false)

  const closeModal = () => {
    handleModalVisibility(false)
  }

  const onClose = () => {
    setIsShow(false)
  }

  const onCrop = (preview) => {
    setPreview(preview)
  }

  const onBeforeFileLoad = () => {
    // if (element.target.files[0].size > 71680) {
    //   alert('File is too big!')
    //   element.target.value = ''
    // }
    setIsShow(true)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="crop-modal"
      ariaHideApp={false}
      overlayClassName="overlay"
      contentLabel="Example modal">

      <div className="modal-inner">

        <Avatar
          width={isMobile() ? window.innerWidth - 30 : 800}
          height={450}
          imageWidth={isMobile() ? window.innerWidth - 30 : null}
          // imageHeight={isMobile() ? 300 : null}
          onCrop={onCrop}
          onClose={onClose}
          onBeforeFileLoad={onBeforeFileLoad}
          shadingColor="black"
          labelStyle={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            fontWeight: 700,
            cursor: 'pointer',
          }}
        />

        {isShow && (
          <div className="wrapper-button">
            <Button
              text="Apply"
              size="medium"
              variant="secondary"
              onClick={closeModal}
            />
          </div>
        )}
      </div>

      <CloseModalButton right={32} top={32} onClick={closeModal} color="white" />

      <div
        role="link"
        className="header-logo"
        onClick={closeModal}>
        <SpriteIcon name="learners-logo" size="logo" />
      </div>

    </Modal>
  )
}

CropImage.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setPreview: PropTypes.func.isRequired,
  handleModalVisibility: PropTypes.func.isRequired,
}

export default CropImage
