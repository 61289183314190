import React from 'react'
import { Helmet } from 'react-helmet'

/* assets */
import image from 'assets/images/404-illustration.png'

/* styles */
import './styles.scss'

/* components */
import BaseLink from 'components/ui/Link/Base'

const ErrorPage = () => (
  <>
    <Helmet>
      <meta
        property="prerender-status-code"
        content={404}
      />
    </Helmet>

    <div className="error-page">
      <div className="container">
        <div className="error-page__message max-width-400">
          <h1 className="title mb-2 font-bold">Oops!</h1>
          <div className="description mb-5">We can’t seem to find the page you’re looking for.</div>
          <BaseLink
            path="/"
            variant="primary"
            title="Head back to the homepage?"
          />
        </div>
      </div>

      <div className="error-page__image">
        <img className="img" src={image} alt="Oops!" />
      </div>
    </div>

  </>
)

export default ErrorPage
