import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import { formFields } from './constants'

export default ({
  onSubmit,
  userData,
  jobTitle,
  photo,
}) => useFormik({
  initialValues: {
    [formFields.handle]: userData.handle || '',
    [formFields.firstName]: userData.firstName || '',
    [formFields.lastName]: userData.lastName || '',
    [formFields.work_title]: userData.work_title || '',
    [formFields.company]: userData.company || '',
    [formFields.country]: userData.country || '',
    [formFields.city]: userData.city || '',
  },

  validationSchema: Yup.object({
    handle: Yup.string()
      .required('Unique handle field is required'),
    firstName: Yup.string()
      .required('First name field is required'),
    lastName: Yup.string()
      .required('Last name field is required'),
  }),

  async onSubmit(values) {
    if (!jobTitle) {
      toast.error('Professional category is required')
      return null
    }

    if (photo) {
      const blob = await fetch(photo)
        .then((res) => res.blob())

      const imageFile = new File([blob], `${Date.now()}.png`, {
        type: blob.type,
      })

      onSubmit({
        ...values,
        jobTitle,
        photo: imageFile,
      })

      return null
    }

    onSubmit({
      ...values,
      jobTitle,
      photo,
    })
  },
})
