import React from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'

/* actions */
import {
  getAdvisors,
  getEvents, getUxJobs, getLatestVideos,
  getPartners,
  getThoughts,
  getTopCreators,
  getTopQuestions, getPastEvents,
} from 'state-manager/actions/content'

/* hooks */
import _useDidMount from 'hooks/lifecycle/use-did-mount'

/* helpers */
import isMobile from 'helpers/check-is-mobile-device'

/* assets */
import backgroundLogo from 'assets/images/background-logo.svg'

/* styles */
import './styles.scss'

/* constants */
import { navConfig } from 'components/ui/NavMenu/constants'

/* components */
import SpriteIcon from 'components/icons/SpriteIcon'
import CreatorsCarousel from 'components/ui/CreatorsCarousel'
import EventCard from 'components/ui/EventCard'
import VideoCarousel from 'components/ui/VideosCarousel'
import QuestionCarousel from 'components/ui/QuestionCarousel'
import NavMenu from 'components/ui/NavMenu'
import CreatorCard from 'components/ui/CreatorsCarousel/CreatorCard'
import JobCard from 'components/ui/JobCard'
import MainSideBar from 'components/MainSideBar'

const UXResearchPage = ({
  latestVideos,
  questions,
  creators,
  advisors,
  partners,
  events,
  jobs,
  pastEvents,
  getTopQuestions,
  getLatestVideos,
  getTopCreators,
  getAdvisors,
  getPartners,
  getEvents,
  getJobs,
  getPastEvents,
}) => {
  _useDidMount(() => {
    getLatestVideos()
    getTopQuestions()
    getTopCreators()
    getAdvisors()
    getPartners()
    getEvents()
    getJobs()
    getPastEvents()
  })

  return (
    <main className="homepage--light">
      <div className="parallax-bg">
        <img src={backgroundLogo} alt="Background watermark" className="logo" />
      </div>
      <div className="homepage">
        {isMobile() && <NavMenu navItems={navConfig} />}
        <div className="homepage__wrapper">
          <div className="container">
            <div className="homepage__inner">
              <MainSideBar />
              <div className="homepage__content">
                <h1 className="ux-research-title">
                  <SpriteIcon
                    name="phone-title"
                    size="sliderMd"
                  />
                  From The App
                </h1>
                <section className="latest-videos thoughts">
                  <h2 className="section__title">
                    New Videos 🔥
                  </h2>
                  <VideoCarousel videos={latestVideos} />
                </section>

                <section className="top-questions thoughts">
                  <h2 className="section__title">
                    Trending Questions 🚀
                  </h2>
                  <QuestionCarousel questions={questions} />
                </section>

                {creators.length && (
                  <section className="top-creators thoughts">
                    <h2 className="section__title">
                      Top Creators 🏅
                    </h2>
                    <CreatorsCarousel creators={creators} />
                  </section>
                )}

                <h1 className="ux-research-title second">
                  <SpriteIcon
                    name="calendar-title"
                    size="sliderMd"
                  />
                  Events by Learners
                </h1>

                <section className="upcoming-events-wrapper thoughts">
                  <h2 className="section__title second">
                    Content Advisors
                  </h2>
                  <div className="upcoming-events">
                    {advisors.map((advisor) => (<CreatorCard creator={advisor} key={advisor.id} isAdvisors />))}
                  </div>
                  <h2 className="section__title second">
                    Community Sponsors
                  </h2>
                  <div className="upcoming-events">
                    {partners.map((partner) => (<CreatorCard creator={partner} key={partner.id} isAdvisors isCompany />))}
                  </div>
                </section>

                {events.length ? (
                  <section className="upcoming-events-wrapper thoughts">
                    <h2 className="section__title second">
                      Upcoming Events
                    </h2>
                    <div className="upcoming-events">
                      {events.map((event) => (<EventCard eventInfo={event} key={event.id} />))}
                    </div>
                  </section>
                ) : null}
                {pastEvents.length ? (
                  <section className="upcoming-events-wrapper thoughts">
                    <h2 className="section__title second">
                      Past Events
                    </h2>
                    <div className="upcoming-events">
                      {pastEvents.map((event) => (<EventCard eventInfo={event} key={event.id} />))}
                    </div>
                  </section>
                ) : null}
                {jobs?.length ? (
                  <>
                    <h1 className="ux-research-title second">
                      <SpriteIcon
                        name="calendar-title"
                        size="sliderMd"
                      />
                      Job Opportunities
                    </h1>
                    <section className="upcoming-events-wrapper thoughts">
                      <div className="job-openings">
                        {jobs.map((job) => (<JobCard job={job} key={job.id} />))}
                      </div>
                    </section>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

UXResearchPage.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  latestVideos: PropTypes.array,
  advisors: PropTypes.array,
  questions: PropTypes.array,
  creators: PropTypes.array,
  events: PropTypes.array,
  pastEvents: PropTypes.array,
  partners: PropTypes.array,
  jobs: PropTypes.array,
  getLatestVideos: PropTypes.func.isRequired,
  getTopQuestions: PropTypes.func.isRequired,
  getTopCreators: PropTypes.func.isRequired,
  getAdvisors: PropTypes.func.isRequired,
  getPartners: PropTypes.func.isRequired,
  getEvents: PropTypes.func.isRequired,
  getJobs: PropTypes.func.isRequired,
  getPastEvents: PropTypes.func.isRequired,
}

UXResearchPage.defaultProps = {
  latestVideos: [],
  questions: [],
  creators: [],
  advisors: [],
  partners: [],
  events: [],
  pastEvents: [],
  jobs: [],
}

const mapStateToProps = (state) => ({
  latestVideos: state.content.latestVideos,
  questions: state.content.topQuestions,
  creators: state.content.topCreators,
  advisors: state.content.advisors,
  partners: state.content.partners,
  events: state.content.upcomingEvents,
  pastEvents: state.content.pastEvents,
  jobs: state.content.jobs,
})

const actionsToProps = {
  getThoughts,
  getTopQuestions,
  getTopCreators,
  getAdvisors,
  getPartners,
  getEvents,
  getPastEvents,
  getLatestVideos,
  getJobs: getUxJobs,
}

export default connect(mapStateToProps, actionsToProps)(UXResearchPage)
