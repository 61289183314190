/* utils */
import reqH from 'utils/request-handler'

/* constants */
import { CONTENT_ACTIONS } from 'state-manager/constants'
import { VECTOR_URL } from 'constants/main'

/* store */
import { store } from 'state-manager/store'

export const getCategories = () => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_CATEGORIES.ATTEMPT })

  return reqH({
    method: 'GET',
    url: 'categories',
    urlPrefix: 'api',
    params: {
      count: 8,
    },
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_CATEGORIES.SUCCESS,
        payload: {
          categories: res.data.data,
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_CATEGORIES.ERROR })
    })
}

export const getThoughts = (count = 100) => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_CATEGORIES.ATTEMPT })

  return reqH({
    method: 'GET',
    url: 'thoughts',
    urlPrefix: 'api',
    params: {
      count,
    },
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_CATEGORIES.SUCCESS,
        payload: {
          categories: [res.data.data],
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_CATEGORIES.ERROR })
    })
}

export const getTopVideos = () => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_TOP_VIDEOS.ATTEMPT })

  return reqH({
    method: 'GET',
    url: 'research/top/video',
    urlPrefix: 'api',
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_TOP_VIDEOS.SUCCESS,
        payload: {
          topVideos: [res.data],
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_TOP_VIDEOS.ERROR })
    })
}
export const getLatestVideos = () => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_LATEST_VIDEOS.ATTEMPT })

  return reqH({
    method: 'GET',
    url: 'research/latest/video',
    urlPrefix: 'api',
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_LATEST_VIDEOS.SUCCESS,
        payload: {
          latestVideos: res.data,
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_LATEST_VIDEOS.ERROR })
    })
}

export const getTopQuestions = () => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_TOP_QUESTIONS.ATTEMPT })

  return reqH({
    method: 'GET',
    url: 'research/top/question',
    urlPrefix: 'api',
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_TOP_QUESTIONS.SUCCESS,
        payload: {
          topQuestions: res.data,
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_TOP_QUESTIONS.ERROR })
    })
}

export const getTopCreators = () => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_TOP_CREATORS.ATTEMPT })

  return reqH({
    method: 'GET',
    url: 'research/top/creator',
    urlPrefix: 'api',
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_TOP_CREATORS.SUCCESS,
        payload: {
          creators: res.data,
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_TOP_CREATORS.ERROR })
    })
}

export const getAdvisors = () => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_ADVISORS.ATTEMPT })

  return reqH({
    method: 'GET',
    url: 'research/advisors',
    urlPrefix: 'api',
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_ADVISORS.SUCCESS,
        payload: {
          advisors: res.data,
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_ADVISORS.ERROR })
    })
}

export const getPartners = () => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_PARTNERS.ATTEMPT })

  return reqH({
    method: 'GET',
    url: 'research/partners',
    urlPrefix: 'api',
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_PARTNERS.SUCCESS,
        payload: {
          partners: res.data,
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_PARTNERS.ERROR })
    })
}

export const getEvents = () => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_UPCOMING_EVENTS.ATTEMPT })

  return reqH({
    method: 'GET',
    url: 'research/events',
    urlPrefix: 'api',
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_UPCOMING_EVENTS.SUCCESS,
        payload: {
          upcomingEvents: res.data,
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_UPCOMING_EVENTS.ERROR })
    })
}

export const getPastEvents = () => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_PAST_EVENTS.ATTEMPT })

  return reqH({
    method: 'GET',
    url: 'research/past-events',
    urlPrefix: 'api',
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_PAST_EVENTS.SUCCESS,
        payload: {
          pastEvents: res.data,
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_PAST_EVENTS.ERROR })
    })
}

export const getJobs = () => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_JOBS.ATTEMPT })

  return reqH({
    method: 'GET',
    url: 'research/public-jobs',
    urlPrefix: 'api',
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_JOBS.SUCCESS,
        payload: {
          jobs: res.data,
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_JOBS.ERROR })
    })
}

export const getUxJobs = () => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_JOBS.ATTEMPT })

  return reqH({
    method: 'GET',
    url: 'research/research-jobs',
    urlPrefix: 'api',
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_JOBS.SUCCESS,
        payload: {
          jobs: res.data,
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_JOBS.ERROR })
    })
}

export const getHeader = () => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_HEADER.ATTEMPT })

  return reqH({
    method: 'GET',
    url: 'featured-items',
    urlPrefix: 'api',
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_HEADER.SUCCESS,
        payload: {
          header: res.data.data,
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_HEADER.ERROR })
    })
}

export const getSingleVideoStats = (slug) => (dispatch) => reqH({
  method: 'GET',
  url: `single-item/${slug}/statistic`,
  urlPrefix: 'api',
})
  .then((res) => {
    dispatch({
      type: CONTENT_ACTIONS.GET_SINGLE_STATS.SUCCESS,
      payload: {
        statistics: res.data.data,
      },
    })
  })
  .catch(() => {
    dispatch({ type: CONTENT_ACTIONS.GET_SINGLE_STATS.ERROR })
  })

export const getEpisodesStats = (slug) => (dispatch) => (
  reqH({
    method: 'GET',
    url: `single-series-item/${slug}/statistic`,
    urlPrefix: 'api',
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_EPISODES_STATS.SUCCESS,
        payload: {
          statistics: res.data.data,
          slug,
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_EPISODES_STATS.ERROR })
    })
)

export const getSingleVideo = (slug) => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_SINGLE.ATTEMPT })

  return reqH({
    method: 'GET',
    url: `single-podcast-item/${slug}`,
    // url: `single-item/${slug}`,
    urlPrefix: 'api',
  })
    .then((res) => {
      dispatch(getSingleVideoStats(slug))
      dispatch({
        type: CONTENT_ACTIONS.GET_SINGLE.SUCCESS,
        payload: {
          video: res.data.data,
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_SINGLE.ERROR })
    })
}

export const searchByCategories = (data) => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_CATEGORIES.ATTEMPT })

  return reqH({
    method: 'GET',
    url: 'categories',
    urlPrefix: 'api',
    params: {
      search: data.search,
      levels: [...data.level],
      categories: [...data.categories],
      count: !data.search && !data.level.length && !data.categories.length ? data.limit : null,
    },
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_CATEGORIES.SUCCESS,
        payload: {
          categories: res.data.data,
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_CATEGORIES.ERROR })
    })
}

export const searchByMiniSeriesPodcasts = (data) => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_MINISERIES.ATTEMPT })

  return reqH({
    method: 'GET',
    url: 'mini-series',
    urlPrefix: 'api',
    params: {
      podcasts_search: data.search,
      podcasts_levels: [...data.level],
      podcasts_categories: [...data.categories],
      count: !data.search && !data.level.length && !data.categories.length ? data.limit : null,
    },
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_MINISERIES.SUCCESS,
        payload: {
          miniSeries: res.data.data,
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_MINISERIES.ERROR })
    })
}

export const getAvailableFilters = () => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_FILTERS.ATTEMPT })

  return reqH({
    method: 'GET',
    url: 'available-filters',
    urlPrefix: 'api',
    skipGlobalSpinner: true,
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_FILTERS.SUCCESS,
        payload: {
          filters: res.data.data,
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_FILTERS.ERROR })
    })
}

export const getAds = () => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_ADS.ATTEMPT })

  return reqH({
    method: 'GET',
    url: 'ads',
    urlPrefix: 'api',
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_ADS.SUCCESS,
        payload: {
          ads: res.data.data,
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_ADS.ERROR })
    })
}

export const sendEvent = (data) => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.SEND_EVENT.ATTEMPT })
  const state = store.getState()

  return reqH({
    method: 'POST',
    url: 'store-event',
    urlPrefix: 'api',
    otherDomain: VECTOR_URL,
    skipGlobalSpinner: true,
    data: {
      type: data.typeId,
      media_id: data.mediaId,
      user_id: state.userData.id,
    },
  }).then(() => {
    dispatch({ type: CONTENT_ACTIONS.SEND_EVENT.SUCCESS })
  }).catch(() => {
    dispatch({ type: CONTENT_ACTIONS.SEND_EVENT.ERROR })
  })
}

export const sendTimeMark = (data) => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.SEND_TIME_MARK.ATTEMPT })

  return reqH({
    method: 'POST',
    url: 'podcast/time-mark',
    urlPrefix: 'api',
    skipGlobalSpinner: true,
    params: {
      podcast_id: data.podcastId,
      time_mark: data.timeMark,
    },
  }).then(() => {
    dispatch({ type: CONTENT_ACTIONS.SEND_TIME_MARK.SUCCESS })
    dispatch({
      type: CONTENT_ACTIONS.UPDATE_TIME_MARK.SYSTEM,
      payload: {
        videoId: data.podcastId,
        timeMark: data.timeMark,
      },
    })
  }).catch(() => {
    dispatch({ type: CONTENT_ACTIONS.SEND_TIME_MARK.ERROR })
  })
}

export const sendReview = (data) => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.SEND_REVIEW.ATTEMPT })

  return reqH({
    method: 'POST',
    url: 'podcast/review',
    urlPrefix: 'api',
    params: {
      podcast_id: data.mediaId,
      rating: data.actionType,
    },
  }).then(() => {
    dispatch({ type: CONTENT_ACTIONS.SEND_REVIEW.SUCCESS })
    dispatch(getSingleVideoStats(data.slug))
  }).catch(() => {
    dispatch({ type: CONTENT_ACTIONS.SEND_REVIEW.ERROR })
  })
}

export const addToBookmarks = (data) => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.ADD_TO_BOOKMARKS.ATTEMPT })
  return reqH({
    method: 'POST',
    url: 'user/playlists/add-item',
    urlPrefix: 'api',
    data: {
      id: data.bookmarkID,
      item_id: data.videoID,
    },
    shouldUpdateUserData: true,
  }).then(() => {
    dispatch({ type: CONTENT_ACTIONS.ADD_TO_BOOKMARKS.SUCCESS })
  }).catch(() => dispatch({ type: CONTENT_ACTIONS.ADD_TO_BOOKMARKS.ERROR }))
}

export const deleteFromBookmarks = (data) => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.DELETE_FROM_BOOKMARKS.ATTEMPT })
  return reqH({
    method: 'POST',
    url: 'user/playlists/delete-item',
    urlPrefix: 'api',
    data: {
      id: data.bookmarkID,
      item_id: data.videoID,
    },
    shouldUpdateUserData: true,
  }).then(() => {
    dispatch({ type: CONTENT_ACTIONS.DELETE_FROM_BOOKMARKS.SUCCESS })
  }).catch(() => {
    dispatch({ type: CONTENT_ACTIONS.DELETE_FROM_BOOKMARKS.ERROR })
  })
}

export const getCreatorProfile = (id) => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_PROFILE.ATTEMPT })

  return reqH({
    method: 'GET',
    url: `profile/${id}`,
    urlPrefix: 'api',
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_PROFILE.SUCCESS,
        payload: {
          creator: res.data.data,
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_PROFILE.ERROR })
    })
}

export const getSingleMiniSeries = (slug) => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_SINGLE_MINISERIES.ATTEMPT })

  return reqH({
    method: 'GET',
    url: `single-series-item/${slug}`,
    urlPrefix: 'api',
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_SINGLE_MINISERIES.SUCCESS,
        payload: {
          singleMiniSeries: res.data.data,
        },
      })
      dispatch(getEpisodesStats(slug))
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_SINGLE_MINISERIES.ERROR })
    })
}

// export const getCreators = () => (dispatch) => {
//   dispatch({ type: CONTENT_ACTIONS.GET_CREATORS.ATTEMPT })
//
//   return reqH({
//     method: 'GET',
//     url: 'creators',
//     urlPrefix: 'api',
//     params: {
//       count: 8,
//     },
//   })
//     .then((res) => {
//       dispatch({
//         type: CONTENT_ACTIONS.GET_CREATORS.SUCCESS,
//         payload: {
//           creators: res.data.data,
//         },
//       })
//     })
//     .catch(() => {
//       dispatch({ type: CONTENT_ACTIONS.GET_CREATORS.ERROR })
//     })
// }

export const getMiniSeries = () => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_MINISERIES.ATTEMPT })

  return reqH({
    method: 'GET',
    url: 'mini-series',
    urlPrefix: 'api',
    params: {
      count: 8,
    },
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_MINISERIES.SUCCESS,
        payload: {
          miniSeries: res.data.data,
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_MINISERIES.ERROR })
    })
}

export const getSingleCreator = (slug) => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_CREATOR.ATTEMPT })

  return reqH({
    method: 'GET',
    url: `single-creator/${slug}`,
    urlPrefix: 'api',
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_CREATOR.SUCCESS,
        payload: {
          creator: res.data.data,
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_CREATOR.ERROR })
    })
}

export const getSingleQuestion = (slug) => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_QUESTION.ATTEMPT })

  return reqH({
    method: 'GET',
    url: `research/single-question/${slug}`,
    urlPrefix: 'api',
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_QUESTION.SUCCESS,
        payload: {
          singleQuestion: res.data,
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_QUESTION.ERROR })
    })
}

export const getFellowShip = () => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_FELLOWSHIP_CATEGORY.ATTEMPT })

  return reqH({
    method: 'GET',
    url: 'new-podcast-items',
    urlPrefix: 'api',
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_FELLOWSHIP_CATEGORY.SUCCESS,
        payload: {
          fellowship: res.data.data,
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_FELLOWSHIP_CATEGORY.ERROR })
    })
}

export const getStreamInfo = () => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_STREAM_INFO.ATTEMPT })

  return reqH({
    method: 'GET',
    url: 'streams-info',
    urlPrefix: 'api',
    skipGlobalSpinner: true,
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_STREAM_INFO.SUCCESS,
        payload: {
          streamInfo: res.data.data || null,
        },
      })
      // if (res.data.data) {
      //   dispatch({
      //     type: CONTENT_ACTIONS.GET_STREAM_INFO.SUCCESS,
      //     payload: {
      //       streamInfo: res.data.data,
      //     },
      //   })
      // }
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_STREAM_INFO.ERROR })
    })

}

export const getUpcomingEvents = () => (dispatch) => {
  dispatch({ type: CONTENT_ACTIONS.GET_UPCOMING_EVENTS.ATTEMPT })

  return reqH({
    method: 'GET',
    url: 'content-card/list',
    urlPrefix: 'api',
    skipGlobalSpinner: true,
  })
    .then((res) => {
      dispatch({
        type: CONTENT_ACTIONS.GET_UPCOMING_EVENTS.SUCCESS,
        payload: {
          upcomingEvents: res.data.data,
        },
      })
    })
    .catch(() => {
      dispatch({ type: CONTENT_ACTIONS.GET_UPCOMING_EVENTS.ERROR })
    })

}
