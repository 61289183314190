import React from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import SpriteIcon from 'components/icons/SpriteIcon'
import MailchimpForm from '3d-party/mailchimp'

const FooterV3 = () => {
  const url = 'https://joinlearners.us16.list-manage.com/subscribe/post?u=26b2429291211f2873913461d&amp;id=018ff432ef'

  return (
    <div className="footer__inner-container">
      <div className="footer__content">
        <div className="footer__mailchimp">
          <div className="footer__mailchimp--title">
            Join Our Newsletter
          </div>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <MailchimpForm
                status={status}
                message={message}
                onSubmit={(formData) => subscribe(formData)}
              />
            )}
          />
        </div>
      </div>
      <div className="footer__content">
        <div className="wrapper-columns">
          <div className="link-column paragraph-primary">
            <a
              rel="noreferrer noopener"
              href="mailto:hello@joinlearners.com"
              target="_blank">
              Contact Us
            </a>
            <a
              rel="noreferrer noopener"
              href="https://www.aboutus.joinlearners.com/"
              target="_blank">
              Meet Our Team
            </a>
            <a
              rel="noreferrer noopener"
              href="https://www.pastprojects.joinlearners.com/"
              target="_blank">
              Past Projects
            </a>
            <a
              rel="noreferrer noopener"
              href="https://www.presskit.joinlearners.com/"
              target="_blank">
              Press Kit
            </a>
          </div>
          <div className="link-column paragraph-primary">
            <a
              rel="noreferrer noopener"
              href="https://www.privacy.joinlearners.com/"
              target="_blank">
              Privacy Policy
            </a>
            <a
              rel="noreferrer noopener"
              href="https://www.termsandconditions.joinlearners.com/"
              target="_blank">
              Terms of Service
            </a>
            <a
              rel="noreferrer noopener"
              href="https://www.codeofconduct.joinlearners.com/"
              target="_blank">
              Code of Conduct
            </a>
          </div>
          <div className="link-column__icons">
            <div
              role="button"
              aria-label="Twitter Share Button"
              className="item"
              onClick={() => window.open('https://twitter.com/JoinLearners', '_blank')}>
              <SpriteIcon
                name="twitter"
                size="md"
              />
            </div>
            <div
              role="button"
              aria-label="Twitter Share Button"
              className="item"
              onClick={() => window.open('https://www.linkedin.com/company/joinlearners1', '_blank')}>
              <SpriteIcon name="linkedin" size="md" />
            </div>
            <div
              role="button"
              aria-label="Twitter Share Button"
              className="item"
              onClick={() => window.open('https://www.tiktok.com/@joinlearners', '_blank')}>
              <SpriteIcon name="tiktok" size="md" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterV3
