import React from 'react'
import showValidation from './show-validation'
import Input from '../components/ui/Inputs/Base'

export default ({
  inputsConfig,

  values,
  touched,
  errors,

  handleChange,
  handleBlur,

  submitCount,
}) => inputsConfig.map((control, index) => {

  const renderInput = ({ control, index }) => {
    const showError = showValidation({
      values,
      errors,
      touched,
      submitCount,
      field: [control.name],
    })

    return (
      <Input
        key={index}
        id={control.name}
        name={control.name}
        placeholder={control.placeholder || control.label}
        spacing={control.spacing}
        variant={control.variant}
        isRequired={control.isRequired}
        isDouble={control.isDouble}
        isHandle={control.isHandle}
        label={control.label}
        type={control.type}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[control.name]}
        error={showError ? errors[control.name] : null}
        errorInput={showError ? 'errorInput' : null}
        errorLabel={showError ? 'errorLabel' : null}
      />
    )
  }

  if (control.inlineGroup) {
    return (
      <div className="controls-inline-group" key={index}>
        {control.inlineGroup.map((control, index) => (
          renderInput({ control, index })
        ))}
      </div>
    )
  }

  return (
    renderInput({ control, index })
  )
})
