import { APP_ACTIONS } from 'state-manager/constants'

/* utils */
import reqH from 'utils/request-handler'

import { CONFIGS_URL } from 'constants/main'

export const getDisciplines = () => (dispatch) => {
  reqH({
    method: 'GET',
    url: 'disciplines.json',
    urlPrefix: 'configs',
    otherDomain: CONFIGS_URL,
  })
    .then((res) => {
      dispatch({
        type: APP_ACTIONS.APP_GET_DISCIPLINES.SUCCESS,
        payload: { disciplines: res.data },
      })
    })
    .catch(() => {
      dispatch({ type: APP_ACTIONS.APP_GET_DISCIPLINES.ERROR })
    })
}

export const getYearsOfExperience = () => (dispatch) => {
  reqH({
    method: 'GET',
    url: 'years_of_experience.json',
    urlPrefix: 'configs',
    otherDomain: CONFIGS_URL,
  })
    .then((res) => {
      dispatch({
        type: APP_ACTIONS.APP_GET_EXPERIENCE.SUCCESS,
        payload: { yearsOfExperience: res.data },
      })
    })
    .catch(() => {
      dispatch({ type: APP_ACTIONS.APP_GET_EXPERIENCE.ERROR })
    })
}

export const getCategoriesList = () => (dispatch) => {
  reqH({
    method: 'GET',
    url: 'categories.json',
    urlPrefix: 'configs',
    otherDomain: CONFIGS_URL,
  })
    .then((res) => {
      dispatch({
        type: APP_ACTIONS.APP_GET_CATEGORIES.SUCCESS,
        payload: { categories: res.data },
      })
    })
    .catch(() => {
      dispatch({ type: APP_ACTIONS.APP_GET_CATEGORIES.ERROR })
    })
}

export const setMute = () => (dispatch) => {
  dispatch({ type: APP_ACTIONS.APP_SET_MUTE.SUCCESS })
}

export const setUnmute = () => (dispatch) => {
  dispatch({ type: APP_ACTIONS.APP_SET_UNMUTE.SUCCESS })
}

export const setThoughtsState = (thoughts) => (dispatch) => {
  dispatch({
    type: APP_ACTIONS.APP_SET_THOUGHTS_STATE.SUCCESS,
    payload: thoughts,
  })
}
export const setThoughtsPlaying = (id) => (dispatch) => {
  dispatch({
    type: APP_ACTIONS.APP_SET_THOUGHTS_PLAYING.SUCCESS,
    payload: { id },
  })
}
