import React, { useEffect } from 'react'

/* constants */
import { TOGGLE__VISIBILITY_DOWNLOAD_APP_MODAL } from 'constants/custom-events'
import { URL } from 'constants/main'

/* utils */
import customEvent from 'utils/custom-event'

import './style.scss'

import monthlyDisscussionImg from 'assets/images/presentation/monthly_discussion.jpg'
import monthlyDisscussionImgV2 from 'assets/images/presentation/monthly_discussion_v2.jpg'
import congratsImg from 'assets/images/presentation/congrats.png'
import smileGroupImg from 'assets/images/presentation/smile_group.png'
import sofaImg from 'assets/images/presentation/sofa.png'
import auth0Img from 'assets/images/presentation/auth0.png'
import indeedImg from 'assets/images/presentation/indeed.png'
import iRobotImg from 'assets/images/presentation/iRobot.png'
import SpotifyImg from 'assets/images/presentation/Spotify.png'
import aleah from 'assets/images/presentation/ALEAH.jpg'
import alec from 'assets/images/presentation/ALEC.jpg'
import eve from 'assets/images/presentation/EVE.jpg'
import sandro from 'assets/images/presentation/SANDRO.jpg'
import maggi from 'assets/images/presentation/MAGGI.jpg'
import tremendousImg from 'assets/images/presentation/Tremendous.svg'
import userInterviewImg from 'assets/images/presentation/UserInterviews.svg'
import lookbackImg from 'assets/images/presentation/Lookback.svg'
import outsetImg from 'assets/images/presentation/outset.png'
import dovetailImg from 'assets/images/presentation/Dovetail.svg'
// import feedback1Img from 'assets/images/presentation/feedback_1.jpg'
// import feedback2Img from 'assets/images/presentation/feedback_2.jpg'
// import feedback3Img from 'assets/images/presentation/feedback_3.jpg'
// import feedback4Img from 'assets/images/presentation/feedback_4.jpg'
import backgroundDetailImg from 'assets/images/presentation/background_detail.png'
import eventImg3 from 'assets/images/presentation/event_3.jpg'
import ChristinaJanzerImg from 'assets/images/presentation/ChristinaJanzer.jpg'
import ColetteKolendaImg from 'assets/images/presentation/ColetteKolenda.jpg'
import VishalBhaiaImg from 'assets/images/presentation/VishalBhaia.jpg'
import AnandTharanathanImg from 'assets/images/presentation/AnandTharanathan.jpg'
import RuchaMakatiImg from 'assets/images/presentation/RuchaMakati.jpg'
import AkhilModaliImg from 'assets/images/presentation/AkhilModali.jpg'

import SpriteIcon from 'components/icons/SpriteIcon'

import shopify from '3d-party/shopifyBtns'
import routes from '../../routes'

const events = [
  // {
  //   title: 'AI and UX Research',
  //   time: 'October 25 1:00pm - 5:00pm PST',
  //   location: 'Remote and San Francisco',
  //   description: "We've never seen more angst (or excitement!) about a new technology in the research space as we've seen with AI. Let's talk about what it all means.",
  //   img: eventImg1,
  //   logoColor: '#22CB07',
  //   backgroundTitle: 'AI+UX Research',
  //   getFreeTicketBtnId: 'product-component-1695952855079',
  //   buyPersonalTicketBtnId: 'product-component-1695952998246',
  //   scheduleLink: 'https://ai-and-ux-research.joinlearners.com/',
  //   learnMoreBtnTitle: 'View Schedule and Learn More',
  // },
  // {
  //   title: 'Landing The Research Role',
  //   time: 'November 9 10:00am - 1:00pm PST',
  //   location: 'Remote and San Francisco',
  //   description: "Every job opening has a long list of requirements, but which ones truly matter? Hear directly from hiring managers about what they're really looking for.",
  //   img: eventImg2,
  //   logoColor: '#FB5209',
  //   backgroundTitle: 'Landing The Research Role',
  //   getFreeTicketBtnId: 'product-component-1695953571085',
  //   buyPersonalTicketBtnId: 'product-component-1695953182323',
  //   scheduleLink: 'http://landing-the-research-role.joinlearners.com/',
  //   learnMoreBtnTitle: 'View Schedule and Learn More',
  // },
  {
    title: 'Research Year In Review',
    time: 'December 5 10:00am - 1:00pm PST',
    location: 'Remote and Toronto',
    description: 'What a year! Let`s take a few hours to unwind and unpack a tumultuous 2023, and talk about our predictions for 2024.',
    img: eventImg3,
    logoColor: '#9B1EFF',
    backgroundTitle: 'Research Year In Review',
    getFreeTicketBtnId: 'product-component-1695953527959',
    buyPersonalTicketBtnId: 'product-component-1695953227420',
    scheduleLink: 'https://research-year-in-review.joinlearners.com/',
    learnMoreBtnTitle: 'View Schedule and Learn More',
  },
  // {
  //   title: 'Great Research',
  //   time: 'January 29 10:00am - 6:00pm PST',
  //   location: 'Remote and San Francisco',
  //   description: 'Colette Kolenda is back with a brand new program! Building on what we learned in Good Research, Great Research focusses on helping you set your research practice up for success.',
  //   img: eventImg4,
  //   logoColor: '#00BFA8',
  //   backgroundTitle: 'Great Research',
  //   getFreeTicketBtnId: 'product-component-1695953493242',
  //   buyPersonalTicketBtnId: 'product-component-1695953263930',
  //   scheduleLink: 'https://great-research.joinlearners.com/',
  //   learnMoreBtnTitle: 'View Schedule and Learn More',
  // }, {
  //   title: 'World Researcher Day',
  //   time: 'February 13 12:00am - 11:59pm PST',
  //   location: 'Remote and San Francisco',
  //   description: 'Join us for our first-ever World Researcher Day, the only day dedicated to celebrating our field and global community. Speaker applications are now open!',
  //   img: eventImg5,
  //   logoColor: '#007256',
  //   backgroundTitle: 'World Research Day',
  //   getFreeTicketBtnId: 'product-component-1695953464361',
  //   buyPersonalTicketBtnId: 'product-component-1695953292964',
  //   scheduleLink: 'https://world-researcher-day.joinlearners.com/',
  //   learnMoreBtnTitle: 'Learn More and Apply to Speak',
  // }, {
  //   title: 'UXRConf 2024',
  //   time: 'March 7 8:00am - 8:00pm PST',
  //   location: 'Remote and San Francisco',
  //   description: "Back for its seventh edition, UXRConf is the annual research event you won't want to miss. Hear from researchers around the globe working on the leading edge of our field. Speaker applications are now open!",
  //   img: eventImg6,
  //   logoColor: '#FF104B',
  //   backgroundTitle: 'UXRConf 2024',
  //   getFreeTicketBtnId: 'product-component-1695953433592',
  //   buyPersonalTicketBtnId: 'product-component-1695953320762',
  //   scheduleLink: 'https://uxrconf.joinlearners.com/',
  //   learnMoreBtnTitle: 'Learn More and Apply to Speak',
  // }, {
  //   title: 'Research Leadership Summit',
  //   time: 'April 19 10:00am - 6:00pm PST',
  //   location: 'San Francisco',
  //   description: "Running a research practice can be a lonely, challenging journey if you don't have the support you need. Join us for a day of learning with an intimate group of research leaders.",
  //   isFreeBtnDissabled: true,
  //   img: eventImg7,
  //   logoColor: '#639200',
  //   backgroundTitle: 'Research Leadership Summit',
  //   buyPersonalTicketBtnId: 'product-component-1695953397471',
  //   scheduleLink: 'https://research-leadership-summit.joinlearners.com/',
  //   learnMoreBtnTitle: 'Learn More',
  // },
]

const pastEvents = [
  {
    name: 'Research Year In Review',
    link: 'https://research-year-in-review.joinlearners.com/',
    background: '#9B1EFF',
  },
  {
    name: 'UXRConf 2023',
    link: `https://${URL}/category/uxrconf-2023`,
    background: '#E2BEFF',
  },
  {
    name: 'AI and UX Research',
    link: 'https://www.youtube.com/watch?v=tQopgLhwnL0&ab_channel=Learners',
    background: '#BBFFF7',
  },
  {
    name: 'Good Research',
    link: `https://${URL}/category/good-research-2022`,
    background: '#BEFFCC',
  },
  {
    name: 'Landing The Research Role',
    link: 'https://www.youtube.com/watch?v=AVx29bIV26c&ab_channel=Learners',
    background: '#FFB1E5',
  },
]

const members = [{
  name: 'Christina Janzer',
  role: 'SVP, Research and Analytics at Slack',
  position: 'Research Leaders',
  avatar: ChristinaJanzerImg,
  roleColor: '#87FFB7',
}, {
  name: 'Colette Kolenda',
  role: 'Sr. Manager, User Research and Data Science at Spotify',
  position: 'Senior Researchers',
  avatar: ColetteKolendaImg,
  roleColor: '#8CF8FF',
}, {
  name: 'Vishal Bhaia',
  role: 'User Researcher at Spotify',
  position: 'Growing Researchers',
  avatar: VishalBhaiaImg,
  roleColor: '#D8D3FF',
}, {
  name: 'Anand Tharanathan',
  role: 'VP, Global Head of  Research and Insights at ServiceNow',
  position: 'Research Leaders',
  avatar: AnandTharanathanImg,
  roleColor: '#87FFB7',
}, {
  name: 'Rucha Makati',
  role: 'Research Manager at Robinhood',
  position: 'Senior Researchers',
  avatar: RuchaMakatiImg,
  roleColor: '#8CF8FF',
}, {
  name: 'Akhil Modali',
  role: 'UXR and Human Factors Engineer',
  position: 'Growing Researchers',
  avatar: AkhilModaliImg,
  roleColor: '#D8D3FF',
}]

const feedbacks = [{
  name: 'Ashley Graham',
  position: 'Product Insights Manager',
  count: 806,
  videoUrl: 'https://media.joinlearners.com/converted/3/5603edf3974f3f7ffe9bed09c1a73e96.mp4',
  link: `https://${URL}/thought/has-anyone-had-success-pulling-insights-from-customer-support-into-your-research-process-how_?id=1600`,
  question: "What's the most clever way you've shared research findings from a project?",
  companyImg: SpotifyImg,
  companyImgAlt: 'Spotify logo',
}, {
  name: 'Joshua Williams',
  position: 'Director of UX Research',
  count: 468,
  videoUrl: 'https://media.joinlearners.com/converted/3/67d330cd28ca881e8ebd48bace69699f.mp4',
  link: `https://${URL}/thought/what-is-the-best-way-to-present-a-research-case-study-during-the-job-interview-process_______?id=1468`,
  question: 'What is the best way to present a research case study during the job interview process?',
  companyImg: indeedImg,
  companyImgAlt: 'Indeed logo',
}, {
  name: 'Brad Orego',
  position: 'Former Head of Research',
  count: 441,
  videoUrl: 'https://media.joinlearners.com/converted/3/968fc6c70bea9ac9d80d8f83eb2fee45.mp4',
  link: `https://${URL}/thought/what-set-a-senior-researcher-apart-from-mid-weights-how-can-i-gain-more-strategic-research-exp_?id=1350`,
  question: "How do you support engagement with your company's repository when the res. function is democratized?",
  companyImg: auth0Img,
  companyImgAlt: 'Auth0 logo',
}, {
  name: 'Lauren Stern',
  position: 'Director of Global Insights',
  count: 341,
  videoUrl: 'https://media.joinlearners.com/converted/3/c5f68062b56793f997da1ee3e6986d8b.mp4',
  link: `https://${URL}/thought/when-preparing-a-uxr-stategy-for-accessibility-what-are-the-most-important-things-to-consider?id=1105`,
  question: 'What is the best way to get started in quantitative research?',
  companyImg: iRobotImg,
  companyImgAlt: 'iRobot logo',
}]

const Presentation = () => {
  useEffect(() => {
    const scripts = []

    function injectScript(btnId) {
      const script = document.createElement('script')
      script.text = shopify[btnId]
      document.body.appendChild(script)
      scripts.push(script)
    }

    events.forEach(({ getFreeTicketBtnId, buyPersonalTicketBtnId }) => {
      getFreeTicketBtnId && injectScript(getFreeTicketBtnId)
      buyPersonalTicketBtnId && injectScript(buyPersonalTicketBtnId)
    })

    return () => {
      scripts.forEach((script) => {
        document.body.removeChild(script)
      })
    }
  }, [])

  const handleDownloadApp = () => customEvent.trigger(TOGGLE__VISIBILITY_DOWNLOAD_APP_MODAL)

  return (
    <main>
      <img src={backgroundDetailImg} alt="" className="background-detail background-detail-right desktop" />
      <img src={backgroundDetailImg} alt="" className="background-detail background-detail-left desktop" />
      <div id="top-fixed-card" className="desktop">
        <div className="card" id="first-card">
          <p className="fw-black fs-18">Interested in hosting or sponsoring an event?</p>
          <p className="mt-8">We’re always looking for great spaces and people to collaborate with.</p>
          <a target="_blank" rel="noreferrer" className="a-btn fixed-card-button purple-btn second-font border-none mt-16 fs-14" href="https://docs.google.com/forms/d/e/1FAIpQLScOi20avXZdIN3PRiFMvOUdDN6hjeCSia5MA7F5br7fGKOgbw/viewform?usp=sf_link">Get in Touch</a>
        </div>
        <div className="card mt-24 d-none" id="second-card">
          <p className="extra-bold">Interested in learning more about sponsorship?</p>
          <button className="fixed-card-button purple-btn second-font border-none mt-16 fs-14" type="button" onClick={handleDownloadApp}>Get in Touch</button>
        </div>
      </div>
      <section className="first-section">
        <div className="width-limit">
          <h1 className="fw-extra-black">
            Really good community and events for researchers.
          </h1>
          {/* <h3 className="upcoming-events">
            Upcoming Events
          </h3>
          <p className="description">
            All the events you need to keep your research practice on point.
          </p> */}
        </div>
        <div className="event-card-container d-none">
          {events.map(({ title, time, location, description, isBuyBtnDissabled, isFreeBtnDissabled, img, logoColor, backgroundTitle, getFreeTicketBtnId, buyPersonalTicketBtnId, scheduleLink, learnMoreBtnTitle }) => (
            <div className="event-card second-font flex-column">
              <div className="event-background">
                <img src={img} className="" alt="" />
                <SpriteIcon name="learners-logo" className="logo" size="event-logo" colorIcon={logoColor} />
                <div className="fw-black fs-26 event-background-title line-height-basic">{backgroundTitle}</div>
              </div>
              <div className="event-card-content flex-column grow">
                <h4>{title}</h4>
                <p className="time">{time}</p>
                <p className="time">{location}</p>
                <p className="card-description mt-8 grow">{description}</p>
                <a target="_blank" rel="noreferrer" className="btn-without-borders mt-8 fs-14" href={scheduleLink}>{learnMoreBtnTitle}</a>
                {isFreeBtnDissabled ? (
                  <button className="mt-8 dissabled-btn border-none fs-14" type="button">Grab free remote ticket</button>
                ) : (
                  <div id={getFreeTicketBtnId} className="free" />
                )}
                {isBuyBtnDissabled ? (
                  <button className="mt-8 dissabled-btn border-none fs-14" type="button">Buy in-person ticket</button>
                ) : (
                  <div id={buyPersonalTicketBtnId} className="buy" />
                )}
              </div>
            </div>
          ))}
        </div>
        <h3 className="mt-120">
          Past Events
        </h3>
        <div className="event-card-container">
          {pastEvents.map(({ name, link, background }) => (
            <a href={link} className="event-card second-font" target="_blank" rel="noreferrer">
              <div className="event-background" style={{ backgroundColor: background }}>
                <SpriteIcon name="learners-logo" className="logo" size="event-logo" />
                <div className="fw-black fs-26 event-background-pastevents-title">{name}</div>
              </div>
              <div className="event-card-content">
                <h4>{name}</h4>
              </div>
            </a>
          ))}
        </div>
        <a href={`https://${URL}${routes.resources}`} target="_blank" rel="noreferrer" className="mt-48 fs-14 ticket-btn link-btn">View all past events</a>
      </section>
      <section className="second-section d-none">
        <img src={monthlyDisscussionImgV2} alt="" className="art desktop" />
        <div className="gradient desktop" />
        <div className="content">
          <SpriteIcon name="learners-club" size="logo" />
          <h2 className="mt-48">Expand your research network in the San Francisco Bay area.</h2>
          <p className="mt-8 sub-title">Join a group of your peers for a collection of four monthly gatherings, running this January to April in the San Francisco Bay area.</p>
          <div className="mt-48 flex-container fs-14">
            <div>
              <div className="img-wrapper">
                <img src={smileGroupImg} alt="" />
              </div>
              <p><b>Connect</b> with a group of your peers</p>
            </div>
            <div>
              <div className="img-wrapper">
                <img src={congratsImg} alt="" />
              </div>
              <p><b>Get social</b> over snacks and beverages</p>
            </div>
            <div>
              <div className="img-wrapper">
                <img src={sofaImg} alt="" />
              </div>
              <p><b>Participate</b> in group-directed discussion</p>
            </div>
          </div>
          <h3 className="mt-48">Meet a few folks who have already signed up</h3>
          <div className="member-container">
            {members.map(({ name, position, role, avatar, roleColor }) => (
              <div className="mt-24 member-container-item flex-column">
                <img src={avatar} alt="avatar" className="member-avatar" />
                <p className="mt-8 bold">{name}</p>
                <p className="role grow">{role}</p>
                <div className="mt-8 position bold" style={{ backgroundColor: roleColor }}>{position}</div>
              </div>
            ))}
          </div>
          <button className="mt-48 fs-14 ticket-btn border-none learn-more" type="button">
            <div>Learn more</div><div>&gt;</div>
          </button>
        </div>
      </section>
      <section className="third-section">
        <div className="background" />
        <div className="content">
          <h2>Continue the conversation on the Learners app.</h2>
          <p className="mt-8">
            Enjoyed the last Learners event and looking for more? Join our speakers and thousands of researchers around the world as we dig a little deeper, asking and answering all of our follow up questions.
          </p>
        </div>
        <div className="feedback">
          <div className="feedback-grid">
            {feedbacks.map(({ link, videoUrl, count, question, name, position, companyImg, companyImgAlt }) => (
              <div>
                <div className="feedback-grid-item">
                  <video autoPlay src={videoUrl} type="video/mp4" quality="360p" muted>
                    <track label="None" />
                  </video>
                  {/* eslint-disable-next-line */}
                  <a href={link} target="_blank" rel="noreferrer" />
                  <div className="shadow" />
                  <div className="mt-8"><SpriteIcon name="triangle-top" rotate={90} /> {count}</div>
                  <p className="second-font">{question}</p>
                </div>
                <div className="feedback-grid-user">
                  <div className="feedback-user-avatar" />
                  <div className="feedback-user-description">
                    <div className="bold">{name}</div>
                    <div>{position}</div>
                  </div>
                </div>
                <img src={companyImg} alt={companyImgAlt} className="feedback-logo mobile mt-2" />
              </div>
            ))}
            {feedbacks.map(({ companyImg, companyImgAlt }) => (
              <img src={companyImg} alt={companyImgAlt} className="feedback-logo desktop" />
            ))}
          </div>
          <div id="download-the-app" className="label-button">
            <div>Download on iOS and Android</div>
            <button type="button" className="purple-btn" onClick={handleDownloadApp}>Get the App</button>
          </div>
        </div>
      </section>
      <section className="team-section">
        <div className="gradient desktop" />
        <img src={monthlyDisscussionImg} alt="" className="art desktop" />
        <div className="content-container">
          <h2>Our Team</h2>
          <p className="mt-8"><span className="bold">Meet the folks behind everything Learners.</span> Have questions or want to chat? Reach out to us at <a href="mailto:hello@joinlearners.com" className="mail">hello@joinlearners.com</a>.</p>
          <div className="mt-48 team-section-grid">
            <div>
              <img src={alec} alt="" />
              <div className="name bold">Alec Levin</div>
              <div>Co-Founder & CEO</div>
            </div>
            <div>
              <img src={maggi} alt="" />
              <div className="name bold">Maggi Mitchell</div>
              <div>Co-Founder & COO</div>
            </div>
            <div>
              <img src={aleah} alt="" />
              <div className="name bold">Aleah Neerhof</div>
              <div>Product & Design</div>
            </div>
            <div>
              <img src={eve} alt="" />
              <div className="name bold">Eve Aphayboun</div>
              <div>Software & Development</div>
            </div>
            <div>
              <img src={sandro} alt="" />
              <div className="name bold">Sandro Pehar</div>
              <div>Production & Marketing</div>
            </div>
          </div>
        </div>
      </section>
      <section id="sponsors-section">
        <h2>Meet our Research Community Sponsors.</h2>
        <p className="mt-8 text"><span className="bold">Our five favourite research tools.</span> These teams walk the walk and talk the talk. If you&apos;re ever looking for a new tool to try or team to join, we couldn&apos;t recommend them enough!</p>
        <div className="mt-48 grid-container">
          <a id="lookback" className="grid-container-item" href="https://www.lookback.com/" target="_blank" rel="noreferrer">
            <img src={lookbackImg} alt="" />
          </a>
          <a id="interviews" className="grid-container-item" href="https://www.userinterviews.com/" target="_blank" rel="noreferrer">
            <img src={userInterviewImg} alt="" />
          </a>
          <a id="tremendeous" className="grid-container-item" href="https://www.tremendous.com/" target="_blank" rel="noreferrer">
            <img src={tremendousImg} alt="" />
          </a>
          <a id="outset" className="grid-container-item" href="https://outset.ai/" target="_blank" rel="noreferrer">
            <img src={outsetImg} alt="" />
          </a>
          <a id="dovetail" className="grid-container-item" href="https://dovetail.com/" target="_blank" rel="noreferrer">
            <img src={dovetailImg} alt="" />
          </a>
        </div>
        <div className="mt-48 label-button d-none">
          <div className="extra-bold">Interested in learning more about sponsorship?</div>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLScOi20avXZdIN3PRiFMvOUdDN6hjeCSia5MA7F5br7fGKOgbw/viewform?usp=sf_link" target="_blank" rel="noreferrer" className="a-btn purple-btn">Get in Touch</a>
        </div>
      </section>
    </main>
  )
}

export default Presentation
