import React from 'react'
import PropTypes from 'prop-types'

/* styles */
import '../category.scss'

const CategoryBanner = ({
  img,
  url,
}) => {
  const handleContextMenu = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <div
      role="button"
      className="item portrait-item"
      onClick={() => window.open(url, '_blank')}>
      <div className="video-preview">
        <div className="image-preview-wrapper banner">
          <img
            role="button"
            aria-label="Your thought here"
            className="image-preview"
            alt="Your thought here"
            src={img}
            onContextMenu={handleContextMenu}
          />
        </div>
      </div>
    </div>
  )
}

CategoryBanner.propTypes = {
  img: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default CategoryBanner
