import React from 'react'
import clsx from 'clsx'
import * as PropTypes from 'prop-types'

/* assets */
import apple from 'assets/images/apple-download-btn.png'
import google from 'assets/images/google-download-btn.png'
import qrCodeApp from 'assets/images/qr-code-app.png'

/* styles */
import classes from './CtaCard.module.scss'

const CtaCard = ({ forVideo }) => (
  <div className={clsx(classes.ctaCardWrapper, forVideo && classes.ctaForVideo)}>
    <div className={classes.ctaCardMain}>
      <h5 className={classes.ctaCardTitle}>Get the app for more content</h5>
      <p className={classes.ctaCardSubtitle}> Scan QR code to download</p>
      {forVideo && <img src={qrCodeApp} className={classes.ctaQrCode} alt="qrCodeApp" />}
      <div className={classes.wrapperButtons}>
        <div
          role="button"
          className={classes.button}
          onClick={() => window.open('https://apps.apple.com/app/learners/id1572654814/')}>
          <img
            src={apple}
            alt="Download app from apple store"
            className={classes.buttonIcon}
          />
        </div>
        <div
          role="button"
          className={classes.button}
          onClick={() => window.open('https://play.google.com/store/apps/details?id=com.joinlearners')}>
          <img
            src={google}
            alt="Download app from play market"
            className={classes.buttonIcon}
          />
        </div>
      </div>
    </div>
    {!forVideo && <img src={qrCodeApp} className={classes.ctaQrCode} alt="qrCodeApp" />}
  </div>
)

CtaCard.propTypes = {
  forVideo: PropTypes.bool,
}

CtaCard.defaultProps = {
  forVideo: false,
}
export default CtaCard
