import React from 'react'
import SpriteIcon from 'components/icons/SpriteIcon'

const FooterV1 = () => (
  <div className="footer__inner">
    <SpriteIcon
      name="learners-logo"
      size="logo"
    />

    <div className="title my-5">Our Vision</div>

    <p className="paragraph-primary mb-5">
      Everyone, not just those that can afford it, deserves access to high-quality professional development
      content to learn new skills, grow in their career and be the best they can be. Learners is here to make that
      happen.
    </p>

    <div className="wrapper-bottom-text">
      <div className="title-small mb-5">Have feedback or want to chat?</div>
      <p className="paragraph-primary">
        Reach out to
        <a
          className="mx-1"
          rel="noreferrer noopener"
          href="mailto:hello@joinlearners.com"
          target="_blank">
          hello@joinlearners.com
        </a>
        any time.
      </p>
    </div>
  </div>
)

export default FooterV1
