import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import clsx from 'clsx'
import SpriteIcon from 'components/icons/SpriteIcon'
import classes from './Inputs.module.scss'

const Input = ({
  label,
  name,
  variant,
  isHandle,
  type,
  onChange,
  onBlur,
  isDouble,
  value,
  placeholder,
  size,
  className,
  labelColor,
  error,
  errorInput,
  errorLabel,
  disabled,
  spacing,
  isRequired,
}) => {
  const [inputType, setInputType] = useState(type)
  const [isShown, setIsShown] = useState(false)

  const togglePasswordVisibility = () => {
    inputType === 'password' ? setInputType('text') : setInputType(type)
    setIsShown(!isShown)
  }

  return (
    <div className={clsx(
      classes.inputWrapper,
      classes[variant],
      isHandle && classes.handle,
      isDouble ? 'controls-inline-group__inner' : null,
      isDouble && !label ? 'no-label mt-0' : null,
      spacing,
      className, 'd-flex flex-column',
    )}>
      {label && (
        <span className={clsx(classes[labelColor], classes.labelWrapper, classes[errorLabel])}>
          <label className={classes.label} htmlFor={name}>
            {label}
          </label>
          {isRequired && (
            <span className={clsx(classes.label, classes.required)}>Required </span>
          )}
        </span>
      )}

      {variant === 'search' && (
        <div className={classes.searchIcon}>
          <SpriteIcon
            name="search-bold"
            size="md"
          />
        </div>
      )}

      <input
        type={inputType}
        className={clsx(classes.input, classes[size], classes[errorInput], !error && 'mb-2')}
        id={name}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
      />

      {type === 'password' && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <span className={classes.passwordShow} onClick={togglePasswordVisibility}>
          <SpriteIcon name={isShown ? 'eye-close' : 'eye'} className={classes.icon} size="sm" />
        </span>
      )}

      {error && (
        <span className={clsx(classes.error, 'd-flex')}>
          {error}
        </span>
      )}
    </div>
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'handle', 'search']),
  labelColor: PropTypes.oneOf(['white', 'black']),
  spacing: PropTypes.oneOf(['mb-0', 'mb-1', 'mb-2', 'mb-3', 'mb-4', 'mb-5']),
  type: PropTypes.oneOf(['text', 'password', 'email', 'tel', 'number']),
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  errorInput: PropTypes.string,
  errorLabel: PropTypes.string,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  isDouble: PropTypes.bool,
  isHandle: PropTypes.bool,
}

Input.defaultProps = {
  label: '',
  placeholder: '',
  value: '',
  variant: 'primary',
  className: '',
  labelColor: 'black',
  size: 'medium',
  type: 'text',
  spacing: 'mb-0',
  onChange: null,
  onBlur: null,
  error: '',
  errorInput: '',
  errorLabel: '',
  disabled: false,
  isRequired: false,
  isDouble: false,
  isHandle: false,
}

export default Input
