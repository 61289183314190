import React from 'react'
import * as PropTypes from 'prop-types'
import clsx from 'clsx'

const TabNavItem = ({ id, activeTab, setActiveTab, children }) => {

  const handleClick = () => {
    setActiveTab(id)
  }

  return (
    <div onClick={handleClick} className={clsx('tab-item', activeTab === id ? 'active' : '')} role="button">
      {children}
    </div>
  )
}
TabNavItem.propTypes = {
  id: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
}
export default TabNavItem
