import { connect } from 'react-redux'
import PropTypes from 'prop-types'

/* hooks */
import _useDidMount from 'hooks/lifecycle/use-did-mount'

/* actions */
import { getStreamInfo } from 'state-manager/actions/content'

const StreamChecker = ({
  getStreamInfo,
}) => {
  _useDidMount(() => {
    getStreamInfo()
    const interval = setInterval(() => {
      getStreamInfo()
    }, 60 * 1000)

    return () => {
      clearInterval(interval)
    }
  })

  return null
}

StreamChecker.propTypes = {
  getStreamInfo: PropTypes.func.isRequired,
}

const actionsToProps = {
  getStreamInfo,
}

export default connect(null, actionsToProps)(StreamChecker)
