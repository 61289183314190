import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { formatRoute } from 'react-router-named-routes'
import { set, format, parseISO, intervalToDuration, isFuture, sub } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import clsx from 'clsx'

/* styles */
import './styles.scss'

/* hooks */
import _useDidMount from 'hooks/lifecycle/use-did-mount'

/* helpers */
import isMobile from 'helpers/check-is-mobile-device'

/* components */
import SpriteIcon from 'components/icons/SpriteIcon'
import Button from 'components/ui/Button/base'

/* routes */
import routes from 'routes'

/* state-manager */
import { history } from 'state-manager/store'
import { types as contentDataTypes } from 'state-manager/reducers/content'

/* actions */
import { enableChat } from 'state-manager/actions/chat'

const Topbar = ({
  streamInfo,
  isStreamPage,
  enableChat,
}) => {
  const [countDownDate] = useState(streamInfo.real_start_date ?
    // parseISO(streamInfo.real_start_date) :
    // parseISO(streamInfo.planned_start_date))
    zonedTimeToUtc(parseISO(streamInfo.real_start_date), 'Canada/Eastern') :
    zonedTimeToUtc(parseISO(streamInfo.planned_start_date), 'Canada/Eastern'))
  const [chatEnabledDate] = useState(sub(
    zonedTimeToUtc(parseISO(streamInfo.planned_start_date), 'Canada/Eastern'),
    { minutes: 20 },
  ))
  const [timer, seTimer] = useState()
  const [isLive, setIsLive] = useState(false)
  const [isChatEnabled, setIsChatEnabled] = useState(false)

  const getDateMask = (date) => {
    if (date.days > 0) {
      return `${date.days}'d':H'h':mm'm':ss's`
    }
    if (date.hours > 0) {
      return "H'h':mm'm':ss's"
    }
    return "mm'm':ss's"
  }

  _useDidMount(() => {
    const interval = setInterval(() => {
      const duration = intervalToDuration({
        start: countDownDate,
        end: new Date(),
      })
      const setDateByDuration = set(new Date(0), duration)
      seTimer(format(setDateByDuration, getDateMask(duration)))
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  })

  const goToStreamPage = () => {
    const singlePagePath = formatRoute(routes.stream, { slug: streamInfo.slug })
    history.push(singlePagePath)
  }

  if (!isLive && !isFuture(countDownDate)) {
    setIsLive(true)
    if (!isChatEnabled) {
      setIsChatEnabled(true)
      enableChat()
    }
  }

  if (!isChatEnabled && !isFuture(chatEnabledDate)) {
    setIsChatEnabled(true)
    enableChat()
  }

  const renderPulsatingDot = () => (
    <div className="stream-topbar__pulsating-dot">
      <div className="stream-topbar__pulsating-dot__inner">
        <div className="ring" />
        <div className="circle" />
      </div>
    </div>
  )

  const renderButtons = () => {
    if (isStreamPage) {
      if (streamInfo.schedule_link || streamInfo.resource_link) {
        return (
          <div className="stream-topbar__wrapper-buttons">
            {streamInfo.schedule_link && (
              <Button
                variant="streamBtn"
                text="SCHEDULE"
                size="medium"
                type="button"
                spacing="mr-2"
                onClick={() => window.open(streamInfo.schedule_link, '_blank')}
              />
            )}

            {streamInfo.resource_link && (
              <Button
                variant="streamBtn"
                text="RESOURCES"
                size="medium"
                type="button"
                onClick={() => window.open(streamInfo.resource_link, '_blank')}
                disabled={!isLive}
              />
            )}
          </div>
        )
      }
    } else {
      if (!isMobile() && !isLive) {
        return (
          <div
            role="button"
            className="stream-topbar__link-button"
            onClick={() => goToStreamPage()}>
            <SpriteIcon
              name="arrow"
              size="md"
              isActive
            />
          </div>
        )
      }
    }
  }

  return (
    <div
      role="button"
      className={clsx('stream-topbar', isLive && 'active', !isStreamPage && isMobile() && 'fixed')}
      onClick={() => goToStreamPage()}>
      <div className="container">
        <div className="stream-topbar__inner">
          <div className="stream-topbar__name-wrapper">
            <div className="stream-topbar__name">
              {streamInfo.title}
            </div>
            {isLive && !isStreamPage && (
              renderPulsatingDot()
            )}
          </div>

          <div className="stream-topbar__wrapper">
            {timer && !isLive && (
              <div className="stream-topbar__time">
                <div className="status">
                  Starts in
                </div>
                <div className="timer">
                  {timer}
                </div>
              </div>
            )}

            {renderButtons()}
          </div>
        </div>
      </div>
    </div>
  )
}

Topbar.propTypes = {
  isStreamPage: PropTypes.bool,
  streamInfo: contentDataTypes.streamInfo,
  enableChat: PropTypes.func.isRequired,
}

Topbar.defaultProps = {
  isStreamPage: false,
  streamInfo: null,
}

const actionsToProps = {
  enableChat,
}

export default connect(null, actionsToProps)(Topbar)
