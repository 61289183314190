import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

export const CloseModalButton = ({
  onClick,
  top,
  right,
  color,
  className,
}) => {
  const checkLineColor = () => {
    switch (color) {
      case 'white':
        return '#fff'
      case 'grey':
        return '#a4a4a4'
      default:
        return '#000'
    }
  }

  return (
    <div
      aria-label="Close"
      role="button"
      className={`CloseModalButton ${className}`}
      onClick={onClick}
      style={{
        top,
        right,
      }}>
      <div style={{
        backgroundColor: checkLineColor(),
      }}
      />
      <div style={{
        backgroundColor: checkLineColor(),
      }}
      />
    </div>
  )
}

CloseModalButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  top: PropTypes.number.isRequired,
  right: PropTypes.number.isRequired,
  color: PropTypes.oneOf(['white', 'grey']).isRequired,
  // eslint-disable-next-line react/require-default-props
  className: PropTypes.string,
}

CloseModalButton.defaultTypes = {
  className: '',
}
