export const PERMISSIONS = {
  CAN_SEE_CONTENT: 'canSeeContent',
  CAN_SEE_PUBLIC_PROFILES: 'canSeePublicProfiles',

  CAN_MANAGE_ACCOUNT: 'canManageAccount',
  CAN_MANAGE_PROFILE_DETAILS: 'canManageProfileDetails',
  CAN_MANAGE_SETTINGS: 'canManageSettings',
  CAN_MANAGE_BILLING_AND_SUBSCRIPTION: 'canManageBillingAndSubscription',
  CAN_MANAGE_SUBSCRIPTION: 'canManageSubscriptions',
  CAN_MANAGE_BILLING: 'canManageBilling',

  CAN_MANAGE_BOOKMARKS: 'canManageBookmarks',

  CAN_SEE_ADS: 'canSeeAds',
  CAN_MANAGE_ADS: 'canManageAds',
}
