import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { intervalToDuration } from 'date-fns'

/* hooks */
import _useDidMount from 'hooks/lifecycle/use-did-mount'

/* actions */
import { setMobileBannerLastClosed } from 'state-manager/actions/user'

/* assets */
import appLogo from 'assets/images/app-logo.png'
import firefoxLogo from 'assets/images/browsers-icons/firefox.png'
import samsungLogo from 'assets/images/browsers-icons/samsung.png'
import operaLogo from 'assets/images/browsers-icons/opera.png'
import edgeLogo from 'assets/images/browsers-icons/edge.png'
import chromeLogo from 'assets/images/browsers-icons/chrome.png'
import safariLogo from 'assets/images/browsers-icons/safari.png'
import ucLogo from 'assets/images/browsers-icons/uc.png'
import unknownLogo from 'assets/images/browsers-icons/unknown.png'

/* helpers */
import isMobile from 'helpers/check-is-mobile-device'

/* components */
import Button from '../ui/Button/base'

/* styles */
import './styles.scss'

const MobileBanner = ({
  mobileBannerLastClosed,
  setMobileBannerLastClosed,
}) => {
  const [userBrowser, setUserBrowser] = useState(null)
  const [isShowBanner, setIsShowBanner] = useState(null)

  const getBrowser = (userAgent) => {
    if (userAgent.includes('Firefox')) {
      // "Mozilla/5.0 (X11; Linux i686; rv:104.0) Gecko/20100101 Firefox/104.0"
      return {
        name: 'Firefox',
        icon: firefoxLogo,
      }
    } if (userAgent.includes('SamsungBrowser')) {
      // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36"
      return {
        name: 'Samsung Internet',
        icon: samsungLogo,
      }
    } if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
      // "Mozilla/5.0 (Macintosh; Intel Mac OS X 12_5_1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36 OPR/90.0.4480.54"
      return {
        name: 'Opera',
        icon: operaLogo,
      }
    } if (userAgent.includes('Edge')) {
      // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
      return {
        name: 'Edge',
        icon: edgeLogo,
      }
    } if (userAgent.includes('Edg')) {
      // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36 Edg/104.0.1293.70"
      return {
        name: 'Edge',
        icon: edgeLogo,
      }
    } if (userAgent.includes('Chrome')) {
      // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36"
      return {
        name: 'Chrome',
        icon: chromeLogo,
      }
    } if (userAgent.includes('Safari')) {
      // "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6 Mobile/15E148 Safari/604.1"
      return {
        name: 'Safari',
        icon: safariLogo,
      }
    } if (userAgent.includes('UCBrowser')) {
      // "UCWEB/2.0 (..) .. UCBrowser/10.7.9.856 U2/1.0.0 Mobile"
      return {
        name: 'UCBrowser',
        icon: ucLogo,
      }
    }

    return {
      name: 'Unknown Browser',
      icon: unknownLogo,
    }
  }

  _useDidMount(() => {
    setUserBrowser(getBrowser(navigator.userAgent))

    if (!mobileBannerLastClosed) {
      setIsShowBanner(true)
    }

    if (mobileBannerLastClosed) {
      const duration = intervalToDuration({
        start: new Date(mobileBannerLastClosed),
        end: new Date(),
      })

      if (duration.days > 1) setIsShowBanner(true)
    }
  })

  const handleClose = () => {
    setMobileBannerLastClosed(new Date())
    setIsShowBanner(false)
  }

  const handleOpenAppClick = () => {
    if (navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad')) {
      window.open('https://apps.apple.com/app/learners/id1572654814/')
      return null
    }
    window.open('https://play.google.com/store/apps/details?id=com.joinlearners')
  }

  if (!userBrowser || !isShowBanner || !isMobile()) {
    return null
  }

  return (
    <div className="mobile-banner">
      <div className="mobile-banner__overlay animated" />
      <div className="mobile-banner__inner animated">
        <h3 className="mobile-banner__title">
          See Learners in ...
        </h3>

        <div className="mobile-banner__actions">
          <div className="mobile-banner__action">
            <div className="app-wrapper">
              <div className="image-wrapper">
                <img src={appLogo} alt="Learners app logo" className="mobile-banner__logo" />
              </div>
              <div className="mobile-banner__name">
                Learners App
              </div>
            </div>

            <div className="button-wrapper">
              <Button
                size="small"
                variant="quaternary"
                text="Open"
                className="mobile-banner__button"
                onClick={handleOpenAppClick}
              />
            </div>
          </div>

          <div className="mobile-banner__action">
            <div className="app-wrapper">
              <div className="image-wrapper">
                <img src={userBrowser.icon} alt="Learners app logo" className="mobile-banner__logo" />
              </div>
              <div className="mobile-banner__name">
                {userBrowser.name}
              </div>
            </div>

            <div className="button-wrapper">
              <Button
                size="small"
                variant="secondary"
                text="Continue"
                className="mobile-banner__button browser-btn"
                onClick={handleClose}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

MobileBanner.propTypes = {
  mobileBannerLastClosed: PropTypes.string.isRequired,
  setMobileBannerLastClosed: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  mobileBannerLastClosed: state.userData.mobileBannerLastClosed,
})

const actionsToProps = {
  setMobileBannerLastClosed,
}

export default connect(mapStateToProps, actionsToProps)(MobileBanner)
