import React from 'react'
import PropTypes from 'prop-types'
import EpisodeItem from '../episodeItem'

import './styles.scss'

const Episodes = ({
  episodes,
  disabled,
}) => {
  const renderEpisodes = () => (
    episodes.map((episode, index) => (
      <EpisodeItem
        key={index}
        index={index + 1}
        id={episode.id}
        slug={episode.slug}
        likes={0}
        views={0}
        video={episode.preview_link}
        img={episode.feature_image}
        title={episode.title}
        shortDescription={episode.short_description}
        duration={episode.duration}
        currentDuration={episode.current_duration}
        disabled={disabled}
      />
    ))
  )

  return (
    <section className="episodes">
      <div className="episodes__wrapper">
        {renderEpisodes()}
      </div>
    </section>
  )
}

Episodes.propTypes = {
  episodes: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default Episodes
