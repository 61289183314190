import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import _useDidUpdate from '../hooks/lifecycle/use-did-update'

const MailchimpForm = ({ status, message, onSubmit }) => {
  const [email, setEmail] = useState('')

  _useDidUpdate(() => {
    if (status === 'success') {
      setEmail('')
      toast.success('Subscribed!')
    }

    if (status === 'error') {
      toast.error(message)
    }
  }, [status])

  _useDidUpdate(() => {

  }, [message])

  const handleSubmit = (event) => {
    event.preventDefault()
    if (email && email.indexOf('@') > -1) {
      onSubmit({
        EMAIL: email,
      })
    }
  }

  return (
    <form onSubmit={(event) => handleSubmit(event)}>
      <input
        type="email"
        placeholder="Your email"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />
      <button
        disabled={status === 'sending'}
        type="submit">
        Submit
      </button>
    </form>
  )
}

MailchimpForm.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
}

MailchimpForm.defaultProps = {
  status: '',
  message: '',
}

export default MailchimpForm
