import React from 'react'
import PropTypes from 'prop-types'

/* components */
import FooterV1 from './v1'
import FooterV2 from './v2'
import FooterV3 from './v3'

/* styles */
import './styles.scss'

const Footer = ({ variant }) => {
  const renderFooter = (variant) => {
    switch (variant) {
      case 'v1':
        return <FooterV1 />
      case 'v2':
        return <FooterV2 />
      case 'v3':
        return <FooterV3 />
      default:
        return <FooterV2 />
    }
  }

  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <div className="container">
          {renderFooter(variant)}
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  variant: PropTypes.oneOf(['v1', 'v2', 'v3']),
}

Footer.defaultProps = {
  variant: 'v3',
}

export default Footer
