import generateConstants from 'state-manager/constants/helpers/generate-constants'

/* app */
const APP_TOGGLE_LOADER = 'APP_TOGGLE_LOADER'
const APP_GET_CHECKSUM = 'APP/CHECKSUM_GET'
const APP_GET_CATEGORIES = 'APP/CATEGORIES_GET'
const APP_GET_LEVELS = 'APP/LEVELS_GET'
const APP_GET_DISCIPLINES = 'APP/DISCIPLINES_GET'
const APP_GET_EXPERIENCE = 'APP/EXPERIENCE_GET'
const APP_SET_MUTE = 'APP/MUTE'
const APP_SET_UNMUTE = 'APP/UNMUTE'
const APP_SET_THOUGHTS_STATE = 'APP/THOUGHTS_STATE_SET'
const APP_SET_THOUGHTS_PLAYING = 'APP/THOUGHTS_PLAYING_SET'

export const APP_ACTIONS = generateConstants({
  APP_TOGGLE_LOADER,
  APP_GET_CHECKSUM,
  APP_GET_CATEGORIES,
  APP_GET_LEVELS,
  APP_GET_DISCIPLINES,
  APP_GET_EXPERIENCE,
  APP_SET_MUTE,
  APP_SET_UNMUTE,
  APP_SET_THOUGHTS_STATE,
  APP_SET_THOUGHTS_PLAYING,
})

/* auth */
const SIGN_IN = 'SIGN_IN'
const SIGN_UP = 'SIGN_UP'
const SIGN_OUT = 'SIGN_OUT'
const VERIFY_EMAIL = 'VERIFY_EMAIL'
const FORGOT_PASSWORD__REQUEST_CODE = 'FORGOT_PASSWORD__REQUEST_CODE'
const FORGOT_PASSWORD__CHECK_CODE = 'FORGOT_PASSWORD__CHECK_CODE'
const RESET_PASSWORD = 'RESET_PASSWORD'
const CHANGE_PASSWORD = 'CHANGE_PASSWORD'

export const AUTH_ACTIONS = generateConstants({
  SIGN_IN,
  SIGN_UP,
  SIGN_OUT,
  VERIFY_EMAIL,
  FORGOT_PASSWORD__REQUEST_CODE,
  FORGOT_PASSWORD__CHECK_CODE,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
})

/* user */
const USER__DATA_GET = 'USER/DATA_GET'
const USER__DATA_UPDATE = 'USER/DATA_UPDATE'
const PLAN__DATA_UPDATE = 'PLAN/DATA_UPDATE'
const USER__TIMER_UPDATE = 'USER/TIMER_UPDATE'
const USER__TIMER_CLEAR = 'USER/TIMER_CLEAR'
const USER__AGREE_WITH_CHAT_RULES = 'USER/AGREE_WITH_CHAT_RULES'
const USER__LAST_CLOSED_BANNER_SET = 'USER/LAST_CLOSED_BANNER_SET'

export const USER_ACTIONS = generateConstants({
  DATA_GET: USER__DATA_GET,
  DATA_UPDATE: USER__DATA_UPDATE,
  PLAN_UPDATE: PLAN__DATA_UPDATE,
  TIMER_UPDATE: USER__TIMER_UPDATE,
  TIMER_CLEAR: USER__TIMER_CLEAR,
  AGREE_WITH_CHAT_RULES: USER__AGREE_WITH_CHAT_RULES,
  LAST_CLOSED_BANNER_SET: USER__LAST_CLOSED_BANNER_SET,
})

/* subscription-info */
const SUBSCRIPTION_INFO__DATA_GET = 'SUBSCRIPTION_INFO/DATA_GET'
const SUBSCRIPTION_INFO__UPDATE = 'SUBSCRIPTION_INFO/UPDATE'
const SUBSCRIPTION_INFO__UPDATE__COUPON = 'SUBSCRIPTION_INFO/UPDATE/COUPON'
const SUBSCRIPTION_INFO__UPDATE__PLAN = 'SUBSCRIPTION_INFO/UPDATE/PLAN'
const SUBSCRIPTION_INFO__CLEAR = 'SUBSCRIPTION_INFO/CLEAR/INFO'

export const SUBSCRIPTION_INFO_ACTIONS = generateConstants({
  DATA_GET: SUBSCRIPTION_INFO__DATA_GET,
  UPDATE: SUBSCRIPTION_INFO__UPDATE,
  UPDATE_COUPON: SUBSCRIPTION_INFO__UPDATE__COUPON,
  UPDATE_PLAN: SUBSCRIPTION_INFO__UPDATE__PLAN,
  CLEAR_INFO: SUBSCRIPTION_INFO__CLEAR,
})

/* content */
const CONTENT__GET_SINGLE = 'CONTENT/GET_SINGLE'
const CONTENT__UPDATE_SINGLE = 'CONTENT/UPDATE_SINGLE'
const CONTENT__GET_SINGLE_STATS = 'CONTENT/GET_SINGLE_STATS'
const CONTENT__GET_EPISODES_STATS = 'CONTENT/GET_EPISODES_STATS'
const CATEGORIES__GET = 'CONTENT/CATEGORIES/GET'
const CATEGORIES__UPDATE = 'CONTENT/CATEGORIES/GET'
const HEADER_GET = 'CONTENT/HEADER/GET'
const FILTERS__GET = 'CONTENT/FILTERS/GET'
const ADS__GET = 'CONTENT/ADS/GET'
const EVENT__SEND = 'CONTENT/EVENT/SEND'
const REVIEW__SEND = 'CONTENT/REVIEW/SEND'
const ADD_TO_BOOKMARKS = 'ADD_TO_BOOKMARKS'
const DELETE_FROM_BOOKMARKS = 'DELETE_FROM_BOOKMARKS'
const CONTENT__GET_PROFILE = 'CONTENT/GET_PROFILE'
const CREATORS__GET = 'CONTENT/CREATORS/GET'
const MINISERIES__GET = 'CONTENT/MINISERIES/GET'
const CREATOR__GET = 'CONTENT/CREATOR/GET'
const TIME_MARK__SEND = 'CONTENT/TIME_MARK/SEND'
const TIME_MARK__UPDATE = 'CONTENT/TIME_MARK/UPDATE'
const CONTENT_GET_SINGLE_MINISERIES = 'CONTENT/GET_SINGLE_MINISERIES'
const CONTENT_GET_FELLOWSHIP_CATEGORY = 'CONTENT/GET_FELLOWSHIP_CATEGORY'
const CONTENT_GET_STREAM_INFO = 'CONTENT/GET_STREAM_INFO'
const CONTENT_UPCOMING_EVENTS__GET = 'CONTENT/GET_UPCOMING_EVENTS'
const CONTENT_GET_TOP_VIDEOS = 'CONTENT/GET_TOP_VIDEOS'
const CONTENT_GET_LATEST_VIDEOS = 'CONTENT/GET_LATEST_VIDEOS'
const CONTENT_GET_TOP_QUESTIONS = 'CONTENT/GET_TOP_QUESTIONS'
const CONTENT_GET_TOP_CREATORS = 'CONTENT/GET_TOP_CREATORS'
const CONTENT_GET_ADVISORS = 'CONTENT/GET_ADVISORS'
const CONTENT_GET_PARTNERS = 'CONTENT/GET_PARTNERS'
const CONTENT_GET_JOBS = 'CONTENT/GET_JOBS'
const CONTENT_GET_SINGLE_QUESTION = 'CONTENT/GET_SINGLE_QUESTION'
const CONTENT_PAST_EVENTS__GET = 'CONTENT/GET_PAST_EVENTS'

export const CONTENT_ACTIONS = generateConstants({
  GET_SINGLE: CONTENT__GET_SINGLE,
  GET_SINGLE_STATS: CONTENT__GET_SINGLE_STATS,
  GET_EPISODES_STATS: CONTENT__GET_EPISODES_STATS,
  UPDATE_SINGLE: CONTENT__UPDATE_SINGLE,
  GET_CATEGORIES: CATEGORIES__GET,
  UPDATE_CATEGORIES: CATEGORIES__UPDATE,
  GET_TOP_VIDEOS: CONTENT_GET_TOP_VIDEOS,
  GET_LATEST_VIDEOS: CONTENT_GET_LATEST_VIDEOS,
  GET_TOP_QUESTIONS: CONTENT_GET_TOP_QUESTIONS,
  GET_TOP_CREATORS: CONTENT_GET_TOP_CREATORS,
  GET_ADVISORS: CONTENT_GET_ADVISORS,
  GET_PARTNERS: CONTENT_GET_PARTNERS,
  GET_JOBS: CONTENT_GET_JOBS,
  GET_HEADER: HEADER_GET,
  GET_FILTERS: FILTERS__GET,
  GET_ADS: ADS__GET,
  SEND_EVENT: EVENT__SEND,
  SEND_REVIEW: REVIEW__SEND,
  ADD_TO_BOOKMARKS,
  DELETE_FROM_BOOKMARKS,
  GET_PROFILE: CONTENT__GET_PROFILE,
  GET_CREATORS: CREATORS__GET,
  GET_MINISERIES: MINISERIES__GET,
  GET_CREATOR: CREATOR__GET,
  SEND_TIME_MARK: TIME_MARK__SEND,
  UPDATE_TIME_MARK: TIME_MARK__UPDATE,
  GET_SINGLE_MINISERIES: CONTENT_GET_SINGLE_MINISERIES,
  GET_FELLOWSHIP_CATEGORY: CONTENT_GET_FELLOWSHIP_CATEGORY,
  GET_STREAM_INFO: CONTENT_GET_STREAM_INFO,
  GET_UPCOMING_EVENTS: CONTENT_UPCOMING_EVENTS__GET,
  GET_QUESTION: CONTENT_GET_SINGLE_QUESTION,
  GET_PAST_EVENTS: CONTENT_PAST_EVENTS__GET,
})

const CHAT__INFO_GET = 'CHAT/INFO_GET'
const CHAT__MESSAGE_GET = 'CHAT/MESSAGE_GET/WS'
const CHAT__MESSAGE_SEND = 'CHAT/MESSAGE_SEND'
const CHAT_STOP_UPDATE = 'CHAT/STOP_UPDATE'
const CHAT_START_UPDATE = 'CHAT/START_UPDATE'
const CHAT_PUSH_QUEUE_TO_MESSAGES = 'CHAT/PUSH_QUEUE_TO_MESSAGES'
const CHAT_CLEAR = 'CHAT/CLEAR/MESSAGES'
const CHAT_ENABLE = 'CHAT/ENABLE'
const CHAT_USER_MUTE = 'CHAT/USER/MUTE'
const CHAT_USER_UNMUTE = 'CHAT/USER/UNMUTE'
const CHAT_MESSAGE_DELETE = 'CHAT/MESSAGE/DELETE'
const CHAT_MESSAGE_DELETED_CHANGE = 'CHAT/MESSAGE/CHANGE_DELETED'

export const CHAT_ACTIONS = generateConstants({
  GET_INFO: CHAT__INFO_GET,
  GET_MESSAGE_WS: CHAT__MESSAGE_GET,
  SEND_MESSAGE: CHAT__MESSAGE_SEND,
  STOP_UPDATE: CHAT_STOP_UPDATE,
  START_UPDATE: CHAT_START_UPDATE,
  PUSH_QUEUE: CHAT_PUSH_QUEUE_TO_MESSAGES,
  CHAT_CLEAR,
  CHAT_ENABLE,
  CHAT_USER_MUTE,
  CHAT_USER_UNMUTE,
  MESSAGE_DELETE: CHAT_MESSAGE_DELETE,
  MESSAGE_DELETED_CHANGE: CHAT_MESSAGE_DELETED_CHANGE,
})

const CLEAR_STORE = 'CLEAR_STORE'

export const CLEAR_STORE_ACTIONS = generateConstants({
  CLEAR_STORE,
})
