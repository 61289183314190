import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import classes from './Tag.module.scss'
import SpriteIcon from '../../icons/SpriteIcon'

const Tag = (
  {
    color,
    text,
    spacing,
    capitalize,
    iconName,
    onClick,
  },
) => (
  <span
    role="button"
    onClick={onClick}
    className={clsx(classes.TagBasic, classes[color], spacing, capitalize && classes.capitalize)}>
    {iconName && (
      <SpriteIcon
        name={iconName}
        size="sm"
        className="mr-1"
      />
    )}
    {text}
  </span>
)

Tag.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'tertiary',
    'danger',
    'success',
  ]),
  text: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  spacing: PropTypes.string,
  capitalize: PropTypes.bool,
  onClick: PropTypes.func,
}

Tag.defaultProps = {
  iconName: '',
  color: 'primary',
  spacing: '',
  capitalize: false,
  onClick: null,
}

export default Tag
