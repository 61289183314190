import React from 'react'
import useDidMount from 'hooks/lifecycle/use-did-mount'

const Testimonials = () => {
  useDidMount(() => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://testimonial.to/js/iframeResizer.min.js'

    script.onload = () => {
      if (window.iFrameResize) {
        window.iFrameResize({ log: false, checkOrigin: false }, '#testimonialto-learners-community-tag-all-light')
      }
    }
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  })

  return (
    <iframe
      id="testimonialto-learners-community-tag-all-light"
      src="https://embed-v2.testimonial.to/w/learners-community?theme=light&card=base&loadMore=on&initialCount=10&tag=all"
      frameBorder="0"
      scrolling="no"
      width="100%"
      height="100%"
      title="testimonials"
    />
  )
}

export default Testimonials
