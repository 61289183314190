import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'

/* styles */
import './styles.scss'

/* assets */
import defaultAvatar from 'assets/images/default-user-photo.png'

/* components */
import Button from 'components/ui/Button/base'

const MessageItem = ({
  handle,
  photo,
  messageBody,
  time,
  isModerator,
  userId,
  messageId,
  conversationId,
  onMuteClick,
  onDeleteClick,
}) => {
  const [isShownBanMenu, setIsShownBanMenu] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)

  const toggleBanMenu = () => {
    setIsShownBanMenu(!isShownBanMenu)
  }
  const onMouseEnter = () => {
    if (isModerator) {
      setIsShownBanMenu(true)
    }
  }

  const onMouseLeave = () => {
    if (isModerator) {
      setIsShownBanMenu(false)
    }
  }

  const handleMute = () => {
    onMuteClick({
      isMuted: !isMuted,
      conversationId,
      userId,
    })
    setIsMuted(!isMuted)
  }

  const handleDelete = () => {
    onDeleteClick({
      conversationId,
      messageId,
    })
    setIsDeleted(true)
  }

  return (
    <div
      role="button"
      className="message"
      onClick={isModerator ? () => toggleBanMenu() : null}
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={() => onMouseLeave()}>
      {isShownBanMenu && (
        <div className="wrapper-ban-menu">
          <Button
            className="mute"
            variant="secondary"
            size="small"
            type="button"
            text={isMuted ? 'Unmute' : 'Mute'}
            onClick={() => handleMute()}
          />
          <Button
            className="mute"
            variant="secondary"
            size="small"
            type="button"
            text={isDeleted ? 'Deleted' : 'Delete'}
            disabled={isDeleted}
            onClick={() => handleDelete()}
          />
        </div>
      )}
      <div className="wrapper-avatar">
        <img src={photo || defaultAvatar} alt={handle} className="avatar" />
      </div>
      <div className="wrapper-body">
        <div className="wrapper-name">
          <div className="name">{`@${handle}`}</div>
          <div className="delimiter">{'\u00b7'}</div>
          <div className="time">{format(time, 'HH:mm')}</div>
        </div>
        <div className="message-body">
          {messageBody}
        </div>
      </div>
    </div>
  )
}

MessageItem.propTypes = {
  handle: PropTypes.string.isRequired,
  photo: PropTypes.string,
  messageBody: PropTypes.string.isRequired,
  time: PropTypes.number.isRequired,
  conversationId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  isModerator: PropTypes.bool,
  onMuteClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  messageId: PropTypes.number.isRequired,
}

MessageItem.defaultProps = {
  photo: null,
  isModerator: false,
  onMuteClick: null,
  onDeleteClick: null,
}

export default MessageItem
