import React from 'react'
import { Route, Switch } from 'react-router-dom'

/* routes */
import routes from 'routes'

/* accessors */
import Access from 'routes/access'

/* shared */
import TermsOfServicePage from 'pages/shared/legals/terms-of-service'
import PrivacyPolicyPage from 'pages/shared/legals/privacy-policy'

/* helpers */
import scrollToTop from 'helpers/scrollToTop'

const AppModel = () => {
  scrollToTop()

  return (
    <Switch>
      <Route exact path={routes.termsOfService} component={TermsOfServicePage} />
      <Route exact path={routes.privacyPolicy} component={PrivacyPolicyPage} />

      <Access path={routes.homepage} />
    </Switch>
  )
}

export default AppModel
