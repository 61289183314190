import LaravelEcho from 'laravel-echo'
import io from 'socket.io-client'
import { store } from 'state-manager/store'

/* constants */
import { LARAVEL_ECHO_HOST_URL } from 'constants/main'

let laravelEchoInstance = null
let connected = false

const getToken = () => {
  const state = store.getState()
  if (state && state.userData && state.userData.isAuthorized) {
    return `${state.userData.authorizationData.token_type} ${state.userData.authorizationData.access_token}`
  }
  return ''
}

function connectLaravelEcho() {
  if (laravelEchoInstance) {
    return laravelEchoInstance
  }

  laravelEchoInstance = new LaravelEcho({
    broadcaster: 'socket.io',
    client: io,
    host: LARAVEL_ECHO_HOST_URL,
    transports: ['websocket'],
    namespace: '',
    // namespace: 'DNC.Messenger.Laravel.Events.Broadcast.Message',
    auth: {
      headers: {
        Authorization: getToken(),
      },
    },
  })
  connected = true
  return laravelEchoInstance
}

class Echo {

  // eslint-disable-next-line class-methods-use-this
  connect() {
    return new Promise((resolve) => {
      if (!connected) {
        connectLaravelEcho()
      }
      laravelEchoInstance.options.auth.headers.Authorization = getToken()
      resolve(laravelEchoInstance)
    })
  }

}

export default new Echo()
