import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

/* constants */
import { openGraphConstants } from './constants'

const OpenGraph = ({
  url,
  title,
  description,
  img,
  type,
  videoUrl,
  profile,
  keywords,
  isDefaultPage,
}) => (
  <Helmet>
    {isDefaultPage ? (
      <title>Learners</title>
    ) : (
      <title>{`${title} | Learners`}</title>
    )}

    <meta
      property="og:url"
      content={url}
    />

    <meta
      property="og:site_name"
      content="Learners"
    />

    <meta
      property="fb:app_id"
      content="2994668407419465"
    />

    <meta
      property="og:title"
      content={title}
    />

    <meta
      name="description"
      content={description}
    />

    <meta
      property="og:description"
      content={description}
    />

    {videoUrl && (
      <meta
        property="og:video"
        content={videoUrl}
      />
    )}

    {videoUrl && (
      <meta
        property="og:video:secure_url"
        content={videoUrl}
      />
    )}

    {videoUrl && (
      <meta
        property="og:video:type"
        content="video/mp4"
      />
    )}

    {videoUrl && (
      <meta
        property="og:video:width"
        content="1280"
      />
    )}

    {videoUrl && (
      <meta
        property="og:video:height"
        content="720"
      />
    )}

    <meta
      property="og:image"
      content={img}
    />

    <meta
      property="og:image:secure_url"
      content={img}
    />

    <meta
      property="og:type"
      content={type}
    />

    {/* Twitter */}
    <meta
      name="twitter:card"
      content={videoUrl ? 'player' : 'summary'}
    />

    <meta
      name="twitter:site"
      content="@JoinLearners"
    />

    {profile?.twitter_nickname && (
      <meta
        name="twitter:creator"
        content={`@${profile.twitter_nickname}`}
      />
    )}

    <meta
      name="twitter:title"
      content={title}
    />

    <meta
      name="twitter:description"
      content={description}
    />

    <meta
      name="twitter:image"
      content={img}
    />

    {videoUrl && (
      <meta
        name="twitter:player"
        content={videoUrl}
      />
    )}

    {videoUrl && (
      <meta
        name="twitter:player:width"
        content="1280"
      />
    )}

    {videoUrl && (
      <meta
        name="twitter:player:height"
        content="720"
      />
    )}
    {/* End Twitter */}

    {keywords && (
      keywords.map((keyword, index) => (
        <meta
          property="og:video:tag"
          content={keyword}
          key={index}
        />
      ))
    )}

    {profile && (
      <meta
        property="og:video:actor:profile:first_name"
        content={profile.first_name}
      />
    )}

    {profile && (
      <meta
        property="og:video:actor:profile:last_name"
        content={profile.last_name}
      />
    )}
  </Helmet>
)

OpenGraph.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  url: PropTypes.string,
  img: PropTypes.string,
  type: PropTypes.string,
  videoUrl: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  profile: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  keywords: PropTypes.array,
  isDefaultPage: PropTypes.bool,
}

OpenGraph.defaultProps = {
  url: window.location.href,
  description: openGraphConstants.description,
  profile: null,
  keywords: null,
  img: openGraphConstants.img,
  type: 'website',
  videoUrl: null,
  isDefaultPage: false,
}

export default OpenGraph
