import React from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'

/* components */
import SpriteIcon from 'components/icons/SpriteIcon'
import Topbar from 'components/ui/Stream/Topbar'

/* actions */
import { signOut } from 'state-manager/actions/auth'

/* state-manager */
import { history } from 'state-manager/store'

/* utils */
import customEvent from 'utils/custom-event'

/* routes */
import routes from 'routes'

/* constants */
import { PERMISSIONS } from 'constants/permissions'
import {
  SHOW_SIGN_IN_MODAL,
  TOGGLE__VISIBILITY_SIGNUP_MODAL,
} from 'constants/custom-events'

/* types */
import { types as userDataTypes } from 'state-manager/reducers/user-data'
import { types as contentDataTypes } from 'state-manager/reducers/content'

/* styles */
import 'pages/shared/layouts/global-layout/header/styles.scss'

const Header = ({
  udIsAuthorized,
  udPermissions,
  streamInfo,
  udAvatar,

  signOut,
}) => {
  const location = useLocation()

  const handleRegisterClick = () => {
    customEvent.trigger(TOGGLE__VISIBILITY_SIGNUP_MODAL)
  }

  const handleLoginClick = () => {
    customEvent.trigger(SHOW_SIGN_IN_MODAL)
  }

  const renderHeader = () => (
    <header className={clsx('header header--authorized',
      location.pathname === routes.resources && 'homepage',
      history.location.pathname.includes('category') && 'category')}>
      <div className="header__wrapper header--authorized__wrapper">
        <div className="container">
          <div className="header__inner header--authorized__inner">
            <div
              role="link"
              aria-label="Homepage button"
              className="header__logo header--authorized__logo"
              onClick={() => history.push(routes.homepage)}>
              <SpriteIcon name="learners-logo" size="logo" />
            </div>

            <div className="header__nav-wrapper">
              <div className="header__nav-wrapper__links">

                {udIsAuthorized && (
                  <div className="header__link">
                    <div
                      role="link"
                      aria-label="Bookmark button"
                      className="header__icon bookmark"
                      onClick={() => history.push(routes.bookmarks)}>
                      <SpriteIcon
                        name="bookmark-bordered"
                        size="fullWidth"
                      />
                    </div>
                  </div>
                )}

                {udPermissions.includes(PERMISSIONS.CAN_MANAGE_ACCOUNT) && (
                  <div
                    role="button"
                    aria-label="My account button"
                    className="header__link"
                    onClick={() => history.push(routes.manageProfileDetails)}>
                    <img className="header__avatar" src={udAvatar} alt="" />
                  </div>
                )}

                {udIsAuthorized ? (
                  <div className="header__link">
                    <div
                      role="link"
                      aria-label="Logout button"
                      className="header__icon"
                      onClick={signOut}>
                      <SpriteIcon
                        name="logout"
                        size="fullWidth"
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="header__link">
                      <div
                        role="button"
                        className="button-link"
                        onClick={handleRegisterClick}>
                        JOIN
                      </div>
                    </div>

                    <div className="header__link">
                      <div
                        role="button"
                        className="button-link"
                        onClick={handleLoginClick}>
                        LOGIN
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

          </div>
        </div>
      </div>
    </header>
  )

  if (streamInfo) {
    return (
      <div className={history.location.pathname.includes('live') ? 'header__topbar reverse' : 'header__topbar'}>
        {streamInfo && (
          <Topbar
            streamInfo={streamInfo}
            isStreamPage={history.location.pathname.includes('live')}
          />
        )}
        {renderHeader()}
      </div>
    )
  }

  return renderHeader()
}

Header.propTypes = {
  udIsAuthorized: userDataTypes.isAuthorized.isRequired,
  udPermissions: userDataTypes.permissions.isRequired,
  streamInfo: contentDataTypes.streamInfo,
  udAvatar: userDataTypes.photo.isRequired,

  signOut: PropTypes.func.isRequired,
}

Header.defaultProps = {
  streamInfo: null,
}

const mapStateToProps = (state) => ({
  udIsAuthorized: state.userData.isAuthorized,
  udPermissions: state.userData.permissions,
  udAvatar: state.userData.photo,

  streamInfo: state.content.streamInfo,
})

const actionsToProps = {
  signOut,
}

export default connect(mapStateToProps, actionsToProps)(Header)
