import React from 'react'
import PropTypes from 'prop-types'

import classes from './Loader.module.scss'

const Loader = ({ action }) => (
  <div
    role="button"
    className={classes.previewSpinner}
    onClick={action}>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
)

Loader.propTypes = {
  action: PropTypes.func,
}

Loader.defaultProps = {
  action: null,
}

export default Loader
