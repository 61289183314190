import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

/* styles */
import 'pages/shared/layouts/global-layout/header-light/styles.scss'

/* routes */
import routes from 'routes'

/* state-manage */
import { history } from 'state-manager/store'

/* components */
import SpriteIcon from 'components/icons/SpriteIcon'
import Button from 'components/ui/Button/base'

/* helpers */
import isMobile from 'helpers/check-is-mobile-device'

const HeaderLight = ({ className, withoutGetAppBtn }) => {
  const handleOpenLearners = () => {
    window.open('https://joinlearners.com/', '_blank')
  }

  return (
    <header className={clsx('header header--light ', className)}>
      <div className="header__wrapper header--light__wrapper">
        <div className="container">
          <div className="header__inner header--light__inner">
            <div
              role="link"
              aria-label="Homepage button"
              className="header__logo header--light__logo"
              onClick={() => history.push(routes.resources)}>
              <SpriteIcon
                name={isMobile() ? 'logo-mobile' : 'learners-logo'}
                size="logo"
              />
            </div>

            {!withoutGetAppBtn && (
              <div className="header__nav-wrapper">
                <div className="header__nav-wrapper__links">
                  <div className="header__link">
                    <Button
                      Btn="redesign-button"
                      className="get-app"
                      variant="primary"
                      text="Learners Home"
                      type="button"
                      size="small"
                      onClick={handleOpenLearners}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}

HeaderLight.defaultProps = {
  className: '',
  withoutGetAppBtn: false,
}

HeaderLight.propTypes = {
  className: PropTypes.string,
  withoutGetAppBtn: PropTypes.bool,
}

export default HeaderLight
