import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import isEmpty from 'lodash.isempty'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

/* assets */
import backgroundLogo from 'assets/images/background-logo.svg'

/* actions */
import { getCategoriesList } from 'state-manager/actions/app-data'
import { searchByCategories } from 'state-manager/actions/content'
import { history } from 'state-manager/store'

/* types */
import { types as appDataTypes } from 'state-manager/reducers/app-data'
import { types as contentDataTypes } from 'state-manager/reducers/content'

/* routes */
import routes from 'routes'

/* hooks */
import _useDidMount from 'hooks/lifecycle/use-did-mount'
import _useDidUpdate from 'hooks/lifecycle/use-did-update'

/* styles */
import './styles.scss'

/* components */
import Categories from 'components/ui/Categories'

const CategoryPage = ({
  categories,
  searchByCategories,
  getCategoriesList,
  categoriesList,
  responseWaiting,
}) => {
  const params = useParams()
  const [readyToSearch, setReadyToSearch] = useState(false)
  const [categoryId, setCategoryId] = useState(null)

  _useDidMount(() => {
    getCategoriesList()
  })

  _useDidUpdate(() => {
    if (!isEmpty(categoriesList) && !categoryId) {
      const category = categoriesList.find((category) => category.slug === params.slug)
      if (category) {
        setCategoryId(category.id)
        setReadyToSearch(true)
        searchByCategories({
          search: '',
          level: [],
          categories: [category.id],
        })
      } else {
        history.push(routes.errorPage)
      }
    }
  }, [categoriesList])

  const renderCategory = () => {
    if (!readyToSearch && responseWaiting) {
      return null
    }

    if (isEmpty(categories) || categories.length > 1) {
      return null
    }

    return (
      <Categories
        isPortraitCategories
        categories={categories}
        disabled={false}
      />
    )
  }

  return (
    <main className="homepage--light">
      <div className="parallax-bg resources">
        <img src={backgroundLogo} alt="Background watermark" className="logo" />
      </div>
      <div className="dashboard-category-page homepage">
        <div className="dashboard-category-page__wrapper">
          <div className="container">
            <div className="dashboard-category-page__inner">
              {renderCategory()}
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

CategoryPage.propTypes = {
  searchByCategories: PropTypes.func.isRequired,
  categories: contentDataTypes.categories.isRequired,
  getCategoriesList: PropTypes.func.isRequired,
  categoriesList: appDataTypes.categories.isRequired,
  responseWaiting: contentDataTypes.responseWaiting.isRequired,
}

const mapStateToProps = (state) => ({
  categoriesList: state.appData.categories,
  categories: state.content.categories,
  responseWaiting: state.content.responseWaiting,
})

const actionsToProps = {
  getCategoriesList,
  searchByCategories,
}
export default connect(mapStateToProps, actionsToProps)(CategoryPage)
