import React, {} from 'react'
import './styles.scss'
import * as PropTypes from 'prop-types'

/* helpers */
import showValidation from 'helpers/show-validation'

/* components */
import Input from 'components/ui/Inputs/Base'
import Button from 'components/ui/Button/base'

/* constants */
import { formFields } from './constants'

const ForgotPasswordTemplate = ({ handleSubmit, handleChange, handleBlur, values, errors, touched, responseWaiting, submitCount }) => {
  const showError = showValidation({
    errors,
    touched,
    values,
    submitCount,
    field: formFields.email,
  })

  return (
    <section className="forgot-password">
      <div className="forgot-password__wrapper">
        <div className="max-width-400">
          <div className="forgot-password__inner">
            <h2 className="forgot-password__description">
              Forgot Your Password?
            </h2>

            <p className="forgot-password__section-title">
              Fear not! Enter your email address below, and we’ll email you a 6-digit recovery code.
            </p>

            <form onSubmit={handleSubmit}>
              <Input
                label="Email address"
                variant="secondary"
                id={formFields.email}
                type={formFields.email}
                name={formFields.email}
                placeholder="email.address@example.com"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={showError ? errors.email : null}
                errorInput={showError ? 'errorInput' : null}
                errorLabel={showError ? 'errorLabel' : null}
              />

              <Button
                text="Send code"
                type="submit"
                variant="primary"
                size="medium"
                disabled={responseWaiting}
              />

            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

ForgotPasswordTemplate.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.string),
  errors: PropTypes.objectOf(PropTypes.string),
  touched: PropTypes.objectOf(PropTypes.bool),
  submitCount: PropTypes.number.isRequired,
  responseWaiting: PropTypes.bool,
}

ForgotPasswordTemplate.defaultProps = {
  errors: {},
  values: {},
  touched: {},
  responseWaiting: false,
}

export default ForgotPasswordTemplate
