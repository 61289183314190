import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { formatRoute } from 'react-router-named-routes'
import { connect } from 'react-redux'

/* styles */
import './styles.scss'
import clsx from 'clsx'

/* routes */
import routes from 'routes'

/* components */
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'
import SpriteIcon from 'components/icons/SpriteIcon'

/* constants */
import { EVENTS } from 'constants/events'

/* actions */
import { sendEvent } from 'state-manager/actions/content'

/* hooks */
import useCopyToClipboard from 'hooks/use-copy-to-clipboard'

const Share = ({
  videoId,
  title,
  description,
  placement,
  icon,
  isOnlyIcon,
  sendEvent,
  iconsWithState,
  isCreator,
  creatorID,
  slug,
  shareIconSize,
  isPortrait,
  isQuestion,
  queryId,
}) => {
  const [isShown, setIsShown] = useState(false)

  const getSinglePageUrl = (slug) => {
    let route = ''
    let query = ''
    if (isCreator) { route = routes.creator } else if (isPortrait) route = routes.thoughtDetails
    else if (isQuestion) route = routes.questionPage
    else { route = routes.talkDetails }
    if (queryId) {
      query = `?id=${queryId}` // for mobile app share
    }
    return window.location.origin + formatRoute(route, { slug }) + query
  }

  const handleClick = () => {
    setIsShown(!isShown)
    sendEvent({
      typeId: EVENTS.SHARING_CLICK,
      mediaId: isCreator ? creatorID : videoId,
    })
  }

  const [copied, copy] = useCopyToClipboard(getSinglePageUrl(slug))
  const [isShownCopied, setIsShownCopied] = useState(false)

  const handleCopyClick = () => {
    copy()
    setIsShownCopied(true)
    setTimeout(() => setIsShownCopied(false), 1500)
    handleClick()
  }

  return (
    <div className={clsx('share-menu', isShownCopied && copied && 'copy', placement)}>
      <OverlayTrigger
        trigger="click"
        key={placement}
        placement={placement}
        show={isShown}
        overlay={(
          <Popover
            onMouseLeave={() => setIsShown(!isShown)}
            id="popover-share">
            <div className={clsx('wrapper-share-buttons', placement)}>
              <div
                role="button"
                aria-label="Twitter Share Button"
                className="item"
                onClick={handleClick}>
                <TwitterShareButton
                  url={getSinglePageUrl(slug)}
                  title={title}>
                  <SpriteIcon name="twitter" size="md" />
                </TwitterShareButton>
              </div>

              <div
                role="button"
                aria-label="Linkedin Share Button"
                className="item"
                onClick={handleClick}>
                <LinkedinShareButton
                  url={getSinglePageUrl(slug)}
                  title={title}>
                  <SpriteIcon name="linkedin" size="md" />
                </LinkedinShareButton>
              </div>

              <div
                role="button"
                aria-label="Facebook Share Button"
                className="item"
                onClick={handleClick}>
                <FacebookShareButton
                  url={getSinglePageUrl(slug)}
                  quote={title}>
                  <SpriteIcon name="facebook" size="md" />
                </FacebookShareButton>
              </div>

              <div
                role="button"
                aria-label="Copy to clipboard button"
                className="item"
                onClick={handleCopyClick}>
                <SpriteIcon name="link" size="md" />
              </div>

              <div
                role="button"
                aria-label="Email Share Button"
                className="item"
                onClick={handleClick}>
                <EmailShareButton
                  url={getSinglePageUrl(slug)}
                  subject={title}
                  body={description}>
                  <SpriteIcon name="email" size="md" />
                </EmailShareButton>
              </div>
            </div>
          </Popover>
        )}>
        <div
          role="button"
          aria-label="Share button"
          className="wrapper-share"
          onClick={() => setIsShown(!isShown)}>
          <SpriteIcon
            name={isCreator ? 'share-arrow' : icon}
            size={shareIconSize}
            withState={iconsWithState}
            className="share-button"
          />
          {!isOnlyIcon && (
            <div className="item-title">
              Share
            </div>
          )}
        </div>

      </OverlayTrigger>
    </div>
  )
}

Share.propTypes = {
  videoId: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  placement: PropTypes.string,
  icon: PropTypes.string,
  isOnlyIcon: PropTypes.bool,
  sendEvent: PropTypes.func.isRequired,
  iconsWithState: PropTypes.bool,
  isCreator: PropTypes.bool,
  creatorID: PropTypes.number,
  slug: PropTypes.string.isRequired,
  shareIconSize: PropTypes.oneOf([
    'sm',
    'md',
    'md-big',
    'lg',
    'fullWidth',
  ]),
  isPortrait: PropTypes.bool,
  isQuestion: PropTypes.bool,
  queryId: PropTypes.string,
}

Share.defaultProps = {
  videoId: 0,
  placement: 'bottom',
  icon: 'share-light',
  isOnlyIcon: false,
  iconsWithState: false,
  isCreator: false,
  isQuestion: false,
  creatorID: 0,
  shareIconSize: 'md',
  isPortrait: false,
  queryId: '',
}

const actionsToProps = {
  sendEvent,
}

export default connect(null, actionsToProps)(Share)
