import * as Yup from 'yup'
import { useFormik } from 'formik'

import { formFields } from './constants'

export default ({
  onSubmit,
}) => useFormik({
  initialValues: {
    [formFields.old_password]: '',
    [formFields.password]: '',
    [formFields.password_confirmation]: '',
  },

  validationSchema: Yup.object({
    [formFields.old_password]: Yup.string()
      .label('Current password')
      .required(),
    [formFields.password]: Yup.string()
      .matches('(?=.*[a-z])', 'Enter lower case letter!')
      .matches('(?=.*[A-Z])', 'Enter upper case letter!')
      .matches('(?=.*[0-9])', 'Enter number!')
      .matches('(?=.*[!@#\\$%\\^&\\*])', 'Enter special symbols!')
      .min(8, 'Enter  minimum 8 characters!')
      .required('Password is required'),
    [formFields.password_confirmation]: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  }),

  onSubmit(values) {
    onSubmit(values)
  },
})
