import { createStore, compose, applyMiddleware } from 'redux'
import { createBrowserHistory } from 'history'
import { persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import logger from 'redux-logger'

/* root-reducer */
import createRootReducer from 'state-manager/reducers/root-reducer'

/* env */
import { ENV } from 'constants/main'

const history = createBrowserHistory()

const middlewares = [thunk, routerMiddleware(history)]
if (ENV === 'development') {
  middlewares.push(logger)
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose
const store = createStore(
  createRootReducer(history),
  composeEnhancers(
    applyMiddleware(
      ...middlewares,
    ),
  ),
)

const persistor = persistStore(store)

if (ENV !== 'production' && module.hot) {
  module.hot.accept('../reducers/root-reducer', () => {
    // eslint-disable-next-line global-require
    const createRootReducerHot = require('../reducers/root-reducer').default
    store.replaceReducer(createRootReducerHot(history))
  })
}

export { store, persistor, history }
