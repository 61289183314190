import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

/* styles */
import './styles.scss'
import '../homepage/styles.scss'

/* components */
import Testimonials from 'components/Testimonials'
import NavMenu from 'components/ui/NavMenu'
import { navConfig } from 'components/ui/NavMenu/constants'
import JobCard from 'components/ui/JobCard'

/* state */
import { getJobs } from 'state-manager/actions/content'

/* helpers */
import isMobile from 'helpers/check-is-mobile-device'

/* hooks */
import _useDidMount from 'hooks/lifecycle/use-did-mount'

/* assets */
import askQuestion from 'assets/images/ask-question.png'
import answerQuestion from 'assets/images/answer-question.png'
import searchQuestion from 'assets/images/search-question.png'
import searchScreen from 'assets/images/search-screen.png'
import askQuestionScreen from 'assets/images/ask-question-screen.png'
import answerQuestionScreen from 'assets/images/answer-question-screen.png'
import answerQuestionScreenMobile from 'assets/images/answer-question-screen-mobile.png'
import CommunityCards from 'assets/images/creatorCards.png'
import CommunityCardsMobile from 'assets/images/creatorCards-mobile.png'
import backgroundLogo from 'assets/images/background-logo.svg'

import MainSideBar from '../../components/MainSideBar'

const HomepageV1 = ({ jobs, getJobs }) => {
  _useDidMount(() => {
    getJobs()
  })

  return (
    <main className="homepage--light">
      <div className="parallax-bg">
        <img src={backgroundLogo} alt="Background watermark" className="logo" />
      </div>
      <div className="homepage">
        {isMobile() && <NavMenu navItems={navConfig} />}
        <div className="homepage__wrapper">
          <div className="container">
            <div className="homepage__inner">
              <MainSideBar />
              <div className="homepage__content">
                <section className="community-cards-wrapper">
                  <div className="community-cards-title">
                    <div className="community-title">
                      <h1 className="main-title">A community approach to learning.</h1>
                      <p className="community-description">Get advice on all your work-related questions.</p>
                    </div>
                    <div className="community-cards">
                      <img
                        src={isMobile() ? CommunityCardsMobile : CommunityCards}
                        className={`community-cards-img ${isMobile() && 'mobile'}`}
                        alt="CommunityCards"
                      />
                    </div>
                  </div>
                </section>
                <section className="join-researchers-wrapper">
                  <h2 className="join-researchers-title">Join a trusted community of 2,000+ researchers.</h2>
                  <div className="ask-questions">
                    <img className="ask-questions__screen" src={askQuestionScreen} alt="askQuestionScreen" />
                    <div className="ask-questions__text">
                      <img src={askQuestion} alt="askQuestion" />
                      <h5 className="ask-questions__title">Ask Questions</h5>
                      <p className="ask-questions__description">Share your work-related questions with thousands of researchers across the globe.</p>
                    </div>
                  </div>
                  <div className={clsx('answer-questions', !isMobile() && 'reverse')}>
                    <img className={clsx('answer-questions__screen', isMobile() && 'reverse')} src={isMobile() ? answerQuestionScreenMobile : answerQuestionScreen} alt="answerQuestionScreen" />
                    <div className={clsx('answer-questions__text', !isMobile() && 'text-right')}>
                      <img src={answerQuestion} alt="askQuestion" />
                      <h5 className={clsx('answer-questions__title', !isMobile() && 'text-right')}>Answer Questions</h5>
                      <p className={clsx('answer-questions__description', !isMobile() && 'text-right')}>
                        Join researchers from companies like Uber, Airbnb and Google by sharing your unique advice.
                      </p>
                    </div>
                  </div>
                  <div className="search-questions">
                    <img className="search-questions__screen" src={searchScreen} alt="searchScreen" />
                    <div className="search-questions__text">
                      <img src={searchQuestion} alt="askQuestion" />
                      <h5 className="search-questions__title">Search</h5>
                      <p className="search-questions__description">
                        With thousands of videos already shared, find the content you need to accelerate your career.
                      </p>
                    </div>
                  </div>
                </section>
                <section className="testomonials-wrapper">
                  <h2 className="testomonials-title">What people are saying.</h2>
                  <div className="testomonials">
                    <Testimonials />
                  </div>
                </section>
                {jobs?.length ? (
                  <section className="job-openings-wrapper">
                    <h2 className="job-openings-title">Open roles at Learners.</h2>
                    <div className="job-openings">
                      {jobs.map((job) => (<JobCard job={job} key={job.id} />))}
                    </div>
                  </section>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

  )
}
HomepageV1.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  jobs: PropTypes.array,
  getJobs: PropTypes.func.isRequired,
}
HomepageV1.defaultProps = {
  jobs: [],
}
const mapStateToProps = (state) => ({
  jobs: state.content.jobs,
})

const actionsToProps = {
  getJobs,
}
export default connect(mapStateToProps, actionsToProps)(HomepageV1)
