import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { formatRoute } from 'react-router-named-routes'
import { format, intervalToDuration, set } from 'date-fns'
import LazyLoad from 'react-lazyload'

/* routes */
import routes from 'routes'

/* actions */
import { sendEvent } from 'state-manager/actions/content'

/* state-manager */
import { history } from 'state-manager/store'

/* constants */
import { EVENTS } from 'constants/events'
import { TOGGLE__VISIBILITY_SIGNUP_MODAL } from 'constants/custom-events'

/* utils */
import customEvent from 'utils/custom-event'

/* helpers */
import getUrlByMediaPrefix from 'helpers/get-url-by-media-prefix'
import checkIsMobileDevice from 'helpers/check-is-mobile-device'

/* styles */
import clsx from 'clsx'
import '../category.scss'

/* components */
import ProgressBar from '@ramonak/react-progress-bar'
import Share from '../../Share'
import SpriteIcon from '../../../icons/SpriteIcon'

const CategoryItem = ({
  img,
  video,
  title,
  viewsCount,
  speakersInfo,
  shortDescription,
  id,
  slug,
  duration,
  currentDuration,
  // tags,
  sendEvent,
  isPortrait,
  disabled,
  // setModalVisibility,
  // getCurrentStory,
}) => {
  const [inHover, setHover] = useState(false)
  const [isLoadedVideo, setIsLoadedVideo] = useState(false)
  const [isLoadedImg, setIsLoadedImg] = useState(false)
  const videoRef = useRef()

  const handleOnMouseEnter = () => {
    setHover(true)
  }

  const handleOnMouseLeave = () => {
    setHover(false)
  }

  const handleClick = () => {
    if (!disabled) {
      const talkDetailsPagePath = formatRoute(routes.talkDetails, { slug })
      history.push(talkDetailsPagePath)
    } else {
      customEvent.trigger(TOGGLE__VISIBILITY_SIGNUP_MODAL)
    }
  }

  const handleLoaded = () => {
    setIsLoadedVideo(true)
  }

  if (isLoadedVideo && inHover) {
    videoRef?.current?.play()
  }

  const handleContextMenu = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleStoryClick = (id) => {
    handleClick(id)
    // if (!disabled) {
    //   getCurrentStory(id)
    //   setModalVisibility()
    // } else {
    //   customEvent.trigger(TOGGLE__VISIBILITY_SIGNUP_MODAL)
    // }
  }

  const formatTime = (sec) => {
    const duration = intervalToDuration({ start: 0, end: sec * 1000 })
    const setDateByDuration = set(new Date(0), duration)
    return format(setDateByDuration, duration.hours > 0 ? 'HH:mm:ss' : 'mm:ss')
  }

  const handleToggleContextMenu = () => {
    sendEvent({
      typeId: EVENTS.MEDIA_CLICK,
      mediaId: id,
    })
  }

  const renderSpinner = () => (
    <div
      role="button"
      className="preview-spinner"
      onClick={isPortrait ? () => handleStoryClick(id) : () => handleClick(id)}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  )

  return (
    <div
      role="button"
      className={clsx('item', isPortrait && 'portrait-item')}>
      <LazyLoad height={160}>
        <div className="video-preview">
          {inHover && (
            <video
              ref={videoRef}
              className="video"
              src={getUrlByMediaPrefix.video(video)}
              muted
              loop
              onLoadedData={handleLoaded}
              onClick={isPortrait ? () => handleStoryClick(id) : () => handleClick(id)}
            />
          )}

          <div
            className="image-preview-wrapper"
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}>
            <img
              role="button"
              aria-label="Open content details"
              className={clsx('image-preview',
                inHover && isLoadedVideo && 'hide',
                isLoadedImg && 'lazyload')}
              // className={inHover && isLoadedVideo ? 'image-preview hide' : 'image-preview'}
              // className={inHover && isLoadedVideo && !isLoadedImg ? 'image-preview hide' : 'image-preview'}
              alt=""
              src={getUrlByMediaPrefix.image.middle(img, id)}
              onLoad={() => setIsLoadedImg(true)}
              onContextMenu={handleContextMenu}
              onClick={isPortrait ? () => handleStoryClick(id) : () => handleClick(id)}
            />

            {!isLoadedImg && renderSpinner()}

            {inHover && !isLoadedVideo && renderSpinner()}
          </div>
          {!isPortrait && (
            <div className="share">
              <Share
                slug={slug}
                title={title}
                videoId={id}
                description={shortDescription}
                icon="menu-ellipsis-h"
                isOnlyIcon
                placement="bottom"
              />
            </div>
          )}

          {/* <div className="tags"> */}
          {/*  {!isEmpty(tags) && ( */}
          {/*    <Tag color="danger" text={tags[0].text} spacing="mb-1" /> */}
          {/*  )} */}
          {/* </div> */}

          {isPortrait && !inHover && (
            <>
              <div
                className={clsx('title', checkIsMobileDevice() && 'text-multiline-ellipsis')}
                role="button"
                onClick={isPortrait ? () => handleStoryClick(id) : () => handleClick(id)}>
                {title}
              </div>
              <div className="views">
                <SpriteIcon
                  name="views"
                  size="md"
                />
                {viewsCount}
              </div>
            </>

          )}

          {!isPortrait && !inHover && (
            <>
              <div className="video-duration">{formatTime(duration)}</div>
              <div className="wrapper-progressbar">
                <ProgressBar
                  className="time-mark__progressbar"
                  completed={(currentDuration / duration) * 100}
                  borderRadius="0"
                  isLabelVisible={false}
                  bgColor="#1e6a89"
                  baseBgColor="#30323C"
                  height="4px"
                />
              </div>
            </>
          )}
        </div>
      </LazyLoad>

      {!isPortrait && (
        <a
          onContextMenu={handleToggleContextMenu}
          href={window.location.origin + formatRoute(routes.talkDetails, { slug })}
          onClick={handleContextMenu}
          className="wrapper-info">
          <div className="title">
            {title}
          </div>
          <div className="description">
            {speakersInfo}
          </div>
        </a>
      )}
    </div>
  )
}

CategoryItem.propTypes = {
  id: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  viewsCount: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  currentDuration: PropTypes.number.isRequired,
  shortDescription: PropTypes.string.isRequired,
  speakersInfo: PropTypes.string.isRequired,
  sendEvent: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // tags: PropTypes.array,
  isPortrait: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  // setModalVisibility: PropTypes.func.isRequired,
  // getCurrentStory: PropTypes.func.isRequired,
}

CategoryItem.defaultProps = {
  // tags: [],
  isPortrait: false,
}

const actionsToProps = {
  sendEvent,
}

export default connect(null, actionsToProps)(CategoryItem)
