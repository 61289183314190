import * as Yup from 'yup'
import { useFormik } from 'formik'

import { formFields } from './constants'

export default ({
  onSubmit,
}) => useFormik({
  initialValues: {
    [formFields.chatMessage]: '',
  },

  validationSchema: Yup.object({
    [formFields.chatMessage]: Yup.string()
      .max(200, 'Limit 200 symbols')
      .required(' '),
  }),

  onSubmit(values) {
    onSubmit(values)
  },
})
