import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

/* hooks */
import useDidMount from 'hooks/lifecycle/use-did-mount'

import classes from '../NavMenu.module.scss'

const NavItem = ({ navItem, selectItem, isActive }) => {

  useDidMount(() => {
    isActive && selectItem(navItem)
  })

  return (
    <div
      className={clsx(classes.navItem, isActive && classes.active, navItem.isSoon && classes.soon)}
      role="button"
      onClick={!navItem.isSoon && (() => selectItem(navItem))}>
      {navItem.title}
    </div>
  )
}

NavItem.propTypes = {
  navItem: PropTypes.shape({
    title: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    isSoon: PropTypes.bool.isRequired,
  }).isRequired,
  selectItem: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
}
export default NavItem
