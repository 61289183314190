import { CLEAR_STORE_ACTIONS } from '../constants'

export const clearStore = (blacklist = ['app', '_persist']) => (
  dispatch,
  getState,
) => {
  const state = getState()

  for (const key in state) {
    if (!blacklist.includes(key) && state.hasOwnProperty(key)) {
      state[key] = undefined
    }
  }

  dispatch({
    type: CLEAR_STORE_ACTIONS.CLEAR_STORE.SYSTEM,
    payload: state,
  })
}
