import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

const Sentry3dParty = () => {
  Sentry.init({
    dsn: 'https://7a655e985ca04c01bd54a6677f17c8f2@o201605.ingest.sentry.io/5715489',
    integrations: [new Integrations.BrowserTracing()],
    environment: 'new-infra',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.02,
    ignoreErrors: [
      // Facebook borked
      'fb_xd_fragment',
      // Other errors
      'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
      // Error if fail login
      'Request failed with status code 422',
      'Non-Error promise rejection captured with value:',
      // [Safari] Error playing video with muted
      'AbortError: The operation was aborted.',
      // [Safari] Error PiP when scrolling page
      'The request is not triggered by a user activation.',
      // Video error
      'AbortError: The play() request was interrupted by a call to pause().',
    ],
    denyUrls: [
      // Intercom,
      /js\.intercomcdn\.com/i,
      /widget\.intercom\.io/i,
      // Typeform
      /((form|embed)\.)?typeform\.com/i,
      // Profitwell
      /((public|www2)\.)?profitwell\.com/i,
      // Hotjar
      /((in|www)\.)?hotjar\.com/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Google Tag Manager
      /(https?:\/\/www\.googletagmanager\.com)/i,
      // Google flakiness
      /\/(gtm|ga|analytics)\.js/i,
      /(https?:\/\/www\.google-analytics\.com)/,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Firefox extensions
      /^resource:\/\//i,
      // Other plugins
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  })

  return null
}

export default Sentry3dParty
