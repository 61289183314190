import React from 'react'
import SpriteIcon from 'components/icons/SpriteIcon'

const FooterV2 = () => (
  <div className="footer__inner-center">
    <SpriteIcon
      name="learners-logo"
      size="logo"
    />

    <div className="wrapper-bottom-text">
      <p className="paragraph-primary">
        Join the
        <a
          className="mx-1"
          rel="noreferrer noopener"
          href="https://landing.joinlearners.com"
          target="_blank">
          Learners Community
        </a>
        or sign up for our
        <a
          className="mx-1"
          rel="noreferrer noopener"
          href="https://www.updates.joinlearners.com"
          target="_blank">
          Community Updates
        </a>
      </p>
    </div>

    <div className="wrapper-bottom-text">
      <p className="paragraph-primary">
        Connect with us on:
        <a
          className="mx-1"
          rel="noreferrer noopener"
          href="https://twitter.com/JoinLearners"
          target="_blank">
          Twitter
        </a>
        |
        <a
          className="mx-1"
          rel="noreferrer noopener"
          href="https://www.linkedin.com/company/11234935"
          target="_blank">
          LinkedIn
        </a>
        |
        <a
          className="mx-1"
          rel="noreferrer noopener"
          href="https://www.tiktok.com/@joinlearners"
          target="_blank">
          TikTok
        </a>
        |
        <a
          className="mx-1"
          rel="noreferrer noopener"
          href="https://blog.joinlearners.com"
          target="_blank">
          The Learners Blog
        </a>
        |
        <a
          className="mx-1"
          rel="noreferrer noopener"
          href="https://join.slack.com/t/researchbylearners/shared_invite/zt-10c12rj38-fZ_4gCgCg1wWi_RTccGi2Q"
          target="_blank">
          The Research by Learners Slack Community
        </a>
      </p>
    </div>
    <div className="wrapper-bottom-text">
      <p className="paragraph-primary">
        <a
          className="mx-1"
          rel="noreferrer noopener"
          href="https://www.aboutus.joinlearners.com"
          target="_blank">
          About Us
        </a>
        |
        <a
          className="mx-1"
          rel="noreferrer noopener"
          href="https://www.pastprojects.joinlearners.com"
          target="_blank">
          Past Projects
        </a>
        |
        <a
          className="mx-1"
          rel="noreferrer noopener"
          href="https://www.presskit.joinlearners.com"
          target="_blank">
          Press Kit
        </a>
        |
        <a
          className="mx-1"
          rel="noreferrer noopener"
          href="https://www.codeofconduct.joinlearners.com"
          target="_blank">
          Code of Conduct
        </a>
      </p>
    </div>
  </div>
)

export default FooterV2
