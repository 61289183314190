import React, {} from 'react'
import './styles.scss'
import * as PropTypes from 'prop-types'

/* components */
import Input from 'components/ui/Inputs/Base'
import Button from 'components/ui/Button/base'

/* constants */
import showValidation from 'helpers/show-validation'
import { formFields } from './constants'

const EnterCodeTemplate = ({ handleSubmit, handleChange, handleBlur, values, errors, touched, responseWaiting, submitCount }) => {
  const showError = showValidation({
    errors,
    touched,
    values,
    submitCount,
    field: formFields.confirmCode,
  })

  return (
    <section className="forgot-password">
      <div className="forgot-password__wrapper">
        <div className="max-width-400">
          <div className="forgot-password__inner">
            <h2 className="forgot-password__description">
              Enter Your Confirmation Code
            </h2>

            <p className="forgot-password__section-title">
              A 6-digit recovery code has been sent to the email address you provided. Please enter below.
            </p>

            <form onSubmit={handleSubmit}>
              <Input
                label="Enter code"
                id={formFields.confirmCode}
                type="text"
                variant="secondary"
                name={formFields.confirmCode}
                placeholder="6-digit recovery code"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmCode}
                error={showError ? errors.confirmCode : null}
                errorInput={showError ? 'errorInput' : null}
                errorLabel={showError ? 'errorLabel' : null}
              />

              <Button
                text="Confirm code"
                type="submit"
                variant="primary"
                size="medium"
                disabled={responseWaiting}
              />

            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

EnterCodeTemplate.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.string),
  errors: PropTypes.objectOf(PropTypes.string),
  touched: PropTypes.objectOf(PropTypes.bool),
  submitCount: PropTypes.number.isRequired,
  responseWaiting: PropTypes.bool,
}

EnterCodeTemplate.defaultProps = {
  errors: {},
  values: {},
  touched: {},
  responseWaiting: false,
}

export default EnterCodeTemplate
