import isMobile from './check-is-mobile-device'

export const getSlidesCount = (defaultValue, skipMobile, itemsCount) => {
  if (isMobile() && !skipMobile) {
    return 1
  }

  if (itemsCount < defaultValue) {
    return itemsCount
  }

  return defaultValue
}
