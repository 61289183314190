import PropTypes from 'prop-types'

/* helpers */
import updateObject from 'state-manager/reducers/helpers/update-object'

/* constants */
import { AUTH_ACTIONS, USER_ACTIONS } from 'state-manager/constants'
import { ROLES } from 'constants/roles'

/* assets */
import avatar from 'assets/images/default-user-photo.png'

const initialState = {
  isAuthorized: false,
  authorizationData: {
    token_type: '',
    access_token: '',
  },
  isProfileFilled: false,
  isAgreeWithCodeOfConduct: false,
  mobileBannerLastClosed: '',

  role: ROLES.UNAUTHORIZED, // free, paid, team, admin, unauthorized
  permissions: [],

  id: null,
  email: '',
  hashedEmail: '',
  handle: '',
  firstName: '',
  lastName: '',
  photo: avatar,

  city: '',
  company: '',
  country: '',

  disciplineId: null,
  yearsOfExperienceId: null,

  plan: null,
  hasSubscription: false,

  // subscriptionInfo: {}, move to separate entity

  // isUpdate: false,
  signUpStep: 1,
  signUpTimer: 0,
  bookmarks: [],

  responseWaiting: false,
}

export const types = {
  isAuthorized: PropTypes.bool,
  authorizationData: PropTypes.shape({
    token_type: PropTypes.string.isRequired,
    access_token: PropTypes.string.isRequired,
  }),

  // verified: PropTypes.bool,
  mobileBannerLastClosed: PropTypes.string.isRequired,

  role: PropTypes.oneOf(['', ...Object.values(ROLES)]),
  permissions: PropTypes.arrayOf(PropTypes.string),

  isProfileFilled: PropTypes.bool,
  isAgreeWithCodeOfConduct: PropTypes.bool,

  id: PropTypes.number,
  email: PropTypes.string,
  hashedEmail: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  photo: PropTypes.string,

  city: PropTypes.string,
  company: PropTypes.string,
  country: PropTypes.string,

  disciplineId: PropTypes.number,
  yearsOfExperienceId: PropTypes.number,

  plan: PropTypes.number,
  hasSubscription: PropTypes.bool,

  // subscriptionInfo: PropTypes.object,
  signUpStep: PropTypes.number.isRequired,
  signUpTimer: PropTypes.number,
  bookmarks: PropTypes.array,
  responseWaiting: PropTypes.bool,

}

export default (state = initialState, action) => {
  switch (action.type) {

    case AUTH_ACTIONS.SIGN_IN.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case AUTH_ACTIONS.SIGN_IN.SUCCESS:
      return updateObject(state, {
        isAuthorized: action.payload.isAuthorized,
        authorizationData: action.payload.authorizationData,

        responseWaiting: false,
      })

    case AUTH_ACTIONS.SIGN_IN.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case AUTH_ACTIONS.SIGN_OUT.SYSTEM:
      return updateObject(state, initialState)

    case USER_ACTIONS.DATA_GET.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case USER_ACTIONS.DATA_GET.SUCCESS:
      return updateObject(state, {
        role: action.payload.user_role,
        permissions: action.payload.permissions,
        signUpStep: action.payload.discipline_id ? 3 : 2,
        isProfileFilled: action.payload.user_role === ROLES.ADMIN ? true : !!action.payload.discipline_id,

        id: action.payload.id,
        email: action.payload.email,
        hashedEmail: action.payload.hashed_email,
        firstName: action.payload.first_name,
        lastName: action.payload.last_name,
        photo: action.payload.photo || initialState.photo,

        handle: action.payload.handle,
        city: action.payload.city,
        company: action.payload.company,
        country: action.payload.country,
        disciplineId: action.payload.discipline_id,
        yearsOfExperienceId: action.payload.years_of_experience_id,

        plan: action.payload.plan, // TODO check existence
        hasSubscription: action.payload.has_subscription,
        // subscriptionInfo
        bookmarks: [action.payload.bookmarks],

        responseWaiting: false,
      })

    case USER_ACTIONS.DATA_GET.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case USER_ACTIONS.PLAN_UPDATE.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
        isUpdate: true,
      })

    // TODO check -> maybe remove
    case USER_ACTIONS.PLAN_UPDATE.SUCCESS:
      return updateObject(state, {
        responseWaiting: false,
        isUpdate: false,
      })

    case USER_ACTIONS.TIMER_UPDATE.SYSTEM:
      return updateObject(state, {
        signUpTimer: state.signUpTimer + 1,
      })

    case USER_ACTIONS.TIMER_CLEAR.SYSTEM:
      return updateObject(state, {
        signUpTimer: initialState.signUpTimer,
      })

    case USER_ACTIONS.AGREE_WITH_CHAT_RULES.SYSTEM:
      return updateObject(state, {
        isAgreeWithCodeOfConduct: true,
      })

    case USER_ACTIONS.LAST_CLOSED_BANNER_SET.SUCCESS:
      return updateObject(state, {
        mobileBannerLastClosed: action.payload.date,
      })

    default:
      return state
  }
}
