import React from 'react'
import PropTypes from 'prop-types'
import SpriteIcon from 'components/icons/SpriteIcon'

const CustomArrow = ({ className, onClick, isPrevArrow, isBold }) => {
  const currentArrow = {
    prev: isBold ? 'slider-prev' : 'slider-arrow-left',
    next: isBold ? 'slider-next' : 'slider-arrow-right',
  }

  return (
    <div
      aria-label={isPrevArrow ? 'Carousel previous button' : 'Carousel next button'}
      className={className}
      onClick={onClick}
      role="button">
      <SpriteIcon
        withState
        name={isPrevArrow ? currentArrow.prev : currentArrow.next}
        size="sliderMd"
      />
    </div>
  )
}

CustomArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  isPrevArrow: PropTypes.bool,
  isBold: PropTypes.bool,
}

CustomArrow.defaultProps = {
  className: '',
  onClick: null,
  isPrevArrow: false,
  isBold: false,
}

export default CustomArrow
