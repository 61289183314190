import React from 'react'
import { connect } from 'react-redux'

/* types */
import { types as userDataTypes } from 'state-manager/reducers/user-data'

/* styles */
import './styles.scss'

/* components */
import Categories from 'components/ui/Categories'

const BookmarksPage = ({
  udIsProfileFilled,
  bookmarks,
}) => (
  <main>
    <div className="dashboard-category-page">
      <div className="dashboard-category-page__wrapper">
        <div className="dashboard-category-page__inner">
          {bookmarks[0]?.podcasts.length ? (
            <Categories
              isPortraitCategories
              categories={bookmarks}
              disabled={!udIsProfileFilled}
            />
          ) : (
            <div className="container">
              <h1 className="mt-2">{'You don\'t have any Bookmarks yet'}</h1>
            </div>
          )}

        </div>
      </div>
    </div>
  </main>
)

BookmarksPage.propTypes = {
  udIsProfileFilled: userDataTypes.isProfileFilled.isRequired,
  bookmarks: userDataTypes.bookmarks.isRequired,
}

const mapStateToProps = (state) => ({
  udIsProfileFilled: state.userData.isProfileFilled,
  bookmarks: state.userData.bookmarks,
})

export default connect(mapStateToProps, null)(BookmarksPage)
