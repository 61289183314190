import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import SpriteIcon from 'components/icons/SpriteIcon'

/* state-manages */
import { history } from 'state-manager/store'

const SidebarItem = ({
  label,
  route,
  isActive,
}) => (
  <div
    role="button"
    className={clsx('sidebar__label', isActive && 'active')}
    onClick={() => history.push(route)}>
    <span className="label">{label}</span>

    {isActive && (
      <div className="arrow">
        <SpriteIcon name="arrow" size="sm" />
      </div>
    )}
  </div>
)

SidebarItem.propTypes = {
  label: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
}

SidebarItem.defaultProps = {
  isActive: false,
}

export default SidebarItem
