import React, { useState } from 'react'

/* hooks */
import _useCustomEventListener from 'hooks/use-custom-event-listener'
import _useEventListener from 'hooks/use-event-listener'

/* constants */
import { TOGGLE__VISIBILITY__SPINNER } from 'constants/custom-events'
import { SHOW, HIDE } from 'constants/visibility-actions'

/* styles */
import 'components/GlobalSpinner/styles.scss'

const GlobalSpinner = () => {

  const [visible, setVisible] = useState(false)

  const _hideSpinner = () => {
    if (visible) {
      setVisible(false)
    }
  }

  const _hideSpinnerDueInactivity = () => {
    setTimeout(_hideSpinner, 1000 * 60 * 2)
  }

  const _showSpinner = () => {
    if (!visible) {
      setVisible(true)
    }

    _hideSpinnerDueInactivity()
  }

  const _toggleSpinnerVisibility = (action) => {
    switch (action) {
      case SHOW:
        _showSpinner()
        break
      case HIDE:
        _hideSpinner()
        break
      default:
        break
    }
  }

  const _onToggleSpinnerVisibility = (e) => {
    const action = e.detail?.action
    _toggleSpinnerVisibility(action)
  }

  _useEventListener(window, 'beforeunload', _showSpinner)
  _useCustomEventListener(TOGGLE__VISIBILITY__SPINNER, _onToggleSpinnerVisibility)

  if (!visible) {
    return null
  }

  /* render same item */
  const renderSpinnerItems = () => (
    Array.from(Array(12)).map((item, key) => (
      <div className="spinner-item" key={key} />
    ))
  )

  return (
    <div className="global-spinner">
      <div className="spinner-box">
        {/* Old spinner */}
        {/* <div className="pulse-container"> */}
        {/*  <div className="pulse-bubble pulse-bubble-1" /> */}
        {/*  <div className="pulse-bubble pulse-bubble-2" /> */}
        {/*  <div className="pulse-bubble pulse-bubble-3" /> */}
        {/* </div> */}
        <div className="spinner-container">
          {renderSpinnerItems()}
        </div>
      </div>
      {/* <SpriteIcon name="logo" size="lg" /> */}
      <div className="global-spinner__text">
        Loading your experience
      </div>

    </div>
  )

}

export default GlobalSpinner
