import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'

/* types */
import { types as contentDataTypes } from 'state-manager/reducers/content'
import { types as userDataTypes } from 'state-manager/reducers/user-data'

/* actions */
import { getAds, sendEvent } from 'state-manager/actions/content'

/* helpers */
import isMobile from 'helpers/check-is-mobile-device'
import getUrlByMediaPrefix from 'helpers/get-url-by-media-prefix'

/* hooks */
import _useDidMount from 'hooks/lifecycle/use-did-mount'

/* constants */
import { PERMISSIONS } from 'constants/permissions'
import { EVENTS } from 'constants/events'
import { ROLES } from 'constants/roles'

/* styles */
import classes from './Ads.module.scss'

const Ads = ({
  ads,
  getAds,
  udPermissions,
  sendEvent,
  udRole,
}) => {
  const [isShown, setIsShown] = useState(false)

  _useDidMount(() => {
    if (udPermissions.includes(PERMISSIONS.CAN_SEE_ADS) || udRole === ROLES.UNAUTHORIZED) {
      setIsShown(true)
      getAds()
    }
  })

  const handleClick = () => {
    sendEvent({
      typeId: EVENTS.AD_CLICK,
      mediaId: ads[0].id,
    })
  }

  if (ads.length === 0) {
    return null
  }

  if (!isShown) {
    return null
  }

  return (
    <section className={classes.ads}>
      <div className="container">
        <div className={classes.adsWrapper}>
          <div className={classes.adsInner}>
            <a
              rel="noreferrer noopener"
              href={ads[0].url}
              target="_blank">

              <img
                role="button"
                onClick={handleClick}
                src={isMobile() ? getUrlByMediaPrefix.ads(ads[0].mobile_img) : getUrlByMediaPrefix.ads(ads[0].desktop_img)}
                alt={ads[0].alt_text}
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

Ads.propTypes = {
  ads: contentDataTypes.ads.isRequired,
  udPermissions: userDataTypes.permissions.isRequired,
  udRole: userDataTypes.role.isRequired,
  getAds: PropTypes.func,
  sendEvent: PropTypes.func.isRequired,
}

Ads.defaultProps = {
  getAds: null,
}

const mapStateToProps = (state) => ({
  udPermissions: state.userData.permissions,
  udRole: state.userData.role,
  ads: state.content.ads,
})

const actionsToProps = {
  getAds,
  sendEvent,
}

export default connect(mapStateToProps, actionsToProps)(Ads)
