import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import * as PropTypes from 'prop-types'
import isEmpty from 'lodash.isempty'
import { connect } from 'react-redux'
import { isPast, parseISO } from 'date-fns'

import './styles.scss'

/* types */
import { types as contentDataTypes } from 'state-manager/reducers/content'
import { types as userDataTypes } from 'state-manager/reducers/user-data'

/* constants */
import { ROLES } from 'constants/roles'
import { DATE_MASK } from 'constants/date-mask'
import { TOGGLE__VISIBILITY_DOWNLOAD_APP_MODAL, TOGGLE__VISIBILITY_SIGNUP_MODAL } from 'constants/custom-events'
import { EVENTS } from 'constants/events'

/* hooks */
import _useDidMount from 'hooks/lifecycle/use-did-mount'
import _useDidUpdate from 'hooks/lifecycle/use-did-update'

/* actions */
import {
  addToBookmarks,
  deleteFromBookmarks,
  getAvailableFilters,
  getSingleVideo,
  getSingleVideoStats,
  sendEvent,
} from 'state-manager/actions/content'

/* helpers */
import renderHtml from 'helpers/render-html'
import getDateByMask from 'helpers/get-date-by-mask'
import isMobile from 'helpers/check-is-mobile-device'

/* utils */
import customEvent from 'utils/custom-event'

/* components */
import VideoPlayer from 'components/ui/VideoPlayer'
import SpriteIcon from 'components/icons/SpriteIcon'
import Ads from 'components/ui/Ads'
import Tag from 'components/ui/Tag'
import OpenGraph from 'components/OpenGraph'
import Share from 'components/ui/Share'

/* helpers */
import getUrlByMediaPrefix from 'helpers/get-url-by-media-prefix'
import ThoughtsPage from '../thoughts'

const DetailsPage = ({
  videos,
  udRole,
  udIsProfileFilled,
  currentVideoData,
  filters,
  getAvailableFilters,
  getSingleVideo,
  // addToBookmarks,
  // bookmarks,
  // deleteFromBookmarks,
  getSingleVideoStats,
  currentStats,
  sendEvent,
}) => {
  const params = useParams()
  const [isFirstPage] = useState(isEmpty(videos))

  _useDidMount(() => {
    if (isEmpty(videos) || isEmpty(currentVideoData)) {
      getSingleVideo(params.slug)
    }

    if (!isEmpty(currentVideoData)) {
      getSingleVideoStats(params.slug)
    }

    if (isEmpty(filters)) {
      getAvailableFilters()
    }
  })

  _useDidUpdate(() => {
    if (currentVideoData.id) {
      sendEvent({
        typeId: EVENTS.MEDIA_CLICK,
        mediaId: currentVideoData.id,
      })
    }
  }, [currentVideoData.id])

  // _useDidUpdate(() => {
  //   if (currentVideoData.orientation === 1) {
  //     customEvent.trigger(SET__DARK_COLOR_THEME)
  //   }
  // }, [currentVideoData.orientation])

  if (isEmpty(currentVideoData)) {
    return null
  }

  if (isEmpty(filters)) {
    return null
  }

  const fullVideos = []
  if (currentVideoData) {
    for (const key in currentVideoData.media.urls) {
      if (currentVideoData.media.urls.hasOwnProperty(key)) {
        fullVideos.push(
          {
            src: getUrlByMediaPrefix.video(currentVideoData.media.urls[key]),
            type: 'video/mp4',
            quality: `${key.substr(1, key.length)}p`,
          },
        )
      }
    }
  }

  const subtitles = currentVideoData.media.subtitles.length ?
    currentVideoData.media.subtitles.map((sub) => ({
      src: getUrlByMediaPrefix.subtitles(sub.url),
      label: sub.lang,
      default: false,
    })) : null
  // const subtitleLangs = subtitles ?
  //   subtitles
  //     .map((lang) => (lang.label))
  //     .join(', ') : null

  const realiseDate = parseISO(currentVideoData.realise_date)
  const isReleased = isPast(realiseDate)
  const previewVideos = []

  previewVideos.push({
    src: getUrlByMediaPrefix.video(currentVideoData.preview_link),
    type: 'video/mp4',
    quality: '360p',
  })

  // const getLevel = (filters) => {
  //   if (currentVideoData.level) {
  //     const level = filters.levels.find((level) => level.id === currentVideoData.level)
  //     return level.name
  //   }
  //   return null
  // }

  // const tagsTitles = currentVideoData.tags
  //   .map((tag) => (tag.text))
  //   .join(', ')

  const topbarItems = [
    {
      icon: 'views',
      value: !isEmpty(currentStats) ? currentStats.clicks : 0,
      description: '',
      isset: true,
    },
    {
      icon: 'like-light',
      value: `${!isEmpty(currentStats) ? currentStats.review_count : 0}`,
      description: '',
      isset: true,
      iconsWithState: true,
      actions: () => customEvent.trigger(TOGGLE__VISIBILITY_DOWNLOAD_APP_MODAL),
    },
    {
      icon: 'comment-light',
      value: !isEmpty(currentVideoData) ? currentVideoData.comments_count : 0,
      description: '',
      isset: true,
      iconsWithState: true,
      actions: () => customEvent.trigger(TOGGLE__VISIBILITY_DOWNLOAD_APP_MODAL),
    },
  ]

  // const sidebarItems = [
  //   {
  //     icon: 'headphones-light',
  //     title: 'Audio',
  //     description: currentVideoData.content_language,
  //     isset: !!currentVideoData.content_language,
  //   },
  //   {
  //     icon: 'subtitles-light',
  //     description: subtitleLangs,
  //     isset: !!subtitleLangs,
  //   },
  //   {
  //     icon: 'clip-light',
  //     description: 'Downloadable Slides',
  //     isset: !!currentVideoData.downloadable_resource,
  //   },
  //   {
  //     icon: 'level-light',
  //     title: 'Level',
  //     description: <Tag color="success" text={currentVideoData.level || ''} spacing="ml-1" />,
  //     isset: !!currentVideoData.level,
  //   },
  //   {
  //     icon: 'comment-light2',
  //     title: 'Format',
  //     description: currentVideoData.content_format,
  //     isset: !!currentVideoData.content_format,
  //   },
  //   {
  //     icon: 'tag',
  //     description: tagsTitles,
  //     isset: !!tagsTitles,
  //   },
  // ]

  const renderItems = (items) => (
    items.filter((item) => item.isset).map((item, index) => (
      <div className="item" key={index} onClick={item.actions} role="button">
        <SpriteIcon
          name={item.icon}
          size="md"
          className="mr-2"
          withState={item.iconsWithState}
        />

        {item.value !== undefined && (
          <div className="mr-1">
            {item.value || 0}
          </div>
        )}

        {item.title && (
          `${item.title}: `
        )}

        {item.description}
      </div>
    ))
  )

  const renderDownloadableResource = () => {
    const getResourceFile = (link) => {
      if (udRole === ROLES.UNAUTHORIZED) {
        customEvent.trigger(TOGGLE__VISIBILITY_SIGNUP_MODAL)
      } else {
        window.open(getUrlByMediaPrefix.downloadableResource(link), '_blank')
      }
    }

    // const handleProClick = () => {
    //   if (udRole === ROLES.UNAUTHORIZED) {
    //     customEvent.trigger(TOGGLE__VISIBILITY_SIGNUP_MODAL)
    //   } else {
    //     history.push(routes.selectSubscription)
    //   }
    // }

    if (currentVideoData.downloadable_resource) {
      /* hide pro features */
      // if (udRole === ROLES.FREE || udRole === ROLES.UNAUTHORIZED) {
      //   return (
      //     <OverlayTrigger
      //       key="bottom"
      //       placement="bottom"
      //       overlay={(
      //         <Popover id="popover-lock-download-resources">
      //           <div className="popover-content">
      //             Access the Additional Resources feature with a Learners Pro Account.
      //           </div>
      //         </Popover>
      //       )}>
      //       <div
      //         role="button"
      //         className="dashboard-details-page__resources">
      //         <div className="resources-title">
      //           <SpriteIcon
      //             name="clip"
      //             size="md"
      //             className="mr-2"
      //           />
      //           <span>Download additional resources</span>
      //         </div>
      //
      //         <Tag
      //           onClick={handleProClick}
      //           text="pro"
      //           iconName="lock"
      //           color="tertiary"
      //         />
      //       </div>
      //     </OverlayTrigger>
      //   )
      // }

      return (
        <div className="dashboard-details-page__resources homepage__light">
          <div className="resources-title">
            <SpriteIcon
              name="clip"
              size="md"
              className="mr-2"
            />
            <span>Download additional resources</span>
          </div>

          <div
            role="button"
            className="resource-button"
            onClick={() => getResourceFile(currentVideoData.downloadable_resource)}>
            <span>Download</span>

            <SpriteIcon
              name="download"
              size="md"
              className="ml-2"
            />
          </div>
        </div>
      )
    }

    return null
  }
  // const isBookmarked = () => bookmarks[0]?.podcasts.some((podcast) => podcast.id === currentVideoData.id)
  // const onAddVideoToBookmarks = () => {
  //   addToBookmarks({
  //     bookmarkID: bookmarks[0].id,
  //     videoID: currentVideoData.id,
  //   })
  // }
  // const onDeleteVideoFromBookmarks = () => {
  //   deleteFromBookmarks({
  //     bookmarkID: bookmarks[0].id,
  //     videoID: currentVideoData.id,
  //   })
  // }

  // const handleBookmarkClick = () => {
  //   if (udRole === ROLES.UNAUTHORIZED) {
  //     customEvent.trigger(TOGGLE__VISIBILITY_SIGNUP_MODAL)
  //   } else {
  //     isBookmarked() ? onDeleteVideoFromBookmarks() : onAddVideoToBookmarks()
  //   }
  // }

  const renderTopSubtitle = () => {
    if (currentVideoData.orientation === 0) {
      return 'A Thought by '
    }

    if (!isEmpty(currentVideoData.creator)) {
      return 'By '
    }

    return 'A talk by '
  }

  const renderAuthor = () => {
    if (currentVideoData.speakers_info) {
      return currentVideoData.speakers_info
    }

    if (!isEmpty(currentVideoData.creator)) {
      return `${currentVideoData.creator.first_name} ${currentVideoData.creator.last_name}`
    }
  }

  const renderVideoPlayer = () => (
    <div className="dashboard-details-page__player">
      <VideoPlayer
        disabled={!udIsProfileFilled}
        mediaId={currentVideoData.id}
        subtitles={subtitles ? [{ label: 'None' }, ...subtitles] : null}
        videos={isReleased ? fullVideos : previewVideos}
        isPortrait={currentVideoData.orientation === 0}
        videoDuration={currentVideoData.duration}
        currentDuration={currentStats.current_duration}
        isMuted={isFirstPage}
      />
    </div>
  )

  // const renderBottomBar = () => (
  //   <div className="dashboard-details-page__bottom-bar">
  //     {!isEmpty(currentStats) && (
  //       <div className="reviews-wrapper d-flex align-items-center">
  //         <ReviewsButtons
  //           isLiked={!!currentStats.is_liked}
  //           disabled={udRole === ROLES.UNAUTHORIZED}
  //           mediaId={currentVideoData.id}
  //           slug={currentVideoData.slug}
  //         />
  //       </div>
  //     )}
  //     <div className="video-options">
  //       <Share
  //         slug={currentVideoData.slug}
  //         videoId={currentVideoData.id}
  //         description={currentVideoData.short_description}
  //         title={currentVideoData.title}
  //         placement={isMobile() ? 'bottom' : 'left'}
  //         iconsWithState
  //       />
  //       {currentVideoData.orientation === 1 && (
  //         <Bookmark
  //           onClick={handleBookmarkClick}
  //           isActive={udRole !== ROLES.UNAUTHORIZED && isBookmarked()}
  //         />
  //       )}
  //     </div>
  //   </div>
  // )

  const renderDetailsPage = () => (
    <main className="homepage--light">
      <section className="dashboard-details-page">

        <Ads />

        <div className="dashboard-details-page__wrapper">
          <div className="container">
            <div className="dashboard-details-page__inner">
              <div className="dashboard-details-page__main">
                {isMobile() && (
                  <>
                    {renderVideoPlayer()}
                    {/* {renderBottomBar()} */}
                  </>
                )}

                <h1 className="dashboard-details-page-title">
                  {currentVideoData.title}
                </h1>

                <div className="dashboard-details-page-author">
                  {renderTopSubtitle()}
                  <span>{renderAuthor()}</span>
                  {isReleased ? (
                    <div className="sidebar__title">
                      <Tag color="primary" text="Premiered:" spacing="mr-1" capitalize />
                      <span className="font-bold">
                        {getDateByMask({
                          date: currentVideoData.realise_date,
                          mask: DATE_MASK.PRIMARY,
                        })}
                      </span>
                    </div>
                  ) : (
                    <div className="sidebar__title">
                      <Tag color="primary" text="Will premiere:" spacing="mr-1" capitalize />
                      <span className="font-bold">
                        {getDateByMask({
                          date: currentVideoData.preview_date,
                          mask: DATE_MASK.PRIMARY,
                        })}
                      </span>
                    </div>
                  )}
                </div>

                <div className="dashboard-details-page__topbar">
                  {renderItems(topbarItems)}
                  <div className="share-stat">
                    <Share
                      slug={currentVideoData.slug}
                      videoId={currentVideoData.id}
                      description={currentVideoData.short_description}
                      title={currentVideoData.title}
                      isOnlyIcon
                      iconsWithState
                    />
                    <div className="share-stat__value">
                      {!isEmpty(currentStats) ? currentStats.shares : 0}
                    </div>
                  </div>
                </div>

                {!isMobile() && (
                  <>
                    {renderVideoPlayer()}
                    {/* {renderBottomBar()} */}
                  </>
                )}

                {renderDownloadableResource()}

                <div className="dashboard-details-page-description">
                  {renderHtml(currentVideoData.description)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )

  return (
    <>
      <OpenGraph
        title={currentVideoData.title}
        description={currentVideoData.short_description}
        img={getUrlByMediaPrefix.image.middle(currentVideoData.feature_image, currentVideoData.id)}
        videoUrl={getUrlByMediaPrefix.video(currentVideoData.preview_link)}
        profile={currentVideoData.creators || null}
        keywords={!isEmpty(currentVideoData.meta.keywords) ? currentVideoData.meta.keywords : null}
        type="video.other"
      />
      {currentVideoData.orientation === 0 ? (
        <ThoughtsPage
          currentVideoData={currentVideoData}
          udIsProfileFilled={udIsProfileFilled}
          isFirstPage={isFirstPage}
          videos={isReleased ? fullVideos : previewVideos}
          subtitles={subtitles ? [{ label: 'None' }, ...subtitles] : null}
          currentDuration={currentStats.current_duration}
          currentStats={currentStats}
        />
      ) :
        renderDetailsPage()}
    </>
  )
}

DetailsPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentVideoData: PropTypes.object,
  videos: contentDataTypes.videos.isRequired,
  filters: contentDataTypes.filters.isRequired,
  udRole: userDataTypes.role.isRequired,
  udIsProfileFilled: userDataTypes.isProfileFilled.isRequired,
  getAvailableFilters: PropTypes.func.isRequired,
  getSingleVideo: PropTypes.func.isRequired,
  // addToBookmarks: PropTypes.func.isRequired,
  // bookmarks: userDataTypes.bookmarks.isRequired,
  // deleteFromBookmarks: PropTypes.func.isRequired,
  getSingleVideoStats: PropTypes.func.isRequired,
  currentStats: PropTypes.shape({
    id: PropTypes.number,
    clicks: PropTypes.number,
    shares: PropTypes.number,
    positive_review_percent: PropTypes.number,
    review_count: PropTypes.number,
    current_duration: PropTypes.number,
    is_liked: PropTypes.number,
  }),
  sendEvent: PropTypes.func.isRequired,
}

DetailsPage.defaultProps = {
  currentVideoData: {},
  currentStats: {},
}

const mapStateToProps = (state, ownProps) => ({
  videos: state.content.videos,
  currentVideoData: state.content.videos.find((video) => video.slug === ownProps.match.params.slug),
  filters: state.content.filters,
  udRole: state.userData.role,
  udIsProfileFilled: state.userData.isProfileFilled,
  bookmarks: state.userData.bookmarks,
  currentStats: state.content.statistics.find((item) => item.slug === ownProps.match.params.slug),
})

const actionsToProps = {
  getSingleVideo,
  getAvailableFilters,
  sendEvent,
  addToBookmarks,
  deleteFromBookmarks,
  getSingleVideoStats,
}
export default connect(mapStateToProps, actionsToProps)(DetailsPage)
