import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import Modal from 'react-modal'

/* routes */
import routes from 'routes'

/* state-manager */
import { history } from 'state-manager/store'

/* hooks */
import _useCustomEventListener from 'hooks/use-custom-event-listener'

/* actions */
import { resetPassword, forgotPasswordRequestCode, forgotPasswordCheckCode } from 'state-manager/actions/auth'

/* constants */
import {
  HIDE_FORGOT_PASSWORD_MODAL,
  // HIDE_SIGN_IN_MODAL,
  NEXT_STEP__FORGOT_PASSWORD,
  SHOW_FORGOT_PASSWORD_MODAL,
  // SHOW_SIGN_IN_MODAL
} from 'constants/custom-events'
import signUpStep1Cover from '../../../assets/images/sign_up_step1_cover.png'
import { CloseModalButton } from '../../ui/CloseModalButton'
import { formFields } from './constants'

/* template */
import ForgotPasswordTemplate from './forgot-password-template'
import EnterCodeTemplate from './enter-code-template'
import CreatePasswordTemplate from './create-password-template'

/* styles */
import '../SignUp/styles.scss'

const ForgotPasswordContainer = ({ forgotPasswordRequestCode, forgotPasswordCheckCode, resetPassword, responseWaiting }) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleClose = () => setIsOpen(false)
  const [step, setStep] = useState(0)

  _useCustomEventListener(SHOW_FORGOT_PASSWORD_MODAL, () => setIsOpen(true))
  _useCustomEventListener(HIDE_FORGOT_PASSWORD_MODAL, () => setIsOpen(false))
  const nextStep = () => {
    if (step < 2) {
      setStep(step + 1)
    } else {
      setStep(0)
    }
  }

  const emailFormik = useFormik({
    initialValues: {
      [formFields.email]: '',
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .email('Enter a valid email')
        .required('Email is required'),
    }),

    onSubmit(values) {
      forgotPasswordRequestCode(values)
    },
  })

  const confirmCodeFormik = useFormik({
    initialValues: {
      [formFields.confirmCode]: '',
    },

    validationSchema: Yup.object({
      confirmCode: Yup.string()
        .max(6, 'Enter 6-DIGIT')
        .min(6, 'Enter 6-DIGIT')
        .required('Recovery code is required'),
    }),

    onSubmit(values) {
      forgotPasswordCheckCode({
        email: emailFormik.values.email,
        code: values.confirmCode,
      })
    },
  })

  const newPasswordFormik = useFormik({
    initialValues: {
      [formFields.password]: '',
      [formFields.password_confirmation]: '',
    },

    validationSchema: Yup.object({
      password: Yup.string()
        .matches('(?=.*[a-z])', 'Enter lower case letter!')
        .matches('(?=.*[A-Z])', 'Enter upper case letter!')
        .matches('(?=.*[0-9])', 'Enter number!')
        .matches('(?=.*[!@#\\$%\\^&\\*])', 'Enter special symbols!')
        .min(8, 'Enter  minimum 8 characters!')
        .required('Password is required'),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    }),

    onSubmit(values) {
      resetPassword({
        email: emailFormik.values.email,
        code: confirmCodeFormik.values.confirmCode,
        password: values.password,
        password_confirmation: values.password_confirmation,
      })
    },
  })

  _useCustomEventListener(NEXT_STEP__FORGOT_PASSWORD, nextStep)

  const renderCurrentStepImage = () => <img src={signUpStep1Cover} alt="Sign up step 1 cover" />

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="ForgotPassword-modal"
      ariaHideApp={false}
      overlayClassName="SignUp-overlay">
      <CloseModalButton
        className="SignUp-close-button"
        color="grey"
        right={10}
        top={10}
        onClick={handleClose}
      />
      <div className="ForgotPassword">
        <div>
          {step === 0 && (
            <ForgotPasswordTemplate
              handleChange={emailFormik.handleChange}
              handleSubmit={emailFormik.handleSubmit}
              handleBlur={emailFormik.handleBlur}
              values={emailFormik.values}
              touched={emailFormik.touched}
              errors={emailFormik.errors}
              submitCount={emailFormik.submitCount}
              responseWaiting={responseWaiting}
            />
          )}
          {step === 1 && (
            <EnterCodeTemplate
              handleChange={confirmCodeFormik.handleChange}
              handleSubmit={confirmCodeFormik.handleSubmit}
              handleBlur={confirmCodeFormik.handleBlur}
              values={confirmCodeFormik.values}
              touched={confirmCodeFormik.touched}
              errors={confirmCodeFormik.errors}
              submitCount={confirmCodeFormik.submitCount}
              responseWaiting={responseWaiting}
            />
          )}
          {step === 2 && (
            <CreatePasswordTemplate
              handleClick={() => history.push(routes.signIn[0])}
              handleChange={newPasswordFormik.handleChange}
              handleSubmit={newPasswordFormik.handleSubmit}
              handleBlur={newPasswordFormik.handleBlur}
              values={newPasswordFormik.values}
              touched={newPasswordFormik.touched}
              errors={newPasswordFormik.errors}
              submitCount={newPasswordFormik.submitCount}
              responseWaiting={responseWaiting}
            />
          )}
        </div>

        <div className="SignUp-image">
          {renderCurrentStepImage()}
        </div>
      </div>
    </Modal>
  )
}

ForgotPasswordContainer.propTypes = {
  responseWaiting: PropTypes.bool.isRequired,
  forgotPasswordRequestCode: PropTypes.func.isRequired,
  forgotPasswordCheckCode: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
}

ForgotPasswordContainer.defaultProps = {}

const mapStateToProps = (state) => ({
  responseWaiting: state.userData.responseWaiting,
})

const actionsToProps = {
  forgotPasswordRequestCode,
  forgotPasswordCheckCode,
  resetPassword,
}
export default connect(mapStateToProps, actionsToProps)(ForgotPasswordContainer)
