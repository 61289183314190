import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import isEmpty from 'lodash.isempty'

/* state-manage */
import { history } from 'state-manager/store'

/* components */
import VideoPlayer from 'components/ui/VideoPlayer'
import Share from 'components/ui/Share'
import SpriteIcon from 'components/icons/SpriteIcon'
import Button from 'components/ui/Button/base'

/* helpers */
import getUrlByMediaPrefix from 'helpers/get-url-by-media-prefix'
import getDateByMask from 'helpers/get-date-by-mask'

/* routes */
import routes from 'routes'

/* constants */
import { DATE_MASK } from 'constants/date-mask'
import { TOGGLE__VISIBILITY_DOWNLOAD_APP_MODAL } from 'constants/custom-events'

/* utils */
import customEvent from 'utils/custom-event'

/* assets */
import avatar from 'assets/images/default_avatar.png'
import { formatRoute } from 'react-router-named-routes'

/* styles */
import './styles.scss'

const ThoughtsPage = ({
  currentVideoData,
  udIsProfileFilled,
  isFirstPage,
  videos,
  subtitles,
  currentDuration,
  currentStats,
}) => {
  const getCreatorPhoto = (creator) => {
    if (creator.id && creator.photo) {
      return getUrlByMediaPrefix.photo(creator.photo, creator.id)
    }
    return avatar
  }

  const getWorkRole = (creator) => {
    if (creator.work_role) {
      return creator.company ? `${creator.work_role} at ${creator.company}` : creator.work_role
    }
    return creator.company ? `${creator.discipline} at ${creator.company}` : creator.discipline
  }

  const handleAuthorClick = () => {
    const detailsPagePath = formatRoute(routes.creator, { slug: currentVideoData.creator.slug })
    history.push(detailsPagePath)
  }

  const renderComments = (comments) => (
    comments.map((comment, index) => (
      <div
        key={index}
        className="thoughts-page__comment">
        <div className="thoughts-page__wrapper-avatar">
          <div className="thoughts-page__avatar">
            <img src={getCreatorPhoto(comment.user)} alt="Avatar" className="photo" />
          </div>
        </div>
        <div className="comment__content">
          <div className="comment__handle">
            {`@${comment.user.handle}`}
          </div>
          <div className="comment__text">
            {comment.text}
          </div>
          <div className="comment__date">
            {getDateByMask({
              date: comment.created_at,
              mask: DATE_MASK.SECONDARY,
            })}
          </div>
        </div>
      </div>
    ))
  )

  return (
    <main>
      <section className="thoughts-page">
        <div className="thoughts-page__wrapper">
          <div className="thoughts-page__inner">
            <div className="thoughts-page__main">
              <div className="thoughts-page__player">
                <VideoPlayer
                  disabled={!udIsProfileFilled}
                  mediaId={currentVideoData.id}
                  subtitles={subtitles}
                  videos={videos}
                  isPortrait={currentVideoData.orientation === 0}
                  videoDuration={currentVideoData.duration}
                  currentDuration={currentDuration}
                  isMuted={isFirstPage}
                />
              </div>
            </div>
            <aside className="thoughts-page__sidebar">
              <div className="thoughts-page__creator">
                <div className="thoughts-page__creator--inner" onClick={handleAuthorClick} role="button">
                  <div className="thoughts-page__avatar">
                    <img src={getCreatorPhoto(currentVideoData.creator)} alt="Avatar" className="photo" />
                  </div>

                  <div className="thoughts-page__creator-info">
                    <div className="thoughts-page__handle">
                      {`@${currentVideoData.creator.handle}`}
                    </div>
                    <div className="thoughts-page__position">
                      {getWorkRole(currentVideoData.creator)}
                    </div>
                  </div>
                </div>
                <Share
                  slug={currentVideoData.slug}
                  title={currentVideoData.title}
                  videoId={currentVideoData.id}
                  description={currentVideoData.speakers_info}
                  icon="share-bold-arrow"
                  isOnlyIcon
                  placement="bottom"
                  shareIconSize="md-big"
                  isPortrait={currentVideoData.orientation === 0}
                />
              </div>

              <div className="thoughts-page__video-info">
                <div className="thoughts-page__title">
                  {currentVideoData.title}
                </div>

                <div className="thoughts-page__stats">
                  <div className="thoughts-page__stats--item">
                    <div className="stats-icon">
                      <SpriteIcon
                        name="views"
                        size="md"
                      />
                    </div>

                    <div className="stats-value">
                      {!isEmpty(currentStats) ? currentStats.clicks : 0}
                    </div>
                  </div>

                  <div className="thoughts-page__stats--item">
                    <div className="stats-icon">
                      <SpriteIcon
                        name="like-light"
                        size="md"
                      />
                    </div>

                    <div className="stats-value">
                      {!isEmpty(currentStats) ? currentStats.review_count : 0}
                    </div>
                  </div>

                  <div className="thoughts-page__stats--item">
                    <div className="stats-icon">
                      <SpriteIcon
                        name="comment-light"
                        size="md"
                      />
                    </div>

                    <div className="stats-value">
                      {currentVideoData.comments_count || 0}
                    </div>

                  </div>
                  <div className="thoughts-page__stats--item">
                    <div className="stats-icon">
                      <SpriteIcon
                        name="share-light"
                        size="md"
                      />
                    </div>
                    <div className="stats-value">
                      {!isEmpty(currentStats) ? currentStats.shares : 0}
                    </div>
                  </div>
                </div>
              </div>

              <div className="thoughts-page__comments">
                <div className="thoughts-page__comments--inner">
                  {renderComments(currentVideoData.comments)}
                </div>
              </div>

              <div className={clsx('thoughts-page__get-app', isEmpty(currentVideoData.comments) && 'no-comments')}>
                <Button
                  Btn="redesign-button"
                  className="get-app"
                  variant="primary"
                  text="Get the app to comment"
                  type="button"
                  size="large"
                  onClick={() => customEvent.trigger(TOGGLE__VISIBILITY_DOWNLOAD_APP_MODAL)}
                />
              </div>
            </aside>
          </div>
        </div>
      </section>
    </main>
  )
}

ThoughtsPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentVideoData: PropTypes.object.isRequired,
  udIsProfileFilled: PropTypes.bool.isRequired,
  isFirstPage: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  videos: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  subtitles: PropTypes.array.isRequired,
  currentDuration: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  currentStats: PropTypes.object,
}

ThoughtsPage.defaultProps = {
  currentDuration: 0,
  currentStats: {},
}

export default ThoughtsPage
