import React from 'react'
import PropTypes from 'prop-types'

/* styles */
import './styles.scss'

/* components */
import Modal from 'react-modal'
import SpriteIcon from 'components/icons/SpriteIcon'
import { CloseModalButton } from '../../CloseModalButton'
import Stories from '../index'

const StoriesModal = ({
  items,
  isOpen,
  currentStoryId,
  handleModalVisibility,
}) => {
  const closeModal = () => {
    handleModalVisibility(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="stories-modal"
      ariaHideApp={false}
      overlayClassName="overlay"
      contentLabel="Example modal">

      <div className="modal-inner">
        <Stories
          currentStory={currentStoryId || 0}
          items={items}
        />
      </div>

      <CloseModalButton right={32} top={32} onClick={closeModal} color="white" />

      <div
        role="link"
        className="header-logo"
        onClick={closeModal}>
        <SpriteIcon name="learners-logo" size="logo" />
      </div>

    </Modal>
  )
}

StoriesModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  currentStoryId: PropTypes.number,
  handleModalVisibility: PropTypes.func.isRequired,
}

StoriesModal.defaultProps = {
  currentStoryId: null,
}

export default StoriesModal
