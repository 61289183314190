import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isEmpty from 'lodash.isempty'

/* components */
import Select from 'react-select'
import Button from 'components/ui/Button/base'

/* actions */
import { updateUserData } from 'state-manager/actions/user'
import { getDisciplines, getYearsOfExperience } from 'state-manager/actions/app-data'

/* helpers */
import renderInputs from 'helpers/render-inputs'

/* hooks */
import _useDidMount from 'hooks/lifecycle/use-did-mount'

/* types */
import { types as appDataTypes } from 'state-manager/reducers/app-data'
import { types as userDataTypes } from 'state-manager/reducers/user-data'

/* formik */
import updateProfileFormik from './formik'

/* constants */
import { formFields } from './constants'

/* styles */
import './styles.scss'

const Step2 = ({
  userData,
  disciplines,
  updateUserData,
  getDisciplines,
}) => {
  const [selectedJobTitleOptions, setJobTitleOptions] = useState(null)

  _useDidMount(() => {
    getDisciplines()
    getYearsOfExperience()
  })

  const inputsConfig = [
    {
      id: formFields.firstName,
      type: 'text',
      name: formFields.firstName,
      label: 'First name',
      placeholder: 'John',
      variant: 'secondary',
      spacing: 'mb-1',
    },
    {
      id: formFields.lastName,
      type: 'text',
      name: formFields.lastName,
      label: 'Last name',
      placeholder: 'Doe',
      variant: 'secondary',
      spacing: 'mb-1',
    },
    {
      id: formFields.handle,
      type: 'text',
      name: formFields.handle,
      label: 'Handle',
      placeholder: ' ',
      variant: 'secondary',
      spacing: 'mb-1',
      isHandle: true,
    },
  ]

  const jobTitleOptions = disciplines.map((discipline) => (
    { value: discipline.id, label: discipline.name }
  ))

  const updateProfileForm = updateProfileFormik({
    onSubmit: updateUserData,
    userData,
    jobTitle: selectedJobTitleOptions,
  })

  const selectConfig = [
    {
      title: 'Field',
      options: jobTitleOptions,
      selected: selectedJobTitleOptions,
      setSelected: setJobTitleOptions,
    },
  ]

  const renderSelects = (config) => config.map((selectItem, index) => (
    <div className="select-item" key={index}>
      <div className="select-item__title">
        {selectItem.title}
      </div>
      <Select
        isSearchable={false}
        defaultValue={selectItem.select}
        onChange={selectItem.setSelected}
        options={selectItem.options}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
        })}
      />
    </div>
  ))

  return (
    <div className="Step2">
      <h2 className="title">Complete Your Profile</h2>

      {!isEmpty(disciplines) && (
        <form onSubmit={updateProfileForm.handleSubmit}>
          {renderInputs({
            inputsConfig,

            values: updateProfileForm.values,
            touched: updateProfileForm.touched,
            errors: updateProfileForm.errors,

            handleChange: updateProfileForm.handleChange,
            handleBlur: updateProfileForm.handleBlur,

            submitCount: updateProfileForm.submitCount,
          })}

          <div className="wrapper-selects">
            {renderSelects(selectConfig)}
          </div>

          <div className="Step2-bottom-content">
            <Button
              text="Save"
              type="submit"
              variant="primary"
              size="large"
            />
          </div>
        </form>
      )}
    </div>
  )
}

Step2.propTypes = {
  userData: PropTypes.shape({
    ...userDataTypes,
  }).isRequired,
  disciplines: appDataTypes.disciplines.isRequired,
  updateUserData: PropTypes.func.isRequired,
  getDisciplines: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  userData: state.userData,
  disciplines: state.appData.disciplines,
})

const actionsToProps = {
  updateUserData,
  getDisciplines,
}

export default connect(mapStateToProps, actionsToProps)(Step2)
