import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isEmpty from 'lodash.isempty'
import { useParams } from 'react-router-dom'

/* types */
import { types as contentDataTypes } from 'state-manager/reducers/content'
import { types as userDataTypes } from 'state-manager/reducers/user-data'

/* hooks */
import _useDidMount from 'hooks/lifecycle/use-did-mount'

/* styles */
import './styles.scss'

/* actions */
import { getSingleMiniSeries } from 'state-manager/actions/content'

/* helpers */
import isMobile from 'helpers/check-is-mobile-device'
import getUrlByMediaPrefix from 'helpers/get-url-by-media-prefix'

/* components */
import OpenGraph from 'components/OpenGraph'
import Calendar from 'components/modals/Calendar'
import Sidebar from './components/sidebar'
import Content from './components/content'

const SeriesPage = ({
  udIsProfileFilled,
  udRole,
  getSingleMiniSeries,
  currentSeriesData,
}) => {
  const params = useParams()

  _useDidMount(() => {
    getSingleMiniSeries(params.slug)
  })

  if (isEmpty(currentSeriesData)) {
    return null
  }

  return (
    <>
      <OpenGraph
        title={currentSeriesData.title}
        description={currentSeriesData.description}
        img={currentSeriesData.hero_image || null}
        videoUrl={currentSeriesData.trailer.urls.p720
        || currentSeriesData.trailer.p480
        || currentSeriesData.trailer.p360
        || null}
        profile={currentSeriesData.creators[0] || null}
        type="video.other"
      />

      {currentSeriesData.google_calendar_link && (
        <Calendar calendarLink={currentSeriesData.google_calendar_link} />
      )}

      {/* <NotifyMeModal /> */}

      {!isMobile() && (
        <>
          <div
            style={{
              backgroundImage: `url(${getUrlByMediaPrefix.heroImage(currentSeriesData.hero_image, currentSeriesData.id)})`,
            }}
            className="SeriesPage-background-avatar"
          />
          <div className="SeriesPage-background-avatar-filtered" />
        </>
      )}
      <div className="SeriesPage container">
        <Sidebar
          creators={currentSeriesData.creators[0]}
          disciplineName={currentSeriesData.creators[0].discipline}
          linkHtml={currentSeriesData.creators[0].custom_link}
        />
        <Content
          miniSeries={currentSeriesData}
          disabled={!udIsProfileFilled}
          udRole={udRole}
          disciplineName={currentSeriesData.creators[0].discipline}
        />
      </div>
    </>
  )
}

SeriesPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentSeriesData: PropTypes.object,
  creators: contentDataTypes.creators.isRequired,
  udIsProfileFilled: userDataTypes.isProfileFilled.isRequired,
  udRole: userDataTypes.role.isRequired,
  getSingleMiniSeries: PropTypes.func.isRequired,
}

SeriesPage.defaultProps = {
  currentSeriesData: {},
}

const mapStateToProps = (state, ownProps) => ({
  creators: state.content.creators,
  currentSeriesData: state.content.singleMiniSeries.find((series) => series.slug === ownProps.match.params.slug),
  udIsProfileFilled: state.userData.isProfileFilled,
  udRole: state.userData.role,
})

const actionsToProps = {
  getSingleMiniSeries,
}

export default connect(mapStateToProps, actionsToProps)(SeriesPage)
