import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'
import SidebarItem from './SidebarItem'

const Sidebar = ({ children }) => (
  <div className="sidebar__inner">
    {children.filter((item) => (item)).map((item, key) => (
      <SidebarItem
        key={key}
        label={item.props.label}
        route={item.props.route}
        isActive={item.props.isActive}
      />
    ))}
  </div>
)

Sidebar.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
}

export default Sidebar
