import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'

/* state-manager */
import { history } from 'state-manager/store'

/* components */
import NavItem from './NavItem'
import SpriteIcon from '../../icons/SpriteIcon'

import classes from './NavMenu.module.scss'

const NavMenu = ({ navItems }) => {
  const [showItems, setShowItems] = useState(false)
  const location = useLocation()
  const [selectedOption, setSelectedOption] = useState(null)

  const handleSelection = (value) => {
    setSelectedOption(value)
    setShowItems(false)
    history.push(value.route)
  }

  const openSelection = () => {
    setShowItems(!showItems)
  }
  const renderItems = (items) => items.map((item) => (
    <NavItem navItem={item} key={item.title} selectItem={handleSelection} isActive={item.route === location?.pathname} />
  ))

  return (
    <div className={classes.navMenuWrapper}>
      <div className={classes.navMenuContent}>
        <div onClick={openSelection} role="button" className={classes.navMenu}>
          <SpriteIcon
            name="for-you"
            size="md"
          />
          <p className={classes.navTitle}>{selectedOption?.title}</p>
          <SpriteIcon
            name="slider-next"
            size="sm"
            className={clsx(classes.navArrow, showItems && classes.show)}
          />
        </div>
      </div>
      <div className={clsx(classes.navItems, showItems && classes.show)}>
        {renderItems(navItems)}
      </div>
    </div>

  )
}

NavMenu.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  navItems: PropTypes.array.isRequired,
}

export default NavMenu
