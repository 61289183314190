import React, { useState } from 'react'
import { connect } from 'react-redux'
import { formatRoute } from 'react-router-named-routes'
import * as PropTypes from 'prop-types'
import clsx from 'clsx'
import isEmpty from 'lodash.isempty'

/* helpers */
import isMobile from 'helpers/check-is-mobile-device'

/* utils */
import customEvent from 'utils/custom-event'

/* constants */
import { TOGGLE__VISIBILITY_SIGNUP_MODAL } from 'constants/custom-events'

/* types */
import { types as contentDataTypes } from 'state-manager/reducers/content'

/* actions  */
import { searchByCategories } from 'state-manager/actions/content'

/* routes */
import routes from 'routes'

/* state-manager */
import { history } from 'state-manager/store'

/* components */
import Button from '../Button/base'
import CategoryItems from '../Category'
import ContentCards from '../ContentCard'
import Ads from '../Ads'
import StoriesModal from '../Stories/Modal'

/* styles */
import './styles.scss'
import SpriteIcon from '../../icons/SpriteIcon'
import CategoryCreator from '../Category/CategoryCreator'

const Categories = ({
  categories,
  upcomingEvents,
  isFullCategories,
  isPortraitCategories,
  isCreatorCategories,
  isSliderCategory,
  isFellowshipCategory,
  disabled,
  isShowMore,
}) => {
  const [modalVisible, setModalVisibility] = useState(false)
  // const submitThoughtLink = 'https://docs.google.com/forms/d/e/1FAIpQLSc9tV2VkIS-3Wy7t7wl-8FXs7lp4MVvVvXSCyVKgEWeIMXsdQ/viewform?usp=sf_link'

  const handleGoPageBySlug = ({ slug, route }) => {
    if (disabled) {
      customEvent.trigger(TOGGLE__VISIBILITY_SIGNUP_MODAL)
    } else {
      const singlePagePath = formatRoute(route, { slug })
      history.push(singlePagePath)
    }
  }

  const [currentStoryId, setCurrentStoryId] = useState(null)
  const getCurrentStory = (id) => {
    setCurrentStoryId(id)
  }

  return (
    <>
      {!isEmpty(upcomingEvents) && (
        <section className="dashboard__categories">
          <div className="container">
            <div className="dashboard__categories-wrapper">

              <div className="dashboard__categories-title">
                Upcoming Events
              </div>

            </div>

            <ContentCards
              items={upcomingEvents}
            />

          </div>
        </section>
      )}

      {categories.filter((category) => category.podcasts.length > 0 && category.id !== 3).map((category, key) => (
        <section className="dashboard__categories" key={key}>
          {isCreatorCategories && (
            <div className="background-creators container" />
          )}

          {isFellowshipCategory && (
            <div className="background-fellowship container" />
          )}

          {key === 3 && !isFellowshipCategory && !isCreatorCategories && (
            <Ads key={key} />
          )}

          <div className={isSliderCategory ? 'container' : undefined}>
            <div className={clsx('dashboard__categories-wrapper', category.podcasts[0].orientation === 0 && 'with-wrap')}>
              {!isCreatorCategories && !isFellowshipCategory && (
                <div className="dashboard__categories-title">
                  {category.name}
                </div>
              )}

              {isShowMore && !isFullCategories && !isCreatorCategories && !isFellowshipCategory && category.podcasts.length === 8 && (
                <div className={clsx('dashboard__show-more h-100', category.podcasts[0].orientation === 0 && 'full-width')}>
                  {/* {category.podcasts[0].orientation === 0 && ( */}
                  {/*  <div className={clsx('dashboard__submit-thought', isMobile() && 'mobile')}> */}
                  {/*    <Button */}
                  {/*      text={isMobile() ? 'Submit' : 'Be A Thought Creator'} */}
                  {/*      size={isMobile() ? 'extraSmall' : 'large'} */}
                  {/*      variant="primary" */}
                  {/*      onClick={() => window.open(submitThoughtLink, '_blank')} */}
                  {/*    /> */}
                  {/*  </div> */}
                  {/* )} */}
                  <Button
                    text={isMobile() ? 'More' : 'Show more'}
                    size={isMobile() ? 'extraSmall' : 'small'}
                    variant="transparent"
                    Btn="redesign-button"
                    onClick={() => handleGoPageBySlug({
                      slug: category.slug,
                      route: routes.singleCategory,
                    })}
                  />
                </div>
              )}

              {isFellowshipCategory && (
                <div className="fellowship__wrapper">
                  <div className="icon">
                    <SpriteIcon name="book-with-bg" size="fullWidth" />
                  </div>
                  <div className="info__wrapper">
                    <div className="title mt-1">
                      Latest Learners Series Episodes
                    </div>
                    {/* <div className="description"> */}
                    {/*  New episodes released weekly. */}
                    {/* </div> */}
                  </div>
                </div>
              )}

              {isCreatorCategories && (
                <CategoryCreator
                  title={category.title}
                  miniSeriesSlug={category.slug}
                  creator={category.creators[0]}
                />
              )}
            </div>

            {category.podcasts[0].orientation === 0 && (
              <StoriesModal
                items={category.podcasts}
                isOpen={modalVisible}
                currentStoryId={currentStoryId}
                handleModalVisibility={setModalVisibility}
              />
            )}

            <CategoryItems
              miniSeriesSlug={isCreatorCategories ? category.slug : null}
              isPortrait={category.podcasts[0].orientation === 0}
              disabled={disabled}
              items={category.podcasts}
              skipPortraitFilter={isPortraitCategories}
              setModalVisibility={() => setModalVisibility(true)}
              getCurrentStory={getCurrentStory}
              isSlider={isSliderCategory}
              categoryId={category.id}
            />

            {/* {category.podcasts[0].orientation === 0 && isMobile() && window.screen.width < 768 ? ( */}
            {/*  <Stories */}
            {/*    isLazyLoad */}
            {/*    items={category.podcasts} */}
            {/*  /> */}
            {/* ) : ( */}
            {/*  <CategoryItems */}
            {/*    miniSeriesSlug={isCreatorCategories ? category.slug : null} */}
            {/*    isPortrait={category.podcasts[0].orientation === 0} */}
            {/*    disabled={disabled} */}
            {/*    items={category.podcasts} */}
            {/*    skipPortraitFilter={isPortraitCategories} */}
            {/*    setModalVisibility={() => setModalVisibility(true)} */}
            {/*    getCurrentStory={getCurrentStory} */}
            {/*    isSlider={isSliderCategory} */}
            {/*    categoryId={category.id} */}
            {/*  /> */}
            {/* )} */}

          </div>
        </section>
      ))}
    </>
  )
}

Categories.propTypes = {
  categories: contentDataTypes.categories.isRequired,
  upcomingEvents: contentDataTypes.upcomingEvents,
  isFullCategories: PropTypes.bool,
  isPortraitCategories: PropTypes.bool,
  isCreatorCategories: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  isSliderCategory: PropTypes.bool,
  isFellowshipCategory: PropTypes.bool,
  isShowMore: PropTypes.bool,
}

Categories.defaultProps = {
  upcomingEvents: [],
  isFullCategories: true,
  isPortraitCategories: false,
  isCreatorCategories: false,
  isSliderCategory: false,
  isFellowshipCategory: false,
  isShowMore: false,
}

const actionsToProps = {
  searchByCategories,
}

export default connect(null, actionsToProps)(Categories)
