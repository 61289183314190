import React from 'react'

/* styles */
import 'react-toastify/dist/ReactToastify.css'

/* env */
import { ENV } from 'constants/main'

/* model */
import AppModel from 'routes/model'

/* components */
import { ToastContainer } from 'react-toastify'
import GlobalSpinner from 'components/GlobalSpinner'
import SignUp from 'components/modals/SignUp'
import SignIn from 'components/modals/SignIn'
import ForgotPassword from 'components/modals/ForgotPassword'
import OpenGraph from 'components/OpenGraph'
// import TriggerSignUp from 'components/TriggerSignUp'
import StreamChecker from 'components/StreamChecker'
import MobileBanner from 'components/MobileBanner'

/* 3d party */
import Profilwell3dParty from '3d-party/profitwell'
import { IntercomProvider } from 'react-use-intercom'
import Sentry3dParty from '3d-party/sentry'
import IntercomHandler from '3d-party/intercom'

/* helpers */
import ScrollToTop from './helpers/scrollToTop'
import DownloadApp from './components/modals/DownloadApp'
import MailList from './components/modals/MailList'

const App = () => (
  <>
    <OpenGraph
      title="Learners"
      isDefaultPage
    />
    <MobileBanner />
    <ScrollToTop />
    <GlobalSpinner />
    <IntercomProvider
      appId="bw4qebge"
      autoBoot={ENV === 'production'}>
      <AppModel />
      {ENV === 'production' && (
        <IntercomHandler />
      )}
    </IntercomProvider>

    <ToastContainer
      position="top-center"
      draggable={false}
    />

    {/* Modals  */}
    <SignUp />
    <SignIn />
    <ForgotPassword />
    <DownloadApp />
    <MailList />

    <StreamChecker />
    {/* <TriggerSignUp /> */}

    {ENV === 'production' && (
      <>
        <Sentry3dParty />
        <Profilwell3dParty />
      </>
    )}
  </>
)

export default App
