import React, { useState } from 'react'
import Modal from 'react-modal'
import { useFormik } from 'formik'
import * as Yup from 'yup'

/* constants */
import { TOGGLE__VISIBILITY_DOWNLOAD_APP_MODAL } from 'constants/custom-events'
import { RECAPTCHA_SITE_KEY } from 'constants/main'

/* hooks */
import _useCustomEventListener from 'hooks/use-custom-event-listener'
import { sendEmailGetLink } from 'state-manager/actions/user'
import _useDidMount from 'hooks/lifecycle/use-did-mount'

/* components */
import { CloseModalButton } from 'components/ui/CloseModalButton'

/* assets */
import apple from 'assets/images/apple-download-btn.png'
import google from 'assets/images/google-download-btn.png'
import qrCode from 'assets/images/qr-code-app.png'

import { formFields } from '../ForgotPassword/constants'

/* styles */
import classes from './DownloadApp.module.scss'

const DownloadApp = () => {
  const [isOpen, setIsOpen] = useState(false)

  _useDidMount(() => {
    // Add reCaptcha
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  })
  const emailFormik = useFormik({
    initialValues: {
      [formFields.email]: '',
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .email('Enter a valid email')
        .required('Email is required'),
    }),

    async onSubmit(values) {
      const recaptcha = window.grecaptcha
      const recaptchaToken = await recaptcha.execute(
        RECAPTCHA_SITE_KEY,
        { action: 'send_email' },
      )

      if (recaptchaToken) {
        await sendEmailGetLink({ email: values.email, reCaptchaToken: recaptchaToken })
      }
      emailFormik.resetForm()
      setIsOpen(false)
    },
  })

  const handleClose = () => setIsOpen(false)

  _useCustomEventListener(TOGGLE__VISIBILITY_DOWNLOAD_APP_MODAL, () => setIsOpen(!isOpen))

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className={classes.DownloadAppModal}
      ariaHideApp={false}
      overlayClassName={classes.DownloadAppOverlay}>
      <div className={classes.DownloadApp}>
        <div className={classes.DownloadAppHeader}>
          <span className={classes.DownloadAppHeaderTitle}>Get the Learners app</span>
          <CloseModalButton right={25} top={25} onClick={handleClose} color="grey" />
        </div>
        <div className={classes.DownloadAppContent}>
          <div className={classes.description}>
            Scan the QR code to download.
          </div>
          <img src={qrCode} alt="qrCode" className={classes.qrCode} />

          <div className={classes.wrapperButtons}>
            <div
              role="button"
              className={classes.button}
              onClick={() => window.open('https://apps.apple.com/app/learners/id1572654814/')}>
              <img
                src={apple}
                alt="Download app from apple store"
                className={classes.buttonIcon}
              />
            </div>

            <div
              role="button"
              className={classes.button}
              onClick={() => window.open('https://play.google.com/store/apps/details?id=com.joinlearners')}>
              <img
                src={google}
                alt="Download app from play market"
                className={classes.buttonIcon}
              />
            </div>
          </div>
        </div>
      </div>

    </Modal>
  )
}

export default DownloadApp
