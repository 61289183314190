/* constants */
import { LARAVEL_ECHO_CHANNEL_PREFIX } from 'constants/main'

const channelPrefix = LARAVEL_ECHO_CHANNEL_PREFIX

const channelsByName = {
  conversationById: (id) => `${channelPrefix}.conversation.${id}`,
}

export default channelsByName
