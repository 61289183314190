import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

/* actions */
import { signUp } from 'state-manager/actions/auth'

/* helpers */
import renderInputs from 'helpers/render-inputs'

/* utils */
import customEvent from 'utils/custom-event'

/* components */
import Button from 'components/ui/Button/base'

/* constants */
import {
  SHOW_SIGN_IN_MODAL,
  TOGGLE__VISIBILITY_SIGNUP_MODAL,
} from 'constants/custom-events'
import { formFields } from './constants'

/* formik */
import signUpFormik from './formik'

/* styles */
import './styles.scss'

const inputsConfig = [
  {
    type: 'email',
    name: formFields.email,
    label: 'Email address',
    placeholder: 'email.address@example.com',
    variant: 'secondary',
    spacing: 'mb-1',
  },
  {
    type: 'password',
    name: formFields.password,
    label: 'Create a secure password',
    variant: 'secondary',
    spacing: 'mb-1',
  },
  {
    type: 'password',
    name: formFields.passwordConfirmation,
    label: 'Confirm password',
    variant: 'secondary',
    spacing: 'mb-1',
  },
]

const Step1 = ({
  signUp,
}) => {
  const signUpForm = signUpFormik({
    onSubmit: signUp,
    email: '',
  })

  const handleLoginClick = () => {
    customEvent.trigger(TOGGLE__VISIBILITY_SIGNUP_MODAL)
    customEvent.trigger(SHOW_SIGN_IN_MODAL)
  }

  return (
    <div className="Step1">
      <h2 className="title">Your home for professional development</h2>
      <div className="subtitle">
        Join our community for research, product and design learnings, made completely free.
      </div>

      <form onSubmit={signUpForm.handleSubmit}>
        {renderInputs({
          inputsConfig,
          values: signUpForm.values,
          touched: signUpForm.touched,
          errors: signUpForm.errors,
          handleChange: signUpForm.handleChange,
          handleBlur: signUpForm.handleBlur,
          submitCount: signUpForm.submitCount,
        })}

        <div className="bottom-description">
          Use at least one lowercase letter, one uppercase letter, one number and one special character (e.g. !, @, #).
          8
          character minimum.
        </div>

        <div className="Step1-bottom-content">
          <Button
            text="Join"
            type="submit"
            variant="primary"
            size="large"
          />

          <div className="SignUp-login">
            Already have an account?
            <span
              role="button"
              className="link-to-login"
              onClick={handleLoginClick}>
              Login
            </span>
          </div>
        </div>
      </form>
    </div>
  )
}

Step1.propTypes = {
  signUp: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  responseWaiting: state.userData.responseWaiting,
})

const mapActionsToProps = {
  signUp,
}

export default connect(mapStateToProps, mapActionsToProps)(Step1)
