import React from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'

/* components */
import SpriteIcon from 'components/icons/SpriteIcon'
import Sidebar from 'components/ui/Sidebar'
import SidebarItem from 'components/ui/Sidebar/SidebarItem'
import Button from 'components/ui/Button/base'

/* actions */
import { getUserData } from 'state-manager/actions/user'
import { changePassword } from 'state-manager/actions/auth'

/* routes */
import routes from 'routes'

/* hooks */
import _useDidMount from 'hooks/lifecycle/use-did-mount'

/* helpers */
import renderInputs from 'helpers/render-inputs'

/* formik */
import accountSettingsFormik from 'pages/authorized/manage-account/settings/formik'

/* types */
import { types as userDataTypes } from 'state-manager/reducers/user-data'

/* constants */
import { formFields } from './constants'

/* styles */
import './styles.scss'
import '../manage-account-page.scss'

const AccountSettingsPage = ({
  userData,
  getUserData,
  changePassword,
}) => {
  _useDidMount(() => {
    if (!userData) {
      getUserData()
    }
  })

  const accountSettingsForm = accountSettingsFormik(
    {
      onSubmit: changePassword,
    },
  )

  const inputsConfig = [
    {
      id: formFields.old_password,
      label: 'Current password',
      spacing: 'mb-2',
      name: formFields.old_password,
      type: 'password',
    },
    {
      inlineGroup: [
        {
          id: formFields.password,
          label: 'New password',
          spacing: 'mb-5',
          name: formFields.password,
          type: 'password',
          isDouble: true,
        },
        {
          id: formFields.password_confirmation,
          label: 'Confirm password',
          spacing: 'mb-5',
          type: 'password',
          name: formFields.password_confirmation,
          isDouble: true,
        },
      ],
    },
  ]

  return (
    <main>
      <div className="dashboard-manage-account-page">
        <div className="dashboard-manage-account-page__header">
          <div className="container">
            <div className="dashboard-manage-account-page__header-inner">
              <SpriteIcon name="logo" size="md" />
              <span className="all-talks">My Account</span>
            </div>
          </div>
        </div>

        <div className="dashboard-manage-account-page__wrapper">
          <div className="container">
            <div className="dashboard-manage-account-page__inner">
              <Sidebar>
                <SidebarItem
                  label="My Profile"
                  route={routes.manageProfileDetails}
                />

                {/* {userData.permissions.includes(PERMISSIONS.CAN_MANAGE_SUBSCRIPTION) && ( */}
                {/*  <SidebarItem */}
                {/*    label="Account and Payment Details" */}
                {/*    route={userData.hasSubscription ? routes.manageBillingAndSubscription : routes.selectSubscription} */}
                {/*  /> */}
                {/* )} */}

                <SidebarItem
                  label="Account Settings"
                  route={routes.manageSettings}
                  isActive
                />
              </Sidebar>

              <div className="manage-profile">
                <div className="manage-profile__title">
                  Account Settings
                </div>

                <div className="max-width-400">
                  <form onSubmit={accountSettingsForm.handleSubmit}>
                    {renderInputs({
                      inputsConfig,

                      values: accountSettingsForm.values,
                      touched: accountSettingsForm.touched,
                      errors: accountSettingsForm.errors,

                      handleChange: accountSettingsForm.handleChange,
                      handleBlur: accountSettingsForm.handleBlur,

                      submitCount: accountSettingsForm.submitCount,
                    })}

                    <Button
                      text="CHANGE"
                      type="submit"
                      variant="primary"
                      size="medium"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

AccountSettingsPage.propTypes = {
  userData: PropTypes.shape({
    ...userDataTypes,
    permissions: userDataTypes.permissions,
    hasSubscription: userDataTypes.hasSubscription,
  }).isRequired,
  getUserData: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  userData: state.userData,
})

const actionsToProps = {
  getUserData,
  changePassword,
}

export default connect(mapStateToProps, actionsToProps)(AccountSettingsPage)
