import React from 'react'
import ReactDOM from 'react-dom'
import { Provider, ReactReduxContext } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ConnectedRouter } from 'connected-react-router'

/* env */
import { ENV } from 'constants/main'

/* app */
import App from 'App'

/* store */
import { store, persistor, history } from 'state-manager/store'

/* assets */
import { ReactComponent as Sprite } from 'assets/sprite.svg'

/* styles */
import 'styles/index.scss'

const renderApp = (App) => {
  ReactDOM.render(
    <Provider store={store} context={ReactReduxContext}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history} context={ReactReduxContext}>
          <Sprite />

          <App />
        </ConnectedRouter>
      </PersistGate>
    </Provider>,
    document.getElementById('root'),
  )
}

renderApp(App)

if (ENV !== 'production' && module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    renderApp(NextApp)
  })
}
