import React from 'react'
import PropTypes from 'prop-types'
import customEvent from 'utils/custom-event'
import { SHOW_CALENDAR_MODAL } from '../../../constants/custom-events'
import SpriteIcon from '../../icons/SpriteIcon'
import './styles.scss'

const CalendarButton = ({
  className,
}) => {
  const openCalendarModal = () => {
    customEvent.trigger(SHOW_CALENDAR_MODAL)
  }
  return (
    <div
      aria-label="Add google calendar"
      className={`CalendarButton ${className}`}
      onClick={openCalendarModal}
      role="button">
      <SpriteIcon
        name="calendar2"
        size="md"
        withState
      />
    </div>
  )
}

CalendarButton.propTypes = {
  className: PropTypes.string,
}

CalendarButton.defaultProps = {
  className: '',
}

export default CalendarButton
