import PropTypes from 'prop-types'

/* helpers */
import updateObject from 'state-manager/reducers/helpers/update-object'

/* constants */
import { SUBSCRIPTION_INFO_ACTIONS } from 'state-manager/constants'

const initialState = {
  gracePeriod: null,
  payment: null,
  status: null,
  plan: null,

  coupon: null,
  amount_off: null,
  percent_off: null,

  responseWaiting: false,
}

export const types = {
  gracePeriod: PropTypes.bool,
  payment: PropTypes.object,
  status: PropTypes.string,
  plan: PropTypes.number,

  coupon: PropTypes.string,
  amount_off: PropTypes.number,
  percent_off: PropTypes.number,

  responseWaiting: PropTypes.bool,
}

export default (state = initialState, action) => {
  switch (action.type) {

    case SUBSCRIPTION_INFO_ACTIONS.DATA_GET.ATTEMPT:
      return updateObject(state, {
        responseWaiting: true,
      })

    case SUBSCRIPTION_INFO_ACTIONS.DATA_GET.SUCCESS:
      return updateObject(state, {
        gracePeriod: action.payload.gracePeriod,
        payment: action.payload.payment,
        status: action.payload.status,
        plan: action.payload.subscription,

        responseWaiting: false,
      })

    case SUBSCRIPTION_INFO_ACTIONS.DATA_GET.ERROR:
      return updateObject(state, {
        responseWaiting: false,
      })

    case SUBSCRIPTION_INFO_ACTIONS.UPDATE.SYSTEM: {
      return updateObject(state, {
        plan: action.payload.plan,
        coupon: action.payload.coupon,
      })
    }

    case SUBSCRIPTION_INFO_ACTIONS.UPDATE_COUPON.SYSTEM: {
      return updateObject(state, {
        amount_off: action.payload.amount_off,
        percent_off: action.payload.percent_off,
      })
    }

    case SUBSCRIPTION_INFO_ACTIONS.CLEAR_INFO.SYSTEM: {
      return updateObject(state, initialState)
    }

    default:
      return state
  }
}
