import React, { useState } from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'

/* components */
import Button from 'components/ui/Button/base'
import { CloseModalButton } from 'components/ui/CloseModalButton'

/* constants */
import { SHOW_CALENDAR_MODAL, HIDE_CALENDAR_MODAL } from 'constants/custom-events'

/* hooks */
import _useCustomEventListener from 'hooks/use-custom-event-listener'

/* styles */
import './styles.scss'

const Calendar = ({
  calendarLink,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleClose = () => setIsOpen(false)

  _useCustomEventListener(SHOW_CALENDAR_MODAL, () => setIsOpen(true))
  _useCustomEventListener(HIDE_CALENDAR_MODAL, () => setIsOpen(true))

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="CalendarModal"
      ariaHideApp={false}
      overlayClassName="SignUp-overlay">

      <CloseModalButton
        color="grey"
        right={10}
        top={10}
        onClick={handleClose}
      />

      <h2 className="d-flex flex-column">
        Never miss an episode!
        <span>Add this series to your Google calendar.</span>
      </h2>

      <Button
        text="Add Google Calendar"
        variant="quaternary"
        className="CalendarModal-add-calendar-button"
        size="max-content"
        onClick={() => window.open(calendarLink, '_blank')}
      />
      {/* <div className="dont-have text-small"> */}
      {/*  Want to follow along with all our series as they come out? */}
      {/*  <Link to="#" className="CalendarModal-link font-bold ml-1">Add all series</Link> */}
      {/* </div> */}

      <div className="Want-to-follow text-small">
        Don’t have Google Calendar? Check out our complete series and events calendar on our
        <a
          className="CalendarModal-link ml-1"
          href="https://blog.joinlearners.com/"
          target="_blank"
          rel="noreferrer">
          Blog
        </a>
      </div>
    </Modal>
  )
}

Calendar.propTypes = {
  calendarLink: PropTypes.string.isRequired,
}

export default Calendar
