import React, { useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import isEmpty from 'lodash.isempty'

/* routes */
import routes from 'routes'

/* layout */
import GlobalLayout from 'pages/shared/layouts/global-layout'

/* pages */
import HomepageV1 from 'pages/homepage-v1'
import ResourcesPage from 'pages/resources'
import DetailsPage from 'pages/details'
import CategoryPage from 'pages/category'
// import BillingAndSubscriptionPage from 'pages/authorized/manage-account/billing-and-subscription'
import ProfileDetailsPage from 'pages/authorized/manage-account/profile-details'
// import SelectSubscriptionPage from 'pages/authorized/manage-account/billing-and-subscription/subscriptions'
// import BillingPage from 'pages/authorized/manage-account/billing-and-subscription/billing'
// import BillingSummaryPage from 'pages/authorized/manage-account/billing-and-subscription/billing-summary'
import AccountSettingsPage from 'pages/authorized/manage-account/settings'
import BookmarksPage from 'pages/authorized/bookmarks'
import SeriesPage from 'pages/series'
import ErrorPage from 'pages/shared/error'
import StreamPage from 'pages/stream'
import CreatorPage from 'pages/creator'
import UXResearchPage from 'pages/ux-research'
import QuestionPage from 'pages/questionPage'

/* constants */
import { PERMISSIONS } from 'constants/permissions'

/* types */
import { types as userDataTypes } from 'state-manager/reducers/user-data'
import Presentation from '../../pages/presentation'
import PurpleHomepage from '../../pages/purple-homepage'

const OnlyAuthorizedAccess = ({
  udRole,
  udPermissions,
  udIsAuthorized,
  ...rest
}) => {
  const routesByPermissionsModel = useCallback(() => (
    [
      {
        permission: PERMISSIONS.CAN_SEE_CONTENT,
        routes: [
          <Route exact path={routes.homepage} component={PurpleHomepage} key={routes.homepage} />,
          <Route exact path={routes.oldHomepage} component={HomepageV1} key={routes.oldHomepage} />,
          <Route exact path={routes.resources} component={ResourcesPage} key={routes.resources} />,
          <Route exact path={routes.uxResearch} component={UXResearchPage} key={routes.uxResearch} />,
          <Route exact path={routes.talkDetails} component={DetailsPage} key={routes.talkDetails} />,
          <Route exact path={routes.thoughtDetails} component={DetailsPage} key={routes.thoughtDetails} />,
          <Route exact path={routes.singleCategory} component={CategoryPage} key={routes.singleCategory} />,
          <Route exact path={routes.series} component={SeriesPage} key={routes.series} />,
          <Route exact path={routes.bookmarks} component={BookmarksPage} key={routes.bookmarks} />,
          <Route exact path={routes.stream} component={StreamPage} key={routes.stream} />,
          <Route exact path={routes.creator} component={CreatorPage} key={routes.creator} />,
        ],
      },
      {
        permission: PERMISSIONS.CAN_MANAGE_ACCOUNT,
        group: [
          {
            permission: PERMISSIONS.CAN_MANAGE_PROFILE_DETAILS,
            routes: [
              <Route exact path={routes.manageProfileDetails} component={ProfileDetailsPage} key={routes.manageProfileDetails} />,
            ],
          },
          {
            permission: PERMISSIONS.CAN_MANAGE_SUBSCRIPTION,
            routes: [
              <Route exact path={routes.manageBillingAndSubscription} key={routes.manageBillingAndSubscription}>
                <Redirect to={routes.errorPage} />
              </Route>,
              <Route exact path={routes.selectSubscription} key={routes.selectSubscription}>
                <Redirect to={routes.errorPage} />
              </Route>,
            ],
          },
          {
            permission: PERMISSIONS.CAN_MANAGE_BILLING,
            routes: [
              <Route exact path={routes.billing} key={routes.billing}>
                <Redirect to={routes.errorPage} />
              </Route>,
              <Route exact path={routes.billingSummary} key={routes.billingSummary}>
                <Redirect to={routes.errorPage} />
              </Route>,
            ],
          },
          {
            permission: PERMISSIONS.CAN_MANAGE_SETTINGS,
            routes: [
              <Route exact path={routes.manageSettings} component={AccountSettingsPage} key={routes.manageSettings} />,
            ],
          },
        ],
      },
    ]
  ), [])

  const mapRoutesGroup = useCallback((routes) => (udPermissions.includes(routes.permission) ? routes.routes.map((route) => route) : null), [udPermissions])

  const precessRoutesGroupsByPermission = useCallback((routesGroupsByPermission) => routesGroupsByPermission.map((routesGroup) => {
    if (routesGroup.group && routesGroup.group.length) {
      return routesGroup.group.map((routesNested) => {
        if (routesNested.group && routesNested.group.length) {
          return precessRoutesGroupsByPermission(routesNested.group)
        }
        return mapRoutesGroup(routesNested)
      })
    }
    return mapRoutesGroup(routesGroup)
  }), [mapRoutesGroup])

  const renderRoutesGroupsByPermission = useMemo(() => precessRoutesGroupsByPermission(routesByPermissionsModel()).flat(Infinity), [routesByPermissionsModel, precessRoutesGroupsByPermission])

  return (
    <Route
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...rest}
      render={() => {
        if (udIsAuthorized && !isEmpty(udPermissions)) {
          return (
            <GlobalLayout>
              <Switch>
                {renderRoutesGroupsByPermission}
                <Route exact path={routes.errorPage} component={ErrorPage} />

                <Redirect to={{ pathname: routes.errorPage }} />
              </Switch>
            </GlobalLayout>
          )
        }

        return (
          <GlobalLayout>
            <Switch>
              <Route exact path={routes.talkDetails} component={DetailsPage} />
              <Route exact path={routes.thoughtDetails} component={DetailsPage} />
              <Route exact path={routes.singleCategory} component={CategoryPage} />
              <Route exact path={routes.homepage} component={Presentation} />,
              <Route exact path={routes.oldHomepage} component={HomepageV1} />,
              <Route exact path={routes.resources} component={ResourcesPage} />
              <Route exact path={routes.uxResearch} component={UXResearchPage} />,
              <Route exact path={routes.series} component={SeriesPage} />
              <Route exact path={routes.stream} component={StreamPage} />
              <Route exact path={routes.creator} component={CreatorPage} />,
              <Route exact path={routes.questionPage} component={QuestionPage} />,
              <Route exact path={routes.errorPage} component={ErrorPage} />

              <Redirect to={{ pathname: routes.errorPage }} />
            </Switch>
          </GlobalLayout>
        )
      }}
    />
  )
}

OnlyAuthorizedAccess.propTypes = {
  udRole: userDataTypes.role.isRequired,
  udPermissions: userDataTypes.permissions.isRequired,
  udIsAuthorized: userDataTypes.isAuthorized.isRequired,
}

const mapStateToProps = (state) => ({
  udRole: state.userData.role,
  udPermissions: state.userData.permissions,
  udIsAuthorized: state.userData.isAuthorized,
})

export default connect(mapStateToProps)(OnlyAuthorizedAccess)
