import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import classes from './SpriteIcon.module.scss'

const SpriteIcon = (
  {
    name,
    className,
    size,
    colorIcon,
    rotate,
    withState,
    isActive,
    disabled,
  },
) => (
  <svg
    className={clsx(classes[size],
      className,
      isActive && classes.active,
      withState && classes.withState,
      disabled && classes.disabled)}
    style={{ transform: `rotate(${rotate}deg)` }}>
    <use xlinkHref={`#${name}`} fill={colorIcon} />
  </svg>
)

SpriteIcon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf([
    'sm',
    'md',
    'md-big',
    'lg',
    'playerMd',
    'playerLg',
    'logo',
    'sliderMd',
    'fullWidth',
  ]),
  colorIcon: PropTypes.string,
  rotate: PropTypes.number,
  withState: PropTypes.bool,
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
}

SpriteIcon.defaultProps = {
  className: '',
  colorIcon: '',
  size: 'sm',
  rotate: 0,
  withState: false,
  isActive: false,
  disabled: false,
}

export default SpriteIcon
