/* utils */
import reqH from 'utils/request-handler'

/* constants */
import { CHAT_ACTIONS } from 'state-manager/constants'

export const getChatInfo = (data) => (dispatch) => {
  dispatch({ type: CHAT_ACTIONS.GET_INFO.ATTEMPT })

  return reqH({
    method: 'GET',
    url: `conversations/${data.id}`,
  })
    .then((res) => {
      dispatch({
        type: CHAT_ACTIONS.GET_INFO.SUCCESS,
        payload: {
          chatInfo: res.data,
        },
      })
    })
    .catch(() => {
      dispatch({ type: CHAT_ACTIONS.GET_INFO.ERROR })
    })
}

export const getMessage = (data) => (dispatch) => {
  dispatch({
    type: CHAT_ACTIONS.GET_MESSAGE_WS.SUCCESS,
    payload: {
      message: data,
    },
  })
}

export const sendMessage = (data) => (dispatch) => {
  dispatch({ type: CHAT_ACTIONS.SEND_MESSAGE.ATTEMPT })

  return reqH({
    method: 'POST',
    url: `conversations/${data.id}/messages`,
    data: {
      type: 1,
      version: 0,
      body: data.message,
    },
    skipGlobalSpinner: true,
  })
    .then(() => {
      dispatch({ type: CHAT_ACTIONS.SEND_MESSAGE.SUCCESS })
    })
    .catch(() => {
      dispatch({ type: CHAT_ACTIONS.SEND_MESSAGE.ERROR })
    })
}

export const stopUpdateMessages = () => (dispatch) => {
  dispatch({ type: CHAT_ACTIONS.STOP_UPDATE.SYSTEM })
}

export const startUpdateMessages = () => (dispatch) => {
  dispatch({ type: CHAT_ACTIONS.START_UPDATE.SYSTEM })
}

export const pushQueueToMessages = () => (dispatch) => {
  dispatch({ type: CHAT_ACTIONS.PUSH_QUEUE.SYSTEM })
}

export const clearChatMessages = () => (dispatch) => {
  dispatch({ type: CHAT_ACTIONS.CHAT_CLEAR.SYSTEM })
  dispatch(startUpdateMessages())
}

export const enableChat = () => (dispatch) => {
  dispatch({ type: CHAT_ACTIONS.CHAT_ENABLE.SYSTEM })
}

export const muteUser = (data) => (dispatch) => {
  dispatch({ type: CHAT_ACTIONS.CHAT_USER_MUTE.ATTEMPT })

  return reqH({
    method: 'POST',
    url: `conversations/${data.conversationId}/participant/${data.userId}/mute`,
    skipGlobalSpinner: true,
  })
    .then(() => {
      dispatch({ type: CHAT_ACTIONS.CHAT_USER_MUTE.SUCCESS })
    })
    .catch(() => {
      dispatch({ type: CHAT_ACTIONS.CHAT_USER_MUTE.ERROR })
    })
}

export const unmuteUser = (data) => (dispatch) => {
  dispatch({ type: CHAT_ACTIONS.CHAT_USER_UNMUTE.ATTEMPT })

  return reqH({
    method: 'POST',
    url: `conversations/${data.conversationId}/participant/${data.userId}/unmute`,
    skipGlobalSpinner: true,
  })
    .then(() => {
      dispatch({ type: CHAT_ACTIONS.CHAT_USER_UNMUTE.SUCCESS })
    })
    .catch(() => {
      dispatch({ type: CHAT_ACTIONS.CHAT_USER_UNMUTE.ERROR })
    })
}

export const deleteMessage = (data) => (dispatch) => {
  dispatch({ type: CHAT_ACTIONS.MESSAGE_DELETE.ATTEMPT })

  return reqH({
    method: 'DELETE',
    url: `conversations/${data.conversationId}/messages/${data.messageId}`,
    skipGlobalSpinner: true,
  })
    .then(() => {
      dispatch({ type: CHAT_ACTIONS.MESSAGE_DELETE.SUCCESS })
    })
    .catch(() => {
      dispatch({ type: CHAT_ACTIONS.MESSAGE_DELETE.ERROR })
    })
}

export const changeDeletedMessage = (data) => (dispatch) => {
  dispatch({
    type: CHAT_ACTIONS.MESSAGE_DELETED_CHANGE.SYSTEM,
    payload: {
      message: data.message,
    },
  })
}
