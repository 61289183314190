import * as Yup from 'yup'
import { useFormik } from 'formik'

import { formFields } from './constants'

export default ({
  onSubmit,
  email,
}) => useFormik({
  initialValues: {
    [formFields.email]: email,
    [formFields.password]: '',
    [formFields.passwordConfirmation]: '',
  },

  validationSchema: Yup.object({
    [formFields.email]: Yup.string()
      .label('Email address')
      .email()
      .required(),

    [formFields.password]: Yup.string()
      .label('Password')
      .matches('(?=.*[a-z])', 'Enter lower case letter')
      .matches('(?=.*[A-Z])', 'Enter upper case letter')
      .matches('(?=.*[0-9])', 'Enter number')
      .matches('(?=.*[!@#\\$%\\^&\\*])', 'Enter special symbols')
      .min(8)
      .required(),

    [formFields.passwordConfirmation]: Yup.string()
      .label('Confirm Password')
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required(),
  }),

  onSubmit(values) {
    onSubmit(values)
  },
})
