import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isEmpty from 'lodash.isempty'

/* components */
import Select from 'react-select'
import Button from 'components/ui/Button/base'
import SpriteIcon from 'components/icons/SpriteIcon'
import Sidebar from 'components/ui/Sidebar'
import SidebarItem from 'components/ui/Sidebar/SidebarItem'
import CropImage from 'components/modals/CropImage'

/* actions */
import { getUserData, updateUserData } from 'state-manager/actions/user'
import { getDisciplines, getYearsOfExperience } from 'state-manager/actions/app-data'

/* routes */
import routes from 'routes'

/* hooks */
import _useDidMount from 'hooks/lifecycle/use-did-mount'

/* helpers */
import renderInputs from 'helpers/render-inputs'

/* formik */
import profileDetailsFormik from 'pages/authorized/manage-account/profile-details/formik'

/* types */
import { types as userDataTypes } from 'state-manager/reducers/user-data'
import { types as appDataTypes } from 'state-manager/reducers/app-data'

/* constants */
import { formFields } from './constants'

/* styles */
import './styles.scss'
import '../manage-account-page.scss'

const ProfileDetailsPage = ({
  getUserData,
  userData,
  disciplines,
  updateUserData,
  getDisciplines,
}) => {
  const [modalVisible, setModalVisibility] = useState(false)
  const [selectedJobTitleOptions, setJobTitleOptions] = useState(null)
  const [preview, setPreview] = useState(userData.photo)

  _useDidMount(() => {
    getDisciplines()
    getYearsOfExperience()
    if (!userData) {
      getUserData()
    }
  })

  const profileDetailsForm = profileDetailsFormik(
    {
      onSubmit: updateUserData,
      userData,
      photo: preview !== userData.photo ? preview : null,
      jobTitle: selectedJobTitleOptions,
    },
  )

  const inputsConfig = [
    {
      id: formFields.handle,
      spacing: 'mb-2',
      name: formFields.handle,
      type: 'text',
      isHandle: true,
      // variant: formFields.handle,
    },
    {
      id: formFields.firstName,
      label: 'Name',
      name: formFields.firstName,
      placeholder: 'FIRST NAME',
      type: 'text',
    },
    {
      id: formFields.lastName,
      spacing: 'mb-2',
      name: formFields.lastName,
      placeholder: 'LAST NAME',
      type: 'text',
    },
    {
      inlineGroup: [
        {
          id: formFields.country,
          label: 'Location',
          name: formFields.country,
          placeholder: 'COUNTRY',
          isDouble: true,
          type: 'text',
        },
        {
          id: formFields.city,
          name: formFields.city,
          placeholder: 'CITY',
          isDouble: true,
          type: 'text',
        },
      ],
    },
  ]

  const jobTitleOptions = disciplines.map((discipline) => (
    { value: discipline.id, label: discipline.name }
  ))

  const currentIndexJobTitle = jobTitleOptions.findIndex((option) => option.value === userData.disciplineId)

  const selectConfig = [
    {
      title: 'Field',
      options: jobTitleOptions,
      selected: selectedJobTitleOptions,
      setSelected: setJobTitleOptions,
      default: jobTitleOptions[currentIndexJobTitle],
    },
  ]

  if (!selectedJobTitleOptions && jobTitleOptions[currentIndexJobTitle]) {
    setJobTitleOptions(jobTitleOptions[currentIndexJobTitle])
  }

  const customStyles = {
    singleValue: () => ({
      color: 'white',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected && 'white',
    }),
  }

  const renderSelects = (config) => config.map((selectItem, index) => (
    <div className="select-item" key={index}>
      <div className="select-item__title">
        {selectItem.title}
      </div>
      <Select
        styles={customStyles}
        defaultValue={selectItem.default || selectItem.select}
        isSearchable={false}
        onChange={selectItem.setSelected}
        options={selectItem.options}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: 'var(--blue-color)',
            primary: 'var(--blue-color)',
            neutral0: 'var(--selected-secondary-input-bg-color)',
            neutral20: '#808794',
          },
        })}
      />
    </div>
  ))

  return (
    <main>
      <div className="dashboard-manage-account-page">
        <div className="dashboard-manage-account-page__header">
          <div className="container">
            <div className="dashboard-manage-account-page__header-inner">
              <SpriteIcon name="logo" size="md" />
              <span className="all-talks">My Account</span>
            </div>
          </div>
        </div>

        <div className="dashboard-manage-account-page__wrapper">
          <div className="container">
            <div className="dashboard-manage-account-page__inner">
              <Sidebar>
                <SidebarItem
                  label="My Profile"
                  route={routes.manageProfileDetails}
                  isActive
                />

                {/* {userData.permissions.includes(PERMISSIONS.CAN_MANAGE_SUBSCRIPTION) && ( */}
                {/*  <SidebarItem */}
                {/*    label="Account and Payment Details" */}
                {/*    route={userData.hasSubscription ? routes.manageBillingAndSubscription : routes.selectSubscription} */}
                {/*  /> */}
                {/* )} */}

                <SidebarItem
                  label="Account Settings"
                  route={routes.manageSettings}
                />
              </Sidebar>

              <div className="manage-profile">
                <div className="manage-profile__title">
                  My Profile
                </div>

                <div className="max-width-400">
                  <div className="wrapper-preview-avatar">
                    {preview && (
                      <div
                        role="button"
                        className="preview"
                        onClick={() => setModalVisibility(true)}>
                        <img src={preview} className="preview__image" alt="Preview" />
                      </div>
                    )}
                  </div>

                  <CropImage
                    preview={preview}
                    setPreview={setPreview}
                    isOpen={modalVisible}
                    handleModalVisibility={setModalVisibility}
                  />

                  {!isEmpty(disciplines) && (
                    <form onSubmit={profileDetailsForm.handleSubmit}>
                      {renderInputs({
                        inputsConfig,

                        values: profileDetailsForm.values,
                        touched: profileDetailsForm.touched,
                        errors: profileDetailsForm.errors,

                        handleChange: profileDetailsForm.handleChange,
                        handleBlur: profileDetailsForm.handleBlur,

                        submitCount: profileDetailsForm.submitCount,
                      })}

                      <div className="wrapper-selects">
                        {renderSelects(selectConfig)}
                      </div>

                      <Button
                        className="mt-5"
                        text="SAVE"
                        type="submit"
                        variant="primary"
                        size="medium"
                      />
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

ProfileDetailsPage.propTypes = {
  getUserData: PropTypes.func.isRequired,
  updateUserData: PropTypes.func.isRequired,
  userData: PropTypes.shape({
    ...userDataTypes,
    permissions: userDataTypes.permissions,
    hasSubscription: userDataTypes.hasSubscription,
    disciplineId: userDataTypes.disciplineId,
    yearsOfExperienceId: userDataTypes.yearsOfExperienceId,
    photo: userDataTypes.photo,
  }).isRequired,
  disciplines: appDataTypes.disciplines.isRequired,
  getDisciplines: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  userData: state.userData,
  disciplines: state.appData.disciplines,
})

const actionsToProps = {
  getUserData,
  updateUserData,
  getDisciplines,
}

export default connect(mapStateToProps, actionsToProps)(ProfileDetailsPage)
