import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'

import { formFields } from './constants'

export default ({
  onSubmit,
  userData,
  jobTitle,
}) => useFormik({
  initialValues: {
    [formFields.firstName]: userData.firstName || '',
    [formFields.lastName]: userData.lastName || '',
    [formFields.handle]: userData.handle || '',
  },

  validationSchema: Yup.object({
    [formFields.firstName]: Yup.string()
      .label('First name')
      .required('First name field is required')
      .min(2),

    [formFields.lastName]: Yup.string()
      .label('Last name')
      .required('Last name field is required')
      .min(2),

    [formFields.handle]: Yup.string()
      .label('Handle')
      .required(),
  }),

  onSubmit(values) {
    if (!jobTitle) {
      toast.error('Professional category is required')
    } else {
      onSubmit({
        ...values,
        jobTitle,
      })
    }
  },
})
