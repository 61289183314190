import { connect } from 'react-redux'
import { useIntercom } from 'react-use-intercom'
import { types as useDataTypes } from '../state-manager/reducers/user-data'

const IntercomHandler = ({
  isAuthorized,
  isProfileFilled,
  firstName,
  lastName,
  email,
  hashedEmail,
  country,
  city,
}) => {
  const { update } = useIntercom()
  if (isAuthorized && isProfileFilled && hashedEmail) {
    update({
      email,
      name: `${firstName} ${lastName}`,
      userHash: hashedEmail,
      country,
      city,
    })
  }
  return null
}

IntercomHandler.propTypes = {
  isAuthorized: useDataTypes.isAuthorized.isRequired,
  isProfileFilled: useDataTypes.isProfileFilled.isRequired,
  email: useDataTypes.email.isRequired,
  firstName: useDataTypes.firstName.isRequired,
  lastName: useDataTypes.lastName.isRequired,
  hashedEmail: useDataTypes.hashedEmail.isRequired,
  country: useDataTypes.country.isRequired,
  city: useDataTypes.city.isRequired,
}

const mapStateToProps = (state) => ({
  isAuthorized: state.userData.isAuthorized,
  isProfileFilled: state.userData.isProfileFilled,
  email: state.userData.email,
  firstName: state.userData.firstName,
  lastName: state.userData.lastName,
  hashedEmail: state.userData.hashedEmail,
  country: state.userData.country,
  city: state.userData.city,
})

export default connect(mapStateToProps, null)(IntercomHandler)
