import React from 'react'
import * as PropTypes from 'prop-types'
import clsx from 'clsx'

import getUrlByMediaPrefix from 'helpers/get-url-by-media-prefix'
import avatar from 'assets/images/default_avatar.png'
import SpriteIcon from '../../icons/SpriteIcon'

import classes from './JobCard.module.scss'

const JobCard = ({ job }) => {
  const getJobPhoto = (job) => {
    if (job.id && job.image) return getUrlByMediaPrefix.jobPhoto(job.image)
    return avatar
  }

  const handleOpenLink = (url) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = `http://${url}`
    }
    window.open(url, '_blank')
  }

  return (
    <div className={classes.jobWrapper} onClick={() => handleOpenLink(job.link)} role="button">
      <img src={getJobPhoto(job)} className={classes.jobImage} alt="job-logo" />
      <div className={classes.jobInfo}>
        <h5 className={clsx(classes.jobTitle, 'text-multiline-ellipsis')}>{job.title}</h5>
        <p className={clsx(classes.jobDescription, 'text-multiline-ellipsis')}>{job.description}</p>
      </div>
      <SpriteIcon
        name="slider-next"
        className={classes.arrow}
        size="sm"
      />
    </div>
  )
}

JobCard.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
}
JobCard.defaultProps = {
  job: {},
}
export default JobCard
