import React from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

/* components */
import ChatInput from 'components/ui/Stream/ChatBox/ChatInput'

/* helpers */
import showValidation from 'helpers/show-validation'

/* formik */
import chatFormik from './formik'

/* constants */
import { formFields } from './constants'

const ChatForm = ({
  chatId,
  sendMessage,
  isMuted,
}) => {
  const inputsConfig = {
    type: 'text',
    name: formFields.chatMessage,
    placeholder: 'Start typing',
    variant: 'secondary',
  }

  const chatForm = chatFormik({
    onSubmit: (value) => {
      if (isMuted) {
        toast.error('You\'re muted in chat by admin')
      } else {
        sendMessage({
          id: chatId,
          message: value[formFields.chatMessage],
        })
        chatForm.resetForm()
      }
    },
  })

  const showError = showValidation({
    values: chatForm.values,
    errors: chatForm.errors,
    touched: chatForm.touched,
    submitCount: chatForm.submitCount,
    field: formFields.chatMessage,
  })

  const onEmojiClick = (event, emojiObject) => {
    chatForm.setValues({
      [formFields.chatMessage]: chatForm.values[formFields.chatMessage] + emojiObject.emoji,
    })
  }

  return (
    <form onSubmit={chatForm.handleSubmit}>
      <ChatInput
        id={inputsConfig.name}
        name={inputsConfig.name}
        placeholder={inputsConfig.placeholder}
        variant={inputsConfig.variant}
        type={inputsConfig.type}
        onEmojiClick={onEmojiClick}
        onChange={chatForm.handleChange}
        onBlur={chatForm.handleBlur}
        value={chatForm.values[formFields.chatMessage]}
        error={showError ? chatForm.errors[formFields.chatMessage] : null}
        errorInput={showError ? 'errorInput' : null}
        errorLabel={showError ? 'errorLabel' : null}
        disabled={showError || chatForm.values[formFields.chatMessage].length === 0}
      />
    </form>
  )
}

ChatForm.propTypes = {
  sendMessage: PropTypes.func.isRequired,
  chatId: PropTypes.number.isRequired,
  isMuted: PropTypes.bool,
}

ChatForm.defaultProps = {
  isMuted: false,
}

export default ChatForm
