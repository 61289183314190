import React from 'react'
import PropTypes from 'prop-types'

/* components */
import SpriteIcon from 'components/icons/SpriteIcon'
import Tag from 'components/ui/Tag'

/* styles */
import './styles.scss'

/* helpers */
import renderHtml from 'helpers/render-html'
import getUrlByMediaPrefix from 'helpers/get-url-by-media-prefix'

/* routes */
import routes from 'routes'

/* state-manager */
import { history } from 'state-manager/store'
import { formatRoute } from 'react-router-named-routes'

const Sidebar = ({
  creators,
  disciplineName,
  linkHtml,
}) => {
  const renderTags = () => (
    creators.tags.map((tag, index) => (
      <Tag
        key={index}
        color="success"
        text={tag.text}
        spacing="mr-1"
      />
    ))
  )

  const getCardIcon = (site) => {
    switch (site) {
      case 'instagram':
        return site

      case 'linkedin':
        return `${site}2`

      case 'twitter':
        return `${site}2`

      default:
        return 'website'
    }
  }

  const renderLinks = () => (
    creators.links.filter((link) => link.url).map((link, index) => (
      <div
        key={index}
        className="item"
        aria-label={`Creator's ${link.title}`}
        role="button"
        onClick={() => window.open(link.url, '_blank')}>
        <SpriteIcon
          size="md"
          name={getCardIcon(link.title)}
        />
      </div>
    ))
  )

  const creatorPagePath = formatRoute(routes.creator, { slug: creators.slug })

  return (
    <aside className="Sidebar">
      <div
        role="button"
        className="Sidebar__inner"
        onClick={() => history.push(creatorPagePath)}>
        <div
          style={{
            backgroundImage: `url(${getUrlByMediaPrefix.photo(creators.photo, creators.id)})`,
          }}
          className="Sidebar-profile-avatar"
        />
        <div className="Sidebar-profile-name">
          {creators.first_name} {creators.last_name}
        </div>
        <div className="text-small">
          <span className="font-bold">{disciplineName}</span> at <span className="font-bold">{creators.company}</span>
        </div>
        <div className="place text-small">
          {`${creators.city}, ${creators.state}, ${creators.country}`}
        </div>
        <div className="Sidebar-tags">
          {renderTags()}
        </div>

        <div className="Sidebar-links mt-4">
          {renderLinks()}
        </div>

        {linkHtml && (
          <div className="Sidebar-links mt-2">
            {renderHtml(linkHtml)}
          </div>
        )}
      </div>
    </aside>
  )
}

Sidebar.propTypes = {
  disciplineName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  creators: PropTypes.object.isRequired,
  linkHtml: PropTypes.string,
}

Sidebar.defaultProps = {
  linkHtml: null,
}

export default Sidebar
