export const ENV = process.env.REACT_APP_ENV

export const URL = process.env.REACT_APP_URL

export const API_URL = process.env.REACT_APP_API_URL
export const REACT_APP_DOMAIN = process.env.REACT_APP_DOMAIN

export const VECTOR_URL = process.env.REACT_APP_VECTOR_URL

export const CONFIGS_URL = process.env.REACT_APP_CONFIGS_URL

export const LARAVEL_ECHO_HOST_URL = process.env.REACT_APP_LARAVEL_ECHO_HOST

export const LARAVEL_ECHO_CHANNEL_PREFIX = process.env.REACT_APP_CHANEL_PREFIX

export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPCTHA_SITE_KEY
